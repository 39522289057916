/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { Button, Row, Col } from "react-bootstrap";
import Shape from "../../ImageComponents/Shape";
import { MrabAnswerData } from "../../../../../Interfaces/AnswerData";
import {
  taskOneTestArray,
  taskThreeTestArray,
  taskFourTestArray,
  taskSevenTestArray,
  taskEightTestArray,
  taskNineTestArray,
  shuffle
} from "../../utils/shared";
import { isEventExpired } from "../../../../../utils/shared";
import { navigate } from "@reach/router";
import { TestEventContext } from "../../../../../Contexts/TestEventContext";
import { isMobileOnly } from "react-device-detect";

interface Props {
  isPractice: boolean;
  practiceCounter: number;
  taskId: number;
  practiceArray: any;
  toggleIncorrectAnswerDisplay(): void;
  updatePracticeCounter(): void;
  finishTask(): void;
  isMobile: boolean;
  isTablet: boolean;
  isIPad13?: boolean;
}

const BaseTaskTestDisplay = ({
  isPractice,
  practiceCounter,
  taskId,
  toggleIncorrectAnswerDisplay,
  updatePracticeCounter,
  finishTask,
  practiceArray,
  isMobile,
  isTablet,
  isIPad13
}: Props) => {
  const [testArray, setTestArray]: any = useState(null);
  const [testIndex, setTestIndex] = useState<number>(0);
  const [delay, setDelay] = useState(false);
  const [startTime, setStartTime] = useState(Date.now());

  const context = useContext(TestEventContext);

  // define which test array we are using, and store it to state
  if (testArray === null) {
    switch (taskId) {
      case 1:
        setTestArray(shuffle(taskOneTestArray));
        break;
      case 3:
        setTestArray(shuffle(taskThreeTestArray));
        break;
      case 4:
        setTestArray(shuffle(taskFourTestArray));
        break;
      case 7:
        setTestArray(shuffle(taskSevenTestArray));
        break;
      case 8:
        setTestArray(taskEightTestArray);
        break;
      case 9:
        setTestArray(shuffle(taskNineTestArray));
        break;
    }
  }

  /* ---------- Event Handler ---------- */

  const handleKeyPress = (event: KeyboardEvent) => {
    if (context && !isEventExpired(context.eventExpirationDate)) {
      if (
        ((taskId === 1 || taskId === 4 || taskId === 7) &&
          (event.key.toLowerCase() === "f" ||
            event.key.toLowerCase() === "j")) ||
        (taskId === 3 &&
          (event.key === "4" || event.key === "5" || event.key === "6") &&
          !delay) ||
        (taskId === 8 &&
          (event.key.toLowerCase() === "f" ||
            event.key.toLowerCase() === "j" ||
            event.key.toLowerCase() === "d" ||
            event.key.toLowerCase() === "k")) ||
        (taskId === 9 &&
          (event.key === "1" ||
            event.key === "2" ||
            event.key === "8" ||
            event.key === "9"))
      ) {
        setDelay(true);
        // set the start time for the just answered question, accounting for the 500 or 300 ms delay.
        if (isPractice) {
          if (
            event.key.toLowerCase() ===
            practiceArray[practiceCounter].correctAnswer.toLowerCase()
          ) {
            if (taskId !== 9) {
              setTimeout(() => {
                setDelay(false);
                updatePracticeCounter();
              }, 500);
            } else {
              const highlighted = document.getElementsByClassName("highlight");
              while (highlighted.length) {
                highlighted[0].classList.remove("highlight");
              }
              setTimeout(() => {
                updatePracticeCounter();
              }, 500);
            }
          } else {
            toggleIncorrectAnswerDisplay();
          }
        } else {
          window.removeEventListener("keyup", handleKeyPress);
          // set answers in local storage until the end of the task, at which point they are sent to redis
          const endTime: number = Date.now();
          const startTimeToSubtract =
            testIndex === 0 ? startTime : startTime + 500;
          const timeTaken: number | null =
            startTime !== null ? (endTime - startTimeToSubtract) / 1000 : null;

          //  create the appropriate answer object9
          const answerData: MrabAnswerData = {
            timeTaken,
            answer: event.key.toLowerCase(),
            stimulusId: testArray[Number(testIndex)].stimulusId
          };

          // retrieve existing answer, if any, from local storage
          const existingAnswers: any = localStorage.getItem("a");

          // create an array from existing answer, or if there none (first question) initialize an empty array
          const newAnswers =
            existingAnswers !== null ? JSON.parse(existingAnswers) : [];

          // push the new answerData to the existing answers and set to local storage
          newAnswers.push(answerData);
          localStorage.setItem("a", JSON.stringify(newAnswers));

          // if testArray is empty (old state is at length 1), the task is complete so send the answers to redis
          // and show the taskComplete display. otherwise, set a new random index and proceed to the next question
          setStartTime(Date.now());
          if (testIndex === testArray.length - 1) {
            finishTask();
          } else {
            if (taskId === 9) {
              const highlighted = document.getElementsByClassName("highlight");
              if (highlighted.length) {
                highlighted[0].classList.remove("highlight");
              }
              setTimeout(() => {
                setTestIndex(testIndex + 1);
              }, 500);
            } else {
              // add a slight pause between images
              setTimeout(() => {
                setDelay(false);
                setTestIndex(testIndex + 1);
              }, 500);
            }
          }
        }
      }
    } else {
      // if the event has expired, we need to update the error message, and
      // navigate to the overview page immediately
      context?.updateExpiredMessage();
      navigate("/overview");
    }
  };

  const handleClickEvent = (event: any) => {
    if (context && !isEventExpired(context.eventExpirationDate)) {
      if (
        ((taskId === 1 || taskId === 4 || taskId === 7) &&
          (event.currentTarget.value === "f" ||
            event.currentTarget.value === "j")) ||
        (taskId === 3 &&
          (event.currentTarget.value === "4" ||
            event.currentTarget.value === "5" ||
            event.currentTarget.value === "6") &&
          !delay) ||
        (taskId === 8 &&
          (event.currentTarget.value === "f" ||
            event.currentTarget.value === "j" ||
            event.currentTarget.value === "d" ||
            event.currentTarget.value === "k")) ||
        (taskId === 9 &&
          (event.currentTarget.value === "1" ||
            event.currentTarget.value === "2" ||
            event.currentTarget.value === "8" ||
            event.currentTarget.value === "9"))
      ) {
        setDelay(true);
        // set the start time for the just answered question, accounting for the 500 or 300 ms delay.
        if (isPractice) {
          if (
            event.currentTarget.value ===
            practiceArray[practiceCounter].correctAnswer.toLowerCase()
          ) {
            if (taskId !== 9) {
              setTimeout(() => {
                setDelay(false);
                updatePracticeCounter();
              }, 500);
            } else {
              const highlighted = document.getElementsByClassName("highlight");
              while (highlighted.length) {
                highlighted[0].classList.remove("highlight");
              }
              setTimeout(() => {
                updatePracticeCounter();
              }, 500);
            }
          } else {
            toggleIncorrectAnswerDisplay();
          }
        } else {
          window.removeEventListener("click", handleClickEvent);
          // set answers in local storage until the end of the task, at which point they are sent to redis
          const endTime: number = Date.now();
          const startTimeToSubtract =
            testIndex === 0 ? startTime : startTime + 500;
          const timeTaken: number | null =
            startTime !== null ? (endTime - startTimeToSubtract) / 1000 : null;

          //  create the appropriate answer object9
          const answerData: MrabAnswerData = {
            timeTaken,
            answer: event.currentTarget.value,
            stimulusId: testArray[Number(testIndex)].stimulusId
          };

          // retrieve existing answer, if any, from local storage
          const existingAnswers: any = localStorage.getItem("a");

          // create an array from existing answer, or if there none (first question) initialize an empty array
          const newAnswers =
            existingAnswers !== null ? JSON.parse(existingAnswers) : [];

          // push the new answerData to the existing answers and set to local storage
          newAnswers.push(answerData);
          localStorage.setItem("a", JSON.stringify(newAnswers));

          // if testArray is empty (old state is at length 1), the task is complete so send the answers to redis
          // and show the taskComplete display. otherwise, set a new random index and proceed to the next question
          setStartTime(Date.now());
          if (testIndex === testArray.length - 1) {
            finishTask();
          } else {
            if (taskId === 9) {
              const highlighted = document.getElementsByClassName("highlight");
              if (highlighted.length) {
                highlighted[0].classList.remove("highlight");
              }
              setTimeout(() => {
                setTestIndex(testIndex + 1);
              }, 500);
            } else {
              // add a slight pause between images
              setTimeout(() => {
                setDelay(false);
                setTestIndex(testIndex + 1);
              }, 500);
            }
          }
        }
      }
    } else {
      // if the event has expired, we need to update the error message, and
      // navigate to the overview page immediately
      context?.updateExpiredMessage();
      navigate("/overview");
    }
  };

  // add and remove the keyup event listener
  useEffect(() => {
    if (isMobile || isIPad13) {
      window.addEventListener("click", handleClickEvent);
      return () => {
        window.removeEventListener("click", handleClickEvent);
      };
    } else {
      window.addEventListener("keyup", handleKeyPress);
      return () => {
        window.removeEventListener("keyup", handleKeyPress);
      };
    }
  }, [practiceCounter, testIndex]);

  return (
    <React.Fragment>
      <div
        className={"task-" + taskId + "-center-screen"}
        style={
          isMobileOnly && (taskId === 7 || taskId === 8 || taskId === 9)
            ? { width: "350px", height: "250px" }
            : undefined
        }
      >
        {practiceArray.length > 0 &&
        isPractice &&
        !delay &&
        taskId !== 7 &&
        taskId !== 8 &&
        taskId !== 9 ? (
          <Shape
            shape={practiceArray[practiceCounter].shape}
            fill={practiceArray[practiceCounter].fill}
            isInstructions={false}
            isMobileOnly={isMobileOnly}
          />
        ) : null}
        {practiceArray.length > 0 && isPractice && !delay && taskId === 7 ? (
          <div
            dangerouslySetInnerHTML={{
              __html: practiceArray[practiceCounter].image
            }}
          />
        ) : null}
        {practiceArray.length > 0 && isPractice && !delay && taskId === 8 ? (
          <div className="task-eight-text-test text-center">
            {practiceArray[practiceCounter].image}
          </div>
        ) : null}
        {practiceArray.length > 0 && isPractice && taskId === 9 ? (
          <div
            dangerouslySetInnerHTML={{
              __html: practiceArray[practiceCounter].image
            }}
          />
        ) : null}
        {!isPractice &&
        testArray !== null &&
        !delay &&
        taskId !== 7 &&
        taskId !== 8 &&
        taskId !== 9 ? (
          <Shape
            shape={testArray[Number(testIndex)].shape}
            fill={testArray[Number(testIndex)].fill}
            isInstructions={false}
            isMobileOnly={isMobileOnly}
          />
        ) : null}
        {!isPractice && testArray !== null && !delay && taskId === 7 ? (
          <div
            dangerouslySetInnerHTML={{
              __html: testArray[testIndex].image
            }}
          />
        ) : null}
        {!isPractice &&
        testArray !== null &&
        !delay &&
        testArray !== null &&
        taskId === 8 ? (
          <div
            className="task-eight-text-test text-center"
            dangerouslySetInnerHTML={{
              __html: testArray[testIndex].image
            }}
          />
        ) : null}
        {!isPractice && testArray !== null && taskId === 9 ? (
          <div
            dangerouslySetInnerHTML={{
              __html: testArray[testIndex].image
            }}
          />
        ) : null}
      </div>
      {(isMobile || isIPad13) &&
      (taskId === 1 || taskId === 4 || taskId === 7) ? (
        <Row
          style={{
            backgroundColor: "#f5f5f5",
            position: "fixed",
            height: "100px",
            width: "100%",
            bottom: 0,
            marginLeft: "-16px",
            marginRight: "-16px"
          }}
        >
          <Col
            sm={6}
            xs={6}
            className="my-auto"
            style={{ paddingRight: "5px" }}
          >
            <Button
              className={
                isTablet || isIPad13
                  ? "my-1 py-1 float-right ripple"
                  : "my-1 py-1 ripple"
              }
              style={{
                backgroundColor: "#ffffff",
                width: isTablet || isIPad13 ? "50%" : "100%",
                borderColor: "#000000",
                borderRadius: "10px",
                borderWidth: "2px"
              }}
              value="f"
              onClick={handleClickEvent}
            >
              <span style={{ color: "#000000", fontSize: "40px" }}>F</span>
            </Button>
          </Col>
          <Col sm={6} xs={6} className="my-auto" style={{ paddingLeft: "5px" }}>
            <Button
              className="my-1 py-1 ripple"
              style={{
                backgroundColor: "#ffffff",
                width: isTablet || isIPad13 ? "50%" : "100%",
                borderColor: "#000000",
                borderRadius: "10px",
                borderWidth: "2px"
              }}
              value="j"
              onClick={handleClickEvent}
            >
              <span style={{ color: "#000000", fontSize: "40px" }}>J</span>
            </Button>
          </Col>
        </Row>
      ) : null}
      {(isMobile || isIPad13) && taskId === 3 ? (
        <Row
          className="justify-content-center"
          style={{
            backgroundColor: "#f5f5f5",
            position: "fixed",
            height: "100px",
            width: "100%",
            bottom: 0,
            marginLeft: "-16px",
            marginRight: "-16px"
          }}
        >
          {isTablet || isIPad13 ? (
            <Col md={6} lg={6} className="my-auto align-items-center">
              <Row>
                <Col md={4} lg={4} className="pr-0">
                  <Button
                    className="my-1 py-1 ripple"
                    style={{
                      backgroundColor: "#ffffff",
                      width: isTablet || isIPad13 ? "100%" : "100%",
                      borderColor: "#000000",
                      borderRadius: "10px",
                      borderWidth: "2px"
                    }}
                    value="4"
                    onClick={handleClickEvent}
                  >
                    <span style={{ color: "#000000", fontSize: "40px" }}>
                      4
                    </span>
                  </Button>
                </Col>
                <Col md={4} lg={4} className="pr-0">
                  <Button
                    className="my-1 py-1 ripple"
                    style={{
                      backgroundColor: "#ffffff",
                      width: isTablet || isIPad13 ? "100%" : "100%",
                      borderColor: "#000000",
                      borderRadius: "10px",
                      borderWidth: "2px"
                    }}
                    value="5"
                    onClick={handleClickEvent}
                  >
                    <span style={{ color: "#000000", fontSize: "40px" }}>
                      5
                    </span>
                  </Button>
                </Col>
                <Col md={4} lg={4} className="pr-0">
                  <Button
                    className="my-1 py-1 ripple"
                    style={{
                      backgroundColor: "#ffffff",
                      width: isTablet || isIPad13 ? "100%" : "100%",
                      borderColor: "#000000",
                      borderRadius: "10px",
                      borderWidth: "2px"
                    }}
                    value="6"
                    onClick={handleClickEvent}
                  >
                    <span style={{ color: "#000000", fontSize: "40px" }}>
                      6
                    </span>
                  </Button>
                </Col>
              </Row>
            </Col>
          ) : (
            <React.Fragment>
              <Col
                sm={4}
                xs={4}
                className="my-auto"
                style={{ padding: "0px 5px" }}
              >
                <Button
                  className="my-1 py-1 ripple"
                  style={{
                    backgroundColor: "#ffffff",
                    width: "100%",
                    borderColor: "#000000",
                    borderRadius: "10px",
                    borderWidth: "2px"
                  }}
                  value="4"
                  onClick={handleClickEvent}
                >
                  <span style={{ color: "#000000", fontSize: "40px" }}>4</span>
                </Button>
              </Col>
              <Col
                sm={4}
                xs={4}
                className="my-auto"
                style={{ padding: "0px 5px" }}
              >
                <Button
                  className="my-1 py-1 ripple"
                  style={{
                    backgroundColor: "#ffffff",
                    width: "100%",
                    borderColor: "#000000",
                    borderRadius: "10px",
                    borderWidth: "2px"
                  }}
                  value="5"
                  onClick={handleClickEvent}
                >
                  <span style={{ color: "#000000", fontSize: "40px" }}>5</span>
                </Button>
              </Col>
              <Col
                sm={4}
                xs={4}
                className="my-auto"
                style={{ padding: "0px 5px" }}
              >
                <Button
                  className="my-1 py-1 ripple"
                  style={{
                    backgroundColor: "#ffffff",
                    width: "100%",
                    borderColor: "#000000",
                    borderRadius: "10px",
                    borderWidth: "2px"
                  }}
                  value="6"
                  onClick={handleClickEvent}
                >
                  <span style={{ color: "#000000", fontSize: "40px" }}>6</span>
                </Button>
              </Col>
            </React.Fragment>
          )}
        </Row>
      ) : null}
      {(isMobile || isIPad13) && taskId === 9 ? (
        <Row
          className="justify-content-center"
          style={{
            backgroundColor: "#f5f5f5",
            position: "fixed",
            height: "100px",
            width: "100%",
            bottom: 0,
            marginLeft: "-16px",
            marginRight: "-16px"
          }}
        >
          {isTablet || isIPad13 ? (
            <Col md={6} lg={6} className="my-auto align-items-center">
              <Row>
                <Col sm={3} xs={3} className="my-auto px-1">
                  <Button
                    className="my-1 py-1 ripple"
                    style={{
                      backgroundColor: "#ffffff",
                      width: "100%",
                      borderColor: "#000000",
                      borderRadius: "10px",
                      borderWidth: "2px"
                    }}
                    value="1"
                    onClick={handleClickEvent}
                  >
                    <span style={{ color: "#000000", fontSize: "40px" }}>
                      1
                    </span>
                  </Button>
                </Col>
                <Col sm={3} xs={3} className="my-auto px-1">
                  <Button
                    className="my-1 py-1 ripple"
                    style={{
                      backgroundColor: "#ffffff",
                      width: "100%",
                      borderColor: "#000000",
                      borderRadius: "10px",
                      borderWidth: "2px"
                    }}
                    value="2"
                    onClick={handleClickEvent}
                  >
                    <span style={{ color: "#000000", fontSize: "40px" }}>
                      2
                    </span>
                  </Button>
                </Col>
                <Col sm={3} xs={3} className="my-auto px-1">
                  <Button
                    className="my-1 py-1 ripple"
                    style={{
                      backgroundColor: "#ffffff",
                      width: "100%",
                      borderColor: "#000000",
                      borderRadius: "10px",
                      borderWidth: "2px"
                    }}
                    value="8"
                    onClick={handleClickEvent}
                  >
                    <span style={{ color: "#000000", fontSize: "40px" }}>
                      8
                    </span>
                  </Button>
                </Col>
                <Col sm={3} xs={3} className="my-auto px-1">
                  <Button
                    className="my-1 py-1 ripple"
                    style={{
                      backgroundColor: "#ffffff",
                      width: "100%",
                      borderColor: "#000000",
                      borderRadius: "10px",
                      borderWidth: "2px"
                    }}
                    value="9"
                    onClick={handleClickEvent}
                  >
                    <span style={{ color: "#000000", fontSize: "40px" }}>
                      9
                    </span>
                  </Button>
                </Col>
              </Row>
            </Col>
          ) : (
            <React.Fragment>
              <Col
                sm={3}
                xs={3}
                className="my-auto"
                style={{ paddingRight: "5px" }}
              >
                <Button
                  className="my-1 py-1 ripple"
                  style={{
                    backgroundColor: "#ffffff",
                    width: "100%",
                    borderColor: "#000000",
                    borderRadius: "10px",
                    borderWidth: "2px"
                  }}
                  value="1"
                  onClick={handleClickEvent}
                >
                  <span style={{ color: "#000000", fontSize: "40px" }}>1</span>
                </Button>
              </Col>
              <Col
                sm={3}
                xs={3}
                className="my-auto"
                style={{ paddingLeft: "5px", paddingRight: "10px" }}
              >
                <Button
                  className="my-1 py-1 ripple"
                  style={{
                    backgroundColor: "#ffffff",
                    width: "100%",
                    borderColor: "#000000",
                    borderRadius: "10px",
                    borderWidth: "2px"
                  }}
                  value="2"
                  onClick={handleClickEvent}
                >
                  <span style={{ color: "#000000", fontSize: "40px" }}>2</span>
                </Button>
              </Col>
              <Col
                sm={3}
                xs={3}
                className="my-auto"
                style={{ paddingLeft: "10px", paddingRight: "5px" }}
              >
                <Button
                  className="my-1 py-1 ripple"
                  style={{
                    backgroundColor: "#ffffff",
                    width: "100%",
                    borderColor: "#000000",
                    borderRadius: "10px",
                    borderWidth: "2px"
                  }}
                  value="8"
                  onClick={handleClickEvent}
                >
                  <span style={{ color: "#000000", fontSize: "40px" }}>8</span>
                </Button>
              </Col>
              <Col
                sm={3}
                xs={3}
                className="my-auto"
                style={{ paddingLeft: "5px" }}
              >
                <Button
                  className="my-1 py-1 ripple"
                  style={{
                    backgroundColor: "#ffffff",
                    width: "100%",
                    borderColor: "#000000",
                    borderRadius: "10px",
                    borderWidth: "2px"
                  }}
                  value="9"
                  onClick={handleClickEvent}
                >
                  <span style={{ color: "#000000", fontSize: "40px" }}>9</span>
                </Button>
              </Col>
            </React.Fragment>
          )}
        </Row>
      ) : null}
      {(isMobile || isIPad13) && taskId === 8 ? (
        <Row
          className="justify-content-center"
          style={{
            backgroundColor: "#f5f5f5",
            position: "fixed",
            height: "100px",
            width: "100%",
            bottom: 0,
            marginLeft: "-16px",
            marginRight: "-16px"
          }}
        >
          {isTablet || isIPad13 ? (
            <Col md={6} lg={6} className="my-auto align-items-center px-1">
              <Row>
                <Col sm={3} xs={3} className="my-auto px-1">
                  <Button
                    className="my-1 py-1 ripple"
                    style={{
                      backgroundColor: "#ffffff",
                      width: "100%",
                      borderColor: "#000000",
                      borderRadius: "10px",
                      borderWidth: "2px"
                    }}
                    value="d"
                    onClick={handleClickEvent}
                  >
                    <span style={{ color: "#000000", fontSize: "40px" }}>
                      D
                    </span>
                  </Button>
                </Col>
                <Col sm={3} xs={3} className="my-auto px-1">
                  <Button
                    className="my-1 py-1 ripple"
                    style={{
                      backgroundColor: "#ffffff",
                      width: "100%",
                      borderColor: "#000000",
                      borderRadius: "10px",
                      borderWidth: "2px"
                    }}
                    value="f"
                    onClick={handleClickEvent}
                  >
                    <span style={{ color: "#000000", fontSize: "40px" }}>
                      F
                    </span>
                  </Button>
                </Col>
                <Col sm={3} xs={3} className="my-auto px-1">
                  <Button
                    className="my-1 py-1 ripple"
                    style={{
                      backgroundColor: "#ffffff",
                      width: "100%",
                      borderColor: "#000000",
                      borderRadius: "10px",
                      borderWidth: "2px"
                    }}
                    value="j"
                    onClick={handleClickEvent}
                  >
                    <span style={{ color: "#000000", fontSize: "40px" }}>
                      J
                    </span>
                  </Button>
                </Col>
                <Col sm={3} xs={3} className="my-auto px-1">
                  <Button
                    className="my-1 py-1 ripple"
                    style={{
                      backgroundColor: "#ffffff",
                      width: "100%",
                      borderColor: "#000000",
                      borderRadius: "10px",
                      borderWidth: "2px"
                    }}
                    value="k"
                    onClick={handleClickEvent}
                  >
                    <span style={{ color: "#000000", fontSize: "40px" }}>
                      K
                    </span>
                  </Button>
                </Col>
              </Row>
            </Col>
          ) : (
            <React.Fragment>
              <Col
                sm={3}
                xs={3}
                className="my-auto"
                style={{ paddingRight: "5px" }}
              >
                <Button
                  className="my-1 py-1 ripple"
                  style={{
                    backgroundColor: "#ffffff",
                    width: "100%",
                    borderColor: "#000000",
                    borderRadius: "10px",
                    borderWidth: "2px"
                  }}
                  value="d"
                  onClick={handleClickEvent}
                >
                  <span style={{ color: "#000000", fontSize: "40px" }}>D</span>
                </Button>
              </Col>
              <Col
                sm={3}
                xs={3}
                className="my-auto"
                style={{ paddingLeft: "5px", paddingRight: "10px" }}
              >
                <Button
                  className="my-1 py-1 ripple"
                  style={{
                    backgroundColor: "#ffffff",
                    width: "100%",
                    borderColor: "#000000",
                    borderRadius: "10px",
                    borderWidth: "2px"
                  }}
                  value="f"
                  onClick={handleClickEvent}
                >
                  <span style={{ color: "#000000", fontSize: "40px" }}>F</span>
                </Button>
              </Col>
              <Col
                sm={3}
                xs={3}
                className="my-auto"
                style={{ paddingLeft: "10px", paddingRight: "5px" }}
              >
                <Button
                  className="my-1 py-1 ripple"
                  style={{
                    backgroundColor: "#ffffff",
                    width: "100%",
                    borderColor: "#000000",
                    borderRadius: "10px",
                    borderWidth: "2px"
                  }}
                  value="j"
                  onClick={handleClickEvent}
                >
                  <span style={{ color: "#000000", fontSize: "40px" }}>J</span>
                </Button>
              </Col>
              <Col
                sm={3}
                xs={3}
                className="my-auto"
                style={{ paddingLeft: "5px" }}
              >
                <Button
                  className="my-1 py-1 ripple"
                  style={{
                    backgroundColor: "#ffffff",
                    width: "100%",
                    borderColor: "#000000",
                    borderRadius: "10px",
                    borderWidth: "2px"
                  }}
                  value="k"
                  onClick={handleClickEvent}
                >
                  <span style={{ color: "#000000", fontSize: "40px" }}>K</span>
                </Button>
              </Col>
            </React.Fragment>
          )}
        </Row>
      ) : null}
    </React.Fragment>
  );
};

export default BaseTaskTestDisplay;
