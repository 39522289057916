import React, { useEffect } from "react";
import { Alert, Button } from "react-bootstrap";
import { mrabCastTaskButtons } from "./utils/shared";

interface Props {
  task: string;
  language: string;
  testId: string;
  taskDataSaved: boolean;
  enableButton: boolean;
  proceedToNextTask(): void;
}

const TaskComplete = ({
  task,
  proceedToNextTask,
  language,
  testId,
  taskDataSaved,
  enableButton
}: Props) => {
  // if the final task has been completed, just call proceedToNextTask, which will end the subTest
  useEffect(() => {
    if (task === "9" && taskDataSaved) {
      proceedToNextTask();
    }
  }, [proceedToNextTask, task, taskDataSaved]);

  let taskCompleteString = null;
  taskCompleteString =
    task !== "9" ? `${mrabCastTaskButtons[language][task]}` : null;
  if (
    taskCompleteString === null &&
    task === "9" &&
    (testId === "13" || testId === "18")
  ) {
    taskCompleteString = `${mrabCastTaskButtons[language].done} CAST`;
  }
  if (
    taskCompleteString === null &&
    task === "9" &&
    (testId === "10" || testId === "12")
  ) {
    taskCompleteString = `${mrabCastTaskButtons[language].done} MRAB`;
  }
  return (
    <div>
      <Alert variant="success" className="text-center">
        <h4>{taskCompleteString}</h4>
      </Alert>
      <div className="text-center" style={{ marginTop: "100px" }}>
        {task !== "9" ? (
          <Button
            variant="primary"
            className="instructions-button"
            onClick={proceedToNextTask}
            disabled={!enableButton}
          >
            {mrabCastTaskButtons[language].continue}
          </Button>
        ) : (
          <p>{mrabCastTaskButtons[language].thanks}</p>
        )}
      </div>
    </div>
  );
};

export default TaskComplete;
