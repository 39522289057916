import React, { useCallback, useLayoutEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  Table,
  Button,
  Modal,
  Dropdown,
  Form,
  Collapse
} from "react-bootstrap";
import { navigate } from "@reach/router";
import queryString from "query-string";
import { apiURL } from "../utils/constants";
import rightArrow from "../Resources/images/arrow-right.png";
import { eventDebugBreakpoints, getEventDebugHeader } from "./helpers";
import CopyableText from "../Components/CopyableText";

interface Props {
  path: string;
}

interface PendingEvent {
  companyName: string;
  eventId: string;
  dateEventStarted: string;
  lockedOut: 0 | 1;
}

interface ResetReason {
  resetReasonId: number;
  reason: string;
}

const EventIdDebug: React.FC<Props> = () => {
  const token = queryString.parse(window.location.search).token;
  const [unlockedEventId, setUnlockedEventId] = useState<null | string>(null);
  const [fetchingPendingEvents, setFetchingPendingEvents] = useState(false);
  const [pendingEventsFetched, setPendingEventsFetched] = useState(false);
  const [invalidToken, setInvalidToken] = useState(false);
  const [pendingEvents, setPendingEvents] = useState<PendingEvent[]>([]);
  const [unlockingEventError, setUnlockingEventError] = useState(false);
  const [unlockingEventLoading, setUnlockingEventLoading] = useState(false);
  const [showReasonModal, setShowReasonModal] = useState<false | string>(false);
  const [selectedReasonForReset, setSelectedReasonForReset] = useState<
    null | number
  >(null);
  const [reasonText, setReasonText] = useState("");
  const [savedReasons, setSavedReasons] = useState<{ [id: number]: string }>(
    {}
  );

  // ------------------ API Fetches ------------------
  const saveResetReasons = useCallback((payload: ResetReason[]) => {
    const savedReasons: {
      [resetReasonId: number]: string;
    } = {};
    payload.forEach(reason => {
      savedReasons[reason.resetReasonId] = reason.reason;
    });
    setSavedReasons(savedReasons);
  }, []);

  const getPendingEvents = useCallback(async () => {
    if (typeof token !== "string") {
      return;
    }
    // We set the loading state for the pending events to true
    setFetchingPendingEvents(true);

    try {
      const response = await fetch(`${apiURL}/getEvents`, {
        method: "POST",
        body: JSON.stringify({ token })
      });

      // If we get a 400 error then we set the invalid token state to true and set the loading state to false
      if (response.status === 400) {
        setInvalidToken(true);
        setFetchingPendingEvents(false);
        return;
      }

      // If a successful API call then save the results in state
      const data = (await response.json()) as {
        pendingResults: PendingEvent[];
        resetReasons: ResetReason[];
      };

      saveResetReasons(data?.resetReasons);
      setPendingEvents(data?.pendingResults ?? []);
    } catch (error) {
      console.error(error);
    }
    // We set the loading state for the pending events to false
    setFetchingPendingEvents(false);
  }, [token, saveResetReasons]);

  // ------------------ Effects ------------------
  useLayoutEffect(() => {
    if (!pendingEventsFetched) {
      // fetch pending events and set the state of pending events fetched to true, so that we don't fetch again
      getPendingEvents();
      setPendingEventsFetched(true);
    }
  }, [pendingEventsFetched, getPendingEvents]);

  // ------------------ Handlers ------------------
  const onUnlockEvent = async (eventId: string) => {
    if (typeof token !== "string") {
      return;
    }
    // We set the loading state for the pending events to true
    setUnlockingEventLoading(true);
    // And reset the value of invalid token to false
    setInvalidToken(false);
    try {
      // We will also send back the reason and the reason text(if any) to this endpoint when unlocking the event
      const response = await fetch(`${apiURL}/unlockEvent`, {
        method: "POST",
        body: JSON.stringify({
          eventId,
          resetReasonId: selectedReasonForReset,
          reason: reasonText
        }),
        headers: {
          Authorization: token
        }
      });

      if (response.status === 400) {
        setInvalidToken(true);
        setUnlockingEventLoading(false);
        setSelectedReasonForReset(null);
        setShowReasonModal(false);
        return;
      }

      await response.json();
      setUnlockedEventId(eventId);
      setPendingEvents(currPendingEvents => {
        return currPendingEvents?.map(event => {
          if (event.eventId === eventId) {
            return {
              ...event,
              lockedOut: 0
            };
          }
          return event;
        });
      });
    } catch (error) {
      setUnlockingEventError(true);
    }
    // If there was an error or if it was successful regardless we set the loading state to false and hide the modal.
    setSelectedReasonForReset(null);
    setShowReasonModal(false);
  };

  // ------------------ Getter functions ------------------
  const getCardBody = () => {
    if (fetchingPendingEvents) {
      return <div>Fetching pending events...</div>;
    }
    if (pendingEvents.length === 0) {
      return invalidToken ? (
        <div>
          <p>
            Something went wrong, please reach out to the support team for more
            help.
          </p>
        </div>
      ) : (
        <div>No pending events</div>
      );
    }

    return (
      <>
        <Row>
          <Col>
            <p>Thanks! Your most recent assessments events are listed below.</p>
            <p>Lost your Event ID? Copy and paste from below.</p>
            <p>
              Locked out of your assessment? Click "Reset" in the status column
              below.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Company</th>
                  <th>Event ID</th>
                  <th>Date Started</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {pendingEvents?.map(row => (
                  <tr key={row.eventId}>
                    <td>{row.companyName}</td>
                    <td>
                      <CopyableText text={row.eventId} />
                    </td>
                    <td>{row.dateEventStarted}</td>
                    <td>
                      {row.lockedOut && !invalidToken ? (
                        <Button
                          variant="link"
                          onClick={() => setShowReasonModal(row.eventId)}
                          style={{
                            cursor: unlockingEventLoading
                              ? "progress"
                              : "pointer"
                          }}
                        >
                          Locked (Click to Reset)
                        </Button>
                      ) : (
                        <Button
                          variant="link"
                          onClick={() =>
                            navigate(`/verify/index/${row.eventId}`)
                          }
                        >
                          Go to Assessment
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        {unlockedEventId !== null && !invalidToken ? (
          <Row className="justify-content-center">
            <Button
              onClick={() => {
                navigate(`/verify/index/${unlockedEventId}`);
              }}
            >
              <div className="d-flex" style={{ gap: "10px" }}>
                Proceed to Assessment
                <img
                  src={rightArrow}
                  alt="right arrow"
                  style={{
                    width: "16px"
                  }}
                  id="rightArrow"
                />
              </div>
            </Button>
          </Row>
        ) : null}
        {unlockingEventError ? (
          <Row className="justify-content-center">
            <p>
              Sorry you are not able to unlock the event, please reach out to
              CSM.
            </p>
          </Row>
        ) : null}
        {invalidToken ? (
          <div>
            <p style={{ color: "red" }}>
              You have exceeded the number of resets allowed using this tool.
              Please reach out to our Support team by selecting the chatbot
              located at the bottom right-hand corner of the page for further
              assistance.
            </p>
          </div>
        ) : null}
      </>
    );
  };

  const onCloseModal = () => {
    setShowReasonModal(false);
    setSelectedReasonForReset(null);
    setReasonText("");
  };

  const handleModalDropdownClick = (e: string | null) => {
    setSelectedReasonForReset(e ? parseInt(e) : null);
  };

  const isModalSubmitDisabled = () => {
    // If we are loading then we disable the button
    if (unlockingEventLoading) {
      return true;
    }

    // If we don't have a reason selected then we disable the button
    if (selectedReasonForReset === null) {
      return true;
    }

    // If we have a reason selected and the reason is other but we don't have any text then we disable the button
    if (
      savedReasons[selectedReasonForReset]?.includes("Other") &&
      reasonText === ""
    ) {
      return true;
    }

    // If we have a reason selected and the reason is other and we have text then we enable the button
    return false;
  };

  const getModal = () => {
    const isButtonDisabled = selectedReasonForReset === null;

    return (
      <Modal
        show={!!showReasonModal}
        onHide={() => {
          onCloseModal();
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Why are you resetting the event?</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "20px"
          }}
        >
          <Dropdown onSelect={handleModalDropdownClick}>
            <Dropdown.Toggle
              variant="light"
              className="w-100 d-flex align-items-center justify-content-center"
            >
              <p className="mb-0">
                {(selectedReasonForReset !== null &&
                  savedReasons[selectedReasonForReset]) ||
                  "Select a reason"}
              </p>
            </Dropdown.Toggle>
            <Dropdown.Menu className="w-100">
              {Object.entries(savedReasons).map(([optionId, title]) => (
                <Dropdown.Item key={optionId} eventKey={optionId}>
                  {title}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <Collapse in={!isButtonDisabled}>
            <Form.Group>
              <Form.Label htmlFor={`reasonText`}>
                Please provide more details:{" "}
              </Form.Label>
              <Form.Control
                id={`reasonText`}
                onChange={e => {
                  setReasonText(e.target.value);
                }}
                as="textarea"
                value={reasonText}
                rows={3}
                maxLength={255}
              />
            </Form.Group>
          </Collapse>
          <Button
            onClick={() =>
              typeof showReasonModal === "string" &&
              onUnlockEvent(showReasonModal)
            }
            style={{
              cursor: unlockingEventLoading ? "progress" : "pointer"
            }}
            disabled={isModalSubmitDisabled()}
          >
            Unlock Event
          </Button>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <Container>
      {getModal()}
      <Row>
        <Col {...eventDebugBreakpoints}>
          <Card className="oda-card">
            {getEventDebugHeader()}
            <Card.Body>{getCardBody()}</Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default EventIdDebug;
