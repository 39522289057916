import Language from "../../Interfaces/Language";

export const tr: Language = {
  common: {
    help: "Yardıma mı ihtiyacınız var?",
    helpCenter: "Yardım Merkezi",
    helpCenterLink: "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/",
    expect: "Bu testlerden ne beklenmelidir",
    expectLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/collections/3396642-about-criteria-assessments",
    faq: "Sıkça Sorulan Sorular",
    faqLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7144514-sikca-sorulan-sorular",
    copyright: "Telif Hakkı",
    copyrightNotices: "Telif Hakkı Bildirimleri",
    copyrightNoticesLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6815627-telif-hakki-bildirimi",
    terms: "Kullanım Şartları",
    termsLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6972002-kullanim-hukum-ve-kosullari",
    privacy: "Gizlilik Bildirimi",
    privacyLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6971995-gizlilik-politikasi",
    dmca: "DMCA Politikası",
    dmcaLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7857155-dijital-milenyum-telif-hakki-yasasi-politikasi",
    accommodations: "Engellilik / Erişilebilirlik",
    accommodationsLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6971993-engellilik-erisilebilirlik",
    technicalSupport: "Teknik Destek",
    chatSupport:
      "Destek için lütfen bu sayfanın sağ alt köşesinden ekibimizle sohbet edin"
  },
  instruction: {
    eppCopyright:
      "This assessment includes material reproduced from SalesAP ©1995 and CSAP ©2002, Multi-Health Systems Inc. All rights reserved.",
    salesApCopyright:
      "SalesAP ©1995, Multi-Health Systems Inc. All rights reserved.",
    csapCopyright: "CSAP ©2002, Multi-Health Systems Inc. All rights reserved.",
    wtmaCopyright:
      "WTMA Copyright © 1997, 1999 Psychological Assessment Resources, Inc."
  }
};
