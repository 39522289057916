import React from "react";
import leftArrow from "../GameResources/arrow_left@2x.png";
import rightArrow from "../GameResources/arrow_right@2x.png";

interface Props {
  leftImage: string;
  rightImage: string;
  isAnswerCorrect?: boolean | null; // needed in the Tutorial session
}
const RobotImages = ({ leftImage, rightImage, isAnswerCorrect }: Props) => {
  return (
    <React.Fragment>
      <td id="leftRobot" className="robot">
        {isAnswerCorrect !== null && isAnswerCorrect !== undefined ? (
          <img
            src={leftArrow}
            alt="left arrow"
            className="feedbackArrow bounce-left"
            id="leftArrow"
          />
        ) : null}
        <img src={leftImage} alt="left robot" />
      </td>
      <td id="rightRobot" className="robot ">
        <img src={rightImage} alt="right robot" />
        {isAnswerCorrect !== null && isAnswerCorrect !== undefined ? (
          <img
            src={rightArrow}
            alt="right arrow"
            className="feedbackArrow bounce-right"
            id="rightArrow"
          />
        ) : null}
      </td>
    </React.Fragment>
  );
};

export default RobotImages;
