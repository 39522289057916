import React, { FunctionComponent } from "react";
import useResume from "../../CustomHooks/useResume";
import { EventDataWithTestProps } from "../../Interfaces/EventData";
import CLIKTestV2 from "../../Tests/TestDisplays/CLIKTestV2/CLIKTestV2";
import { getCLIKStartingQuestion } from "../../utils/redisHelpers";
import NetworkError from "../utilty/error/NetworkError";
import Loader from "../utilty/loader/Loader";

const CLIK_SIMULATION_SECTIONS = 3;

const CLIK_SIMULATION_TIME_ALLOWED = 180;

const Clik: FunctionComponent<
  EventDataWithTestProps & {
    resumeMethod: ReturnType<typeof getCLIKStartingQuestion>;
    returnUrl: string;
  }
> = ({ resumeMethod: method, returnUrl, ...props }) => {
  const { data, error } = useResume({ method });
  const totalSimulationTime =
    CLIK_SIMULATION_TIME_ALLOWED * props.timeFactor * CLIK_SIMULATION_SECTIONS;

  if (error) {
    return <NetworkError />;
  }

  if (!data) {
    return <Loader />;
  }

  if (data.startingQuestion === props.testData.questions.length) {
    window.location.replace(returnUrl);
    return <Loader />;
  }

  return (
    <CLIKTestV2
      {...props}
      {...data}
      handleVisibilityChange={() => {}}
      timeRemaining={
        props.timeAllowed - totalSimulationTime - Number(data.expiredTime)
      }
      originalSimulationTimeAllowed={CLIK_SIMULATION_TIME_ALLOWED}
    />
  );
};

export default Clik;
