import React, { useEffect, useState } from "react";
import { navigate } from "@reach/router";
import { Container, Row, Col, Card, Image } from "react-bootstrap";
import queryString from "query-string";
import JwtDecode from "jwt-decode";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageContinueButton from "../../Components/PageContinueButton";
import linkResultsImage from "../../Resources/images/link-results.png";
import noResultsImage from "../../Resources/images/no-results.png";
import downArrowIcon from "../../Resources/images/down-arrow.png";
import { fetchLinkResults, doLinkResults } from "../helpers";

export const LinkResultsCheck = (props: any) => {
  /****************************************************************************
   * State Variables                                                          *
   ****************************************************************************/
  const [hasResults, setHasResults] = useState(false);
  const [token, setToken] = useState("");
  const [originalEmail, setOriginalEmail] = useState("");
  const [destinationEmail, setDestinationEmail] = useState("");

  /****************************************************************************
   * Styles                                                                  *
   ****************************************************************************/
  const emailContainer: any = {
    backgroundColor: "#eceef9",
    height: "100%",
    minHeight: "40px",
    padding: "8px 15px 8px 16px",
    borderRadius: "20px",
    fontWeight: "bold"
  };

  /****************************************************************************
   * Handlers                                                                 *
   ****************************************************************************/
  const handleLoginButton = () => {
    navigate("https://hireselect.criteriacorp.com");
  };

  const handleConnectResults = () => {
    doLinkResults(token).then(response => {
      console.log("response: ", response);

      if (response.userAccountId) {
        navigate(
          `link-results-success?uid=${response.userAccountId}&setup=${response.setupAccount}`
        );
      }
    });
  };

  /****************************************************************************
   * useEffects                                                               *
   ****************************************************************************/
  useEffect(() => {
    const token: any = queryString.parse(props.location.search).token;
    setToken(token);
    const decodedToken: any = JwtDecode(token);

    if (decodedToken) {
      setOriginalEmail(decodedToken.emailToSearch);
      setDestinationEmail(decodedToken.primaryEmail);
    }

    fetchLinkResults(token).then(response => {
      setHasResults(response);
    });
  }, [props.location.search]);
  return (
    <Container>
      <Row>
        <Col
          xl={{
            span: 8,
            offset: 2
          }}
          lg={{
            span: 8,
            offset: 2
          }}
          md={{
            span: 10,
            offset: 1
          }}
          sm={12}
        >
          <Card className="oda-card">
            <Header backgroundColor="#FFF" />
            <Card.Body style={{ paddingLeft: "60px", paddingRight: "60px" }}>
              <Row>
                <Col>
                  <div className="text-center mt-3 mb-5">
                    <Image
                      fluid={true}
                      src={hasResults ? linkResultsImage : noResultsImage}
                      alt="Insights"
                      height="160px"
                      width="160px"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-center link-results-header mb-3">
                    {hasResults
                      ? `Link Previous Results`
                      : `No Results Available`}
                  </div>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  {hasResults ? (
                    <p className="text-center mt-3 link-results-text">
                      {`You have personality test results that are available to be
                      linked to your Criteria profile. Click below to link the
                      results associated with ${originalEmail} with ${destinationEmail}`}
                      .
                    </p>
                  ) : (
                    <p className="text-center mt-3 link-results-text">
                      You do not have any personality test results available.
                      You can take the Criteria personality assessment by
                      clicking on the invitation in your email or by logging
                      into your Criteria account.
                    </p>
                  )}
                </Col>
              </Row>
              {hasResults ? (
                <>
                  <Row className="mb-3">
                    <Col
                      lg={{
                        span: 8,
                        offset: 2
                      }}
                      md={{
                        span: 8,
                        offset: 2
                      }}
                      sm={{
                        span: 6,
                        offset: 3
                      }}
                    >
                      <div style={emailContainer} className="text-center">
                        {originalEmail}
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3 text-center">
                    <Col
                      lg={{
                        span: 6,
                        offset: 3
                      }}
                      md={{
                        span: 6,
                        offset: 3
                      }}
                      sm={{
                        span: 6,
                        offset: 3
                      }}
                    >
                      <img
                        src={downArrowIcon}
                        alt="down arrow"
                        height="32px"
                        width="32px"
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col
                      lg={{
                        span: 8,
                        offset: 2
                      }}
                      md={{
                        span: 8,
                        offset: 2
                      }}
                      sm={{
                        span: 6,
                        offset: 3
                      }}
                    >
                      <div style={emailContainer} className="text-center">
                        {destinationEmail}
                      </div>
                    </Col>
                  </Row>
                </>
              ) : null}
              <Row className="mt-5">
                <Col>
                  <PageContinueButton
                    handler={
                      hasResults ? handleConnectResults : handleLoginButton
                    }
                    buttonText={
                      hasResults ? "Connect my results" : "Login to Criteria"
                    }
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Footer />
        </Col>
      </Row>
    </Container>
  );
};
