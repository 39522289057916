import React, { Component } from "react";
import { Form } from "react-bootstrap";
import ApplicationQuestions from "../../Interfaces/ApplicationQuestions";

interface Props {
  question: ApplicationQuestions;
  onChangeHandler: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  missingQuestions: string[];
}

interface State {
  textAreaValue: string;
}

export default class ApplicationEssay extends Component<Props, State> {
  state = {
    textAreaValue: ""
  };

  handleTextArea = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    this.setState({ textAreaValue: event.target.value });
    this.props.onChangeHandler(event);
  };
  render() {
    const { missingQuestions } = this.props;
    const { applicationQuestionId, label, required } = this.props.question;

    // if the question was required, and the application was submitted without an answer, change the
    // background color as an indication.
    const backgroundColor = missingQuestions.includes(
      `q_${applicationQuestionId}`
    )
      ? "#F7CECD"
      : "";

    return (
      <Form.Group>
        <Form.Label
          style={{ backgroundColor }}
          htmlFor={`${applicationQuestionId}`}
        >
          {required ? (
            <>
              <i aria-hidden="true">*</i> {label}
              <span className="sr-only">Required</span>
            </>
          ) : (
            label
          )}
        </Form.Label>
        <Form.Control
          as="textarea"
          name={`q_${applicationQuestionId}`}
          id={`q_${applicationQuestionId}`}
          value={this.state.textAreaValue}
          onChange={this.handleTextArea}
          rows={5}
          cols={105}
        />
      </Form.Group>
    );
  }
}
