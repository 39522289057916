import React, { useContext, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Redirect } from "@reach/router";
import warningIcon from "../Resources/images/warning.png";
import Header from "../Components/Header";
import PageContinueButton from "../Components/PageContinueButton";
import { TestEventContext } from "../Contexts/TestEventContext";
import LandingPageStyle from "../Interfaces/LandingPageStyle";

interface Props {
  continueButtonHandler: () => void;
  landingPageStyle: LandingPageStyle;
  handleUpdateComponentShowing(componentName: string): void;
}

const BrowserWarning = ({
  landingPageStyle,
  continueButtonHandler,
  handleUpdateComponentShowing
}: Props) => {
  const context = useContext(TestEventContext);

  useEffect(() => {
    handleUpdateComponentShowing("Browser Warning");
  }, [handleUpdateComponentShowing]);

  if (context) {
    return (
      <div>
        <Container>
          <Card className="oda-card">
            <Header
              onWelcomePage={false}
              backgroundColor={landingPageStyle.backgroundColor}
            />
            <Card.Body
              style={{
                backgroundColor: landingPageStyle.backgroundColor,
                color: landingPageStyle.textColor
              }}
            >
              <Row className="text-center">
                <Col>
                  <img
                    src={warningIcon}
                    height="48px"
                    className="text-center"
                    alt="warning icon"
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col className="text-center">
                  {context && context.testEventData.translatedText ? (
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          context.testEventData.translatedText.browserHeading
                      }}
                    />
                  ) : (
                    <strong>Incompatible Browser</strong>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  {context && context.testEventData.translatedText ? (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: context.testEventData.translatedText.browserNote
                      }}
                    />
                  ) : (
                    <React.Fragment>
                      <p>
                        We recommend using Chrome, FireFox, or Safari if
                        possible to complete this test event.
                      </p>
                    </React.Fragment>
                  )}
                </Col>
              </Row>
              <PageContinueButton handler={continueButtonHandler} />
            </Card.Body>
          </Card>
        </Container>
      </div>
    );
  } else {
    // If the user has arrived here without any way of fetching the testEventData, redirect back to the
    // eventIdForm
    return <Redirect from="verify" to="/" noThrow={true} />;
  }
};

export default BrowserWarning;
