import React from "react";
import TaskOneInstructions from "./Instructions/TaskOneInstructions";
import VigilanceInstructions from "./Instructions/VigilanceInstructions";
import TaskThreeInstructions from "./Instructions/TaskThreeInstructions";
import TaskFourInstructions from "./Instructions/TaskFourInstructions";
import VerbalMemoryInstructions from "./Instructions/VerbalMemoryInstructions";
import SpatialMemoryInstructions from "./Instructions/SpatialMemoryInstructions";
import TaskSevenInstructions from "./Instructions/TaskSevenInstructions";
import TaskEightInstructions from "./Instructions/TaskEightInstructions";
import TaskNineInstructions from "./Instructions/TaskNineInstructions";

interface Props {
  taskId: number;
  language: string;
  togglePractice: () => void;
  isMobile: boolean;
  isMobileOnly?: boolean;
  isIPad13: boolean;
}
const Instructions = ({
  taskId,
  togglePractice,
  language,
  isMobile,
  isMobileOnly,
  isIPad13
}: Props) => {
  return (
    <div>
      {taskId === 1 ? (
        <TaskOneInstructions
          togglePractice={togglePractice}
          language={language}
          isMobile={isMobile}
          isMobileOnly={isMobileOnly}
          isIPad13={isIPad13}
        />
      ) : null}
      {taskId === 2 ? (
        <VigilanceInstructions
          togglePractice={togglePractice}
          language={language}
          isMobile={isMobile}
          isMobileOnly={isMobileOnly}
          isIPad13={isIPad13}
        />
      ) : null}
      {taskId === 3 ? (
        <TaskThreeInstructions
          togglePractice={togglePractice}
          language={language}
          isMobile={isMobile}
          isMobileOnly={isMobileOnly}
          isIPad13={isIPad13}
        />
      ) : null}
      {taskId === 4 ? (
        <TaskFourInstructions
          togglePractice={togglePractice}
          language={language}
          isMobile={isMobile}
          isMobileOnly={isMobileOnly}
          isIPad13={isIPad13}
        />
      ) : null}
      {taskId === 5 ? (
        <VerbalMemoryInstructions
          togglePractice={togglePractice}
          language={language}
          isMobile={isMobile}
          isMobileOnly={isMobileOnly}
          isIPad13={isIPad13}
        />
      ) : null}
      {taskId === 6 ? (
        <SpatialMemoryInstructions
          togglePractice={togglePractice}
          language={language}
          isMobile={isMobile}
          isMobileOnly={isMobileOnly}
          isIPad13={isIPad13}
        />
      ) : null}
      {taskId === 7 ? (
        <TaskSevenInstructions
          togglePractice={togglePractice}
          language={language}
          isMobile={isMobile}
          isMobileOnly={isMobileOnly}
          isIPad13={isIPad13}
        />
      ) : null}
      {taskId === 8 ? (
        <TaskEightInstructions
          togglePractice={togglePractice}
          language={language}
          isMobile={isMobile}
          isMobileOnly={isMobileOnly}
          isIPad13={isIPad13}
        />
      ) : null}
      {taskId === 9 ? (
        <TaskNineInstructions
          togglePractice={togglePractice}
          language={language}
          isMobile={isMobile}
          isMobileOnly={isMobileOnly}
          isIPad13={isIPad13}
        />
      ) : null}
    </div>
  );
};

export default Instructions;
