import { useState, useEffect } from "react";
import { sendMrabAnswersToRedis } from "../../../../utils/redisHelpers";

interface InjectedProps {
  isPractice: boolean;
  showIncorrectAnswerDisplay: boolean;
  practiceCounter: number;
  showFinalInstructions: boolean;
  doShowCountdown: boolean;
  showFinishTask: boolean;
  updateStartTask(): void;
  dismissCountdown(): void;
  togglePractice(): void;
  updatePracticeCounter(): void;
  toggleIncorrectAnswerDisplay(): void;
  showCountdown(): void;
  startTask: boolean;
  finishTask(): void;
  taskDataSaved: boolean;
  enableButton: boolean;
  context: any;
}

interface TaskWrapperProps {
  children(props: InjectedProps): JSX.Element;
  toggleProgressBar(): void;
  toggleIsIncorrectDisplay(): void;
  practiceArray: any[];
  context: any;
  taskId: number;
}

const TasksWrapper = ({
  children,
  toggleProgressBar,
  toggleIsIncorrectDisplay,
  practiceArray,
  context,
  taskId
}: TaskWrapperProps) => {
  const [isPractice, setPractice] = useState(false);
  const [startTask, setStartTask] = useState(false);
  const [showIncorrectAnswerDisplay, setShowIncorrectAnswerDisplay] =
    useState(false);
  const [practiceCounter, setPracticeCounter] = useState(0);
  const [showFinalInstructions, setShowFinalInstructions] = useState(false);
  const [doShowCountdown, setShowCountdown] = useState(false);
  const [showFinishTask, setShowFinishTask] = useState(false);
  const [taskDataSaved, setTaskDataSaved] = useState(true);
  const [enableButton, setEnableButton] = useState(false);

  useEffect(() => {
    setShowFinishTask(false);
  }, [taskId]);

  const updateStartTask = () => {
    setStartTask(true);
    setShowCountdown(false);
  };

  const dismissCountdown = () => {
    setShowCountdown(false);
  };

  const togglePractice = () => {
    setPractice(!isPractice);
    toggleProgressBar();
  };

  const updatePracticeCounter = () => {
    if (practiceArray && practiceCounter === practiceArray.length - 1) {
      setShowFinalInstructions(true);
      togglePractice();
      setStartTask(false);
      setShowCountdown(false);
      setPracticeCounter(0);
    } else {
      setPracticeCounter(practiceCounter + 1);
    }
  };

  const toggleIncorrectAnswerDisplay = () => {
    toggleIsIncorrectDisplay();
    setShowIncorrectAnswerDisplay(!showIncorrectAnswerDisplay);
  };

  const showCountdown = () => {
    setShowFinalInstructions(false);
    setShowCountdown(true);
    toggleProgressBar();
  };

  const showTutorialCountdown = () => {
    togglePractice();
    setShowCountdown(true);
  };

  const updateTaskDataSaved = (value: boolean) => {
    setTaskDataSaved(value);
  };

  const finishTask = () => {
    setTaskDataSaved(false);
    setPractice(false);
    setStartTask(false);
    setShowFinishTask(true);
    if (context && context.testEventId !== null) {
      sendMrabAnswersToRedis(
        context.testEventId,
        taskId,
        context.testIdsArray[context.testIndex],
        updateTaskDataSaved,
        context.token,
        setEnableButton
      );
    }
    localStorage.clear();
  };

  return children({
    isPractice,
    showIncorrectAnswerDisplay,
    practiceCounter,
    showFinalInstructions,
    doShowCountdown,
    showFinishTask,
    updateStartTask,
    togglePractice: taskId === 2 ? showTutorialCountdown : togglePractice,
    dismissCountdown,
    updatePracticeCounter,
    toggleIncorrectAnswerDisplay,
    showCountdown,
    startTask,
    finishTask,
    taskDataSaved,
    enableButton,
    context
  });
};

export default TasksWrapper;
