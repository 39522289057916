import React from "react";
import { Row, Col } from "react-bootstrap";
import { AiOutlineStop } from "react-icons/ai";
import {
  FiTrash,
  FiArchive,
  FiTag,
  FiUsers,
  FiMoreHorizontal
} from "react-icons/fi";
import {
  FaBars,
  FaRegEnvelope,
  FaRegFolder,
  FaInbox,
  FaRegCalendarAlt,
  FaAngleDown,
  FaAngleRight
} from "react-icons/fa";
import { BsPencil } from "react-icons/bs";
import WindowHeader from "./WindowHeader";

interface MicrosoftOutlookInterface {
  minimized: boolean;
  maximized: boolean;
  handleToggleMinimize(windowName: string): void;
  handleToggleMaximize(windowName: string): void;
  handleCloseWindow(windowName: string): void;
  handleDesktopItemClick(windowName: string): void;
  zIndexValue: number;
}

const navMenuItems = ["Delete", "Archive", "Junk", "Move to", "Categorize"];

const getNavItemIcons = (menuItem: string) => {
  switch (menuItem) {
    case "Delete":
      return <FiTrash />;
    case "Archive":
      return <FiArchive />;
    case "Junk":
      return <AiOutlineStop />;
    case "Move to":
      return <FaRegFolder />;
    case "Categorize":
      return <FiTag />;
  }
};

const navMenu = navMenuItems.map(menuItem => {
  const icon = getNavItemIcons(menuItem);
  const showAngleDown =
    menuItem === "Junk" || menuItem === "Move to" || menuItem === "Categorize";
  return (
    <button key={menuItem} className="hover-shade">
      {icon}
      {menuItem}
      {showAngleDown ? <FaAngleDown /> : null}
    </button>
  );
});

const sidebarMenuItems = [
  "Favorites",
  "Folders",
  "Inbox",
  "Junk Email",
  "Drafts"
];

const getSidebarMenuItemIcons = (sidebarMenuItem: string) => {
  switch (sidebarMenuItem) {
    case "Favorites":
      return <FaAngleRight className="ml-1 mr-4" />;
    case "Folders":
      return <FaAngleDown />;
    case "Inbox":
      return <FaInbox />;
    case "Junk Email":
      return <AiOutlineStop />;
    case "Drafts":
      return <BsPencil />;
  }
};

const sidebarMenu = sidebarMenuItems.map(sidebarMenuItem => {
  const icon = getSidebarMenuItemIcons(sidebarMenuItem);
  const sidebarMenuItemStyle = {
    backgroundColor: sidebarMenuItem === "Inbox" ? "#d9def4" : "#edeeee",
    color: sidebarMenuItem === "Inbox" ? "#425cc7" : "black",
    display: "block",
    width: "100%",
    height: "25%",
    textAlign: "left" as "left"
  };
  return (
    <button
      key={sidebarMenuItem}
      className="hover-shade"
      style={sidebarMenuItemStyle}
    >
      {icon}
      {sidebarMenuItem}
    </button>
  );
});

const MicrosoftOutlook = ({
  minimized,
  maximized,
  handleToggleMinimize,
  handleToggleMaximize,
  handleCloseWindow,
  handleDesktopItemClick,
  zIndexValue
}: MicrosoftOutlookInterface) => {
  const openNewEmail = () => {
    handleDesktopItemClick("composeEmail");
  };

  return (
    <Col
      onClick={() => handleDesktopItemClick("outlook")}
      xl={maximized ? 12 : 7}
      lg={maximized ? 12 : 7}
      md={maximized ? 12 : 6}
      sm={maximized ? 12 : 6}
      xs={maximized ? 12 : 6}
      style={{
        position: "absolute",
        top: maximized ? "0" : "45px",
        left: maximized ? "0" : "250px",
        minWidth: "750px",
        width: "100%",
        height: maximized ? "100%" : "",
        display: minimized ? "none" : "",
        zIndex: zIndexValue,
        maxHeight: "350px"
      }}
    >
      <WindowHeader
        titleText="Email"
        minimized={minimized}
        maximized={maximized}
        handleToggleMinimize={handleToggleMinimize}
        handleToggleMaximize={handleToggleMaximize}
        handleCloseWindow={handleCloseWindow}
      >
        <Row
          noGutters={true}
          style={{
            width: "100%",
            position: "relative",
            backgroundColor: "#f7f7f7",
            height: "50px",
            padding: "5px"
          }}
        >
          <Col xs={3}>
            <button className="outlook-menu-bars">
              <FaBars />
            </button>
            <button className="outlook-action-button" onClick={openNewEmail}>
              New Message
            </button>
          </Col>
          <Col xs={9} className="outlook-nav">
            {navMenu}
          </Col>
        </Row>
        <Row
          noGutters={true}
          style={{
            width: "100%",
            minHeight: "300px",
            height: "100%"
          }}
        >
          <Col
            xl={3}
            lg={3}
            md={3}
            sm={3}
            xs={3}
            style={{ backgroundColor: "#edeeee" }}
          >
            <Row>
              <Col className="outlook-sidebar-menu" xs={12}>
                {sidebarMenu}
              </Col>
              <Col xs={12} style={{ position: "absolute", bottom: "0" }}>
                <Row style={{ margin: "0 auto", padding: "10px" }}>
                  <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                    <button>
                      <FaRegEnvelope style={{ fill: "#425cc7" }} />
                    </button>
                  </Col>
                  <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                    <button>
                      <FaRegCalendarAlt />
                    </button>
                  </Col>
                  <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                    <button>
                      <FiUsers />
                    </button>
                  </Col>
                  <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                    <button>
                      <FiMoreHorizontal />
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col
            xl={9}
            lg={9}
            md={9}
            sm={9}
            xs={9}
            style={{ backgroundColor: "white" }}
          >
            <Col
              xs={12}
              style={{ padding: "10px", borderBottom: "1px solid #edebe9" }}
            >
              <Row>
                <Col>
                  <span style={{ fontSize: "16px" }}>Inbox</span>
                </Col>
                <Col style={{ textAlign: "right" }}>
                  <button
                    style={{
                      backgroundColor: "white",
                      color: "#425cc7"
                    }}
                  >
                    Filter <FaAngleDown className="ml-1" />
                  </button>
                </Col>
              </Row>
            </Col>
            <Col xs={12}>
              <p className="mt-3">Your Inbox is empty.</p>
            </Col>
          </Col>
        </Row>
      </WindowHeader>
    </Col>
  );
};

export default MicrosoftOutlook;
