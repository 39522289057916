import React, { useState } from "react";
import { Card, Col, Container, Row, Button, Form } from "react-bootstrap";
import { translate } from "../utils/translationHelpers";
import { apiURL } from "../utils/constants";
import { eventDebugBreakpoints, getEventDebugHeader } from "./helpers";

interface Props {
  path: string;
}

const EventIdDebug: React.FC<Props> = () => {
  const [testTakerEmail, setTestTakerEmail] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailAddressChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setTestTakerEmail(e.target.value);
    setFormSubmitted(false);
    setIsLoading(false);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    onSendForm();
  };

  const onSendForm = async () => {
    try {
      await fetch(`${apiURL}/getEventsByEmailLink`, {
        method: "POST",
        body: JSON.stringify({ emailAddress: testTakerEmail })
      });
      setFormSubmitted(true);
    } catch (error) {}
    setIsLoading(false);
  };

  const linkNames = ["copyrightNotices", "terms", "privacy", "accommodations"];

  const FooterLinks = linkNames.map(linkName => (
    <a
      key={linkName}
      href={translate("common", `${linkName}Link`, "english")}
      target="_blank"
      rel="noopener noreferrer"
    >
      {translate("common", linkName, "english")}
    </a>
  ));

  return (
    <Container>
      <Row>
        <Col {...eventDebugBreakpoints}>
          <Card className="oda-card">
            {getEventDebugHeader()}
            <Card.Body>
              <Row>
                <Col>
                  <p>
                    Thank you for reaching out for assistance. Please enter the
                    email address you used for your application below. Once you
                    click Submit, we can help you find your Event ID or request
                    a lockout reset for your assessment.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col
                  xl={{ span: 8, offset: 2 }}
                  lg={12}
                  md={12}
                  sm={12}
                  className="text-center"
                >
                  <Form
                    inline
                    style={{ flexFlow: "row", marginTop: "20px" }}
                    onSubmit={handleSubmit}
                  >
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      onChange={handleEmailAddressChange}
                      value={testTakerEmail}
                      required
                      style={{
                        width: "90%",
                        marginRight: "10px",
                        flexFlow: "row"
                      }}
                    />
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isLoading}
                    >
                      Submit
                    </Button>
                  </Form>
                </Col>
              </Row>
              {formSubmitted ? (
                <Row style={{ marginTop: "20px" }}>
                  <Col>
                    <p className="text-center" style={{ color: "green" }}>
                      Your request was successfully submitted!
                    </p>
                    <p>
                      If an email address was found, you will receive a
                      verification email to confirm your identity/submission.
                    </p>
                    <p>
                      Haven't received an email? We may not have an active
                      invitation under this email address, either because no
                      event exists or the invitation has expired.
                    </p>
                    <p>You can try:</p>
                    <ul>
                      <li>
                        If you have another email address, please try this now.
                      </li>
                      <li>Check your spam/junk folder.</li>
                      <li>
                        Check your invitation email for the deadline provided.
                        For invitations that have since expired, please contact
                        the company you are applying to directly to request an
                        extension.
                      </li>
                      <li>
                        If you have not received an invitation email or testing
                        link from the company, please contact them to request
                        this.
                      </li>
                    </ul>
                    <p>
                      Due to privacy reasons, Criteria are unable to provide
                      direct contact information for the company. We recommend
                      checking the job ad or any messages you have received from
                      them, as they may have provided contact details there. If
                      not, you may be able to obtain contact information on
                      their website.
                    </p>
                  </Col>
                </Row>
              ) : null}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="footer">
        <Col
          xl={{ span: 8, offset: 2 }}
          lg={{ span: 8, offset: 2 }}
          md={{ span: 10, offset: 1 }}
          sm={12}
          className="text-center"
        >
          <div>
            <p className="mb-0">
              Copyright &copy;
              {new Date().getFullYear()} Criteria Corp. | {FooterLinks[0]}
            </p>
            <p className="mt-0">
              {FooterLinks[1]}
              {" | "}
              {FooterLinks[2]}
              {" | "}
              {FooterLinks[3]}
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default EventIdDebug;
