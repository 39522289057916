export const gameTutorialReducer = (state: any, action: any) => {
  switch (action.type) {
    case "onBeginTutorial":
      return {
        ...state,
        showStartTutorialModal: false,
        isTutorialTestBegin: true
      };
    case "onCheckAnswer":
      return {
        ...state,
        isAnswerCorrect: action.isAnswerCorrect
      };
    case "onTimeExpired":
      return {
        ...state,
        isTimeExpired: true
      };

    case "onProgressNext":
      return {
        ...state,
        isAnswerCorrect: null,
        showCompleteTutorialModal: true
      };
    case "onRedoTutorial":
      return {
        ...state,
        isTimeExpired: false,
        isTutorialTestBegin: false,
        showCompleteTutorialModal: false,
        isAnswerCorrect: null,
        showStartTutorialModal: true
      };
    case "onBeginAssessment":
      return {
        ...state,
        isTutorialTestBeing: false
      };
  }
};
