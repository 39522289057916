import React, { Component } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FadeLoader } from "react-spinners";
import Header from "../Components/Header";
import { apiURL } from "../utils/constants";

interface State {
  showSuccess: boolean;
  invalidRequest: boolean;
}

interface Props {
  path: string;
  token?: string;
  responseId?: string;
  testTakerIds?: string;
  answer?: string;
}

export default class CandidateVerificationForm extends Component<Props> {
  state: State = {
    showSuccess: false,
    invalidRequest: false
  };

  componentDidMount() {
    this.fetchRequestData();
  }

  fetchRequestData = async (): Promise<boolean> => {
    try {
      const formData = {
        answer: this.props.answer,
        testTakerIds: this.props.testTakerIds
      };
      const response = await fetch(
        `${apiURL}/customerResponse/${this.props.token}/${this.props.responseId}`,
        {
          method: "PUT",
          body: JSON.stringify(formData)
        }
      );
      if (response.status === 404) {
        this.setState({ invalidRequest: true, showSuccess: true });
      } else {
        const data = await response.json();
        if (!data.error) {
          this.setState({ showSuccess: true });
          return true;
        }
      }
      return false;
    } catch {
      return false;
    }
  };

  render() {
    if (!this.state.showSuccess) {
      return (
        <Row>
          <Col className="spinner-container">
            <div className="spinner text-center">
              <FadeLoader height={15} color="#425CC7" />
            </div>
          </Col>
        </Row>
      );
    } else {
      return (
        <Container>
          <Row>
            <Col
              xl={{ span: 8, offset: 2 }}
              lg={{ span: 8, offset: 2 }}
              md={{ span: 10, offset: 1 }}
              sm={12}
            >
              <Card className="oda-card">
                <Header
                  onWelcomePage={false}
                  backgroundColor="#ffffff"
                  customLogo=""
                />
                <Card.Body>
                  <Card.Title className="text-center">
                    Privacy Policy
                  </Card.Title>
                  {this.state.invalidRequest ? (
                    <p>This request has already been processed.</p>
                  ) : (
                    <p>Thank you. We have received your response.</p>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      );
    }
  }
}
