import React from "react";
import { Row, Col } from "react-bootstrap";
import Avatar from "./Avatar";

interface ContactProps {
  name: string;
  latestMessage: string;
  avatarColor: string;
  currentContact: string;
  handleContactClick(contactName: string): void;
  maximized: boolean;
  isMessageBold: boolean;
}

const Contact = ({
  name,
  latestMessage,
  avatarColor,
  currentContact,
  handleContactClick,
  maximized,
  isMessageBold
}: ContactProps) => {
  const onHandleContactClick = () => {
    handleContactClick(name);
  };

  const getMessageDisplay = () => {
    if (
      (maximized && latestMessage.length < 40) ||
      (!maximized && latestMessage.length < 20)
    ) {
      return latestMessage;
    } else if (maximized) {
      return `${latestMessage.substring(0, 40)}...`;
    } else {
      return `${latestMessage.substring(0, 20)}...`;
    }
  };

  return (
    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
      <button
        style={{
          backgroundColor: currentContact === name ? "#eceef9" : "white",
          width: "100%",
          display: "flex",
          padding: "5px 0px 5px 15px",
          borderRight: currentContact === name ? "5px solid #425cc7" : "none"
        }}
        onClick={onHandleContactClick}
      >
        <Avatar name={name} backgroundColor={avatarColor} />
        <div>
          <p style={{ marginBottom: "0px", textAlign: "left" }}>{name}</p>
          <p
            style={{
              color: "#64666a",
              marginBottom: "0px",
              fontSize: "14px",
              textAlign: "left",
              fontWeight: isMessageBold ? "bold" : "normal"
            }}
          >
            {getMessageDisplay()}
          </p>
        </div>
      </button>
    </Col>
  );
};

interface MessagesContactListInterface {
  contacts: any;
  currentContact: string;
  handleContactClick(contactName: string): void;
  maximized: boolean;
}

const MessagesContactList = ({
  contacts,
  currentContact,
  handleContactClick,
  maximized
}: MessagesContactListInterface) => {
  const contactList = contacts.map((contact: any, i: number) => {
    const { name, avatarColor, boldMessage, conversation } = contact;
    const latestMessageToShow = Object.keys(
      conversation[conversation.length - 1]
    )[0];

    return (
      <Contact
        key={name}
        name={name}
        latestMessage={latestMessageToShow}
        avatarColor={avatarColor}
        currentContact={currentContact}
        handleContactClick={handleContactClick}
        maximized={maximized}
        isMessageBold={boldMessage}
      />
    );
  });

  return <Row>{contactList}</Row>;
};

export default MessagesContactList;
