import React, { Component } from "react";
import { Row, Col, Button, Image } from "react-bootstrap";
import { FaDownload, FaSpinner } from "react-icons/fa";
import Chart from "chart.js";
import Encryption from "../utils/Encryption";
import { sskowk, apiURL } from "../utils/constants";
import logo from "../Resources/images/criteriaLogo_wordmark.png";

export default class WorkplaceInsightsPersonify extends Component {
  constructor() {
    super();
    this.state = {
      html: "",
      data: null,
      name: "",
      downloadClicked: false
    };
  }

  /* ---------- React LifeCycle Methods ---------- */

  componentDidMount() {
    document.title = `Workplace Insights - Criteria Corp`;
    const apiUrl = `${apiURL.replace("oda", "v1")}/personifyReportsLink/${
      this.props.param1
    }/${this.props.param2}/html`;
    // eslint-disable-next-line no-unused-vars
    const getWorkplaceInsights = (async () => {
      try {
        const response = await fetch(apiUrl);
        const responseData = await response.json();

        const encryption = new Encryption();
        const decryptedData = responseData.data
          ? JSON.parse(encryption.decrypt(responseData.data, sskowk))
          : null;

        const report = atob(decryptedData.report);
        this.setState({
          html: report,
          data: decryptedData.scores
        });
      } catch (error) {
        console.log("error: ", error);
      }
    })();
  }

  handleDownload = () => {
    this.setState({ downloadClicked: true });
    const apiUrl = `${apiURL.replace("oda", "v1")}/personifyReportsLink/${
      this.props.param1
    }/${this.props.param2}/pdf`;

    // eslint-disable-next-line no-unused-vars
    const getWorkplaceInsights = (async () => {
      try {
        const response = await fetch(apiUrl);
        const data = await response.json();

        const encryption = new Encryption();
        const decryptedData = data.data
          ? JSON.parse(encryption.decrypt(data.data, sskowk))
          : null;

        if (window.navigator.msSaveOrOpenBlob) {
          const sliceSize = 1024;
          const byteCharacters = atob(decryptedData.report.replace(/[Â]/g, ""));
          const bytesLength = byteCharacters.length;
          const slicesCount = Math.ceil(bytesLength / sliceSize);
          const byteArrays = new Array(slicesCount);
          // eslint-disable-next-line no-plusplus
          for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            const begin = sliceIndex * sliceSize;
            const end = Math.min(begin + sliceSize, bytesLength);

            const bytes = new Array(end - begin);
            // eslint-disable-next-line no-plusplus
            for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
              bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
          }
          const blob = new Blob(byteArrays, { type: "application/pdf" });
          window.navigator.msSaveOrOpenBlob(blob, "WorkplaceInsights.pdf");
        } else {
          const dlnk = document.getElementById("testReportDwnldLnk");

          const dlnkDownloadBefore = dlnk.download;
          dlnk.download += `-Workplace-Insights.pdf`;
          dlnk.href = `data:application/pdf;base64,${decryptedData.report}`;
          dlnk.click();
          dlnk.download = dlnkDownloadBefore;
        }
        this.setState({ downloadClicked: false });
      } catch (error) {
        console.log("error: ", error);
      }
    })();
  };

  render() {
    if (this.state.data) {
      const adjustedWorkplaceInsightsScores = [];
      this.state.data.map(item => {
        adjustedWorkplaceInsightsScores.push(item + 25);
        return true;
      });
      Chart.defaults.global.defaultFontColor = "#001f4e";
      setTimeout(() => {
        const ctx = document.getElementById("myChart")
          ? document.getElementById("myChart").getContext("2d")
          : null;

        if (ctx) {
          // eslint-disable-next-line no-unused-vars
          const myRadarChart = new Chart(ctx, {
            type: "radar",
            data: {
              labels: [
                "Reliable",
                "Ambitious",
                "Social Boldness",
                "Innovative",
                "Willful",
                "Critical",
                "Reserved",
                "Careful"
              ],
              datasets: [
                {
                  data: [25, 25, 25, 25, 25, 25, 25, 25],
                  fill: true,
                  borderColor: "rgb(183.6, 191.25, 201.45)",
                  backgroundColor: "rgba(204, 209, 219, 1)",
                  borderWidth: 1,
                  pointBorderWidth: 0,
                  pointHitRadius: 0,
                  pointRadius: 0
                },
                {
                  data: adjustedWorkplaceInsightsScores,
                  fill: true,
                  backgroundColor: "rgba(0, 31, 78, 0.2)",
                  borderColor: "rgb(0, 31, 78)",
                  pointBackgroundColor: "rgb(0, 31, 78)",
                  pointBorderColor: "#fff",
                  pointHoverBackgroundColor: "#fff",
                  pointHoverBorderColor: "rgb(0, 31, 78)"
                },
                {
                  data: [75, 75, 75, 75, 75, 75, 75, 75],
                  fill: true,
                  backgroundColor: "rgba(255, 255, 255, 0)",
                  borderColor: "rgb(100, 100, 100)",
                  borderDash: [3, 3],
                  borderDashOffset: 3,
                  borderWidth: 2,
                  pointBorderWidth: 0,
                  pointHitRadius: 0,
                  pointRadius: 0
                }
              ]
            },
            options: {
              animation: false,
              legend: {
                display: false
              },
              scale: {
                ticks: {
                  beginAtZero: true,
                  min: 0,
                  max: 125,
                  stepSize: 25,
                  display: false
                },
                pointLabels: {
                  fontColor: [
                    "#FFA300",
                    "#FF5C38",
                    "#D60057",
                    "#8F1478",
                    "#633099",
                    "#425CC7",
                    "#00A8E0",
                    "#2ECCD4"
                  ],
                  fontStyle: "bold",
                  fontSize: window.innerWidth < 500 ? 9 : 14
                }
              },
              layout: {
                padding: {
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0
                }
              },
              tooltips: {
                enabled: false
              }
            }
          });
        }
      }, 100);
    }

    return (
      <>
        <Row
          className="pb-3 mb-3"
          style={{
            position: "sticky",
            top: 0,
            boxShadow: "0 2px 2px #CCC",
            backgroundColor: "#FFF",
            zIndex: 1020,
            marginTop: "-16px",
            paddingTop: "16px"
          }}
        >
          <Col>
            <Image
              src={logo}
              alt="Criteria Corp. On Demand Assessment"
              fluid={true}
            />
          </Col>
          <Col className="text-right">
            <Button
              variant="outline-primary"
              onClick={this.handleDownload}
              disabled={this.state.downloadClicked}
            >
              {this.state.downloadClicked ? <FaSpinner /> : <FaDownload />}{" "}
              {this.state.downloadClicked ? "Please Wait..." : "Download PDF"}
            </Button>
          </Col>
        </Row>
        <div dangerouslySetInnerHTML={{ __html: this.state.html }} />
        <div className="text-center" style={{ color: "#AAA" }}>
          &copy; {new Date().getFullYear()} Criteria Corporation. All Rights
          Reserved &bull; www.criteriacorp.com
        </div>
        <a
          id="testReportDwnldLnk"
          download={this.state.name}
          style={{ display: "none" }}
          href
        >
          dwnld
        </a>
      </>
    );
  }
}
