import React, { useState } from "react";
import BrowseHopLandingPage from "./BrowseHopLandingPage";
import BrowseHopResultsPage from "./BrowseHopResultsPage";
import { Test } from "./types";
import { containsWordsFromAllArrays } from "./helpers";

interface BrowseHopProps {
  setFirstSearchText?: React.Dispatch<React.SetStateAction<string>>;
  test: Test;
}

const BrowseHop = ({ setFirstSearchText, test }: BrowseHopProps) => {
  const [input, setInput] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [showQueryError, setShowQueryError] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(0);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  const submitInput = (e: React.FormEvent) => {
    e.preventDefault();

    if (formSubmitted === 0 && setFirstSearchText) {
      setFirstSearchText(input);
    }

    setFormSubmitted(prev => prev + 1);

    const queryIsValid = containsWordsFromAllArrays(input, test.searchWords);

    setShowResults(true);

    if (queryIsValid) {
      setShowQueryError(false);
    } else {
      setShowQueryError(true);
    }
  };

  return (
    <div style={{ overflowX: "hidden", overflowY: "scroll", height: "300px" }}>
      {showResults ? (
        <BrowseHopResultsPage
          showQueryError={showQueryError}
          input={input}
          onChange={onChange}
          submitInput={submitInput}
          test={test}
        />
      ) : (
        <BrowseHopLandingPage
          input={input}
          onChange={onChange}
          submitInput={submitInput}
        />
      )}
    </div>
  );
};

export default BrowseHop;
