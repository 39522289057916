import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import SalesReport from "./SalesReport";
import BrowseHop from "../../../../Components/BrowseHop";
import { olympicsTest } from "../../../../Components/BrowseHop/constants";

interface InternetContentInterface {
  enteredAddress: string;
  currentSection: number;
  displayTab: boolean;
  submitProfileUpdate(
    answerVal: number,
    birthYear: number,
    searchText: string
  ): void;
  handleUpdateBirthYear(year: string): void;
  handleCopyActionComplete(): void;
  disableSendButton: boolean;
}

const InternetContent = ({
  currentSection,
  enteredAddress,
  displayTab,
  submitProfileUpdate,
  handleUpdateBirthYear,
  handleCopyActionComplete,
  disableSendButton
}: InternetContentInterface) => {
  const [profileText, updateProfileText] = useState("");
  const [firstSearchText, setFirstSearchText] = useState("");

  const showBrowseHop =
    (enteredAddress === "www.browsehop.com" ||
      enteredAddress === "browsehop.com" ||
      enteredAddress === "https://www.browsehop.com") &&
    currentSection === 2;
  const showProfileUpdater =
    enteredAddress === "http://www.profile-updater.com";
  const showSalesReport =
    enteredAddress === "www.acme-intranet.com/sales-report";

  const handleProfileText = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateProfileText(e.target.value);
    handleUpdateBirthYear(e.target.value);
  };

  const handleSubmitProfileUpdate = (e: React.FormEvent) => {
    e.preventDefault();
    const answerVal = Number(profileText.trim()) === 1896 ? 1 : 0;
    submitProfileUpdate(answerVal, Number(profileText.trim()), firstSearchText);
  };

  return (
    <Row style={{ display: displayTab ? "" : "none" }}>
      <Col
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ backgroundColor: "#FFFFFF" }}
      >
        {showBrowseHop && (
          <BrowseHop
            test={olympicsTest}
            setFirstSearchText={setFirstSearchText}
          />
        )}
        {showProfileUpdater && (
          <form
            onSubmit={handleSubmitProfileUpdate}
            style={{ padding: "10px" }}
          >
            <Form.Group>
              <Form.Label>
                What year was the first modern olympics held?
              </Form.Label>
              <Form.Control type="text" onChange={handleProfileText} />
              <br />
              <input
                type="submit"
                value="Submit"
                disabled={disableSendButton}
              />
            </Form.Group>
          </form>
        )}
        {showSalesReport && (
          <SalesReport handleCopyActionComplete={handleCopyActionComplete} />
        )}
      </Col>
    </Row>
  );
};

export default InternetContent;
