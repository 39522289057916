import React from "react";
import Key from "../../ImageComponents/Key";
import { instructionsObjectEnglish } from "../instructionsObject";
import { instructionsObjectSpanish } from "../instructionsObject";
import InstructionsWrapper from "../InstructionsWrapper";
import NavigateButtons from "../NavigateButtons";
import FingerPositions from "../../Images/finger_positions_FJ.jpeg";
import FingerPositionsMobile from "../../Images/finger_positions_FJ_mobile.png";

interface Props {
  togglePractice(): void;
  language: string;
  isMobile: boolean;
  isMobileOnly?: boolean;
  isIPad13?: boolean;
}

const TaskFourInstructions = ({
  togglePractice,
  language,
  isMobile,
  isMobileOnly,
  isIPad13
}: Props) => {
  const instructionsObject =
    language === "english"
      ? instructionsObjectEnglish
      : instructionsObjectSpanish;

  const isMobileAndEnglish = (isMobile || isIPad13) && language === "english";

  return (
    <InstructionsWrapper>
      {({ instructionPage, handleContinue, handleBack }) => {
        const finalPage = 6;
        if (instructionPage > finalPage) {
          togglePractice();
        }
        return (
          <div>
            {instructionPage === 1 ? (
              <div>
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile mt-3"
                      : "instruction-text mt-3"
                  }
                >
                  {instructionsObject[4][instructionPage].text_1}
                </p>
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile mt-3"
                      : "instruction-text mt-3"
                  }
                >
                  {instructionsObject[4][instructionPage].text_2}
                </p>
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile mt-3"
                      : "instruction-text mt-3"
                  }
                >
                  {isMobileAndEnglish
                    ? instructionsObject[4][instructionPage].text_3.slice(0, -5)
                    : instructionsObject[4][instructionPage].text_3}
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="F" />
                  {isMobileAndEnglish
                    ? instructionsObject[4][instructionPage].text_4a.slice(1)
                    : instructionsObject[4][instructionPage].text_4a}
                </p>
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile"
                      : "instruction-text"
                  }
                >
                  {isMobileAndEnglish
                    ? instructionsObject[4][instructionPage].text_3.slice(0, -5)
                    : instructionsObject[4][instructionPage].text_3}
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="J" />
                  {isMobileAndEnglish
                    ? instructionsObject[4][instructionPage].text_4b.slice(1)
                    : instructionsObject[4][instructionPage].text_4b}
                </p>
              </div>
            ) : null}
            {instructionPage === 2 ? (
              <div>
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile mt-3"
                      : "instruction-text mt-3"
                  }
                >
                  {instructionsObject[4][instructionPage].text_1}
                </p>
                <div className="text-center">
                  <svg
                    width={isMobileOnly ? "350" : "440"}
                    height={isMobileOnly ? "200" : "240"}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      id="mr_02"
                      style={{ stroke: "#000", fill: "#FFF" }}
                      height={isMobileOnly ? "100%" : "100"}
                      width="150"
                    >
                      <rect x="5" y="50" height="25" width="25" />
                      <rect
                        x="30"
                        y="50"
                        height="25"
                        width="25"
                        style={{ fill: "#000" }}
                      />

                      <rect x="30" y="75" height="25" width="25" />
                      <rect x="55" y="75" height="25" width="25" />
                      <rect x="80" y="75" height="25" width="25" />

                      <rect x="30" y="100" height="25" width="25" />

                      <rect x="30" y="125" height="25" width="25" />

                      <rect x="5" y="150" height="25" width="25" />
                      <rect x="30" y="150" height="25" width="25" />

                      <rect x="30" y="175" height="25" width="25" />
                    </g>
                    <g
                      id="mr_02"
                      style={{ stroke: "#000", fill: "#FFF" }}
                      height={isMobileOnly ? "100%" : "100"}
                      width="150"
                      transform="rotate(140 262.5 125)"
                    >
                      <rect x="275" y="50" height="25" width="25" />
                      <rect
                        x="250"
                        y="50"
                        height="25"
                        width="25"
                        style={{ fill: " #000" }}
                      />

                      <rect x="250" y="75" height="25" width="25" />
                      <rect x="225" y="75" height="25" width="25" />
                      <rect x="200" y="75" height="25" width="25" />

                      <rect x="250" y="100" height="25" width="25" />

                      <rect x="250" y="125" height="25" width="25" />

                      <rect x="275" y="150" height="25" width="25" />
                      <rect x="250" y="150" height="25" width="25" />

                      <rect x="250" y="175" height="25" width="25" />
                      <animateTransform
                        id="rotate"
                        attributeName="transform"
                        type="rotate"
                        from="140 262.5 125"
                        to="0 262.5 125"
                        begin="2;rotate.end+2"
                        dur="2s"
                        fill="freeze"
                        repeatCount="1"
                      />
                    </g>
                  </svg>
                </div>
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile"
                      : "instruction-text"
                  }
                >
                  {isMobileAndEnglish
                    ? instructionsObject[4][instructionPage].text_2.slice(0, -5)
                    : instructionsObject[4][instructionPage].text_2}
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="F" />.
                </p>
              </div>
            ) : null}
            {instructionPage === 3 ? (
              <div>
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile mt-3"
                      : "instruction-text mt-3"
                  }
                >
                  {instructionsObject[4][instructionPage].text_1}
                </p>
                <div className="text-center">
                  <svg
                    width={isMobileOnly ? "350" : "440"}
                    height={isMobileOnly ? "200" : "240"}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      id="mr_02"
                      style={{ stroke: "#000", fill: "#FFF" }}
                      height={isMobileOnly ? "100%" : "100"}
                      width="100"
                    >
                      <rect x="5" y="50" height="25" width="25" />
                      <rect
                        x="30"
                        y="50"
                        height="25"
                        width="25"
                        style={{ fill: "#000" }}
                      />

                      <rect x="30" y="75" height="25" width="25" />
                      <rect x="55" y="75" height="25" width="25" />
                      <rect x="80" y="75" height="25" width="25" />

                      <rect x="30" y="100" height="25" width="25" />

                      <rect x="30" y="125" height="25" width="25" />

                      <rect x="5" y="150" height="25" width="25" />
                      <rect x="30" y="150" height="25" width="25" />

                      <rect x="30" y="175" height="25" width="25" />
                    </g>
                    <g
                      id="mr_02"
                      style={{ stroke: "#000", fill: "#FFF" }}
                      height={isMobileOnly ? "100%" : "100"}
                      width="100"
                      transform="rotate(-120 262.5 125)"
                    >
                      <rect x="225" y="50" height="25" width="25" />
                      <rect
                        x="250"
                        y="50"
                        height="25"
                        width="25"
                        style={{ fill: "#000" }}
                      />

                      <rect x="250" y="75" height="25" width="25" />
                      <rect x="275" y="75" height="25" width="25" />
                      <rect x="300" y="75" height="25" width="25" />

                      <rect x="250" y="100" height="25" width="25" />

                      <rect x="250" y="125" height="25" width="25" />

                      <rect x="225" y="150" height="25" width="25" />
                      <rect x="250" y="150" height="25" width="25" />

                      <rect x="250" y="175" height="25" width="25" />
                      <animateTransform
                        id="rotate2"
                        attributeName="transform"
                        type="rotate"
                        from="-120 262.5 125"
                        to="0 262.5 125"
                        begin="2;rotate2.end+2"
                        dur="2s"
                        fill="freeze"
                        repeatCount="1"
                      />
                    </g>
                  </svg>
                </div>
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile"
                      : "instruction-text"
                  }
                >
                  {isMobileAndEnglish
                    ? instructionsObject[4][instructionPage].text_2.slice(0, -4)
                    : instructionsObject[4][instructionPage].text_2}
                  <Key isMobile={isMobile} text="J" />.
                </p>
              </div>
            ) : null}
            {instructionPage === 4 ? (
              <div>
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile mt-3"
                      : "instruction-text mt-3"
                  }
                >
                  {isMobileAndEnglish
                    ? instructionsObject[4][instructionPage].text_1.slice(0, -5)
                    : instructionsObject[4][instructionPage].text_1}
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="F" />
                </p>
                <div className="text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={isMobileOnly ? "350" : "440"}
                    height="130"
                  >
                    <g fill="#fff" stroke="#000">
                      <path d="M25 20h20v20H25z" />
                      <path fill="#000" d="M45 20h20v20H45z" />
                      <path d="M45 40h20v20H45zM65 40h20v20H65zM85 40h20v20H85zM45 60h20v20H45zM45 80h20v20H45zM25 100h20v20H25zM45 100h20v20H45z" />
                    </g>
                    <g fill="#fff" stroke="#000">
                      <path d="M220 20h20v20h-20z" />
                      <path fill="#000" d="M200 20h20v20h-20z" />
                      <path d="M200 40h20v20h-20zM180 40h20v20h-20zM160 40h20v20h-20zM200 60h20v20h-20zM200 80h20v20h-20zM220 100h20v20h-20zM200 100h20v20h-20z" />
                    </g>
                  </svg>
                </div>
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile"
                      : "instruction-text"
                  }
                >
                  {isMobileAndEnglish
                    ? instructionsObject[4][instructionPage].text_2.slice(0, -5)
                    : instructionsObject[4][instructionPage].text_2}
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="J" />
                </p>
                <div className="text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={isMobileOnly ? "350" : "440"}
                    height="130"
                  >
                    <g fill="#fff" stroke="#000">
                      <path d="M25 20h20v20H25z" />
                      <path fill="#000" d="M45 20h20v20H45z" />
                      <path d="M45 40h20v20H45zM65 40h20v20H65zM85 40h20v20H85zM45 60h20v20H45zM45 80h20v20H45zM25 100h20v20H25zM45 100h20v20H45z" />
                    </g>
                    <g fill="#fff" stroke="#000">
                      <path d="M180 20h20v20h-20z" />
                      <path fill="#000" d="M200 20h20v20h-20z" />
                      <path d="M200 40h20v20h-20zM220 40h20v20h-20zM240 40h20v20h-20zM200 60h20v20h-20zM200 80h20v20h-20zM180 100h20v20h-20zM200 100h20v20h-20z" />
                    </g>
                  </svg>
                </div>
              </div>
            ) : null}
            {instructionPage === 5 ? (
              <div
                className={
                  isMobileOnly
                    ? "instruction-text-mobile mt-3"
                    : "instruction-text mt-3"
                }
              >
                {isMobileAndEnglish
                  ? "You will see F and J buttons on the screen as shown below"
                  : instructionsObject[4][instructionPage].text_1}
                <div className="text-center mt-3">
                  {isMobile || isIPad13 ? (
                    <img
                      src={FingerPositionsMobile}
                      alt="letters F and J as buttons"
                      width="250px"
                      height="67px"
                    />
                  ) : (
                    <img src={FingerPositions} alt="finger positions" />
                  )}
                </div>
              </div>
            ) : null}
            {instructionPage === 6 ? (
              <div
                className={
                  isMobileOnly
                    ? "instruction-text-mobile mt-3"
                    : "instruction-text mt-3"
                }
              >
                <p>{instructionsObject[4][instructionPage].text_1}</p>
                <p>
                  {isMobileAndEnglish
                    ? "If you make a mistake, your screen will display a message."
                    : instructionsObject[4][instructionPage].text_2}
                </p>
                <p>{instructionsObject[4][instructionPage].text_3}</p>
                <p>
                  {isMobileAndEnglish
                    ? instructionsObject[4][instructionPage].text_4a.slice(
                        0,
                        -5
                      )
                    : instructionsObject[4][instructionPage].text_4a}
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="F" />
                  {isMobileAndEnglish
                    ? instructionsObject[4][instructionPage].text_4b
                        .slice(0, -5)
                        .slice(1)
                    : instructionsObject[4][instructionPage].text_4b}
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="J" />
                  {isMobileAndEnglish
                    ? instructionsObject[4][instructionPage].text_4c.slice(1)
                    : instructionsObject[4][instructionPage].text_4c}
                </p>
              </div>
            ) : null}
            <NavigateButtons
              instructionPage={instructionPage}
              handleBack={handleBack}
              handleContinue={handleContinue}
              finalPage={finalPage}
              language={language}
            />
          </div>
        );
      }}
    </InstructionsWrapper>
  );
};

export default TaskFourInstructions;
