import React, { useReducer } from "react";
import { Col, Modal } from "react-bootstrap";
import { getGameTutorialData } from "../../../helpers";
import TutorialComponent from "./TutorialComponent";
import BeginTutorialModal from "./GameTutorialModals/BeginTutorialModal";
import TutorialFeedbackModal from "./GameTutorialModals/TutorialFeedbackModal";
import TutorialTimesUpModal from "./GameTutorialModals/TutorialTimesUpModal";
import CompleteTutorialModal from "./GameTutorialModals/CompleteTutorialModal";
import { gameTutorialReducer } from "../reducer";
import {
  onRedoTutorial,
  onProgressNext,
  onBeginAssessment,
  onCheckAnswer,
  onTimeExpired,
  onBeginTutorial
} from "../actions";

interface Props {
  gameTutorialId: number;
  handleStartRealTest: () => void;
  timeFactor: number;
  testData: any;
}

interface InitialState {
  showStartTutorialModal: boolean;
  isTutorialTestBegin: boolean;
  isTimeExpired: boolean;
  isAnswerCorrect: boolean | null;
  showCompleteTutorialModal: boolean;
}

const initialState: InitialState = {
  showStartTutorialModal: true,
  isTutorialTestBegin: false,
  isTimeExpired: false,
  isAnswerCorrect: null,
  showCompleteTutorialModal: false
};

const GameTutorial = ({
  gameTutorialId,
  handleStartRealTest,
  timeFactor,
  testData
}: Props) => {
  const [state, dispatch] = useReducer(gameTutorialReducer, initialState);

  const GameTutorialData = getGameTutorialData(testData);

  // when the feedback modal shows, the test taker has to click on the 'next' button to see the CompleteTutorialModal
  const handleProgressNext = () => {
    dispatch(onProgressNext());
  };

  // proceed to the REAL GAME assessment
  const handleBeginAssessment = () => {
    dispatch(onBeginAssessment());
    handleStartRealTest();
  };

  // re-do the tutorial
  const handleRedoTutorial = () => {
    dispatch(onRedoTutorial());
  };

  // check the answer and show the corresponding feedback modal
  const handleCheckAnswer = (userInputAnswer: string) => {
    let isAnswerCorrect =
      userInputAnswer === GameTutorialData[gameTutorialId - 1].exampleAnswer;
    dispatch(onCheckAnswer(isAnswerCorrect));
  };

  return (
    <>
      <TutorialComponent
        gameTutorialId={gameTutorialId}
        isTutorialTestBegin={state.isTutorialTestBegin}
        exampleQuestion={GameTutorialData[gameTutorialId - 1].exampleQuestion}
        answerOptions={GameTutorialData[gameTutorialId - 1].answerOptions}
        pressKeys={{
          pressFKey: testData.text.pressFKey,
          pressJKey: testData.text.pressJKey
        }}
        handleCheckAnswer={handleCheckAnswer}
        handleShowTimeExpiredModal={() => dispatch(onTimeExpired())}
        showStartTutorialModal={state.showStartTutorialModal}
        isAnswerCorrect={state.isAnswerCorrect}
        timeFactor={timeFactor}
        disconnectModalDisplayText={{
          testPaused: testData.text.testPaused,
          errorSavingQuestion: testData.text.errorSavingQuestion,
          pleaseWaitReconnecting: testData.text.pleaseWaitReconnecting,
          unableToReconnectTryAgainLater:
            testData.text.unableToReconnectTryAgainLater
        }}
      />
      <Col
        xl={{ span: 8, offset: 2 }}
        lg={{ span: 8, offset: 2 }}
        md={{ span: 10, offset: 1 }}
        sm={12}
        className="modal-position"
        id={
          gameTutorialId === 1 && !state.isTimeExpired
            ? "robot-feedback-modal-position"
            : ""
        }
      >
        {
          <Modal
            show={state.showStartTutorialModal}
            onHide={() => {}}
            dialogClassName="modal-max-width"
          >
            <BeginTutorialModal
              hideStartModal={() => dispatch(onBeginTutorial())}
              timeAllowedPerQuestion={Math.floor(
                GameTutorialData[gameTutorialId - 1].timeAllowedPerQuestion *
                  timeFactor
              )}
              displayText={{
                task: GameTutorialData[gameTutorialId - 1].task,
                gameTitle: GameTutorialData[gameTutorialId - 1].gameTitle,
                instructions: GameTutorialData[gameTutorialId - 1].instructions,
                beginTutorial: testData.text.beginTutorial,
                timeYouHaveToAnswer: testData.text.timeYouHaveToAnswer
              }}
            />
          </Modal>
        }
        {state.isTimeExpired && (
          <TutorialTimesUpModal
            handleRedoTutorial={handleRedoTutorial}
            timeAllowedPerQuestion={Math.floor(
              GameTutorialData[gameTutorialId - 1].timeAllowedPerQuestion *
                timeFactor
            )}
            displayText={{
              timesUp: testData.text.timesUp,
              outOfTimeForQuestion: testData.text.outOfTimeForQuestion,
              tryAgain: testData.text.tryAgain,
              timeLeftForQuestion: testData.text.timeLeftForQuestion
            }}
          />
        )}
        {state.showStartTutorialModal ||
        state.isAnswerCorrect === null ? null : (
          <TutorialFeedbackModal
            handleProgressNext={handleProgressNext}
            isAnswerCorrect={state.isAnswerCorrect}
            displayText={{
              correctOrIncorrect: state.isAnswerCorrect
                ? testData.text.correct
                : testData.text.incorrect,
              feedbackText: state.isAnswerCorrect
                ? GameTutorialData[gameTutorialId - 1].feedbackText.correct
                : GameTutorialData[gameTutorialId - 1].feedbackText.incorrect,
              next: testData.text.next
            }}
          />
        )}
        {
          <Modal
            show={state.showCompleteTutorialModal}
            onHide={() => {}}
            dialogClassName="modal-max-width"
          >
            <CompleteTutorialModal
              handleRedoTutorial={handleRedoTutorial}
              handleBeginAssessment={handleBeginAssessment}
              numberOfQuestions={
                GameTutorialData[gameTutorialId - 1].numberOfQuestions
              }
              timeAllowedPerQuestion={Math.floor(
                GameTutorialData[gameTutorialId - 1].timeAllowedPerQuestion *
                  timeFactor
              )}
              displayText={{
                task: GameTutorialData[gameTutorialId - 1].task,
                tutorialCompleted: testData.text.tutorialCompleted,
                clickBeginOrRedoTutorial:
                  testData.text.clickBeginOrRedoTutorial,
                numberOfQuestions: testData.text.numberOfQuestions,
                timePerQuestion: testData.text.timePerQuestion,
                questions: testData.text.questions,
                seconds: testData.text.seconds,
                beginAssessment: testData.text.beginAssessment,
                redoTutorial: testData.text.redoTutorial
              }}
            />
          </Modal>
        }
      </Col>
    </>
  );
};

export default GameTutorial;
