import { Hook } from "../utils/Reference/Hook";

type TokenFields = {
  subTestId: string;
  eventId: string;
  returnUrl: string;
};

type GetTokenResult = {
  subTestId: string;
  eventId: string | null;
  returnUrl: string;
};

const useGetToken: Hook<void, GetTokenResult> = () => {
  const locationSearchParams = new URLSearchParams(window.location.search);
  const token = locationSearchParams.get("token");
  if (token) {
    try {
      const { subTestId, eventId, returnUrl } = JSON.parse(
        window.atob(replaceUrlChars(token))
      ) as TokenFields;
      return {
        subTestId,
        eventId,
        returnUrl
      };
    } catch (e) {
      console.error(e);
      return {
        subTestId: "",
        eventId: null,
        returnUrl: ""
      };
    }
  } else {
    return {
      subTestId: "",
      eventId: null,
      returnUrl: ""
    };
  }
};

function replaceUrlChars(token: string) {
  return token.replace(/-/g, "+").replace(/_/g, "/");
}

export default useGetToken;
