import React, { useState, useContext } from "react";
import { navigate } from "@reach/router";
import queryString from "query-string";
import { Container, Row, Col, Card, Image } from "react-bootstrap";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { TestEventContext } from "../../Contexts/TestEventContext";
import invitationSentImage from "../../Resources/images/invitation-sent.png";
import successCheckIcon from "../../Resources/images/check-close-fill.png";
import { linkResults } from "../helpers";

export const LinkResultsVerify = (props: any) => {
  /****************************************************************************
   * Context                                                                  *
   ****************************************************************************/
  const testEventContext = useContext(TestEventContext);

  /****************************************************************************
   * State Variables                                                                  *
   ****************************************************************************/
  const [emailToVerify] = useState(
    queryString.parse(props.location.search).email
  );
  const [verificationResent, setVerificationResent] = useState(false);

  /****************************************************************************
   * Handlers                                                               *
   ****************************************************************************/

  const handleLinkClick = () => {
    navigate("/verify");
  };

  const handleResendVerification = () => {
    const decodedURI: any = decodeURIComponent(String(emailToVerify));
    if (testEventContext && testEventContext.token && decodedURI) {
      linkResults(
        testEventContext.testEventData?.testTaker?.testTakerId,
        decodedURI,
        testEventContext.token
      ).then(success => {
        if (success) {
          setVerificationResent(true);
        }
      });
    }
  };

  /****************************************************************************
   * Styles                                                                  *
   ****************************************************************************/
  const linkButtonStyle: any = {
    color: "#425cc7",
    textDecoration: "underline",
    fontWeight: "bold",
    backgroundColor: "#FFF",
    border: "0",
    margin: 0,
    padding: 0
  };

  const resendVerificationButton: any = {
    backgroundColor: "#FFF",
    border: "solid 1px #425cc7",
    color: "#425cc7",
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "center",
    height: "40px",
    width: "90%",
    lineHeight: "1.71",
    borderRadius: "4px"
  };

  return (
    <Container>
      <Row>
        <Col
          xl={{
            span: 8,
            offset: 2
          }}
          lg={{
            span: 8,
            offset: 2
          }}
          md={{
            span: 10,
            offset: 1
          }}
          sm={12}
        >
          <Card className="oda-card">
            <Header backgroundColor="#FFF" />
            <Card.Body>
              <Row>
                <Col>
                  <div className="text-center mt-3 mb-3">
                    <Image
                      fluid={true}
                      src={invitationSentImage}
                      alt="Invitation Sent"
                      height="160px"
                      width="160px"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-center link-results-header">
                    Please verify your email address to continue
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="text-center mt-4 mb-4 link-results-text">
                    To link previous results with your employee profile, please
                    verify your email address through the email we sent to that
                    email address.
                  </p>
                </Col>
              </Row>
              <Row className="text-center mt-3 mb-3">
                <Col
                  xl={{ span: 6, offset: 3 }}
                  lg={{ span: 6, offset: 3 }}
                  md={{ span: 8, offset: 2 }}
                  sm={12}
                >
                  <button
                    style={resendVerificationButton}
                    onClick={handleResendVerification}
                  >
                    {verificationResent ? (
                      <span className="mb-2 mr-1">
                        <img
                          src={successCheckIcon}
                          alt="success"
                          height="16px"
                          width="16px"
                        />
                      </span>
                    ) : null}
                    {verificationResent
                      ? "Verification Email Sent"
                      : "Resend verification email"}
                  </button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="text-center mt-4 link-results-text">
                    Change your mind?{" "}
                    <button style={linkButtonStyle} onClick={handleLinkClick}>
                      Click here
                    </button>{" "}
                    to start the personality test.
                  </p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Footer />
        </Col>
      </Row>
    </Container>
  );
};
