import React from "react";

interface Props {
  text: string;
  isMobile: boolean;
  isIPad13?: boolean;
}
const Key = ({ text, isMobile, isIPad13 }: Props) => {
  return (
    <React.Fragment>
      <span style={{ margin: "0 7px" }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="45" height="50">
          <g stroke="#000">
            <rect
              width="40"
              height="40"
              x="2"
              y="9"
              fill="#fff"
              strokeWidth="2"
              rx="10"
              ry="10"
            />
            <text
              x="23"
              y="36"
              strokeWidth="0"
              fontFamily="sans-serif"
              fontSize="24"
              textAnchor="middle"
            >
              {text}
            </text>
          </g>
        </svg>
      </span>
      {isMobile || isIPad13 ? null : <span>key</span>}
    </React.Fragment>
  );
};

export default Key;
