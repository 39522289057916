import React, { useEffect } from "react";
import ActionButtons from "../../GameComponents/ActionButtons";
import DisconnectModal from "../../GameComponents/DisconnectModal";
import WordImages from "../../GameComponents/WordImages";
import TimerBar from "../../../../../Components/TimerBar";
import InterruptModal from "../../../../../Components/InterruptModal";

interface Props {
  handleAnswerSubmit: (answer: string) => void;
  onExpiration: () => void;
  placedAtTutorial: boolean;
  showImages: boolean;
  pauseTimer: boolean;
  optionA: string;
  optionB: string;
  questionStartTime: number;
  doPauseTimer: () => void;
  showInterruptModal?: boolean;
  didReconnectFail?: boolean;
  isTutorialTestBegin?: boolean;
  showGameTutorialStartModal?: boolean;
  timeFactor: number;
  answerOptions: { [propName: string]: string };
  pressKeys: { pressFKey: string; pressJKey: string };
  disconnectModalDisplayText: {
    testPaused: string;
    errorSavingQuestion: string;
    pleaseWaitReconnecting: string;
    unableToReconnectTryAgainLater: string;
  };
}

const WeighStationComponent = ({
  handleAnswerSubmit,
  onExpiration,
  placedAtTutorial,
  showImages,
  pauseTimer,
  optionA,
  optionB,
  questionStartTime,
  doPauseTimer,
  showInterruptModal,
  didReconnectFail,
  isTutorialTestBegin,
  showGameTutorialStartModal,
  timeFactor,
  answerOptions,
  pressKeys,
  disconnectModalDisplayText
}: Props) => {
  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    // get initial inner height of window to set they custom css --vh property to be used to size #game-test
    const vh = window.innerHeight * 0.01;
    if (document && document.documentElement) {
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
    return () => window.removeEventListener("keydown", handleKeyPress);
  });

  const handleKeyPress = (event: KeyboardEvent) => {
    // if placed at the tutorial, do not respond to key press if the test hasn't begun
    if (placedAtTutorial && !isTutorialTestBegin) {
      return;
    }
    let answer = null;
    if (event.key.toUpperCase() === "F") {
      answer = "left";
    }
    if (event.key.toUpperCase() === "J") {
      answer = "right";
    }
    if (answer !== null && !pauseTimer) {
      handleAnswerSubmit(answer);
    }
  };

  const handleButtonClick = (event: any) => {
    // if placed at the tutorial, do not respond to button click if the test hasn't begun
    if (placedAtTutorial && !isTutorialTestBegin) {
      return;
    }

    const answer: string = event.currentTarget.dataset.answer;
    if (!pauseTimer) {
      handleAnswerSubmit(answer);
    }
  };

  return (
    <div id="game-test">
      <TimerBar
        questionStartTime={questionStartTime}
        onExpiration={onExpiration}
        pauseTimer={pauseTimer}
        doPauseTimer={doPauseTimer}
        showGameTutorialStartModal={showGameTutorialStartModal}
        timeFactor={timeFactor}
      />
      <div id="weigh-station-background">
        <table id="word-images" style={{ width: "100%" }}>
          <tbody>
            <WordImages
              leftImage={optionA}
              rightImage={optionB}
              isWeighStation={true}
              showImages={showImages}
            />
          </tbody>
        </table>
        <ActionButtons
          leftLabel={answerOptions.redGreater}
          rightLabel={answerOptions.blueGreater}
          pressKeys={pressKeys}
          handleAnswerSubmit={handleButtonClick}
          isWeighStation={true}
        />
      </div>
      {showInterruptModal ? (
        <InterruptModal>
          <DisconnectModal
            didReconnectFail={true}
            disconnectModalDisplayText={disconnectModalDisplayText}
          />
        </InterruptModal>
      ) : null}
    </div>
  );
};

export default WeighStationComponent;
