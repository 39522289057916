// tslint:disable jsx-no-lambda
import React from "react";
import { Row, Col } from "react-bootstrap";
import {
  FaArrowLeft,
  FaArrowRight,
  FaRegFile,
  FaRegFileAlt,
  FaSearch,
  FaDesktop,
  FaDownload,
  FaMusic,
  FaAngleDown,
  FaRegImage
} from "react-icons/fa";
import { FiVideo } from "react-icons/fi";

import word_document from "../CLIKResources/word_document.png";
import excel_document from "../CLIKResources/excel_document.png";
import WindowHeader from "./WindowHeader";

const sidebarMenuItems = [
  "This PC",
  "Download",
  "Documents",
  "Pictures",
  "Music",
  "Video"
];

const getSidebarMenuIcons = (sidebarMenuItem: string) => {
  switch (sidebarMenuItem) {
    case "This PC":
      return <FaDesktop className="mr-2" />;
    case "Download":
      return <FaDownload className="mr-2" />;
    case "Documents":
      return <FaRegFileAlt className="mr-2" />;
    case "Pictures":
      return <FaRegImage className="mr-2" />;
    case "Music":
      return <FaMusic className="mr-2" />;
    case "Video":
      return <FiVideo className="mr-2" />;
  }
};

const menu = sidebarMenuItems.map(sidebarMenuItem => {
  const icon = getSidebarMenuIcons(sidebarMenuItem);
  const sidebarMenuItemStyle = {
    marginLeft: sidebarMenuItem === "This PC" ? "-33px" : "-7px",
    backgroundColor: sidebarMenuItem === "Documents" ? "#d9def4" : "white",
    color: sidebarMenuItem === "Documents" ? "#425cc7" : "black",
    fontSize: sidebarMenuItem === "This PC" ? "14px" : "12.6px"
  };
  return (
    <li key={sidebarMenuItem} style={sidebarMenuItemStyle} id={sidebarMenuItem}>
      {sidebarMenuItem === "This PC" ? (
        <FaAngleDown style={{ fontSize: "20px", marginRight: "5px" }} />
      ) : null}
      {icon}
      {sidebarMenuItem}
    </li>
  );
});

interface MyDocumentsInterface {
  minimized: boolean;
  maximized: boolean;
  handleToggleMinimize(windowName: string): void;
  handleToggleMaximize(windowName: string): void;
  handleCloseWindow(windowName: string): void;
  handleDesktopItemClick(windowName: string): void;
  zIndexValue: number;
}

const MyDocuments = ({
  maximized,
  minimized,
  handleToggleMaximize,
  handleToggleMinimize,
  handleCloseWindow,
  handleDesktopItemClick,
  zIndexValue
}: MyDocumentsInterface) => {
  return (
    <Col
      onClick={() => handleDesktopItemClick("my_documents")}
      xl={maximized ? 12 : 7}
      lg={maximized ? 12 : 7}
      md={maximized ? 12 : 6}
      sm={maximized ? 12 : 6}
      xs={maximized ? 12 : 6}
      style={{
        position: "absolute",
        top: maximized ? "0" : "25px",
        left: maximized ? "0" : "150px",
        minWidth: "702px",
        minHeight: "350px",
        height: maximized ? "100%" : "",
        width: "100%",
        zIndex: zIndexValue,
        display: minimized ? "none" : ""
      }}
    >
      <WindowHeader
        titleText="Documents"
        minimized={minimized}
        maximized={maximized}
        handleToggleMinimize={handleToggleMinimize}
        handleToggleMaximize={handleToggleMaximize}
        handleCloseWindow={handleCloseWindow}
      >
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{
            height: "35px",
            borderBottom: "1px solid #ddddde",
            padding: "0px"
          }}
        >
          <Row
            noGutters={true}
            style={{
              backgroundColor: "#f7f7f7",
              height: "100%",
              padding: "5px",
              fontSize: "14px"
            }}
          >
            <Col
              xl={2}
              lg={2}
              md={2}
              sm={2}
              xs={2}
              style={{ paddingLeft: "10px", maxWidth: "90px" }}
            >
              <FaArrowLeft className="mr-3" color="#005a9e" />
              <FaArrowRight className="mr-3" color="#005a9e" />
            </Col>
            <Col
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={6}
              style={{
                backgroundColor: "white",
                paddingLeft: "10px",
                marginRight: "10px"
              }}
            >
              <FaRegFile className="mr-3" color="#005a9e" />
              <span>
                This PC <span style={{ color: "#75787b" }}>&gt;</span> Documents
              </span>
            </Col>
            <Col
              xl={4}
              lg={4}
              md={4}
              sm={4}
              xs={4}
              style={{ backgroundColor: "white", paddingLeft: "10px" }}
            >
              <FaSearch className="mr-3" color="#005a9e" />
              <span style={{ color: "#75787b" }}>Search Documents</span>
            </Col>
          </Row>
        </Col>
        <Row
          noGutters={true}
          style={{
            backgroundColor: "#FFFFFF",
            width: "100%",
            height: "100%",
            minHeight: "300px",
            maxHeight: "365px"
          }}
        >
          <Col
            xl={3}
            lg={3}
            md={3}
            sm={3}
            xs={3}
            className="pt-2 pl-2"
            style={{
              borderRight: "1px solid #ddddde"
            }}
          >
            <ol style={{ listStyle: "none", marginLeft: "-5px" }}>{menu}</ol>
          </Col>
          <Col xl={9} lg={9} md={9} sm={9} xs={9}>
            <Row className="m-2">
              <Col className="px-0" xl={2} lg={2} md={2} sm={2} xs={2}>
                <button
                  className="button-primary-text documents-button"
                  style={{ cursor: "default" }}
                  onDoubleClick={() => {
                    handleDesktopItemClick("letterDoc");
                    setTimeout(() => {
                      const wordDocContent = document.getElementById(
                        "word-document-content"
                      );
                      if (wordDocContent) {
                        wordDocContent.focus();
                      }
                    }, 300);
                  }}
                >
                  <img src={word_document} alt="word document" />
                  <span>letter.doc</span>
                </button>
              </Col>
              <Col xl={2} lg={2} md={2} sm={2} xs={2} className="px-0">
                <button
                  className="button-primary-text documents-button"
                  style={{ cursor: "default" }}
                >
                  <img src={excel_document} alt="excel document" />
                  <span>budget.xls</span>
                </button>
              </Col>
              <Col xl={2} lg={2} md={2} sm={2} xs={2} className="px-0">
                <button
                  className="button-primary-text documents-button"
                  style={{ cursor: "default" }}
                  onDoubleClick={() => {
                    handleDesktopItemClick("reportDoc");
                    setTimeout(() => {
                      const wordDocContent = document.getElementById(
                        "word-document-content"
                      );
                      if (wordDocContent) {
                        wordDocContent.focus();
                      }
                    }, 300);
                  }}
                >
                  <img src={word_document} alt="word document" />
                  <span>report.doc</span>
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </WindowHeader>
    </Col>
  );
};

export default MyDocuments;
