import React, { useContext } from "react";
import {
  taskOnePracticeArray,
  taskThreePracticeArray,
  taskFourPracticeArray,
  taskSevenPracticeArray,
  taskEightPracticeArray,
  taskNinePracticeArray
} from "../../utils/shared";
import Countdown from "../../Countdown";
import TaskComplete from "../../TaskComplete";
import Instructions from "../../Instructions/Instructions";
import FinalInstructions from "../../Instructions/FinalInstructions";
import BaseTaskTestDisplay from "./BaseTaskTestDisplay";
import { TestEventContext } from "../../../../../Contexts/TestEventContext";
import IncorrectAnswers from "../../Instructions/IncorrectAnswers";
import TasksWrapper from "../TasksWrapper";

interface Props {
  toggleProgressBar(): void;
  proceedToNextTask(): void;
  toggleIsIncorrectDisplay(): void;
  taskId: number;
  subTestId: string;
  language: string;
  isMobile: boolean;
  isMobileOnly: boolean;
  isTablet: boolean;
  isIPad13: boolean;
}

const BaseTask = ({
  toggleProgressBar,
  proceedToNextTask,
  toggleIsIncorrectDisplay,
  taskId,
  language,
  isMobile,
  isMobileOnly,
  isTablet,
  isIPad13
}: Props) => {
  // define the context
  const contextObject = useContext(TestEventContext);

  // define which practice array we are using, so we can check the length to determine when we are finished with
  // the practice part.
  let practiceArray: any[] = [];
  switch (taskId) {
    case 1:
      practiceArray = taskOnePracticeArray;
      break;
    case 3:
      practiceArray = taskThreePracticeArray;
      break;
    case 4:
      practiceArray = taskFourPracticeArray;
      break;
    case 7:
      practiceArray = taskSevenPracticeArray;
      break;
    case 8:
      practiceArray = taskEightPracticeArray;
      break;
    case 9:
      practiceArray = taskNinePracticeArray;
  }

  return (
    <TasksWrapper
      practiceArray={practiceArray}
      toggleProgressBar={toggleProgressBar}
      toggleIsIncorrectDisplay={toggleIsIncorrectDisplay}
      context={contextObject}
      taskId={taskId}
    >
      {({
        isPractice,
        showIncorrectAnswerDisplay,
        practiceCounter,
        showFinalInstructions,
        doShowCountdown,
        showFinishTask,
        updateStartTask,
        togglePractice,
        updatePracticeCounter,
        toggleIncorrectAnswerDisplay,
        showCountdown,
        startTask,
        finishTask,
        taskDataSaved,
        enableButton,
        context
      }) => (
        <React.Fragment>
          {!startTask &&
          !isPractice &&
          !showFinalInstructions &&
          !doShowCountdown &&
          !showFinishTask ? (
            <Instructions
              taskId={taskId}
              togglePractice={togglePractice}
              language={language}
              isMobile={isMobile}
              isMobileOnly={isMobileOnly}
              isIPad13={isIPad13}
            />
          ) : null}
          {!startTask &&
          !isPractice &&
          showFinalInstructions &&
          !doShowCountdown ? (
            <FinalInstructions
              taskId={taskId}
              showCountdown={showCountdown}
              language={language}
              isMobile={isMobile}
              isMobileOnly={isMobileOnly}
              isIPad13={isIPad13}
            />
          ) : null}
          {!startTask &&
          !isPractice &&
          !showFinalInstructions &&
          doShowCountdown ? (
            <Countdown updateStartTask={updateStartTask} language={language} />
          ) : null}
          {(isPractice || startTask) &&
          !showIncorrectAnswerDisplay &&
          !doShowCountdown ? (
            <BaseTaskTestDisplay
              taskId={taskId}
              isPractice={isPractice}
              practiceCounter={practiceCounter}
              practiceArray={practiceArray}
              toggleIncorrectAnswerDisplay={toggleIncorrectAnswerDisplay}
              updatePracticeCounter={updatePracticeCounter}
              finishTask={finishTask}
              isMobile={isMobile}
              isTablet={isTablet}
              isIPad13={isIPad13}
            />
          ) : null}
          {!startTask && !isPractice && showFinishTask ? (
            <TaskComplete
              task={String(taskId)}
              proceedToNextTask={proceedToNextTask}
              language={language}
              testId={context.testIdsArray[context.testIndex]}
              taskDataSaved={taskDataSaved}
              enableButton={enableButton}
            />
          ) : null}
          {isPractice && showIncorrectAnswerDisplay ? (
            <IncorrectAnswers
              taskId={taskId}
              toggleIncorrectAnswerDisplay={toggleIncorrectAnswerDisplay}
              language={language}
              isMobile={isMobile}
              isMobileOnly={isMobileOnly}
              isIPad13={isIPad13}
            />
          ) : null}
        </React.Fragment>
      )}
    </TasksWrapper>
  );
};

export default BaseTask;
