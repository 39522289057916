import React from "react";
import Key from "../../ImageComponents/Key";
import { instructionsObjectEnglish } from "../instructionsObject";
import { instructionsObjectSpanish } from "../instructionsObject";
import InstructionsWrapper from "../InstructionsWrapper";
import NavigateButtons from "../NavigateButtons";
import FingerPositions from "../../Images/finger_positions_DFJK.jpeg";
import FingerPositionsMobile from "../../Images/finger_positions_DFJK_mobile.png";

interface Props {
  togglePractice(): void;
  language: string;
  isMobile: boolean;
  isMobileOnly?: boolean;
  isIPad13?: boolean;
}

const TaskEightInstructions = ({
  togglePractice,
  language,
  isMobile,
  isMobileOnly,
  isIPad13
}: Props) => {
  const instructionsObject =
    language === "english"
      ? instructionsObjectEnglish
      : instructionsObjectSpanish;

  const isMobileAndEnglish = (isMobile || isIPad13) && language === "english";

  return (
    <InstructionsWrapper>
      {({ instructionPage, handleContinue, handleBack }) => {
        const finalPage = 6;
        if (instructionPage > finalPage) {
          togglePractice();
        }
        return (
          <div>
            {instructionPage === 1 ? (
              <div>
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile mt-3"
                      : "instruction-text mt-3"
                  }
                >
                  {instructionsObject[8][instructionPage].text_1}
                </p>
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile mt-3"
                      : "instruction-text mt-3"
                  }
                >
                  {instructionsObject[8][instructionPage].text_2}
                </p>
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile mt-3"
                      : "instruction-text mt-3"
                  }
                >
                  {instructionsObject[8][instructionPage].text_3}
                </p>
              </div>
            ) : null}
            {instructionPage === 2 ? (
              <div
                className={
                  isMobileOnly
                    ? "instruction-text-mobile mt-3"
                    : "instruction-text mt-3"
                }
              >
                <p>
                  {isMobileAndEnglish
                    ? instructionsObject[8][instructionPage].text_1.slice(0, -1)
                    : instructionsObject[8][instructionPage].text_1}
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="D" />,
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="F" />,
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="J" />, and
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="K" />
                  {!isMobile ? "s" : "buttons"}{" "}
                  {instructionsObject[8][instructionPage].text_2}
                </p>
                <div className={isMobile || isIPad13 ? "" : "text-center"}>
                  <p>
                    <Key isMobile={isMobile} isIPad13={isIPad13} text="D" />
                    {instructionsObject[8][instructionPage].text_3}
                  </p>
                  <p>
                    <Key isMobile={isMobile} isIPad13={isIPad13} text="F" />
                    {instructionsObject[8][instructionPage].text_4}
                  </p>
                  <p>
                    <Key isMobile={isMobile} isIPad13={isIPad13} text="J" />
                    {instructionsObject[8][instructionPage].text_5}
                  </p>
                  <p>
                    <Key isMobile={isMobile} isIPad13={isIPad13} text="K" />
                    {instructionsObject[8][instructionPage].text_6}
                  </p>
                </div>
              </div>
            ) : null}
            {instructionPage === 3 ? (
              <div
                className={
                  isMobileOnly
                    ? "instruction-text-mobile mt-3"
                    : "instruction-text mt-3"
                }
              >
                <p>{instructionsObject[8][instructionPage].text_1}</p>
                <div className="text-center task-eight-text">
                  <p>{instructionsObject[8][instructionPage].text_2}</p>
                </div>
                <p>{instructionsObject[8][instructionPage].text_3}</p>
                <p>{instructionsObject[8][instructionPage].text_4}</p>
                <p>{instructionsObject[8][instructionPage].text_5}</p>
                <p>
                  {isMobile || isIPad13
                    ? instructionsObject[8][instructionPage].text_6.slice(0, -5)
                    : instructionsObject[8][instructionPage].text_6}
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="F" />
                </p>
              </div>
            ) : null}
            {instructionPage === 4 ? (
              <div
                className={
                  isMobileOnly
                    ? "instruction-text-mobile mt-3"
                    : "instruction-text mt-3"
                }
              >
                <p>{instructionsObject[8][instructionPage].text_1}</p>
                <div className="text-center task-eight-text">
                  <p>{instructionsObject[8][instructionPage].text_2}</p>
                </div>
                <p>{instructionsObject[8][instructionPage].text_3}</p>
                <p>{instructionsObject[8][instructionPage].text_4}</p>
                <p>
                  {isMobileAndEnglish
                    ? instructionsObject[8][instructionPage].text_5.slice(0, -5)
                    : instructionsObject[8][instructionPage].text_5}
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="K" />
                </p>
              </div>
            ) : null}
            {instructionPage === 5 ? (
              <div
                className={
                  isMobileOnly
                    ? "instruction-text-mobile mt-3"
                    : "instruction-text mt-3"
                }
              >
                <p>
                  {isMobileAndEnglish
                    ? "For this task, you will see D, F, J and K buttons on the screen as shown below."
                    : instructionsObject[8][instructionPage].text_1}
                </p>
                <div className="text-center mt-3">
                  {isMobile || isIPad13 ? (
                    <img
                      src={FingerPositionsMobile}
                      alt="letters F and J as buttons"
                      width="250px"
                      height="67px"
                    />
                  ) : (
                    <img src={FingerPositions} alt="finger positions" />
                  )}
                </div>
              </div>
            ) : null}
            {instructionPage === 6 ? (
              <div
                className={
                  isMobileOnly
                    ? "instruction-text-mobile mt-3"
                    : "instruction-text mt-3"
                }
              >
                <p>{instructionsObject[8][instructionPage].text_1}</p>
                <p>
                  {isMobileAndEnglish
                    ? "If you make a mistake, your screen will display a message."
                    : instructionsObject[8][instructionPage].text_2}
                </p>
                <p>
                  {instructionsObject[8][instructionPage].text_3}
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="D" />,
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="F" />,
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="J" />, and
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="K" />
                  {!isMobile ? "s" : "buttons"}
                  {instructionsObject[8][instructionPage].text_4}
                </p>
              </div>
            ) : null}
            <NavigateButtons
              instructionPage={instructionPage}
              handleBack={handleBack}
              handleContinue={handleContinue}
              finalPage={finalPage}
              language={language}
            />
          </div>
        );
      }}
    </InstructionsWrapper>
  );
};

export default TaskEightInstructions;
