import React from "react";
import { Col } from "react-bootstrap";
import PageContinueButton from "../../../../../Components/PageContinueButton";
import timesup from "../../GameResources/timesup.png";

interface Props {
  handleRedoTutorial: () => void;
  timeAllowedPerQuestion: number;
  displayText: {
    timesUp: string;
    outOfTimeForQuestion: string;
    tryAgain: string;
    timeLeftForQuestion: string;
  };
}

const TutorialTimesUpModal = ({
  handleRedoTutorial,
  timeAllowedPerQuestion,
  displayText
}: Props) => {
  const handleRetry = () => {
    handleRedoTutorial();
  };

  return (
    <Col className="game-tutorial-modal">
      <div className="game-tutorial-feedback">
        <h2>
          <img
            className="game-tutorial-icon-img"
            src={timesup}
            alt="times-up"
          />
          {displayText.timesUp}
        </h2>
        <p>{displayText.outOfTimeForQuestion}</p>
        <p>{displayText.timeLeftForQuestion}</p>
        <PageContinueButton
          handler={handleRetry}
          buttonText={displayText.tryAgain}
        />
      </div>
    </Col>
  );
};

export default TutorialTimesUpModal;
