import React, { useState, useEffect } from "react";
import RobotInspectorComponent from "./GameTutorialComponents/RobotInspectorComponent";

interface Props {
  exampleQuestion: { optionA: string; optionB: string };
  isTutorialTestBegin: boolean;
  handleCheckAnswer: (userInputAnswer: string) => void;
  handleShowTimeExpiredModal: () => void;
  showGameTutorialStartModal: boolean;
  placedAtTutorial: boolean;
  isAnswerCorrect: boolean | null;
  timeFactor: number;
  answerOptions: { [propName: string]: string };
  pressKeys: { pressFKey: string; pressJKey: string };
  disconnectModalDisplayText: {
    testPaused: string;
    errorSavingQuestion: string;
    pleaseWaitReconnecting: string;
    unableToReconnectTryAgainLater: string;
  };
}

const RobotInspectorTutorial = ({
  exampleQuestion,
  isTutorialTestBegin,
  handleCheckAnswer,
  handleShowTimeExpiredModal,
  showGameTutorialStartModal,
  placedAtTutorial,
  isAnswerCorrect,
  timeFactor,
  answerOptions,
  pressKeys,
  disconnectModalDisplayText
}: Props) => {
  const [startTime, setStartTime] = useState(Date.now());
  const [pauseTimer, setPauseTimer] = useState(false);
  const [showImages, setShowImages] = useState(false);
  const [isRobotTutorialTestBegin, setIsRobotTutorialTestBegin] =
    useState(isTutorialTestBegin);

  useEffect(() => {
    setStartTime(Date.now());
    // delay the showing of the next images by 200 ms
    const timerID = setTimeout(() => {
      if (showGameTutorialStartModal) {
        setShowImages(false);
        setPauseTimer(false);
      }
    }, 200);
    return () => clearTimeout(timerID);
  }, [showGameTutorialStartModal]);

  useEffect(() => {
    setStartTime(Date.now());
    setShowImages(showGameTutorialStartModal ? false : true);
    setPauseTimer(false);
    setIsRobotTutorialTestBegin(isTutorialTestBegin);
  }, [isTutorialTestBegin, showGameTutorialStartModal]);

  /* ---------- Tutorial Event Handlers ---------- */

  const doPauseTimer = () => {
    setPauseTimer(true);
  };

  const handleAnswerSubmit = (answer: string) => {
    setPauseTimer(true);
    handleCheckAnswer(answer);
  };

  const onExpiration = () => {
    setShowImages(false);
    setPauseTimer(true);
    handleShowTimeExpiredModal();
  };

  return (
    <RobotInspectorComponent
      handleAnswerSubmit={handleAnswerSubmit}
      onExpiration={onExpiration}
      showImages={showImages}
      pauseTimer={pauseTimer}
      placedAtTutorial={placedAtTutorial}
      leftRobotImage={exampleQuestion.optionA}
      rightRobotImage={exampleQuestion.optionB}
      questionStartTime={startTime + 475}
      doPauseTimer={doPauseTimer}
      isTutorialTestBegin={isRobotTutorialTestBegin}
      showGameTutorialStartModal={showGameTutorialStartModal}
      isAnswerCorrect={isAnswerCorrect} // this prop is specifically needed for RobotInspectorTutorial to display the arrows that point out the difference between the robots
      timeFactor={timeFactor}
      answerOptions={answerOptions}
      pressKeys={pressKeys}
      disconnectModalDisplayText={disconnectModalDisplayText}
    />
  );
};

export default RobotInspectorTutorial;
