import React from "react";

const SpatialMemoryExample = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="350" height="80">
    <path fill="#fff" stroke="#000" strokeWidth="2" d="M0 1h40v40H0z" />
    <text
      x="30"
      y="-66"
      stroke="#000"
      strokeWidth="0"
      fontFamily="serif"
      fontSize="24"
      textAnchor="middle"
      transform="matrix(1 0 0 .57143 0 52)"
    >
      5
    </text>
    <path fill="#fff" stroke="#000" strokeWidth="2" d="M50 1h40v40H50z" />
    <text
      x="60"
      y="-29"
      stroke="#000"
      strokeWidth="0"
      fontFamily="serif"
      fontSize="24"
      textAnchor="middle"
      transform="matrix(1 0 0 .57143 0 52)"
    >
      5
    </text>
    <path
      fill="#fff"
      stroke="#000"
      strokeWidth="2"
      d="M110 1h40v40h-40zM170 1h40v40h-40zM230 1h40v40h-40zM290 1h40v40h-40z"
    />
    <text
      x="141"
      y="-27"
      stroke="#000"
      strokeWidth="0"
      fontFamily="serif"
      fontSize="24"
      textAnchor="middle"
      transform="matrix(1 0 0 .57143 0 52)"
    >
      5
    </text>
    <text
      x="179"
      y="-27"
      stroke="#000"
      strokeWidth="0"
      fontFamily="serif"
      fontSize="24"
      textAnchor="middle"
      transform="matrix(1 0 0 .57143 0 52)"
    >
      5
    </text>
    <text
      x="261"
      y="-26"
      stroke="#000"
      strokeWidth="0"
      fontFamily="serif"
      fontSize="24"
      textAnchor="middle"
      transform="matrix(1 0 0 .57143 0 52)"
    >
      5
    </text>
    <text
      x="299"
      y="-64"
      stroke="#000"
      strokeWidth="0"
      fontFamily="serif"
      fontSize="24"
      textAnchor="middle"
      transform="matrix(1 0 0 .57143 0 52)"
    >
      5
    </text>
    <g>
      <path
        fill="#00f"
        d="M186 53l-7-7h-2l-7 7v1a1 1 0 0 0 2 0l5-5v19l1 1 1-1V49l5 5h2v-1zM269 52l-7-7h-1l-7 7v2a1 1 0 0 0 1 0l5-5v18l1 1 2-1V49l5 5h1v-2z"
      />
    </g>
  </svg>
);
export default SpatialMemoryExample;
