import React from "react";
import sadCarrot from "../../Resources/images/sad-carrot.png";

const NoResults = () => (
  <div
    className="d-flex flex-column justify-content-center align-items-center mt-3"
    style={{ padding: "0px 50px" }}
  >
    <img src={sadCarrot} alt="Sad Search Icon" width="32px" />
    <p
      style={{
        maxWidth: "400px",
        width: "100%",
        fontSize: "14px",
        textAlign: "center"
      }}
      className="mt-3"
    >
      Your search returned no results. Make sure you spelled all words
      correctly, or try different search words.
    </p>
  </div>
);

export default NoResults;
