import React from "react";
import TaskOneIncorrectDisplay from "./IncorrectAnswers/TaskOneIncorrectDisplay";
import VigilanceIncorrectDisplay from "./IncorrectAnswers/VigilanceIncorrectDisplay";
import TaskThreeIncorrectDisplay from "./IncorrectAnswers/TaskThreeIncorrectDisplay";
import TaskFourIncorrectDisplay from "./IncorrectAnswers/TaskFourIncorrectDisplay";
import VerbalMemoryIncorrectDisplay from "./IncorrectAnswers/VerbalMemoryIncorrectDisplay";
import SpatialMemoryIncorrectDisplay from "./IncorrectAnswers/SpatialMemoryIncorrectDisplay";
import TaskSevenIncorrectDisplay from "./IncorrectAnswers/TaskSevenIncorrectDisplay";
import TaskNineIncorrectDisplay from "./IncorrectAnswers/TaskNineIncorrectDisplay";
import TaskEightIncorrectDisplay from "./IncorrectAnswers/TaskEightIncorrectDisplay";

interface Props {
  taskId: number;
  language: string;
  toggleIncorrectAnswerDisplay(): void;
  isMobile: boolean;
  isMobileOnly?: boolean;
  isIPad13: boolean;
}

const IncorrectAnswers = ({
  taskId,
  toggleIncorrectAnswerDisplay,
  language,
  isMobile,
  isMobileOnly,
  isIPad13
}: Props) => {
  return (
    <div>
      {taskId === 1 ? (
        <TaskOneIncorrectDisplay
          toggleIncorrectAnswerDisplay={toggleIncorrectAnswerDisplay}
          language={language}
          isMobile={isMobile}
          isMobileOnly={isMobileOnly}
          isIPad13={isIPad13}
        />
      ) : null}
      {taskId === 2 ? (
        <VigilanceIncorrectDisplay
          toggleIncorrectAnswerDisplay={toggleIncorrectAnswerDisplay}
          language={language}
          isMobile={isMobile}
          isMobileOnly={isMobileOnly}
          isIPad13={isIPad13}
        />
      ) : null}
      {taskId === 3 ? (
        <TaskThreeIncorrectDisplay
          toggleIncorrectAnswerDisplay={toggleIncorrectAnswerDisplay}
          language={language}
          isMobile={isMobile}
          isMobileOnly={isMobileOnly}
          isIPad13={isIPad13}
        />
      ) : null}
      {taskId === 4 ? (
        <TaskFourIncorrectDisplay
          toggleIncorrectAnswerDisplay={toggleIncorrectAnswerDisplay}
          language={language}
          isMobile={isMobile}
          isMobileOnly={isMobileOnly}
          isIPad13={isIPad13}
        />
      ) : null}
      {taskId === 5 ? (
        <VerbalMemoryIncorrectDisplay
          toggleIncorrectAnswerDisplay={toggleIncorrectAnswerDisplay}
          language={language}
          isMobile={isMobile}
          isMobileOnly={isMobileOnly}
          isIPad13={isIPad13}
        />
      ) : null}
      {taskId === 6 ? (
        <SpatialMemoryIncorrectDisplay
          toggleIncorrectAnswerDisplay={toggleIncorrectAnswerDisplay}
          language={language}
          isMobile={isMobile}
          isMobileOnly={isMobileOnly}
          isIPad13={isIPad13}
        />
      ) : null}
      {taskId === 7 ? (
        <TaskSevenIncorrectDisplay
          toggleIncorrectAnswerDisplay={toggleIncorrectAnswerDisplay}
          language={language}
          isMobile={isMobile}
          isMobileOnly={isMobileOnly}
          isIPad13={isIPad13}
        />
      ) : null}
      {taskId === 8 ? (
        <TaskEightIncorrectDisplay
          toggleIncorrectAnswerDisplay={toggleIncorrectAnswerDisplay}
          language={language}
          isMobile={isMobile}
          isMobileOnly={isMobileOnly}
          isIPad13={isIPad13}
        />
      ) : null}
      {taskId === 9 ? (
        <TaskNineIncorrectDisplay
          toggleIncorrectAnswerDisplay={toggleIncorrectAnswerDisplay}
          language={language}
          isMobile={isMobile}
          isMobileOnly={isMobileOnly}
          isIPad13={isIPad13}
        />
      ) : null}
    </div>
  );
};

export default IncorrectAnswers;
