import React from "react";

interface AvatarInterface {
  name: string;
  backgroundColor: string;
}

const Avatar = ({ name, backgroundColor }: AvatarInterface) => {
  const [firstName, lastName] = name.split(" ");

  return (
    <div style={{ marginRight: "10px", marginTop: "4px" }}>
      <span
        style={{
          backgroundColor: backgroundColor,
          color: "white",
          display: "block",
          padding: "6px",
          borderRadius: "50%",
          height: "35px",
          width: "35px"
        }}
      >
        {firstName.charAt(0).toUpperCase()}
        {lastName.charAt(0).toUpperCase()}
      </span>
    </div>
  );
};

export default Avatar;
