import React from "react";
import Key from "../../ImageComponents/Key";
import { instructionsObjectEnglish } from "../instructionsObject";
import { instructionsObjectSpanish } from "../instructionsObject";
import InstructionsWrapper from "../InstructionsWrapper";
import NavigateButtons from "../NavigateButtons";
import FingerPositions from "../../Images/finger_positions_FJ.jpeg";
import FingerPositionsMobile from "../../Images/finger_positions_FJ_mobile.png";

interface Props {
  togglePractice(): void;
  language: string;
  isMobile: boolean;
  isMobileOnly?: boolean;
  isIPad13?: boolean;
}

const TaskSevenInstructions = ({
  togglePractice,
  language,
  isMobile,
  isMobileOnly,
  isIPad13
}: Props) => {
  const instructionsObject =
    language === "english"
      ? instructionsObjectEnglish
      : instructionsObjectSpanish;

  const isMobileAndEnglish = (isMobile || isIPad13) && language === "english";

  return (
    <InstructionsWrapper>
      {({ instructionPage, handleContinue, handleBack }) => {
        const finalPage = 4;
        if (instructionPage > finalPage) {
          togglePractice();
        }
        return (
          <div>
            {instructionPage === 1 ? (
              <div>
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile mt-3"
                      : "instruction-text mt-3"
                  }
                >
                  {instructionsObject[7][instructionPage].text_1}
                </p>
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile mt-3"
                      : "instruction-text mt-3"
                  }
                >
                  {instructionsObject[7][instructionPage].text_2}
                </p>

                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile mt-3"
                      : "instruction-text mt-3"
                  }
                >
                  {isMobileAndEnglish
                    ? instructionsObject[7][instructionPage].text_3a.slice(
                        0,
                        -5
                      )
                    : instructionsObject[7][instructionPage].text_3a}
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="J" />
                  {isMobileAndEnglish
                    ? instructionsObject[7][instructionPage].text_3b.slice(1)
                    : instructionsObject[7][instructionPage].text_3b}
                </p>
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile"
                      : "instruction-text"
                  }
                >
                  {isMobileAndEnglish
                    ? instructionsObject[7][instructionPage].text_4a.slice(
                        0,
                        -5
                      )
                    : instructionsObject[7][instructionPage].text_4a}
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="F" />
                  {isMobileAndEnglish
                    ? instructionsObject[7][instructionPage].text_4b.slice(1)
                    : instructionsObject[7][instructionPage].text_4b}
                </p>
              </div>
            ) : null}
            {instructionPage === 2 ? (
              <div
                className={
                  isMobileOnly
                    ? "instruction-text-mobile mt-3"
                    : "instruction-text mt-3"
                }
              >
                <p>{instructionsObject[7][instructionPage].text_1}</p>
                <ul
                  className="text-center"
                  style={{ listStyleType: "none", fontSize: "24px" }}
                >
                  <li>{instructionsObject[7][instructionPage].text_2}</li>
                  <li>{instructionsObject[7][instructionPage].text_3}</li>
                  <li className="true_statement">
                    {instructionsObject[7][instructionPage].text_4}
                  </li>
                </ul>
                <p className={isMobile || isIPad13 ? "mt-3" : ""}>
                  {isMobileAndEnglish
                    ? instructionsObject[7][instructionPage].text_5a.slice(
                        0,
                        -5
                      )
                    : instructionsObject[7][instructionPage].text_5a}
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="F" />
                  {instructionsObject[7][instructionPage].text_5b}
                </p>
                <p className="text-center">
                  {instructionsObject[7][instructionPage].text_6}
                </p>
              </div>
            ) : null}
            {instructionPage === 3 ? (
              <div
                className={
                  isMobileOnly
                    ? "instruction-text-mobile mt-3"
                    : "instruction-text mt-3"
                }
              >
                {isMobileAndEnglish
                  ? "You will see F and J buttons on the screen as shown below"
                  : instructionsObject[7][instructionPage].text_1}
                <div className="text-center mt-3">
                  {isMobile || isIPad13 ? (
                    <img
                      src={FingerPositionsMobile}
                      alt="letters F and J as buttons"
                      width="250px"
                      height="67px"
                    />
                  ) : (
                    <img src={FingerPositions} alt="finger positions" />
                  )}
                </div>
              </div>
            ) : null}
            {instructionPage === 4 ? (
              <div
                className={
                  isMobileOnly
                    ? "instruction-text-mobile mt-3"
                    : "instruction-text mt-3"
                }
              >
                <p>{instructionsObject[7][instructionPage].text_1}</p>
                <p>
                  {isMobileAndEnglish
                    ? "If you make a mistake, your screen will display a message."
                    : instructionsObject[7][instructionPage].text_2}
                </p>
                <p>{instructionsObject[7][instructionPage].text_3}</p>
                <p>
                  {isMobileAndEnglish
                    ? instructionsObject[7][instructionPage].text_4a.slice(
                        0,
                        -5
                      )
                    : instructionsObject[7][instructionPage].text_4a}
                  <span>
                    <Key isMobile={isMobile} isIPad13={isIPad13} text="J" />
                  </span>
                  {isMobileAndEnglish
                    ? instructionsObject[7][instructionPage].text_4b.slice(1)
                    : instructionsObject[7][instructionPage].text_4b}
                </p>
                <p>
                  {isMobileAndEnglish
                    ? instructionsObject[7][instructionPage].text_5a.slice(
                        0,
                        -5
                      )
                    : instructionsObject[7][instructionPage].text_5a}
                  <span>
                    <Key isMobile={isMobile} isIPad13={isIPad13} text="F" />
                  </span>
                  {isMobileAndEnglish
                    ? instructionsObject[7][instructionPage].text_5b.slice(1)
                    : instructionsObject[7][instructionPage].text_5b}
                </p>
              </div>
            ) : null}
            <NavigateButtons
              instructionPage={instructionPage}
              handleBack={handleBack}
              handleContinue={handleContinue}
              finalPage={finalPage}
              language={language}
            />
          </div>
        );
      }}
    </InstructionsWrapper>
  );
};

export default TaskSevenInstructions;
