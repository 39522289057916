/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { Button, Row, Col } from "react-bootstrap";
import Shape from "../../ImageComponents/Shape";
import { taskTwoPracticeArray } from "../../utils/shared";
import useInterval from "../../../../../CustomHooks/useInterval";
import { MrabAnswerData } from "../../../../../Interfaces/AnswerData";
import { TestEventContext } from "../../../../../Contexts/TestEventContext";
import { navigate } from "@reach/router";
import { isEventExpired } from "../../../../../utils/shared";

interface Props {
  isPractice: boolean;
  practiceCounter: number;
  testArray: any;
  toggleIncorrectAnswerDisplay(): void;
  updatePracticeCounter(): void;
  finishTask(): void;
  isMobile: boolean;
  isTablet: boolean;
  isIPad13: boolean;
}

const VigilanceTestDisplay = ({
  isPractice,
  practiceCounter,
  toggleIncorrectAnswerDisplay,
  updatePracticeCounter,
  finishTask,
  testArray,
  isMobile,
  isTablet,
  isIPad13
}: Props) => {
  // set the state and set state variables
  const [testIndex, setTestIndex] = useState(0);
  const [startTime, setStartTime] = useState(Date.now());
  const [showImage, setShowImage] = useState(false);
  const [answer, setAnswer]: any = useState(null);
  const [intervalTime, setIntervalTime] = useState(0);

  const context = useContext(TestEventContext);

  // when the component first mounts, start a new timer
  useEffect(() => {
    if (!isPractice) {
      setStartTime(Date.now());
    }
  }, [isPractice]);

  useEffect(() => {
    if (testIndex === testArray - 1) {
      finishTask();
    } else if (isPractice) {
      const delay: number = taskTwoPracticeArray[practiceCounter].delay;
      setIntervalTime(delay);
    } else if (!isPractice && testArray.length > 0) {
      const delay: number = testArray[testIndex].delay;
      setIntervalTime(delay);
    }
  }, [testIndex, testArray, finishTask, isPractice, practiceCounter]);

  // for the first image, this effect will show it for the 500 milliseconds only (needed to be separated out from the
  // useInterval hook).
  useEffect(() => {
    setShowImage(true);
    setTimeout(() => {
      setShowImage(false);
    }, 500);
  }, []);

  // cycle through the images in the appropriate array, displaying them at various intervals, depending on the image.
  useInterval(() => {
    if (isPractice) {
      if (answer !== null) {
        updatePracticeCounter();
        setAnswer(null);
        setShowImage(true);
        setTimeout(() => {
          setShowImage(false);
        }, 500);
      } else {
        toggleIncorrectAnswerDisplay();
        window.removeEventListener("keyup", handleKeyPress);
      }
    } else {
      // start a new timer
      setStartTime(Date.now());
      // reset answer value
      if (answer === null) {
        const answerData: MrabAnswerData = {
          timeTaken: testArray[Number(testIndex)].delay / 1000,
          answer: 0,
          stimulusId: testArray[Number(testIndex)].stimulusId
        };
        // retrieve existing answer, if any, from local storage
        const existingAnswers: any = localStorage.getItem("a");

        // create an array from existing answer, or if there none (first question) initialize an empty array
        const newAnswers =
          existingAnswers !== null ? JSON.parse(existingAnswers) : [];

        // push the new answerData to the existing answers and set to local storage
        newAnswers.push(answerData);
        localStorage.setItem("a", JSON.stringify(newAnswers));
      } else {
        setAnswer(null);
      }

      // set up timeout that only shows the image for 500 milliseconds
      setShowImage(true);
      if (testIndex === testArray.length - 1) {
        finishTask();
      }

      setTimeout(() => {
        setShowImage(false);
      }, 500);

      // increment the test index (moves to the next image)
      setTestIndex(testIndex + 1);
    }
  }, intervalTime);

  /* ---------- Event Handler ---------- */

  const handleKeyPress = (event: KeyboardEvent) => {
    if (context && !isEventExpired(context.eventExpirationDate)) {
      if (event.key.toLowerCase() === "f" || event.key.toLowerCase() === "j") {
        if (isPractice) {
          setAnswer(event.key);
          if (
            event.key.toLowerCase() !==
            taskTwoPracticeArray[practiceCounter].correctAnswer.toLowerCase()
          ) {
            toggleIncorrectAnswerDisplay();
          }
        } else {
          window.removeEventListener("keyup", handleKeyPress);
          // set time taken
          const endTime: number = Date.now();
          const timeTaken: number | null =
            startTime !== null ? (endTime - startTime) / 1000 : null;
          setAnswer(event.key.toLowerCase());
          // set answer data to be saved to local storage

          const answerData: MrabAnswerData = {
            timeTaken,
            answer: event.key.toLowerCase(),
            stimulusId: testArray[Number(testIndex)].stimulusId
          };

          // retrieve existing answer, if any, from local storage
          const existingAnswers: any = localStorage.getItem("a");

          // create an array from existing answer, or if there none (first question) initialize an empty array
          const newAnswers =
            existingAnswers !== null ? JSON.parse(existingAnswers) : [];

          // push the new answerData to the existing answers and set to local storage
          newAnswers.push(answerData);
          localStorage.setItem("a", JSON.stringify(newAnswers));

          // if testArray is empty (old state is at length 1), the task is complete so send the answers to redis
          // and show the taskComplete display.
          if (testIndex === testArray.length - 1) {
            finishTask();
          }
        }
      }
    } else {
      // if the event has expired, we need to update the error message, and
      // navigate to the overview page immediately
      context?.updateExpiredMessage();
      navigate("/overview");
    }
  };

  const handleClickEvent = (event: any) => {
    if (context && !isEventExpired(context.eventExpirationDate)) {
      if (
        event.currentTarget.value === "f" ||
        event.currentTarget.value === "j"
      ) {
        if (isPractice) {
          setAnswer(event.key);
          if (
            event.currentTarget.value !==
            taskTwoPracticeArray[practiceCounter].correctAnswer.toLowerCase()
          ) {
            toggleIncorrectAnswerDisplay();
          }
        } else {
          window.removeEventListener("click", handleClickEvent);
          // set time taken
          const endTime: number = Date.now();
          const timeTaken: number | null =
            startTime !== null ? (endTime - startTime) / 1000 : null;
          setAnswer(event.currentTarget.value);
          // set answer data to be saved to local storage

          const answerData: MrabAnswerData = {
            timeTaken,
            answer: event.currentTarget.value,
            stimulusId: testArray[Number(testIndex)].stimulusId
          };

          // retrieve existing answer, if any, from local storage
          const existingAnswers: any = localStorage.getItem("a");

          // create an array from existing answer, or if there none (first question) initialize an empty array
          const newAnswers =
            existingAnswers !== null ? JSON.parse(existingAnswers) : [];

          // push the new answerData to the existing answers and set to local storage
          newAnswers.push(answerData);
          localStorage.setItem("a", JSON.stringify(newAnswers));

          // if testArray is empty (old state is at length 1), the task is complete so send the answers to redis
          // and show the taskComplete display.
          if (testIndex === testArray.length - 1) {
            finishTask();
          }
        }
      }
    } else {
      // if the event has expired, we need to update the error message, and
      // navigate to the overview page immediately
      context?.updateExpiredMessage();
      navigate("/overview");
    }
  };

  // add and remove the keyup event listener
  useEffect(() => {
    if (isMobile || isIPad13) {
      window.addEventListener("click", handleClickEvent);
      return () => {
        window.removeEventListener("click", handleClickEvent);
      };
    } else {
      window.addEventListener("keyup", handleKeyPress);
      return () => {
        window.removeEventListener("keyup", handleKeyPress);
      };
    }
  }, [practiceCounter, testIndex]);

  return (
    <React.Fragment>
      <div className="task-two-center-screen">
        {isPractice && showImage ? (
          <Shape
            shape={taskTwoPracticeArray[practiceCounter].shape}
            fill={taskTwoPracticeArray[practiceCounter].fill}
            isInstructions={false}
          />
        ) : null}
        {!isPractice && showImage ? (
          <Shape
            shape={testArray[Number(testIndex)].shape}
            fill="#999"
            isInstructions={false}
          />
        ) : null}
      </div>
      {isMobile || isIPad13 ? (
        <Row
          style={{
            backgroundColor: "#f5f5f5",
            position: "fixed",
            height: "100px",
            width: "100%",
            bottom: 0,
            marginLeft: "-16px",
            marginRight: "-16px"
          }}
        >
          <Col
            sm={6}
            xs={6}
            className="my-auto"
            style={{ paddingRight: "5px" }}
          >
            <Button
              className={
                isTablet || isIPad13
                  ? "my-1 py-1 float-right ripple"
                  : "my-1 py-1 ripple"
              }
              style={{
                backgroundColor: "#ffffff",
                width: isTablet || isIPad13 ? "50%" : "100%",
                borderColor: "#000000",
                borderRadius: "10px",
                borderWidth: "2px"
              }}
              value="f"
              onClick={handleClickEvent}
            >
              <span style={{ color: "#000000", fontSize: "40px" }}>F</span>
            </Button>
          </Col>
          <Col sm={6} xs={6} className="my-auto" style={{ paddingLeft: "5px" }}>
            <Button
              className="my-1 py-1 ripple"
              style={{
                backgroundColor: "#ffffff",
                width: isTablet || isIPad13 ? "50%" : "100%",
                borderColor: "#000000",
                borderRadius: "10px",
                borderWidth: "2px"
              }}
              value="j"
              onClick={handleClickEvent}
            >
              <span style={{ color: "#000000", fontSize: "40px" }}>J</span>
            </Button>
          </Col>
        </Row>
      ) : null}
    </React.Fragment>
  );
};

export default VigilanceTestDisplay;
