import { ChartJsLabelPlugin } from "./ChartJsLabelPlugin";

export const getChartConfig = (scores, isIllustrait, isWIR) => {
  const labels = isIllustrait
    ? [
        "Reliable",
        "Ambitious",
        "Socially Bold",
        "Innovative",
        "Self-willed",
        "Critical",
        "Reserved",
        "Careful"
      ]
    : [
        "Achievement",
        "Motivation",
        "Conscientiousness",
        "Assertiveness",
        "Extroversion",
        "Cooperativeness",
        "Competitiveness",
        "Patience",
        "Self-Confidence",
        "Openness"
      ];

  // Illustrait charts have 8 data points while regular WIR chart has 10
  const datasets = [
    {
      data: isIllustrait
        ? [25, 25, 25, 25, 25, 25, 25, 25]
        : [25, 25, 25, 25, 25, 25, 25, 25, 25, 25],
      fill: true,
      borderColor: "rgb(183.6, 191.25, 201.45)",
      backgroundColor: "rgba(204, 209, 219, 1)",
      borderWidth: 1,
      pointBorderWidth: 0,
      pointHitRadius: 0,
      pointRadius: 0
    },
    {
      data: scores,
      fill: true,
      backgroundColor: "rgba(0, 31, 78, 0.2)",
      borderColor: "rgb(0, 31, 78)",
      pointBackgroundColor: "rgb(0, 31, 78)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgb(0, 31, 78)"
    },
    {
      data: isIllustrait
        ? [75, 75, 75, 75, 75, 75, 75, 75]
        : [75, 75, 75, 75, 75, 75, 75, 75, 75, 75],
      fill: true,
      backgroundColor: "rgba(255, 255, 255, 0)",
      borderColor: "rgb(100, 100, 100)",
      borderDash: [3, 3],
      borderDashOffset: 3,
      borderWidth: 2,
      pointBorderWidth: 0,
      pointHitRadius: 0,
      pointRadius: 0
    }
  ];

  const pointLabels = {
    // the Illustrait labels' fontColor needs to be transparent because we will overlay
    // them with new labels via the ChartJsLabelPlugin, and need to avoid visual overlap.
    fontColor: isIllustrait
      ? [
          "rgba(0, 0, 0, 0)",
          "rgba(0, 0, 0, 0)",
          "rgba(0, 0, 0, 0)",
          "rgba(0, 0, 0, 0)",
          "rgba(0, 0, 0, 0)",
          "rgba(0, 0, 0, 0)",
          "rgba(0, 0, 0, 0)",
          "rgba(0, 0, 0, 0)"
        ]
      : [
          "#425CC7",
          "#425CC7",
          "#425CC7",
          "#ffa300",
          "#ffa300",
          "#ffa300",
          "#FF5C39",
          "#FF5C39",
          "#2dccd3",
          "#2dccd3"
        ],
    fontStyle: "bold",
    fontSize: 13
  };

  const config = {
    type: "radar",
    data: {
      labels,
      datasets
    },
    options: {
      animation: false,
      legend: {
        display: false
      },
      scale: {
        ticks: {
          beginAtZero: true,
          min: 0,
          max: 125,
          stepSize: 25,
          display: false
        },
        pointLabels
      },
      layout: {
        padding: {
          top: isIllustrait ? 65 : 0,
          right: isIllustrait ? 0 : 25,
          bottom: isIllustrait ? 65 : 0,
          left: isIllustrait ? 0 : 25
        }
      },
      tooltips: {
        enabled: false
      }
    },
    plugins: isIllustrait ? [ChartJsLabelPlugin(isWIR)] : ""
  };

  return config;
};
