import React from "react";
import FadeContent from "./FadeContent";
import lincolnImage from "../../Resources/images/lincoln.png";

interface LincolnResultsSummaryInterface {
  showFadeContent: boolean;
}

const LincolnResultsSummary = ({
  showFadeContent
}: LincolnResultsSummaryInterface) => {
  const resultsSummaryLinks = [
    "Quotes",
    "Education",
    "Speeches",
    "Top Stories",
    "Achievements",
    "Books",
    "Career",
    "Personal Life",
    "Family & Childhood",
    "Movies"
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: 16,
          justifyContent: "space-around",
          alignItems: "center"
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            gap: 16,
            justifyContent: "start",
            alignItems: "center",
            padding: "16px",
            borderRadius: "8px",
            border: "1px solid #CBCCCD"
          }}
        >
          <img src={lincolnImage} alt="Lincoln" width="51px" height="50px" />
          <div>
            <div style={{ fontSize: "24px", fontWeight: 500 }}>
              Abraham Lincoln
            </div>
            <div style={{ fontSize: "12px" }}>
              President of the United States
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            flex: 1,
            padding: "16px",
            borderRadius: "8px",
            backgroundColor: "#ECEEF9"
          }}
        >
          <div style={{ fontSize: "12px" }}>Date of birth</div>
          <div style={{ fontSize: "24px", fontWeight: 700, color: "#2E408A" }}>
            February 12, 1809
          </div>
        </div>
      </div>
      <div
        style={{
          margin: "16px 0",
          display: "flex",
          gap: "8px",
          alignItems: "center",
          justifyContent: "start",
          flexWrap: "nowrap",
          width: "100%",
          overflow: "hidden",
          position: "relative"
        }}
      >
        {resultsSummaryLinks.map(link => (
          <div
            key={link}
            style={{
              borderRadius: "24px",
              padding: "8px 18px",
              border: "1px solid #425CC7",
              backgroundColor: "#ECEEF9",
              color: "#2E408A",
              fontSize: "14px",
              fontWeight: 700,
              whiteSpace: "nowrap"
            }}
          >
            {link}
          </div>
        ))}
        {showFadeContent && (
          <FadeContent showIcon={false} width="24px" height="24px" />
        )}
      </div>
    </div>
  );
};

export default LincolnResultsSummary;
