export const getRedirectUrl = (context: any) => {
  let redirectUrl: any = null;
  const testData: any = context.testEventData;

  if (
    context !== null &&
    context.optionalReturnUrl &&
    context.optionalReturnUrl !== null
  ) {
    redirectUrl = `${context.optionalReturnUrl}`;
  }

  // some fairly dense conditional here, but it is basically checking whether or not the redirectUrl needs to
  // contain the eventId and/or the orderId, and if so, determines where to get those values.
  if (redirectUrl === null) {
    if (testData && testData.exitPage.customExitPageType === "redirect") {
      redirectUrl = testData.exitPage.redirectUrl;
      if (
        context &&
        testData.exitPage.includeEventId &&
        !testData.exitPage.includeOrderId
      ) {
        redirectUrl = `${redirectUrl}?event=${context.eventId}`;
      }
      if (
        context &&
        !testData.exitPage.includeEventId &&
        testData.exitPage.includeOrderId
      ) {
        redirectUrl = `${redirectUrl}?orderId=${
          context.testEventData.orderId !== null
            ? context.testEventData.orderId
            : context.orderId
        }`;
      }
      if (
        context &&
        testData.exitPage.includeEventId &&
        testData.exitPage.includeOrderId
      ) {
        redirectUrl = `${redirectUrl}?event=${context.eventId}&orderId=${
          context.testEventData.orderId !== null
            ? context.testEventData.orderId
            : context.orderId
        }`;
      }
    }
  }
  return redirectUrl;
};
