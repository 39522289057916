import Language from "../../Interfaces/Language";

export const ja: Language = {
  common: {
    help: "お困りですか？",
    helpCenter: "ヘルプセンター",
    expect: "この診断で期待されること",
    faq: "よくある質問",
    faqLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7853533-%E3%82%88%E3%81%8F%E3%81%82%E3%82%8B%E8%B3%AA%E5%95%8F",
    copyright: "著作権",
    copyrightNotices: "著作権について",
    copyrightNoticesLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994367-%E8%91%97%E4%BD%9C%E6%A8%A9%E8%A1%A8%E7%A4%BA",
    terms: "利用規約",
    termsLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994199-%E5%88%A9%E7%94%A8%E8%A6%8F%E7%B4%84%E3%81%A8%E6%9D%A1%E4%BB%B6",
    privacy: "個人情報保護方針",
    privacyLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6990029-%E3%83%97%E3%83%A9%E3%82%A4%E3%83%90%E3%82%B7%E3%83%BC%E3%83%9D%E3%83%AA%E3%82%B7%E3%83%BC",
    dmca: "障がい／配慮事項",
    dmcaLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7857025-%E3%83%87%E3%82%B8%E3%82%BF%E3%83%AB%E3%83%9F%E3%83%AC%E3%83%8B%E3%82%A2%E3%83%A0%E8%91%97%E4%BD%9C%E6%A8%A9%E6%B3%95%E3%83%9D%E3%83%AA%E3%82%B7%E3%83%BC",
    accommodations: "障がい／配慮事項",
    accommodationsLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994434-%E9%9A%9C%E5%AE%B3-%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B7%E3%83%93%E3%83%AA%E3%83%86%E3%82%A3",
    technicalSupport: "テクニカルサポート",
    chatSupport:
      "サポートについては、本ページ右下の弊社チームとのチャットをご利用ください"
  },
  instruction: {
    eppCopyright:
      "この評価には、1995年のSalesAP©と2002年のCSAP©、Multi-Health Systems Inc.の著作物が複製されています。全ての権利を保有しています。",
    salesApCopyright:
      "SalesAP ©1995, Multi-Health Systems Inc. All rights reserved.",
    csapCopyright: "CSAP ©2002, Multi-Health Systems Inc. All rights reserved.",
    wtmaCopyright:
      "WTMA Copyright © 1997, 1999 Psychological Assessment Resources, Inc."
  }
};
