import { useState } from "react";

interface InjectedProps {
  instructionPage: number;
  handleContinue: () => void;
  handleBack: () => void;
}

interface InstructionsWrapperProps {
  children(props: InjectedProps): JSX.Element;
}

const InstructionsWrapper = ({ children }: InstructionsWrapperProps) => {
  const [instructionPage, setInstructionPage] = useState(1);

  const handleContinueButton = () => {
    setInstructionPage(instructionPage + 1);
  };

  const handleBackButton = () => {
    setInstructionPage(instructionPage - 1);
  };

  return children({
    instructionPage,
    handleContinue: handleContinueButton,
    handleBack: handleBackButton
  });
};

export default InstructionsWrapper;
