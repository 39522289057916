import React from "react";
import Input from "./Input";
import Navbar from "./Navbar";

interface BrowseHopLandingPageInterface {
  input: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  submitInput: (e: React.FormEvent) => void;
}

const BrowseHopLandingPage = ({
  input,
  onChange,
  submitInput
}: BrowseHopLandingPageInterface) => {
  return (
    <div style={{ fontFamily: "Lato" }}>
      <Navbar />
      <div
        className="d-flex justify-content-center"
        style={{ marginTop: "73px" }}
      >
        <Input value={input} onChange={onChange} submitInput={submitInput} />
      </div>
    </div>
  );
};

export default BrowseHopLandingPage;
