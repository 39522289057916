import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Timer from "../../../../Components/Timer";
import { TestEventContext } from "../../../../Contexts/TestEventContext";
import { nodeApiURL } from "../../../../utils/constants";
import CLIKInterruptModal from "../CLIKComponents/CLIKInterruptModal";
import Desktop from "../CLIKComponents/Desktop";
import TopInstruction from "../CLIKComponents/TopInstruction";

import {
  CLIKSim3AnswerData,
  Sim3Answer
} from "../../../../Interfaces/AnswerData";

interface CLIKSimulation3Props {
  handleGoToNextSection: () => void;
  testEventId: number;
  simulationTimeAllowed: number;
  currentSection: number;
  subTestId: string;
}

interface CLIKSimulation3State {
  answer: Sim3Answer;
  timeStarted: Date | null;
  timeTaken: number | null;
  showInterruptModal: boolean;
  pauseTimer: boolean;
  failCounter: number;
  didReconnectFail: boolean;
  showSimulation: boolean;
  copyActionComplete: boolean;
  currentContact: string;
  salesSummaryText: string;
  disableSendButton: boolean;
}

export default class CLIKSimulation3 extends React.PureComponent<
  CLIKSimulation3Props,
  CLIKSimulation3State
> {
  state: CLIKSimulation3State = {
    answer: {
      11: {
        answer: 0
      },
      12: {
        answer: 0
      },
      13: {
        answer: 0
      },
      14: {
        answer: 0
      },
      15: {
        answer: 0
      }
    },
    timeStarted: null,
    timeTaken: null,
    showInterruptModal: false,
    pauseTimer: false,
    failCounter: 0,
    didReconnectFail: false,
    showSimulation: false,
    copyActionComplete: false,
    currentContact: "",
    salesSummaryText: "",
    disableSendButton: false
  };

  // define the context type so that we can use this.context throughout the class
  static readonly contextType = TestEventContext;

  componentDidMount() {
    requestAnimationFrame(() => {
      window.scrollTo(0, 0);
    });
  }

  handleStart = () => {
    // start the timer
    if (this.state.timeStarted === null) {
      this.setState({ timeStarted: new Date(), showSimulation: true });
    }
    // show the simulation
  };

  handleSetSim3Answer = (answerNum: number, answerVal: number) => {
    this.setState({
      answer: { ...this.state.answer, [answerNum]: { answer: answerVal } }
    });
  };

  handleSendSalesSummary = (
    salesSummaryInput: string,
    currentContact: string
  ) => {
    // calculate time to complete (in seconds);
    this.setState({ disableSendButton: true });
    const finishTime: number = Date.now();
    const timeTaken =
      this.state.timeTaken !== null
        ? this.state.timeTaken
        : this.state.timeStarted !== null
        ? (+finishTime - +this.state.timeStarted) / 1000
        : 0;
    this.setState({ timeTaken });
    const isCurrentContactCorrect = currentContact === "Dominic Gonzalez";
    const isSalesSummaryCorrect =
      salesSummaryInput ===
      "Lead Conversion Rate: 18:1 Leads: 2,536 Opportunities: 537 Negotiation: 295 Wins: 138";
    // create answerData object and send that data to redis, and then end the subTest
    const answerData: CLIKSim3AnswerData = {
      testEventId: this.props.testEventId,
      subTestId: this.props.subTestId,
      timeTaken,
      sim: {
        ...this.state.answer,
        13: { answer: this.state.copyActionComplete ? 1 : 0 },
        14: {
          answer:
            this.state.copyActionComplete && isCurrentContactCorrect ? 1 : 0
        },
        15: { answer: isCurrentContactCorrect && isSalesSummaryCorrect ? 1 : 0 }
      },
      salesSummaryInput
    };
    this.sendCLIKSim3DataToRedis(answerData);
  };

  handleRetryButton = () => {
    this.setState({ didReconnectFail: false }, () => {
      this.handleRedisFailure();
    });
  };

  handleRedisFailure = () => {
    const { failCounter, salesSummaryText, currentContact } = this.state;
    this.setState(state => ({
      showInterruptModal: true,
      pauseTimer: true,
      failCounter: state.failCounter + 1
    }));
    const rootElement = document.getElementById("root");
    if (rootElement !== null) {
      rootElement.style.filter = "blur(10px)";
    }
    if (failCounter < 30) {
      setTimeout(
        () => this.handleSendSalesSummary(salesSummaryText, currentContact),
        1000
      );
    } else {
      this.setState({ didReconnectFail: true, failCounter: 0 });
    }
  };

  sendCLIKSim3DataToRedis = async (
    answerData: CLIKSim3AnswerData
  ): Promise<boolean> => {
    const rootElement = document.getElementById("root");
    try {
      const response = await fetch(`${nodeApiURL}/sendCLIKSim3Answer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.context.token
        },
        body: JSON.stringify(answerData)
      });
      const responseData = await response.json();
      if (responseData.submitted !== "OK") {
        this.handleRedisFailure();
      } else {
        if (rootElement !== null) {
          rootElement.style.filter = "none";
        }
        this.props.handleGoToNextSection();
      }
    } catch (error) {
      this.handleRedisFailure();
    }
    return true;
  };

  handleCopyActionComplete = () => {
    this.setState({ copyActionComplete: true });
  };

  handleUpdateCurrentContact = (contact: string) => {
    this.setState({ currentContact: contact });
  };

  handleUpdateSalesSummaryText = (text: string) => {
    this.setState({ salesSummaryText: text });
  };

  handleTimerExpire = () => {
    this.handleSendSalesSummary(
      this.state.salesSummaryText,
      this.state.currentContact
    );
  };

  render() {
    const { simulationTimeAllowed } = this.props;
    return (
      <Container fluid={true}>
        <Row>
          <TopInstruction
            showSimulation={this.state.showSimulation}
            handleStart={this.handleStart}
            simulationTimeAllowed={simulationTimeAllowed}
          />
          {this.state.showSimulation && (
            <Col style={{ marginTop: "6px" }}>
              <Timer
                seconds={simulationTimeAllowed}
                onCompletion={this.handleTimerExpire}
                startTimer={true}
                color="black"
                pauseTimer={this.state.pauseTimer}
                placedAt="clikv2"
              />
            </Col>
          )}
        </Row>
        <Row>
          <ol>
            <li>
              Open the <b>chat notification</b> and read the message
            </li>
            <li>
              Launch the Internet browser and open the{" "}
              <b>Sales Report bookmark</b>
            </li>
            <li>Copy the URL of the Sales Report webpage</li>
            <li>
              Paste and send the URL into the chat window with Asako Satoshi
            </li>
            <li>Return to the Sales Report webpage</li>
            <li>
              Copy the <b>‘Sales Summary’</b> using the ‘Copy’ button
            </li>
            <li>Find Dominic Gonzalez in the messages application</li>
            <li>
              Paste and send the Sales Summary into the chat window with Dominic
              Gonzalez
            </li>
          </ol>
        </Row>
        {this.state.showSimulation && (
          <div style={{ marginBottom: "50px" }}>
            <Desktop
              handleSendEmail={() => {}}
              submitProfileUpdate={() => {}}
              handleSetSim1Answer={() => {}}
              handleSetSim2Answer={() => {}}
              handleSetSim3Answer={this.handleSetSim3Answer}
              handleUpdateBirthYear={() => {}}
              handleUpdateFields={() => {}}
              handleCopyActionComplete={this.handleCopyActionComplete}
              handleUpdateCurrentContact={this.handleUpdateCurrentContact}
              handleUpdateSalesSummaryText={this.handleUpdateSalesSummaryText}
              handleSendSalesSummary={this.handleSendSalesSummary}
              disableSendButton={this.state.disableSendButton}
              currentSection={this.props.currentSection}
            />
          </div>
        )}
        {this.state.showInterruptModal && (
          <CLIKInterruptModal
            handleRetryButton={this.handleRetryButton}
            didReconnectFail={this.state.didReconnectFail}
          />
        )}
      </Container>
    );
  }
}
