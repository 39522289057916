import React, { useState, useContext } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  Alert,
  Form,
  Image
} from "react-bootstrap";
import { apiURL } from "../utils/constants";
import veryPoor from "../Resources/images/very_poor.png";
import veryPoorActive from "../Resources/images/very_poor_active.png";
import poor from "../Resources/images/poor.png";
import poorActive from "../Resources/images/poor_active.png";
import fair from "../Resources/images/fair.png";
import fairActive from "../Resources/images/fair_active.png";
import good from "../Resources/images/good.png";
import goodActive from "../Resources/images/good_active.png";
import excellent from "../Resources/images/excellent.png";
import excellentActive from "../Resources/images/excellent_active.png";
import checkCircle from "../Resources/images/check_circle.png";
import useInterval from "../CustomHooks/useInterval";
import Header from "../Components/Header";
import LandingPageParameters from "../Interfaces/LandingPageParameters";
import LandingPageStyle from "../Interfaces/LandingPageStyle";
import { TestEventContext } from "../Contexts/TestEventContext";
import { JoinResearch } from "../Components/JoinResearch";

interface Props {
  testEventData: any;
  generateLandingPageStyle: (
    landingPageParameters: LandingPageParameters
  ) => LandingPageStyle;
  numberOfSecondsToDelay: number;
}

const RevelianTestEventCompleted = ({
  testEventData,
  generateLandingPageStyle,
  numberOfSecondsToDelay
}: Props) => {
  const context: any = useContext(TestEventContext);
  const [seconds, setSeconds] = useState(
    numberOfSecondsToDelay ? numberOfSecondsToDelay : 5
  );
  const [numberOfAttempts, setNumberOfAttempts] = useState(2);
  const [showURL, setShowURL] = useState(false);
  const [ratingValue, setRating] = useState<number | null>(null);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

  const landingPageStyle = generateLandingPageStyle(testEventData.landingPage);

  let redirectUrl: any = null;

  if (
    testEventData !== null &&
    testEventData.optionalReturnUrl &&
    testEventData.optionalReturnUrl !== null
  ) {
    redirectUrl = `${testEventData.optionalReturnUrl}`;
  }

  // some fairly dense conditional here, but it is basically checking whether or not the redirectUrl needs to
  // contain the eventId and/or the orderId, and if so, determines where to get those values.
  if (redirectUrl === null) {
    if (
      testEventData &&
      testEventData.exitPage.customExitPageType === "redirect"
    ) {
      redirectUrl = testEventData.exitPage.redirectUrl;
      if (
        testEventData &&
        testEventData.exitPage.includeEventId &&
        !testEventData.exitPage.includeOrderId
      ) {
        redirectUrl = `${redirectUrl}?event=${testEventData.eventId}`;
      }
      if (
        testEventData &&
        !testEventData.exitPage.includeEventId &&
        testEventData.exitPage.includeOrderId
      ) {
        redirectUrl = `${redirectUrl}?orderId=${
          testEventData.orderId !== null
            ? testEventData.orderId
            : testEventData.orderId
        }`;
      }
      if (
        testEventData &&
        testEventData.exitPage.includeEventId &&
        testEventData.exitPage.includeOrderId
      ) {
        redirectUrl = `${redirectUrl}?event=${testEventData.eventId}&orderId=${
          testEventData.orderId !== null
            ? testEventData.orderId
            : testEventData.orderId
        }`;
      }
    }
  }
  // on mount only, set the interval to count down the seconds, and clean up on unmount
  useInterval(() => {
    const timeRemaining = seconds - 1;
    const attemptsRemaining = numberOfAttempts - 1;
    if (attemptsRemaining === 0) {
      setShowURL(true);
    } else {
      const decodedURI = decodeURIComponent(redirectUrl);
      if (timeRemaining === 0 && decodedURI && redirectUrl) {
        window.location.href =
          decodedURI.slice(0, 7) === "http://" ||
          decodedURI.slice(0, 8) === "https://"
            ? decodedURI
            : `https://${decodedURI}`;
        setNumberOfAttempts(attemptsRemaining);
      } else {
        setSeconds(timeRemaining);
      }
    }
  }, 1000);

  const handleRatingInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const ratingInputValue: number = Number(event.target.value);
    setRating(ratingInputValue);
  };

  interface FormData {
    testEventId: number;
    surveyQuestionId: number;
    response: number | null;
  }

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();

    const formData: FormData = {
      testEventId: testEventData.testEventId,
      surveyQuestionId: 1,
      response: ratingValue
    };

    try {
      const response = await fetch(`${apiURL}/surveyResponse`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(formData)
      });
      const responseData = await response;
      if (responseData.status === 200) {
        setFormSubmitted(true);
      }
    } catch (error) {
      console.log("Error occured when submitting survey: ", error);
      setFormSubmitted(true);
    }
  };

  const veryPoorImageToShow = ratingValue === 1 ? veryPoorActive : veryPoor;
  const poorImageToShow = ratingValue === 2 ? poorActive : poor;
  const fairImageToShow = ratingValue === 3 ? fairActive : fair;
  const goodImageToShow = ratingValue === 4 ? goodActive : good;
  const excellentImageToShow = ratingValue === 5 ? excellentActive : excellent;

  const surveyQuestionJSON =
    testEventData && testEventData.odaSurveyQuestion
      ? JSON.parse(testEventData.odaSurveyQuestion.question)
      : null;

  const ratingsList = surveyQuestionJSON
    ? [
        {
          id: 1,
          image: veryPoorImageToShow,
          text: surveyQuestionJSON.options[0],
          backgroundColor: "#ffceb1"
        },
        {
          id: 2,
          image: poorImageToShow,
          text: surveyQuestionJSON.options[1],
          backgroundColor: "#ffda99"
        },
        {
          id: 3,
          image: fairImageToShow,
          text: surveyQuestionJSON.options[2],
          backgroundColor: "#d7ebb3"
        },
        {
          id: 4,
          image: goodImageToShow,
          text: surveyQuestionJSON.options[3],
          backgroundColor: "#acebcd"
        },
        {
          id: 5,
          image: excellentImageToShow,
          text: surveyQuestionJSON.options[4],
          backgroundColor: "#abebed"
        }
      ]
    : [];

  const ratingInputs = ratingsList.map(rating => {
    const textToShow =
      rating.id === ratingValue ? <strong>{rating.text}</strong> : rating.text;
    return (
      <td
        className="text-center"
        style={{
          width: "20%",
          verticalAlign: "top",
          border: 0,
          padding: 0,
          margin: 0
        }}
      >
        <Form.Check
          type="radio"
          id={`${rating.text}-radio`}
          style={{ padding: 0, margin: 0 }}
        >
          <Form.Check.Input
            value={rating.id}
            onChange={handleRatingInput}
            type="radio"
            style={{ opacity: "0" }}
            checked={rating.id === ratingValue}
          />
          <Form.Check.Label style={{ cursor: "pointer" }}>
            <div
              aria-hidden="true"
              style={{
                margin: "auto",
                height: "56px",
                width: "56px",
                borderRadius: "50%",
                backgroundColor:
                  rating.id === ratingValue ? rating.backgroundColor : ""
              }}
            >
              <Image
                fluid={true}
                className="mb-2"
                src={rating.image}
                alt=""
                style={{ paddingTop: "8px" }}
              />
            </div>
            {textToShow}
          </Form.Check.Label>
        </Form.Check>
      </td>
    );
  });

  const isExitPageDefault = testEventData.exitPage.isDefault === 2;

  const isPostAssessmentResearchEnabled: boolean =
    context.configCatObject &&
    context.configCatObject.isPostAssessmentResearchEnabled &&
    isExitPageDefault &&
    redirectUrl === null &&
    !testEventData.hasOwnProperty("isTeamingEvent");

  const isMarketingODAResearchEnabled: boolean =
    context.configCatObject &&
    context.configCatObject.isMarketingODAResearchEnabled &&
    isExitPageDefault &&
    redirectUrl === null &&
    !testEventData.hasOwnProperty("isTeamingEvent");

  // showJoinResearch is true if isPostAssessmentResearchEnabled or isMarketingODAResearchEnabled is true but not both.
  const showJoinResearch: boolean =
    (isPostAssessmentResearchEnabled || isMarketingODAResearchEnabled) &&
    !(isPostAssessmentResearchEnabled && isMarketingODAResearchEnabled);

  const researchLink: string = isMarketingODAResearchEnabled
    ? "https://go.criteriacorp.com/candidate-survey"
    : "https://landing.research.revelian.com/?eventId=" + context.eventId;

  return (
    <Container>
      <Row>
        <Col
          xl={{ span: 8, offset: 2 }}
          lg={{ span: 8, offset: 2 }}
          md={{ span: 10, offset: 1 }}
          sm={12}
        >
          <Card className="oda-card">
            <Header
              onWelcomePage={false}
              fromRevelianEventComplete={true}
              testEventData={testEventData}
              backgroundColor={landingPageStyle.backgroundColor}
              customLogo={testEventData.logo}
            />
            <Card.Body>
              <div
                className="mt-5"
                style={{
                  borderBottom: isExitPageDefault ? "1px solid #eef0f0" : ""
                }}
              >
                {redirectUrl ? (
                  <React.Fragment>
                    <Card.Title className="text-center mb-1">
                      Thank You.
                    </Card.Title>
                    <p>
                      You have completed the last assessment and your responses
                      have been successfully submitted.
                    </p>
                    <p>No further action is required.</p>
                    <p>
                      {context?.testEventData?.translatedText?.redirect
                        ? context.testEventData.translatedText.redirect.replace(
                            "{seconds}",
                            seconds
                          )
                        : `Please wait. You will be redirected in ${seconds} seconds...`}
                    </p>
                  </React.Fragment>
                ) : isExitPageDefault ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: testEventData.translatedText.testCompletionMsg
                    }}
                  />
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: testEventData.exitPage.customMessage
                    }}
                  />
                )}
                {showURL ? (
                  <Col xl={6} lg={6} md={10} sm={12}>
                    <Alert variant="info">
                      Your event has been successfully completed, but we are
                      having trouble redirecting you. Please continue to wait or
                      click <a href={decodeURIComponent(redirectUrl)}>here</a>{" "}
                      to finish redirecting.
                    </Alert>
                  </Col>
                ) : null}
              </div>
              {showJoinResearch && testEventData.freeTrial === 0 ? (
                <JoinResearch
                  researchLink={researchLink}
                  isMarketingODAResearchEnabled={isMarketingODAResearchEnabled}
                />
              ) : null}
              {isExitPageDefault ? (
                !showJoinResearch || testEventData.freeTrial === 1 ? (
                  !formSubmitted ? (
                    <React.Fragment>
                      <Row className="mb-1 pt-4">
                        <Col>
                          {surveyQuestionJSON ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: surveyQuestionJSON.questionText
                              }}
                            />
                          ) : (
                            <React.Fragment>
                              <strong>
                                Overall, how would you rate your experience
                                during this assessment process?
                              </strong>{" "}
                              <em>
                                (Responses are anonymous and won't impact your
                                test results in any way.)
                              </em>
                            </React.Fragment>
                          )}
                        </Col>
                      </Row>
                      <Row className="text-center mr-0 mb-1">
                        <Col>
                          <Form onSubmit={handleSubmit}>
                            <Row className="text-center mb-1">
                              <Col className="text-center">
                                <table className="table">
                                  <tr>{ratingInputs}</tr>
                                </table>
                              </Col>
                            </Row>
                            <Row>
                              <Col
                                xl={{ span: 6, offset: 3 }}
                                lg={{ span: 6, offset: 3 }}
                                md={{ span: 8, offset: 2 }}
                                sm={12}
                              >
                                <Button
                                  variant="primary"
                                  type="submit"
                                  style={{
                                    pointerEvents:
                                      ratingValue === null ? "none" : "auto"
                                  }}
                                  disabled={ratingValue === null}
                                  block={true}
                                >
                                  {testEventData && testEventData.translatedText
                                    ? testEventData.translatedText.submit
                                    : "Submit"}
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Row className="text-center mt-5">
                        <Col className="pt-5 pb-5">
                          <h6
                            className="mb-4"
                            style={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            {testEventData?.translatedText?.feedbackMsg
                              ? testEventData.translatedText.feedbackMsg
                              : "Thank you for your feedback!"}
                          </h6>
                          <img
                            src={checkCircle}
                            alt="checkmark inside circle"
                            className="mb-4"
                          />
                        </Col>
                      </Row>
                    </React.Fragment>
                  )
                ) : null
              ) : null}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default RevelianTestEventCompleted;
