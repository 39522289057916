import React, { useContext } from "react";
import PageContinueButton from "./PageContinueButton";
import { TestEventContext } from "../Contexts/TestEventContext";

interface Props {
  researchLink: string;
  isMarketingODAResearchEnabled: boolean;
}

const DEFAULT_MARKETING_RESEARCH_PARAGRAPH =
  "<ul><li>This is completely <strong>optional</strong> and for research purposes only.</li><li>This survey is <strong>not</strong> related to your assessment process.</li><li>Your individual responses in this research are anonymous and will never be released to employers.</li></ul>";
const DEFAULT_PSYCH_RESEARCH_PARAGRAPH =
  "<ul><li>This is completely <strong>optional</strong> and for research and development purposes only.</li><li>This survey is <strong>not</strong> related to your assessment process.</li><li>Your individual results in this research are anonymous and will never be released to employers.</li></ul>";

// May consider changing to switch statements if more research links are added and text is drastically changed.
export const JoinResearch = (props: Props) => {
  const context = useContext(TestEventContext);
  const { researchLink, isMarketingODAResearchEnabled } = props;
  const handleOnClick = () => {
    window.location.href = researchLink;
  };

  return (
    <div className="mt-5">
      <h3 className="mb-3">
        {context?.testEventData?.translatedText?.titleJoinResearch
          ? context.testEventData.translatedText.titleJoinResearch
          : "Join Criteria's Research"}
      </h3>
      <div className="mb-3"></div>
      {isMarketingODAResearchEnabled ? (
        <p>
          {context?.testEventData?.translatedText?.marketResearchEnabled
            ? context.testEventData.translatedText.marketResearchEnabled
            : "We would love your help in our efforts to learn more about the candidate job market."}
        </p>
      ) : (
        <p>
          {context?.testEventData?.translatedText?.marketResearchDisabled
            ? context.testEventData.translatedText.marketResearchDisabled
            : "We would love your help in our continued efforts to produce high quality assessments."}
        </p>
      )}
      {isMarketingODAResearchEnabled ? (
        <p
          dangerouslySetInnerHTML={{
            __html: context?.testEventData?.translatedText
              ?.marketingResearchDotPoints
              ? context.testEventData.translatedText.marketingResearchDotPoints
              : DEFAULT_MARKETING_RESEARCH_PARAGRAPH
          }}
        />
      ) : (
        <p
          dangerouslySetInnerHTML={{
            __html: context?.testEventData?.translatedText?.researchDotPoints
              ? context.testEventData.translatedText.researchDotPoints
              : DEFAULT_PSYCH_RESEARCH_PARAGRAPH
          }}
        />
      )}
      <p className="mb-5">
        {context?.testEventData?.translatedText?.researchThankYou ? (
          context.testEventData.translatedText.researchThankYou
        ) : (
          <>
            Thank you for your help. To begin this{" "}
            <strong>
              <u>optional</u>
            </strong>{" "}
            survey, please click the button below.
          </>
        )}
      </p>
      <div className="mt-3">
        <PageContinueButton
          handler={handleOnClick}
          buttonText={
            context?.testEventData?.translatedText?.clickToBegin
              ? context.testEventData.translatedText.clickToBegin
              : "Click here to begin"
          }
        />
      </div>
    </div>
  );
};
