import React from "react";
import { Image, Card, Row, Col } from "react-bootstrap";
import logo from "../Resources/images/criteriaLogo_wordmark.png";
import HelpMenu from "./HelpMenu";
import { isNeedHelpMenuDisabled } from "../utils/customConfig";

interface Props {
  onWelcomePage?: boolean;
  backgroundColor?: string;
  customLogo?: string;
  fromRevelianEventComplete?: boolean;
  testEventData?: object;
  isVideoInterviewDiscoveryJob?: boolean;
}

const Header = ({
  onWelcomePage,
  backgroundColor,
  customLogo,
  fromRevelianEventComplete,
  testEventData,
  isVideoInterviewDiscoveryJob
}: Props) => {
  if (onWelcomePage) {
    return (
      <Card.Header
        style={{ borderBottom: 0, backgroundColor: backgroundColor }}
      >
        <Row
          className="text-right"
          style={{ position: "relative", zIndex: 10 }}
        >
          <Col>
            <HelpMenu backgroundColor={backgroundColor} />
          </Col>
        </Row>
        <Row
          className="text-center"
          style={{
            marginTop: isNeedHelpMenuDisabled() ? "0" : "-24px",
            zIndex: 8
          }}
        >
          <Col
            lg={{ span: 8, offset: 2 }}
            md={{ span: 8, offset: 2 }}
            sm={{ span: 8, offset: 2 }}
            xs={{ span: 8, offset: 2 }}
          >
            <Image
              src={
                customLogo && !isVideoInterviewDiscoveryJob
                  ? `/criteria-corp-object-store/ondemandassessment/logos/${customLogo}`
                  : logo
              }
              alt={
                customLogo && !isVideoInterviewDiscoveryJob
                  ? "On Demand Assessment"
                  : "Criteria Corp. On Demand Assessment"
              }
              fluid={true}
            />
          </Col>
        </Row>
      </Card.Header>
    );
  }
  return (
    <Card.Header style={{ borderBottom: 0, backgroundColor: backgroundColor }}>
      <Row>
        <Col>
          <Image
            src={
              customLogo && !isVideoInterviewDiscoveryJob
                ? `/criteria-corp-object-store/ondemandassessment/logos/${customLogo}`
                : logo
            }
            alt={
              customLogo && !isVideoInterviewDiscoveryJob
                ? "On Demand Assessment"
                : "Criteria Corp. On Demand Assessment"
            }
            className={
              customLogo && !isVideoInterviewDiscoveryJob
                ? "non-welcome-logo"
                : ""
            }
          />
        </Col>
        <Col>
          <HelpMenu
            backgroundColor={backgroundColor}
            fromRevelianEventComplete={fromRevelianEventComplete}
            testEventData={testEventData}
          />
        </Col>
      </Row>
    </Card.Header>
  );
};

export default Header;
