import React from "react";
import { Button, Row, Col } from "react-bootstrap";
import rightArrow from "../Resources/images/arrow-right.png";

interface Props {
  handler: () => void;
  buttonText?: string;
  disabled?: any;
  showNewEventIdPage?: boolean;
}

const PageContinueButton = ({
  handler,
  disabled,
  buttonText,
  showNewEventIdPage
}: Props) => {
  return (
    <Row className={showNewEventIdPage ? "mb-0" : "mb-2"}>
      <Col
        xl={{ span: 6, offset: 3 }}
        lg={{ span: 6, offset: 3 }}
        md={{ span: 8, offset: 2 }}
        sm={12}
      >
        <Button
          onClick={handler}
          disabled={disabled}
          block={true}
          style={{ height: "40px" }}
        >
          {buttonText || "Continue"}
          <img src={rightArrow} height="16px" alt="" className="ml-2" />
        </Button>
      </Col>
    </Row>
  );
};

export default PageContinueButton;
