export const taskOnePracticeArray = [
  { shape: "square", fill: "#000", correctAnswer: "j" },
  { shape: "star", fill: "#FFF", correctAnswer: "f" },
  { shape: "circle", fill: "#434343", correctAnswer: "f" },
  { shape: "triangle", fill: "#B7B7B7", correctAnswer: "j" },
  { shape: "square", fill: "#FFF", correctAnswer: "f" },
  { shape: "star", fill: "#000", correctAnswer: "j" },
  { shape: "circle", fill: "#B7B7B7", correctAnswer: "f" },
  { shape: "square", fill: "#000", correctAnswer: "j" },
  { shape: "triangle", fill: "#434343", correctAnswer: "j" },
  { shape: "star", fill: "#000", correctAnswer: "j" },
  { shape: "circle", fill: "#B7B7B7", correctAnswer: "f" },
  { shape: "triangle", fill: "#434343", correctAnswer: "j" },
  { shape: "square", fill: "#FFF", correctAnswer: "f" },
  { shape: "star", fill: "#FFF", correctAnswer: "f" },
  { shape: "triangle", fill: "#B7B7B7", correctAnswer: "j" },
  { shape: "circle", fill: "#434343", correctAnswer: "f" }
];

export const taskOneTestArray = [
  { shape: "star", fill: "#000", stimulusId: 8 },
  { shape: "star", fill: "#000", stimulusId: 8 },
  { shape: "star", fill: "#000", stimulusId: 8 },
  { shape: "star", fill: "#000", stimulusId: 8 },
  { shape: "star", fill: "#000", stimulusId: 8 },
  { shape: "star", fill: "#FFF", stimulusId: 3 },
  { shape: "star", fill: "#FFF", stimulusId: 3 },
  { shape: "star", fill: "#FFF", stimulusId: 3 },
  { shape: "star", fill: "#FFF", stimulusId: 3 },
  { shape: "star", fill: "#FFF", stimulusId: 3 },
  { shape: "square", fill: "#000", stimulusId: 7 },
  { shape: "square", fill: "#000", stimulusId: 7 },
  { shape: "square", fill: "#000", stimulusId: 7 },
  { shape: "square", fill: "#000", stimulusId: 7 },
  { shape: "square", fill: "#000", stimulusId: 7 },
  { shape: "square", fill: "#FFF", stimulusId: 4 },
  { shape: "square", fill: "#FFF", stimulusId: 4 },
  { shape: "square", fill: "#FFF", stimulusId: 4 },
  { shape: "square", fill: "#FFF", stimulusId: 4 },
  { shape: "square", fill: "#FFF", stimulusId: 4 },
  { shape: "circle", fill: "#434343", stimulusId: 1 },
  { shape: "circle", fill: "#434343", stimulusId: 1 },
  { shape: "circle", fill: "#434343", stimulusId: 1 },
  { shape: "circle", fill: "#434343", stimulusId: 1 },
  { shape: "circle", fill: "#434343", stimulusId: 1 },
  { shape: "circle", fill: "#B7B7B7", stimulusId: 2 },
  { shape: "circle", fill: "#B7B7B7", stimulusId: 2 },
  { shape: "circle", fill: "#B7B7B7", stimulusId: 2 },
  { shape: "circle", fill: "#B7B7B7", stimulusId: 2 },
  { shape: "circle", fill: "#B7B7B7", stimulusId: 2 },
  { shape: "triangle", fill: "#434343", stimulusId: 6 },
  { shape: "triangle", fill: "#434343", stimulusId: 6 },
  { shape: "triangle", fill: "#434343", stimulusId: 6 },
  { shape: "triangle", fill: "#434343", stimulusId: 6 },
  { shape: "triangle", fill: "#434343", stimulusId: 6 },
  { shape: "triangle", fill: "#B7B7B7", stimulusId: 5 },
  { shape: "triangle", fill: "#B7B7B7", stimulusId: 5 },
  { shape: "triangle", fill: "#B7B7B7", stimulusId: 5 },
  { shape: "triangle", fill: "#B7B7B7", stimulusId: 5 },
  { shape: "triangle", fill: "#B7B7B7", stimulusId: 5 }
];

export const taskTwoPracticeArray = [
  {
    shape: "right parallel",
    delay: 1500,
    correctAnswer: "j",
    fill: "#999"
  },
  {
    shape: "rectangle",
    delay: 2500,
    correctAnswer: "f",
    fill: "#999"
  },
  {
    shape: "trapezoid",
    delay: 3500,
    correctAnswer: "f",
    fill: "#999"
  },
  {
    shape: "right parallel",
    delay: 2500,
    correctAnswer: "j",
    fill: "#999"
  },
  {
    shape: "left parallel",
    delay: 2500,
    correctAnswer: "f",
    fill: "#999"
  },
  {
    shape: "down trapezoid",
    delay: 1500,
    correctAnswer: "f",
    fill: "#999"
  }
];

export const taskTwoTestArray = [
  {
    shape: "right parallel",
    delay: 1500,
    stimulusId: 1
  },
  {
    shape: "right parallel",
    delay: 1500,
    stimulusId: 1
  },
  {
    shape: "right parallel",
    delay: 1500,
    stimulusId: 1
  },
  {
    id: 4,
    shape: "right parallel",
    delay: 1500,
    stimulusId: 1
  },
  {
    id: 5,
    shape: "right parallel",
    delay: 1500,
    stimulusId: 1
  },
  {
    id: 6,
    shape: "right parallel",
    delay: 2500,
    stimulusId: 1
  },
  {
    id: 7,
    shape: "right parallel",
    delay: 2500,
    stimulusId: 1
  },
  {
    id: 8,
    shape: "right parallel",
    delay: 2500,
    stimulusId: 1
  },
  {
    id: 9,
    shape: "right parallel",
    delay: 2500,
    stimulusId: 1
  },
  {
    id: 10,
    shape: "right parallel",
    delay: 2500,
    stimulusId: 1
  },
  {
    id: 11,
    shape: "right parallel",
    delay: 3500,
    stimulusId: 1
  },
  {
    id: 12,
    shape: "right parallel",
    delay: 3500,
    stimulusId: 1
  },
  {
    id: 13,
    shape: "right parallel",
    delay: 3500,
    stimulusId: 1
  },
  {
    id: 14,
    shape: "right parallel",
    delay: 3500,
    stimulusId: 1
  },
  {
    id: 15,
    shape: "right parallel",
    delay: 3500,
    stimulusId: 1
  },
  {
    id: 16,
    shape: "rectangle",
    delay: 1500,
    stimulusId: 3
  },
  {
    id: 17,
    shape: "rectangle",
    delay: 1500,
    stimulusId: 3
  },
  {
    id: 18,
    shape: "rectangle",
    delay: 1500,
    stimulusId: 3
  },
  {
    id: 19,
    shape: "rectangle",
    delay: 1500,
    stimulusId: 3
  },
  {
    id: 20,
    shape: "rectangle",
    delay: 1500,
    stimulusId: 3
  },
  {
    id: 21,
    shape: "rectangle",
    delay: 2500,
    stimulusId: 3
  },
  {
    id: 22,
    shape: "rectangle",
    delay: 2500,
    stimulusId: 3
  },
  {
    id: 23,
    shape: "rectangle",
    delay: 2500,
    stimulusId: 3
  },
  {
    id: 24,
    shape: "rectangle",
    delay: 2500,
    stimulusId: 3
  },
  {
    id: 25,
    shape: "rectangle",
    delay: 2500,
    stimulusId: 3
  },
  {
    id: 26,
    shape: "rectangle",
    delay: 3500,
    stimulusId: 3
  },
  {
    id: 27,
    shape: "rectangle",
    delay: 3500,
    stimulusId: 3
  },
  {
    id: 28,
    shape: "rectangle",
    delay: 3500,
    stimulusId: 3
  },
  {
    id: 29,
    shape: "rectangle",
    delay: 3500,
    stimulusId: 3
  },
  {
    id: 30,
    shape: "rectangle",
    delay: 3500,
    stimulusId: 3
  },
  {
    id: 31,
    shape: "down trapezoid",
    delay: 1500,
    stimulusId: 5
  },
  {
    id: 32,
    shape: "down trapezoid",
    delay: 1500,
    stimulusId: 5
  },
  {
    id: 33,
    shape: "down trapezoid",
    delay: 1500,
    stimulusId: 5
  },
  {
    id: 34,
    shape: "down trapezoid",
    delay: 1500,
    stimulusId: 5
  },
  {
    id: 35,
    shape: "down trapezoid",
    delay: 1500,
    stimulusId: 5
  },
  {
    id: 36,
    shape: "down trapezoid",
    delay: 2500,
    stimulusId: 5
  },
  {
    id: 37,
    shape: "down trapezoid",
    delay: 2500,
    stimulusId: 5
  },
  {
    id: 38,
    shape: "down trapezoid",
    delay: 2500,
    stimulusId: 5
  },
  {
    id: 39,
    shape: "down trapezoid",
    delay: 2500,
    stimulusId: 5
  },
  {
    id: 40,
    shape: "down trapezoid",
    delay: 2500,
    stimulusId: 5
  },
  {
    id: 41,
    shape: "down trapezoid",
    delay: 3500,
    stimulusId: 5
  },
  {
    id: 42,
    shape: "down trapezoid",
    delay: 3500,
    stimulusId: 5
  },
  {
    id: 43,
    shape: "down trapezoid",
    delay: 3500,
    stimulusId: 5
  },
  {
    id: 44,
    shape: "down trapezoid",
    delay: 3500,
    stimulusId: 5
  },
  {
    id: 45,
    shape: "down trapezoid",
    delay: 3500,
    stimulusId: 5
  },
  {
    id: 46,
    shape: "trapezoid",
    delay: 1500,
    stimulusId: 4
  },
  {
    id: 47,
    shape: "trapezoid",
    delay: 1500,
    stimulusId: 4
  },
  {
    id: 48,
    shape: "trapezoid",
    delay: 1500,
    stimulusId: 4
  },
  {
    id: 49,
    shape: "trapezoid",
    delay: 1500,
    stimulusId: 4
  },
  {
    id: 50,
    shape: "trapezoid",
    delay: 1500,
    stimulusId: 4
  },
  {
    id: 51,
    shape: "trapezoid",
    delay: 2500,
    stimulusId: 4
  },
  {
    id: 52,
    shape: "trapezoid",
    delay: 2500,
    stimulusId: 4
  },
  {
    id: 53,
    shape: "trapezoid",
    delay: 2500,
    stimulusId: 4
  },
  {
    id: 54,
    shape: "trapezoid",
    delay: 2500,
    stimulusId: 4
  },
  {
    id: 55,
    shape: "trapezoid",
    delay: 2500,
    stimulusId: 4
  },
  {
    id: 56,
    shape: "trapezoid",
    delay: 3500,
    stimulusId: 4
  },
  {
    id: 57,
    shape: "trapezoid",
    delay: 3500,
    stimulusId: 4
  },
  {
    id: 58,
    shape: "trapezoid",
    delay: 3500,
    stimulusId: 4
  },
  {
    id: 59,
    shape: "trapezoid",
    delay: 3500,
    stimulusId: 4
  },
  {
    id: 60,
    shape: "trapezoid",
    delay: 3500,
    stimulusId: 4
  },
  {
    id: 61,
    shape: "left parallel",
    delay: 1500,
    stimulusId: 2
  },
  {
    id: 62,
    shape: "left parallel",
    delay: 1500,
    stimulusId: 2
  },
  {
    id: 63,
    shape: "left parallel",
    delay: 1500,
    stimulusId: 2
  },
  {
    id: 64,
    shape: "left parallel",
    delay: 1500,
    stimulusId: 2
  },
  {
    id: 65,
    shape: "left parallel",
    delay: 1500,
    stimulusId: 2
  },
  {
    id: 66,
    shape: "left parallel",
    delay: 2500,
    stimulusId: 2
  },
  {
    id: 67,
    shape: "left parallel",
    delay: 2500,
    stimulusId: 2
  },
  {
    id: 68,
    shape: "left parallel",
    delay: 2500,
    stimulusId: 2
  },
  {
    id: 69,
    shape: "left parallel",
    delay: 2500,
    stimulusId: 2
  },
  {
    id: 70,
    shape: "left parallel",
    delay: 2500,
    stimulusId: 2
  },
  {
    id: 71,
    shape: "left parallel",
    delay: 3500,
    stimulusId: 2
  },
  {
    id: 72,
    shape: "left parallel",
    delay: 3500,
    stimulusId: 2
  },
  {
    id: 73,
    shape: "left parallel",
    delay: 3500,
    stimulusId: 2
  },
  {
    id: 74,
    shape: "left parallel",
    delay: 3500,
    stimulusId: 2
  },
  {
    id: 75,
    shape: "left parallel",
    delay: 3500,
    stimulusId: 2
  }
];

export const taskThreePracticeArray = [
  {
    shape: "six_fours",
    correctAnswer: "6",
    stimulusId: 3
  },
  {
    shape: "five_sixes",
    correctAnswer: "5",
    stimulusId: 8
  },
  {
    shape: "four_fours",
    correctAnswer: "4",
    stimulusId: 1
  },
  {
    shape: "six_sixes",
    correctAnswer: "6",
    stimulusId: 9
  },
  {
    shape: "five_fours",
    correctAnswer: "5",
    stimulusId: 2
  },
  {
    shape: "five_fives",
    correctAnswer: "5",
    stimulusId: 5
  }
];

export const taskThreeTestArray = [
  {
    shape: "four_fours",
    correctAnswer: "4",
    stimulusId: 1
  },
  {
    shape: "four_fours",
    correctAnswer: "4",
    stimulusId: 1
  },
  {
    shape: "four_fours",
    correctAnswer: "4",
    stimulusId: 1
  },
  {
    shape: "four_fours",
    correctAnswer: "4",
    stimulusId: 1
  },
  {
    shape: "four_fours",
    correctAnswer: "4",
    stimulusId: 1
  },
  {
    shape: "four_fours",
    correctAnswer: "4",
    stimulusId: 1
  },
  {
    shape: "four_fours",
    correctAnswer: "4",
    stimulusId: 1
  },
  {
    shape: "four_fours",
    correctAnswer: "4",
    stimulusId: 1
  },
  {
    shape: "four_fours",
    correctAnswer: "4",
    stimulusId: 1
  },
  {
    shape: "four_fours",
    correctAnswer: "4",
    stimulusId: 1
  },
  {
    shape: "four_fours",
    correctAnswer: "4",
    stimulusId: 1
  },
  {
    shape: "four_fours",
    correctAnswer: "4",
    stimulusId: 1
  },
  {
    shape: "four_fours",
    correctAnswer: "4",
    stimulusId: 1
  },
  {
    shape: "five_fours",
    correctAnswer: "5",
    stimulusId: 2
  },
  {
    shape: "five_fours",
    correctAnswer: "5",
    stimulusId: 2
  },
  {
    shape: "five_fours",
    correctAnswer: "5",
    stimulusId: 2
  },
  {
    shape: "five_fours",
    correctAnswer: "5",
    stimulusId: 2
  },
  {
    shape: "five_fours",
    correctAnswer: "5",
    stimulusId: 2
  },
  {
    shape: "five_fours",
    correctAnswer: "5",
    stimulusId: 2
  },
  {
    shape: "five_fours",
    correctAnswer: "5",
    stimulusId: 2
  },
  {
    shape: "six_fours",
    correctAnswer: "6",
    stimulusId: 3
  },
  {
    shape: "six_fours",
    correctAnswer: "6",
    stimulusId: 3
  },
  {
    shape: "six_fours",
    correctAnswer: "6",
    stimulusId: 3
  },
  {
    shape: "six_fours",
    correctAnswer: "6",
    stimulusId: 3
  },
  {
    shape: "six_fours",
    correctAnswer: "6",
    stimulusId: 3
  },
  {
    shape: "six_fours",
    correctAnswer: "6",
    stimulusId: 3
  },
  {
    shape: "six_fours",
    correctAnswer: "6",
    stimulusId: 3
  },
  {
    shape: "four_fives",
    correctAnswer: "4",
    stimulusId: 4
  },
  {
    shape: "four_fives",
    correctAnswer: "4",
    stimulusId: 4
  },
  {
    shape: "four_fives",
    correctAnswer: "4",
    stimulusId: 4
  },
  {
    shape: "four_fives",
    correctAnswer: "4",
    stimulusId: 4
  },
  {
    shape: "four_fives",
    correctAnswer: "4",
    stimulusId: 4
  },
  {
    shape: "four_fives",
    correctAnswer: "4",
    stimulusId: 4
  },
  {
    shape: "four_fives",
    correctAnswer: "4",
    stimulusId: 4
  },
  {
    shape: "five_fives",
    correctAnswer: "5",
    stimulusId: 5
  },
  {
    shape: "five_fives",
    correctAnswer: "5",
    stimulusId: 5
  },
  {
    shape: "five_fives",
    correctAnswer: "5",
    stimulusId: 5
  },
  {
    shape: "five_fives",
    correctAnswer: "5",
    stimulusId: 5
  },
  {
    shape: "five_fives",
    correctAnswer: "5",
    stimulusId: 5
  },
  {
    shape: "five_fives",
    correctAnswer: "5",
    stimulusId: 5
  },
  {
    shape: "five_fives",
    correctAnswer: "5",
    stimulusId: 5
  },
  {
    shape: "five_fives",
    correctAnswer: "5",
    stimulusId: 5
  },
  {
    shape: "five_fives",
    correctAnswer: "5",
    stimulusId: 5
  },
  {
    shape: "five_fives",
    correctAnswer: "5",
    stimulusId: 5
  },
  {
    shape: "five_fives",
    correctAnswer: "5",
    stimulusId: 5
  },
  {
    shape: "five_fives",
    correctAnswer: "5",
    stimulusId: 5
  },
  {
    shape: "five_fives",
    correctAnswer: "5",
    stimulusId: 5
  },
  {
    shape: "five_fives",
    correctAnswer: "5",
    stimulusId: 5
  },
  {
    shape: "six_fives",
    correctAnswer: "6",
    stimulusId: 6
  },
  {
    shape: "six_fives",
    correctAnswer: "6",
    stimulusId: 6
  },
  {
    shape: "six_fives",
    correctAnswer: "6",
    stimulusId: 6
  },
  {
    shape: "six_fives",
    correctAnswer: "6",
    stimulusId: 6
  },
  {
    shape: "six_fives",
    correctAnswer: "6",
    stimulusId: 6
  },
  {
    shape: "six_fives",
    correctAnswer: "6",
    stimulusId: 6
  },
  {
    shape: "four_sixes",
    correctAnswer: "4",
    stimulusId: 7
  },
  {
    shape: "four_sixes",
    correctAnswer: "4",
    stimulusId: 7
  },
  {
    shape: "four_sixes",
    correctAnswer: "4",
    stimulusId: 7
  },
  {
    shape: "four_sixes",
    correctAnswer: "4",
    stimulusId: 7
  },
  {
    shape: "four_sixes",
    correctAnswer: "4",
    stimulusId: 7
  },
  {
    shape: "four_sixes",
    correctAnswer: "4",
    stimulusId: 7
  },
  {
    shape: "five_sixes",
    correctAnswer: "5",
    stimulusId: 8
  },
  {
    shape: "five_sixes",
    correctAnswer: "5",
    stimulusId: 8
  },
  {
    shape: "five_sixes",
    correctAnswer: "5",
    stimulusId: 8
  },
  {
    shape: "five_sixes",
    correctAnswer: "5",
    stimulusId: 8
  },
  {
    shape: "five_sixes",
    correctAnswer: "5",
    stimulusId: 8
  },
  {
    shape: "five_sixes",
    correctAnswer: "5",
    stimulusId: 8
  },
  {
    shape: "five_sixes",
    correctAnswer: "5",
    stimulusId: 8
  },
  {
    shape: "six_sixes",
    correctAnswer: "6",
    stimulusId: 9
  },
  {
    shape: "six_sixes",
    correctAnswer: "6",
    stimulusId: 9
  },
  {
    shape: "six_sixes",
    correctAnswer: "6",
    stimulusId: 9
  },
  {
    shape: "six_sixes",
    correctAnswer: "6",
    stimulusId: 9
  },
  {
    shape: "six_sixes",
    correctAnswer: "6",
    stimulusId: 9
  },
  {
    shape: "six_sixes",
    correctAnswer: "6",
    stimulusId: 9
  },
  {
    shape: "six_sixes",
    correctAnswer: "6",
    stimulusId: 9
  },
  {
    shape: "six_sixes",
    correctAnswer: "6",
    stimulusId: 9
  },
  {
    shape: "six_sixes",
    correctAnswer: "6",
    stimulusId: 9
  },
  {
    shape: "six_sixes",
    correctAnswer: "6",
    stimulusId: 9
  },
  {
    shape: "six_sixes",
    correctAnswer: "6",
    stimulusId: 9
  },
  {
    shape: "six_sixes",
    correctAnswer: "6",
    stimulusId: 9
  },
  {
    shape: "six_sixes",
    correctAnswer: "6",
    stimulusId: 9
  }
];

export const taskFourPracticeArray = [
  {
    shape: "mr_08",
    correctAnswer: "j",
    stimulusId: 8
  },
  {
    shape: "mr_01",
    correctAnswer: "f",
    stimulusId: 1
  },
  {
    shape: "mr_15",
    correctAnswer: "j",
    stimulusId: 15
  },
  {
    shape: "mr_14",
    correctAnswer: "f",
    stimulusId: 14
  }
];

export const taskFourTestArray = [
  {
    shape: "mr_01",
    correctAnswer: "f",
    stimulusId: 1
  },
  {
    shape: "mr_02",
    correctAnswer: "j",
    stimulusId: 2
  },
  {
    shape: "mr_03",
    correctAnswer: "f",
    stimulusId: 3
  },
  {
    shape: "mr_04",
    correctAnswer: "j",
    stimulusId: 4
  },
  {
    shape: "mr_05",
    correctAnswer: "f",
    stimulusId: 5
  },
  {
    shape: "mr_06",
    correctAnswer: "j",
    stimulusId: 6
  },
  {
    shape: "mr_07",
    correctAnswer: "f",
    stimulusId: 7
  },
  {
    shape: "mr_08",
    correctAnswer: "j",
    stimulusId: 8
  },
  {
    shape: "mr_09",
    correctAnswer: "j",
    stimulusId: 9
  },
  {
    shape: "mr_10",
    correctAnswer: "f",
    stimulusId: 10
  },
  {
    shape: "mr_11",
    correctAnswer: "j",
    stimulusId: 11
  },
  {
    shape: "mr_12",
    correctAnswer: "f",
    stimulusId: 12
  },
  {
    shape: "mr_13",
    correctAnswer: "j",
    stimulusId: 13
  },
  {
    shape: "mr_14",
    correctAnswer: "f",
    stimulusId: 14
  },
  {
    shape: "mr_15",
    correctAnswer: "j",
    stimulusId: 15
  },
  {
    shape: "mr_16",
    correctAnswer: "f",
    stimulusId: 16
  },
  {
    shape: "mr_17",
    correctAnswer: "f",
    stimulusId: 17
  },
  {
    shape: "mr_18",
    correctAnswer: "j",
    stimulusId: 18
  },
  {
    shape: "mr_19",
    correctAnswer: "f",
    stimulusId: 19
  },
  {
    shape: "mr_20",
    correctAnswer: "j",
    stimulusId: 20
  },
  {
    shape: "mr_21",
    correctAnswer: "f",
    stimulusId: 21
  },
  {
    shape: "mr_22",
    correctAnswer: "j",
    stimulusId: 22
  },
  {
    shape: "mr_23",
    correctAnswer: "f",
    stimulusId: 23
  },
  {
    shape: "mr_24",
    correctAnswer: "j",
    stimulusId: 24
  },
  {
    shape: "mr_25",
    correctAnswer: "j",
    stimulusId: 25
  },
  {
    shape: "mr_26",
    correctAnswer: "f",
    stimulusId: 26
  },
  {
    shape: "mr_27",
    correctAnswer: "j",
    stimulusId: 27
  },
  {
    shape: "mr_28",
    correctAnswer: "f",
    stimulusId: 28
  },
  {
    shape: "mr_29",
    correctAnswer: "j",
    stimulusId: 29
  },
  {
    shape: "mr_30",
    correctAnswer: "f",
    stimulusId: 30
  },
  {
    shape: "mr_31",
    correctAnswer: "j",
    stimulusId: 31
  },
  {
    shape: "mr_32",
    correctAnswer: "f",
    stimulusId: 32
  }
];

export const taskSevenPracticeArray = [
  {
    image:
      "<p class='three_term_text'>Donna is not as old as Taylor.</p><br><p class='three_term_text'>Brad is older than Taylor.</p><br><p class='true_statement'>Donna is the youngest.</p>",
    correctAnswer: "j"
  },
  {
    image:
      "<p class='three_term_text'>Margaret is not as young as James.</p><br><p class='three_term_text'>James is older than Diana.</p><br><p class='true_statement'>James is the oldest.</p>",
    correctAnswer: "f"
  }
];

export const taskSevenTestArray = [
  {
    image:
      "<p class='three_term_text'>Jennifer is older than Michael.</p><br><p class='three_term_text'>Michael is older than Brittany</p><br><p class='three_term_text' style='font-weight:bold;'>Brittany is youngest</p>",
    stimulusId: 1
  },
  {
    image:
      "<p class='three_term_text'>Kate is younger than David.</p><br><p class='three_term_text'>William is younger than Kate.</p><br><p class='three_term_text' style='font-weight:bold;'>William is youngest.</p>",
    stimulusId: 2
  },
  {
    image:
      "<p class='three_term_text'>Jennifer is younger than Christopher.</p><br><p class='three_term_text'>Andrew is younger than Jennifer.</p><br><p class='three_term_text' style='font-weight:bold;'>Andrew is not youngest.</p>",
    stimulusId: 3
  },
  {
    image:
      "<p class='three_term_text'>Lisa is older than Rebecca.</p><br><p class='three_term_text'>Lisa is younger than Brittany.</p><br><p class='three_term_text' style='font-weight:bold;'>Rebecca is youngest.</p>",
    stimulusId: 4
  },
  {
    image:
      "<p class='three_term_text'>John is older than Melissa.</p><br><p class='three_term_text'>John is younger than Lisa.</p><br><p class='three_term_text' style='font-weight:bold;'>Melissa is not the youngest.</p>",
    stimulusId: 5
  },
  {
    image:
      "<p class='three_term_text'>Emily is older than Robert.</p><br><p class='three_term_text'>Nicole is younger than Robert.</p><br><p class='three_term_text' style='font-weight:bold;'>Emily is oldest.</p>",
    stimulusId: 6
  },
  {
    image:
      "<p class='three_term_text'>Tiffany is younger than Eric.</p><br><p class='three_term_text'>Tiffany is older than Lisa.</p><br><p class='three_term_text' style='font-weight:bold;'>Eric is oldest.</p>",
    stimulusId: 7
  },
  {
    image:
      "<p class='three_term_text'>Christopher is younger than Thomas.</p><br><p class='three_term_text'>Christopher is older than Melissa.</p><br><p class='three_term_text' style='font-weight:bold;'>Thomas is not oldest.</p>",
    stimulusId: 8
  }
];

export const taskEightPracticeArray = [
  {
    image: "B P p p",
    correctAnswer: "d"
  },
  {
    image: "b B p B",
    correctAnswer: "j"
  }
];

export const taskEightTestArray = [
  {
    image: "I t I i",
    stimulusId: 1
  },
  {
    image: "i I T i",
    stimulusId: 2
  },
  {
    image: "T t t I",
    stimulusId: 3
  },
  {
    image: "i t T T",
    stimulusId: 4
  },
  {
    image: "T I t I",
    stimulusId: 5
  },
  {
    image: "T T I i",
    stimulusId: 6
  },
  {
    image: "i T t i",
    stimulusId: 7
  },
  {
    image: "I t t i",
    stimulusId: 8
  },
  {
    image: "t I I T",
    stimulusId: 9
  },
  {
    image: "T  I  i  T",
    stimulusId: 10
  },
  {
    image: "t  i  t  I",
    stimulusId: 11
  },
  {
    image: "T  t  i  i",
    stimulusId: 12
  },
  {
    image: "T  i  t  T",
    stimulusId: 13
  },
  {
    image: "i  i  I  T",
    stimulusId: 14
  },
  {
    image: "t  I  i  I",
    stimulusId: 15
  },
  {
    image: "t  I  t  T",
    stimulusId: 16
  },
  {
    image: "T  T  i  t",
    stimulusId: 17
  },
  {
    image: "I  T  I  t",
    stimulusId: 18
  },
  {
    image: "i  t  I  t",
    stimulusId: 19
  },
  {
    image: "I  i  T  T",
    stimulusId: 20
  },
  {
    image: "i  i  T  t",
    stimulusId: 21
  },
  {
    image: "I  t  T  t",
    stimulusId: 22
  },
  {
    image: "i  I  I  t",
    stimulusId: 23
  },
  {
    image: "I  T  i  i",
    stimulusId: 24
  }
];

export const taskNinePracticeArray = [
  {
    image:
      "<div id='one'>1</div><div id='two'>2</div><div class='highlight' id='nine'>9</div><div id='eight'>8</div>",
    correctAnswer: "9"
  },
  {
    image:
      "<div id='one'>1</div><div class='highlight' id='two'>2</div><div id='nine'>9</div><div id='eight'>8</div>",
    correctAnswer: "2"
  },
  {
    image:
      "<div id='one' class='highlight'>1</div><div id='two'>2</div><div id='nine'>9</div><div id='eight'>8</div>",
    correctAnswer: "1"
  },
  {
    image:
      "<div id='one'>1</div><div id='two'>2</div><div id='nine'>9</div><div class='highlight' id='eight'>8</div>",
    correctAnswer: "8"
  }
];

export const taskNineTestArray = [
  {
    image:
      "<div id='image1'><div id='one' class='highlight'>1</div><div id='two'>2</div><div id='nine'>9</div><div id='eight'>8</div></div>",
    stimulusId: 1
  },
  {
    image:
      "<div id='image2'><div id='one' class='highlight'>1</div><div id='two'>2</div><div id='nine'>9</div><div id='eight'>8</div></div>",
    stimulusId: 1
  },
  {
    image:
      "<div id='image3'><div id='one' class='highlight'>1</div><div id='two'>2</div><div id='nine'>9</div><div id='eight'>8</div></div>",
    stimulusId: 1
  },
  {
    image:
      "<div id='image4'><div id='one' class='highlight'>1</div><div id='two'>2</div><div id='nine'>9</div><div id='eight'>8</div></div>",
    stimulusId: 1
  },
  {
    image:
      "<div id='image5'><div id='one' class='highlight'>1</div><div id='two'>2</div><div id='nine'>9</div><div id='eight'>8</div></div>",
    stimulusId: 1
  },
  {
    image:
      "<div id='image6'><div id='one' class='highlight'>1</div><div id='two'>2</div><div id='nine'>9</div><div id='eight'>8</div></div>",
    stimulusId: 1
  },
  {
    image:
      "<div id='image7'><div id='one' class='highlight'>1</div><div id='two'>2</div><div id='nine'>9</div><div id='eight'>8</div></div>",
    stimulusId: 1
  },
  {
    image:
      "<div id='image8'><div id='one' class='highlight'>1</div><div id='two'>2</div><div id='nine'>9</div><div id='eight'>8</div></div>",
    stimulusId: 1
  },
  {
    image:
      "<div id='image9'><div id='one' class='highlight'>1</div><div id='two'>2</div><div id='nine'>9</div><div id='eight'>8</div></div>",
    stimulusId: 1
  },
  {
    image:
      "<div id='image10'><div id='one' class='highlight'>1</div><div id='two'>2</div><div id='nine'>9</div><div id='eight'>8</div></div>",
    stimulusId: 1
  },
  {
    image:
      "<div id='image11'><div id='one'>1</div><div class='highlight' id='two'>2</div><div id='nine'>9</div><div id='eight'>8</div></div>",
    stimulusId: 2
  },
  {
    image:
      "<div id='image12'><div id='one'>1</div><div class='highlight' id='two'>2</div><div id='nine'>9</div><div id='eight'>8</div></div>",
    stimulusId: 2
  },
  {
    image:
      "<div id='image13'><div id='one'>1</div><div class='highlight' id='two'>2</div><div id='nine'>9</div><div id='eight'>8</div></div>",
    stimulusId: 2
  },
  {
    image:
      "<div id='image14'><div id='one'>1</div><div class='highlight' id='two'>2</div><div id='nine'>9</div><div id='eight'>8</div></div>",
    stimulusId: 2
  },
  {
    image:
      "<div id='image15'><div id='one'>1</div><div class='highlight' id='two'>2</div><div id='nine'>9</div><div id='eight'>8</div></div>",
    stimulusId: 2
  },
  {
    image:
      "<div id='image16'><div id='one'>1</div><div class='highlight' id='two'>2</div><div id='nine'>9</div><div id='eight'>8</div></div>",
    stimulusId: 2
  },
  {
    image:
      "<div id='image17'><div id='one'>1</div><div class='highlight' id='two'>2</div><div id='nine'>9</div><div id='eight'>8</div></div>",
    stimulusId: 2
  },
  {
    image:
      "<div id='image18'><div id='one'>1</div><div class='highlight' id='two'>2</div><div id='nine'>9</div><div id='eight'>8</div></div>",
    stimulusId: 2
  },
  {
    image:
      "<div id='image19'><div id='one'>1</div><div class='highlight' id='two'>2</div><div id='nine'>9</div><div id='eight'>8</div></div>",
    stimulusId: 2
  },
  {
    image:
      "<div id='image20'><div id='one'>1</div><div class='highlight' id='two'>2</div><div id='nine'>9</div><div id='eight'>8</div></div>",
    stimulusId: 2
  },
  {
    image:
      "<div id='image21'><div id='one'>1</div><div id='two'>2</div><div id='nine'>9</div><div class='highlight' id='eight'>8</div></div>",
    stimulusId: 8
  },
  {
    image:
      "<div id='image22'><div id='one'>1</div><div id='two'>2</div><div id='nine'>9</div><div class='highlight' id='eight'>8</div></div>",
    stimulusId: 8
  },
  {
    image:
      "<div id='image23'><div id='one'>1</div><div id='two'>2</div><div id='nine'>9</div><div class='highlight' id='eight'>8</div></div>",
    stimulusId: 8
  },
  {
    image:
      "<div id='image24'><div id='one'>1</div><div id='two'>2</div><div id='nine'>9</div><div class='highlight' id='eight'>8</div></div>",
    stimulusId: 8
  },
  {
    image:
      "<div id='image25'><div id='one'>1</div><div id='two'>2</div><div id='nine'>9</div><div class='highlight' id='eight'>8</div></div>",
    stimulusId: 8
  },
  {
    image:
      "<div id='image26'><div id='one'>1</div><div id='two'>2</div><div id='nine'>9</div><div class='highlight' id='eight'>8</div></div>",
    stimulusId: 8
  },
  {
    image:
      "<div id='image27'><div id='one'>1</div><div id='two'>2</div><div id='nine'>9</div><div class='highlight' id='eight'>8</div></div>",
    stimulusId: 8
  },
  {
    image:
      "<div id='image28'><div id='one'>1</div><div id='two'>2</div><div id='nine'>9</div><div class='highlight' id='eight'>8</div></div>",
    stimulusId: 8
  },
  {
    image:
      "<div id='image29'><div id='one'>1</div><div id='two'>2</div><div id='nine'>9</div><div class='highlight' id='eight'>8</div></div>",
    stimulusId: 8
  },
  {
    image:
      "<div id='image30'><div id='one'>1</div><div id='two'>2</div><div id='nine'>9</div><div class='highlight' id='eight'>8</div></div>",
    stimulusId: 8
  },
  {
    image:
      "<div id='image31'><div id='one'>1</div><div id='two'>2</div><div class='highlight' id='nine'>9</div><div id='eight'>8</div></div>",
    stimulusId: 9
  },
  {
    image:
      "<div id='image32'><div id='one'>1</div><div id='two'>2</div><div class='highlight' id='nine'>9</div><div id='eight'>8</div></div>",
    stimulusId: 9
  },
  {
    image:
      "<div id='image33'><div id='one'>1</div><div id='two'>2</div><div class='highlight' id='nine'>9</div><div id='eight'>8</div></div>",
    stimulusId: 9
  },
  {
    image:
      "<div id='image34'><div id='one'>1</div><div id='two'>2</div><div class='highlight' id='nine'>9</div><div id='eight'>8</div></div>",
    stimulusId: 9
  },
  {
    image:
      "<div id='image35'><div id='one'>1</div><div id='two'>2</div><div class='highlight' id='nine'>9</div><div id='eight'>8</div></div>",
    stimulusId: 9
  },
  {
    image:
      "<div id='image36'><div id='one'>1</div><div id='two'>2</div><div class='highlight' id='nine'>9</div><div id='eight'>8</div></div>",
    stimulusId: 9
  },
  {
    image:
      "<div id='image37'><div id='one'>1</div><div id='two'>2</div><div class='highlight' id='nine'>9</div><div id='eight'>8</div></div>",
    stimulusId: 9
  },
  {
    image:
      "<div id='image38'><div id='one'>1</div><div id='two'>2</div><div class='highlight' id='nine'>9</div><div id='eight'>8</div></div>",
    stimulusId: 9
  },
  {
    image:
      "<div id='image39'><div id='one'>1</div><div id='two'>2</div><div class='highlight' id='nine'>9</div><div id='eight'>8</div></div>",
    stimulusId: 9
  },
  {
    image:
      "<div id='image40'><div id='one'>1</div><div id='two'>2</div><div class='highlight' id='nine'>9</div><div id='eight'>8</div></div>",
    stimulusId: 9
  }
];

export const mrabCastTaskButtons: any = {
  english: {
    "1": "You have completed the first task.",
    "2": "You have completed the second task.",
    "3": "You have completed the third task.",
    "4": "You have completed the fourth task.",
    "5": "You have completed the fifth task.",
    "6": "You have completed the sixth task.",
    "7": "You have completed the seventh task.",
    "8": "You have completed the eighth task.",
    done: "You have completed the ",
    thanks: "Thank you!",
    continue: "Continue to the next task"
  },
  spanish: {
    "1": "Ha terminado la primera prueba.",
    "2": "Ha terminado la segunda prueba.",
    "3": "Ha terminado la tercera prueba.",
    "4": "Ha terminado la cuarta prueba.",
    "5": "Ha terminado la quinta prueba.",
    "6": "Ha terminado la sexta prueba.",
    "7": "Ha terminado la séptima prueba.",
    "8": "Ha terminado la octava prueba.",
    done: "Ha finalizado la ",
    thanks: "¡Gracias!",
    continue: "Continuar con la siguiente actividad"
  }
};

export const shuffle = (array: any) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};
