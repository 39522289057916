import React, { useState } from "react";
import useInterval from "../CustomHooks/useInterval";

interface Props {
  path: string;
  returnUrl?: string;
  language?: string;
}

const setSpanishText = () => {
  return {
    __html: `<h1>Gracias</h1><p>No est&aacute; obligado a realizar ninguna prueba en este momento.</p>`
  };
};

const setSpanishCountdownText = (seconds: number) => {
  return {
    __html: `<p>Por favor espera. Ser&aacute;s redirigido en ${seconds} segundos ...</p>`
  };
};

const NoTests = ({ returnUrl, language }: Props) => {
  const [seconds, setSeconds] = useState(3);

  // decode the url, remove the tildes, and the base64 decode it
  let url = returnUrl ? decodeURIComponent(returnUrl) : null;
  url = url ? url.replace(/~/g, "/") : null;
  url = url ? atob(url) : null;

  // on mount only, set the interval to count down the seconds, and clean up on unmount
  useInterval(() => {
    const timeRemaining = seconds - 1;
    if (timeRemaining === 0 && url) {
      window.location.href = url;
    } else {
      setSeconds(timeRemaining);
    }
  }, 1000);

  return (
    <div className="text-center">
      {language === undefined ? (
        <React.Fragment>
          <h1>Thank You</h1>
          <p>You are not required to take any tests at this time.</p>
          {returnUrl ? (
            <p>{`Please wait. You will be redirected in ${seconds} seconds...`}</p>
          ) : null}
        </React.Fragment>
      ) : (
        <div dangerouslySetInnerHTML={setSpanishText()} />
      )}
      {returnUrl && language === "spanish" ? (
        <div dangerouslySetInnerHTML={setSpanishCountdownText(seconds)} />
      ) : null}
    </div>
  );
};

export default NoTests;
