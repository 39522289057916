import React, { Component } from "react";
import { formatTime } from "../utils/shared";

interface State {
  seconds: number;
  questionNumber: number | null | undefined;
}

interface Props {
  seconds: number;
  startTimer: boolean;
  color: string;
  pauseTimer: boolean | null;
  questionNumber?: number | null;
  subTestId?: string | null;
  onCompletion: () => void;
  placedAt?: string;
}
export default class Timer extends Component<Props, State> {
  state = {
    seconds: this.props.seconds,
    questionNumber: this.props.questionNumber
  };

  // declare class property type
  timerID: any;
  // set the interval to countdown the clock, if the startTimer prop was initially set to true
  componentDidMount() {
    if (this.props.startTimer) {
      this.startTimer();
    }
  }

  componentDidUpdate(prevProps: Props) {
    // check if the startTimer has been set to true on the parent component, and if so, start the timer
    // used primarily with the TypingTest and TenKeyTest components
    if (this.props.startTimer !== prevProps.startTimer) {
      this.startTimer();
    }
    // check if the pauseTimer prop has been set to true, and if so, clear the timer interval so that
    // the timer stops
    if (
      this.props.pauseTimer !== prevProps.pauseTimer &&
      this.props.pauseTimer
    ) {
      clearInterval(this.timerID);
    }
    // check if the pauseTimer prop has been set to false, and if so, restart the timer
    if (
      this.props.pauseTimer !== prevProps.pauseTimer &&
      !this.props.pauseTimer
    ) {
      this.startTimer();
    }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.questionNumber && props.questionNumber !== state.questionNumber) {
      return { seconds: props.seconds, questionNumber: props.questionNumber };
    }
  }

  // clean up the setInterval
  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  // method to start the timer
  startTimer = () => {
    this.timerID = setInterval(() => this.tick(), 1000);
  };

  // method to decrement the seconds value in state whenever it is called
  // if time is up, calls completion function and clears the interval
  tick = () => {
    const newTime = this.state.seconds - 1;
    this.setState({ seconds: newTime });
    if (newTime <= 0) {
      clearInterval(this.timerID);
      this.props.onCompletion();
    }
  };

  render() {
    return (
      <div
        id="timer"
        style={{
          color: this.props.color,
          textAlign: this.props.placedAt === "clikv2" ? "left" : "right"
        }}
      >
        {formatTime(this.state.seconds)}
      </div>
    );
  }
}
