/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */

// This plugin is for specific usage with our Chart.js radar charts in Illustrait Workplace Insights
// Reports and Work Styles Reports to generate rounded "pill-style" point labels with colored backgrounds,
// which is not built-in behavior for our current 2.9.4 version of the Chart.js package.

const labelBackgroundColors = [
  "#ffa300",
  "#ff5c39",
  "#d50057",
  "#8e1578",
  "#643099",
  "#425cc7",
  "#00a9e0",
  "#2dccd3"
];

// alignmentLabels are the 4 gray labels seen on typical X and Y axes
// the keys correspond to the pointLabel index they will be set next to
// ex. "STEADY" will be above the "Reliable" point label at index 0
const alignmentLabels = {
  0: {
    label: "STEADY",
    xFactor: 0,
    yFactor: -1
  },
  2: {
    label: "DYNAMIC",
    xFactor: 1,
    yFactor: 0
  },
  4: {
    label: "UNINHIBITED",
    xFactor: 0,
    yFactor: 1
  },
  6: {
    label: "STATIC",
    xFactor: -1,
    yFactor: 0
  }
};

const labelFontSize = 13;
const labelBackgroundPadding = 8;
const distanceFromPointToLabel = 6;

const alignmentLabelFontSize = 12;
const xDistanceFromPointLabel = 60;
const yDistanceFromPointLabel = 35;

export const ChartJsLabelPlugin = isWIR => ({
  afterRender: chart => {
    const { ctx, scale, data } = chart;

    const getIndexAngle = index => {
      const angleMultiplier = 360 / data.labels.length;
      const angle = -90 + index * angleMultiplier;
      return angle < 0 ? angle + 360 : angle;
    };

    const getPointPosition = (
      index, // labelIndex
      distanceFromCenter,
      padding // label's background padding
    ) => {
      const updatedDistanceFromCenter = distanceFromCenter + padding;
      const thisAngle = getIndexAngle(index);
      const textWidth = scale._pointLabelSizes[index].w;
      const textHeight = scale._pointLabelSizes[index].h;
      const x_align =
        // Center top and center bottom points
        thisAngle === 90 || thisAngle === 270
          ? textWidth / 2
          : thisAngle > 90 && thisAngle < 270
          ? // Left half
            textWidth
          : // Right half
            0;
      const y_align =
        thisAngle === 0 || thisAngle === 180
          ? textHeight / 2
          : thisAngle > 0 && thisAngle < 180
          ? 0
          : textHeight + padding / 2;
      const radianAngle = (thisAngle * Math.PI * 2) / 360;
      return {
        x:
          Math.cos(radianAngle) * updatedDistanceFromCenter +
          scale.xCenter -
          x_align,
        y:
          Math.sin(radianAngle) * updatedDistanceFromCenter +
          scale.yCenter -
          y_align,
        textWidth,
        textHeight
      };
    };

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < data.labels.length; i++) {
      const pointLabelPosition = getPointPosition(
        i,
        scale.drawingArea + distanceFromPointToLabel,
        labelBackgroundPadding
      );
      const backgroundHeight =
        pointLabelPosition.textHeight + labelBackgroundPadding;

      ctx.save();
      ctx.fillStyle = labelBackgroundColors[i];

      // Draw Rectangle
      ctx.beginPath();
      ctx.fillRect(
        pointLabelPosition.x,
        pointLabelPosition.y,
        pointLabelPosition.textWidth,
        backgroundHeight
      );
      ctx.closePath();

      // Draw left half circle
      ctx.beginPath();
      ctx.arc(
        // Center of the circle
        pointLabelPosition.x + 1,
        pointLabelPosition.y + backgroundHeight / 2,
        // Radius
        backgroundHeight / 2 - 0.25,
        // Start angle, 90 degree
        Math.PI / 2,
        // End angle, 270 degree
        (Math.PI * 3) / 2,
        // isCounterClockwise
        false
      );
      ctx.closePath();
      ctx.fill();

      // Draw right half circle
      ctx.beginPath();
      ctx.arc(
        // Center of the circle
        pointLabelPosition.x + pointLabelPosition.textWidth,
        pointLabelPosition.y + backgroundHeight / 2,
        // Radius
        backgroundHeight / 2 - 0.25,
        // Start angle, -90 degree
        -Math.PI / 2,
        // End angle, 270 degree
        (Math.PI * 3) / 2,
        false
      );
      ctx.closePath();
      ctx.fill();

      const textContent = scale.pointLabels[i];
      ctx.fillStyle = "white";
      ctx.textAlign = "left";
      ctx.font = "Helvetica";
      ctx.fillText(
        textContent,
        pointLabelPosition.x,
        pointLabelPosition.y + labelFontSize + labelBackgroundPadding / 2
      );

      if (!isWIR && alignmentLabels[i]) {
        ctx.fillStyle = "#7f8fa6";
        ctx.textAlign = "left";
        ctx.font = `bold ${alignmentLabelFontSize}px Helvetica`;

        // Insert spacing between label text characters to follow design
        const alignmentLabelText = alignmentLabels[i].label.split("").join(" ");
        const alignmentLabelWidth = ctx.measureText(alignmentLabelText).width;

        ctx.fillText(
          alignmentLabelText,
          scale.xCenter -
            // center the label halfway through the word
            alignmentLabelWidth / 2 +
            alignmentLabels[i].xFactor *
              (scale.drawingArea +
                distanceFromPointToLabel +
                pointLabelPosition.textWidth +
                alignmentLabelWidth / 2 +
                xDistanceFromPointLabel),
          scale.yCenter +
            alignmentLabelFontSize / 2 +
            alignmentLabels[i].yFactor *
              (scale.drawingArea +
                distanceFromPointToLabel +
                pointLabelPosition.textHeight +
                labelBackgroundPadding +
                yDistanceFromPointLabel)
        );
      }

      ctx.restore();
    }
  }
});
