import React from "react";
import Key from "../../ImageComponents/Key";
import { instructionsObjectEnglish } from "../instructionsObject";
import { instructionsObjectSpanish } from "../instructionsObject";
import InstructionsWrapper from "../InstructionsWrapper";
import NavigateButtons from "../NavigateButtons";
import FingerPositions from "../../Images/finger_positions_FJ.jpeg";
import Shape from "../../ImageComponents/Shape";
import TaskTwoShapesRow from "../../ImageComponents/TaskTwoShapesRow";
import FingerPositionsMobile from "../../Images/finger_positions_FJ_mobile.png";

interface Props {
  togglePractice(): void;
  language: string;
  isMobile: boolean;
  isMobileOnly?: boolean;
  isIPad13: boolean;
}

const VigilanceInstructions = ({
  togglePractice,
  language,
  isMobile,
  isMobileOnly,
  isIPad13
}: Props) => {
  const instructionsObject =
    language === "english"
      ? instructionsObjectEnglish
      : instructionsObjectSpanish;

  const isMobileAndEnglish = (isMobile || isIPad13) && language === "english";

  return (
    <InstructionsWrapper>
      {({ instructionPage, handleContinue, handleBack }) => {
        const finalPage = 5;
        if (instructionPage > finalPage) {
          togglePractice();
        }
        return (
          <div>
            {instructionPage === 1 ? (
              <div>
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile mt-3"
                      : "instruction-text mt-3"
                  }
                >
                  {instructionsObject[2][instructionPage].text_1}
                </p>
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile"
                      : "instruction-text"
                  }
                >
                  {isMobileAndEnglish
                    ? instructionsObject[2][instructionPage].text_2a.slice(
                        0,
                        -4
                      )
                    : instructionsObject[2][instructionPage].text_2a}
                  <span>
                    <Key isMobile={isMobile} isIPad13={isIPad13} text="J" />
                  </span>
                  {isMobileAndEnglish
                    ? instructionsObject[2][instructionPage].text_2b.slice(1)
                    : instructionsObject[2][instructionPage].text_2b}
                </p>
                <br />
                <div className="text-center">
                  <Shape
                    shape="right parallel"
                    fill={"#999"}
                    isInstructions={true}
                  />
                </div>
                <br />
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile"
                      : "instruction-text"
                  }
                >
                  {isMobileAndEnglish
                    ? instructionsObject[2][instructionPage].text_2a.slice(
                        0,
                        -4
                      )
                    : instructionsObject[2][instructionPage].text_2a}
                  <span>
                    <Key isMobile={isMobile} isIPad13={isIPad13} text="F" />
                  </span>
                  {isMobileAndEnglish
                    ? instructionsObject[2][instructionPage].text_3b.slice(1)
                    : instructionsObject[2][instructionPage].text_3b}
                </p>
                <TaskTwoShapesRow isMobile={isMobile} isIPad13={isIPad13} />
              </div>
            ) : null}
            {instructionPage === 2 ? (
              <div
                className={
                  isMobileOnly
                    ? "instruction-text-mobile mt-3"
                    : "instruction-text mt-3"
                }
              >
                <div>
                  {instructionsObject[2][instructionPage].text_1}
                  {instructionsObject[2][instructionPage].text_2}{" "}
                  <span
                    className="instructions-shape"
                    style={{ position: "relative", top: "12px" }}
                  >
                    <Shape
                      shape="right parallel"
                      fill="#999"
                      isInstructions={true}
                      isMobile={isMobile}
                      isIPad13={isIPad13}
                      isMobileOnly={isMobileOnly}
                    />
                  </span>
                  {instructionsObject[2][instructionPage].text_3}
                  <span>
                    <Key isMobile={isMobile} isIPad13={isIPad13} text="J" />
                  </span>
                </div>
                <div className={isMobile || isIPad13 ? "mt-3" : "mt-1"}>
                  {instructionsObject[2][instructionPage].text_1}
                  {instructionsObject[2][instructionPage].text_2}
                  <span
                    className="instructions-shape"
                    style={{ position: "relative", top: "12px" }}
                  >
                    <Shape
                      shape="left parallel"
                      fill="#999"
                      isInstructions={true}
                      isMobile={isMobile}
                      isIPad13={isIPad13}
                      isMobileOnly={isMobileOnly}
                    />
                  </span>
                  {instructionsObject[2][instructionPage].text_3}
                  <span>
                    <Key isMobile={isMobile} isIPad13={isIPad13} text="F" />
                  </span>
                </div>
                <div className={isMobile || isIPad13 ? "mt-3" : "mt-1"}>
                  {instructionsObject[2][instructionPage].text_1}
                  {instructionsObject[2][instructionPage].text_2}
                  <span
                    className="instructions-shape"
                    style={{ position: "relative", top: "12px" }}
                  >
                    <Shape
                      shape="rectangle"
                      fill="#999"
                      isInstructions={true}
                      isMobile={isMobile}
                      isIPad13={isIPad13}
                      isMobileOnly={isMobileOnly}
                    />
                  </span>
                  {instructionsObject[2][instructionPage].text_3}
                  <span>
                    <Key isMobile={isMobile} isIPad13={isIPad13} text="F" />
                  </span>
                </div>
                <div className={isMobile || isIPad13 ? "mt-3" : "mt-1"}>
                  {instructionsObject[2][instructionPage].text_1}
                  {instructionsObject[2][instructionPage].text_2}
                  <span
                    className="instructions-shape"
                    style={{ position: "relative", top: "12px" }}
                  >
                    <Shape
                      shape="down trapezoid"
                      fill="#999"
                      isInstructions={true}
                      isMobile={isMobile}
                      isIPad13={isIPad13}
                      isMobileOnly={isMobileOnly}
                    />
                  </span>
                  {instructionsObject[2][instructionPage].text_3}
                  <span>
                    <Key isMobile={isMobile} isIPad13={isIPad13} text="F" />
                  </span>
                </div>
                <div className={isMobile || isIPad13 ? "mt-3" : "mt-1"}>
                  {instructionsObject[2][instructionPage].text_1}
                  {instructionsObject[2][instructionPage].text_2}
                  <span
                    className="instructions-shape"
                    style={{ position: "relative", top: "12px" }}
                  >
                    <Shape
                      shape="trapezoid"
                      fill="#999"
                      isInstructions={true}
                      isMobile={isMobile}
                      isIPad13={isIPad13}
                      isMobileOnly={isMobileOnly}
                    />
                  </span>
                  {instructionsObject[2][instructionPage].text_3}
                  <span>
                    <Key isMobile={isMobile} isIPad13={isIPad13} text="F" />
                  </span>
                </div>
              </div>
            ) : null}
            {instructionPage === 3 ? (
              <div
                className={
                  isMobileOnly
                    ? "instruction-text-mobile mt-3"
                    : "instruction-text mt-3"
                }
              >
                <p>{instructionsObject[2][instructionPage].text_1}</p>
                <p>{instructionsObject[2][instructionPage].text_2}</p>
              </div>
            ) : null}
            {instructionPage === 4 ? (
              <div
                className={
                  isMobileOnly
                    ? "instruction-text-mobile mt-3"
                    : "instruction-text mt-3"
                }
              >
                {isMobileAndEnglish
                  ? "You will see F and J buttons on the screen as shown below"
                  : instructionsObject[2][instructionPage].text_1}
                <div className="text-center mt-3">
                  {isMobile || isIPad13 ? (
                    <img
                      src={FingerPositionsMobile}
                      alt="letters F and J as buttons"
                      width="250px"
                      height="67px"
                    />
                  ) : (
                    <img src={FingerPositions} alt="finger positions" />
                  )}
                </div>
              </div>
            ) : null}
            {instructionPage === 5 ? (
              <div
                className={
                  isMobileOnly
                    ? "instruction-text-mobile mt-3"
                    : "instruction-text mt-3"
                }
              >
                <p>{instructionsObject[2][instructionPage].text_1}</p>
                <p>
                  {isMobileAndEnglish
                    ? "If you make a mistake or do not respond before the next shape appears, your screen will display a message."
                    : instructionsObject[2][instructionPage].text_2}
                </p>
                <div>
                  {isMobileAndEnglish
                    ? instructionsObject[2][instructionPage].text_3a.slice(
                        0,
                        -5
                      )
                    : instructionsObject[2][instructionPage].text_3a}
                  <span>
                    <Key isMobile={isMobile} isIPad13={isIPad13} text="J" />
                  </span>
                  {isMobileAndEnglish
                    ? instructionsObject[2][instructionPage].text_3b.slice(1)
                    : instructionsObject[2][instructionPage].text_3b}
                  <span>
                    <Shape
                      shape="right parallel"
                      fill="#999"
                      isInstructions={true}
                      isMobileOnly={isMobileOnly}
                    />
                  </span>
                </div>
                <div className={isMobile || isIPad13 ? "mt-3" : "mt-1"}>
                  {isMobileAndEnglish
                    ? instructionsObject[2][instructionPage].text_4a.slice(
                        0,
                        -5
                      )
                    : instructionsObject[2][instructionPage].text_4a}
                  <span>
                    <Key isMobile={isMobile} isIPad13={isIPad13} text="F" />
                  </span>
                  {isMobileAndEnglish
                    ? instructionsObject[2][instructionPage].text_4b.slice(1)
                    : instructionsObject[2][instructionPage].text_4b}
                </div>
                <div>
                  <TaskTwoShapesRow
                    isMobile={isMobile}
                    isMobileOnly={isMobileOnly}
                    isIPad13={isIPad13}
                  />
                </div>
                {language === "english" ? (
                  <p className={isMobileOnly ? "my-0" : "mt-1"}>
                    {instructionsObject[2][instructionPage].text_5}
                  </p>
                ) : null}
              </div>
            ) : null}
            <NavigateButtons
              instructionPage={instructionPage}
              handleBack={handleBack}
              handleContinue={handleContinue}
              finalPage={finalPage}
              language={language}
            />
          </div>
        );
      }}
    </InstructionsWrapper>
  );
};

export default VigilanceInstructions;
