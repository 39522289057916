import React, { useState } from "react";
import useInterval from "../../../CustomHooks/useInterval";

interface Props {
  updateStartTask(): void;
  language: string;
  isPractice?: boolean;
}

const Countdown = ({ updateStartTask, language, isPractice }: Props) => {
  const [seconds, setSeconds] = useState(5);

  // on mount only, set the interval to count down the seconds, and clean up on unmount
  useInterval(() => {
    const timeRemaining = seconds - 1;
    if (timeRemaining === 0) {
      updateStartTask();
    } else {
      setSeconds(timeRemaining);
    }
  }, 1000);

  let countdownMessage = "";
  if (language === "english") {
    if (isPractice) countdownMessage = "Your tutorial will begin in:";
    else countdownMessage = "Your test will begin in:";
  } else {
    if (isPractice) countdownMessage = "Su tutorial comenzará en:";
    else countdownMessage = "Su prueba comenzará en:";
  }
  return (
    <div className="text-center">
      <h1 className="mb-5">{countdownMessage}</h1>
      <div id="countdown-number">
        <p style={{ lineHeight: "1.2" }}>{seconds}</p>
      </div>
    </div>
  );
};

export default Countdown;
