/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { Button, Row, Col } from "react-bootstrap";
import useInterval from "../../../../../CustomHooks/useInterval";
import { navigate } from "@reach/router";
import { TestEventContext } from "../../../../../Contexts/TestEventContext";
import { isEventExpired } from "../../../../../utils/shared";

interface Props {
  isPractice: boolean;
  practiceCounter: any;
  practiceArray: number[];
  toggleIncorrectAnswerDisplay(): void;
  finishTask(): void;
  updatePracticeCounter(): void;
  isMobile: boolean;
  isTablet: boolean;
  isIPad13: boolean;
}

interface AnswerData {
  timeTaken: number | null;
  answer: number;
  stimulusId: number;
}

const VerbalMemoryTestDisplay = ({
  isPractice,
  practiceCounter,
  practiceArray,
  toggleIncorrectAnswerDisplay,
  updatePracticeCounter,
  finishTask,
  isMobile,
  isTablet,
  isIPad13
}: Props) => {
  // set up the state
  const [testArray]: any = useState(
    Array.from({ length: 60 }, () => Math.floor(Math.random() * 4 + 1))
  );
  const [testCounter, setTestCounter] = useState(0);
  const [startTime, setStartTime] = useState(new Date());
  const [showImage, setShowImage] = useState(false);
  const [answer, setAnswer]: any = useState(null);

  const context = useContext(TestEventContext);

  // for the first image, this effect will show it for the 500 milliseconds only (needed to be separated out from the
  // useInterval hook).
  useEffect(() => {
    setShowImage(true);
    setTimeout(() => {
      setShowImage(false);
    }, 1000);
  }, []);

  // when the component first mounts, start a new timer
  useEffect(() => {
    if (!isPractice) {
      setStartTime(new Date());
    }
  }, [isPractice]);

  // cycle through the numbers in the appropriate array, displaying them for 2 seconds.
  useInterval(() => {
    if (isPractice) {
      if (answer !== null) {
        updatePracticeCounter();
        setAnswer(null);
        setShowImage(true);
        setTimeout(() => {
          setShowImage(false);
        }, 1000);
      } else {
        toggleIncorrectAnswerDisplay();
        window.removeEventListener("keyup", handleKeyPress);
      }
    } else {
      // start a new timer
      setStartTime(new Date());
      if (!answer) {
        //  create the appropriate answer object
        const answerData: AnswerData = {
          answer: 0,
          timeTaken: 2.0,
          stimulusId: testArray[testCounter]
        };
        // retrieve existing answer, if any, from local storage
        const existingAnswers: any = localStorage.getItem("a");
        // create an array from existing answer, or if there none (first question) initialize an empty array
        const newAnswers =
          existingAnswers !== null ? JSON.parse(existingAnswers) : [];
        // push the new answerData to the existing answers and set to local storage
        newAnswers.push(answerData);
        localStorage.setItem("a", JSON.stringify(newAnswers));
      }
      // set up timeout that only shows the image for 1000 milliseconds
      setShowImage(true);
      setTimeout(() => {
        setShowImage(false);
      }, 1000);

      // increment the test counter
      setTestCounter(testCounter + 1);

      // reset answer
      setAnswer(false);

      // if testCounter gets to 60, the test is finished
      if (testCounter === 59) {
        finishTask();
      }
    }
  }, 2000);

  /* ---------- Event Handler ---------- */

  const handleKeyPress = (event: KeyboardEvent) => {
    if (context && !isEventExpired(context.eventExpirationDate)) {
      if (event.key.toLowerCase() === "f" || event.key.toLowerCase() === "j") {
        window.removeEventListener("keyup", handleKeyPress);
        if (isPractice) {
          if (
            practiceCounter > 1 &&
            practiceArray[practiceCounter] ===
              practiceArray[practiceCounter - 2]
          ) {
            if (event.key.toLowerCase() === "j") {
              setAnswer(event.key);
            } else {
              toggleIncorrectAnswerDisplay();
            }
          } else if (
            practiceCounter > 1 &&
            practiceArray[practiceCounter] !==
              practiceArray[practiceCounter - 2]
          ) {
            if (event.key.toLowerCase() === "f") {
              setAnswer(event.key);
            } else {
              toggleIncorrectAnswerDisplay();
            }
          } else {
            if (event.key.toLowerCase() === "f") {
              setAnswer(event.key);
            } else {
              toggleIncorrectAnswerDisplay();
            }
          }
        } else {
          window.removeEventListener("keyup", handleKeyPress);
          // set answers in local storage until the end of the task, at which point they are sent to redis
          const endTime: Date = new Date();
          const timeTaken: number | null =
            startTime !== null
              ? (endTime.getTime() - startTime.getTime()) / 1000
              : null;

          setAnswer(true);

          // determine if answer is correct
          let isCorrect: number;
          if (
            testCounter > 1 &&
            testArray[testCounter] === testArray[testCounter - 2]
          ) {
            if (event.key.toLowerCase() === "j") {
              isCorrect = 1;
            } else {
              isCorrect = 0;
            }
          } else if (
            testCounter > 1 &&
            testArray[testCounter] !== testArray[testCounter - 2]
          ) {
            if (event.key.toLowerCase() === "f") {
              isCorrect = 1;
            } else {
              isCorrect = 0;
            }
          } else {
            if (event.key.toLowerCase() === "f") {
              isCorrect = 1;
            } else {
              isCorrect = 0;
            }
          }

          //  create the appropriate answer object
          const answerData: AnswerData = {
            answer: isCorrect,
            timeTaken,
            stimulusId: testArray[testCounter]
          };
          // retrieve existing answer, if any, from local storage
          const existingAnswers: any = localStorage.getItem("a");
          // create an array from existing answer, or if there none (first question) initialize an empty array
          const newAnswers =
            existingAnswers !== null ? JSON.parse(existingAnswers) : [];
          // push the new answerData to the existing answers and set to local storage
          newAnswers.push(answerData);
          localStorage.setItem("a", JSON.stringify(newAnswers));

          // if testCounter is at end of array the task is complete so send the answers to redis
          // and show the taskComplete display.
          if (testCounter === 59) {
            finishTask();
          }
        }
      }
    } else {
      // if the event has expired, we need to update the error message, and
      // navigate to the overview page immediately
      context?.updateExpiredMessage();
      navigate("/overview");
    }
  };

  const handleClickEvent = (event: any) => {
    if (context && !isEventExpired(context.eventExpirationDate)) {
      if (
        event.currentTarget.value === "f" ||
        event.currentTarget.value === "j"
      ) {
        window.removeEventListener("keyup", handleKeyPress);
        if (isPractice) {
          if (
            practiceCounter > 1 &&
            practiceArray[practiceCounter] ===
              practiceArray[practiceCounter - 2]
          ) {
            if (event.currentTarget.value === "j") {
              setAnswer(event.key);
            } else {
              toggleIncorrectAnswerDisplay();
            }
          } else if (
            practiceCounter > 1 &&
            practiceArray[practiceCounter] !==
              practiceArray[practiceCounter - 2]
          ) {
            if (event.currentTarget.value === "f") {
              setAnswer(event.key);
            } else {
              toggleIncorrectAnswerDisplay();
            }
          } else {
            if (event.currentTarget.value === "f") {
              setAnswer(event.key);
            } else {
              toggleIncorrectAnswerDisplay();
            }
          }
        } else {
          window.removeEventListener("click", handleClickEvent);
          // set answers in local storage until the end of the task, at which point they are sent to redis
          const endTime: Date = new Date();
          const timeTaken: number | null =
            startTime !== null
              ? (endTime.getTime() - startTime.getTime()) / 1000
              : null;

          setAnswer(true);

          // determine if answer is correct
          let isCorrect: number;
          if (
            testCounter > 1 &&
            testArray[testCounter] === testArray[testCounter - 2]
          ) {
            if (event.currentTarget.value === "j") {
              isCorrect = 1;
            } else {
              isCorrect = 0;
            }
          } else if (
            testCounter > 1 &&
            testArray[testCounter] !== testArray[testCounter - 2]
          ) {
            if (event.currentTarget.value === "f") {
              isCorrect = 1;
            } else {
              isCorrect = 0;
            }
          } else {
            if (event.currentTarget.value === "f") {
              isCorrect = 1;
            } else {
              isCorrect = 0;
            }
          }

          //  create the appropriate answer object
          const answerData: AnswerData = {
            answer: isCorrect,
            timeTaken,
            stimulusId: testArray[testCounter]
          };
          // retrieve existing answer, if any, from local storage
          const existingAnswers: any = localStorage.getItem("a");
          // create an array from existing answer, or if there none (first question) initialize an empty array
          const newAnswers =
            existingAnswers !== null ? JSON.parse(existingAnswers) : [];
          // push the new answerData to the existing answers and set to local storage
          newAnswers.push(answerData);
          localStorage.setItem("a", JSON.stringify(newAnswers));

          // if testCounter is at end of array the task is complete so send the answers to redis
          // and show the taskComplete display.
          if (testCounter === 59) {
            finishTask();
          }
        }
      }
    } else {
      // if the event has expired, we need to update the error message, and
      // navigate to the overview page immediately
      context?.updateExpiredMessage();
      navigate("/overview");
    }
  };

  // add and remove the keyup event listener, only when component first mounts
  useEffect(() => {
    if (isMobile || isIPad13) {
      window.addEventListener("click", handleClickEvent);
      return () => {
        window.removeEventListener("click", handleClickEvent);
      };
    } else {
      window.addEventListener("keyup", handleKeyPress);
      return () => {
        window.removeEventListener("keyup", handleKeyPress);
      };
    }
  }, [practiceCounter, testCounter]);

  return (
    <React.Fragment>
      <div className="center-screen">
        {isPractice && showImage ? (
          <div
            className="text-center"
            style={{ fontSize: "64px", fontWeight: "bold" }}
          >
            {practiceArray[practiceCounter]}
          </div>
        ) : null}
        {!isPractice && showImage ? (
          <div
            className="text-center"
            style={{ fontSize: "64px", fontWeight: "bold" }}
          >
            {testArray[testCounter]}
          </div>
        ) : null}
      </div>
      {isMobile || isIPad13 ? (
        <Row
          style={{
            backgroundColor: "#f5f5f5",
            position: "fixed",
            height: "100px",
            width: "100%",
            bottom: 0,
            marginLeft: "-16px",
            marginRight: "-16px"
          }}
        >
          <Col
            sm={6}
            xs={6}
            className="my-auto"
            style={{ paddingRight: "5px" }}
          >
            <Button
              className={
                isTablet || isIPad13
                  ? "my-1 py-1 float-right ripple"
                  : "my-1 py-1 ripple"
              }
              style={{
                backgroundColor: "#ffffff",
                width: isTablet || isIPad13 ? "50%" : "100%",
                borderColor: "#000000",
                borderRadius: "10px",
                borderWidth: "2px"
              }}
              value="f"
              onClick={handleClickEvent}
            >
              <span style={{ color: "#000000", fontSize: "40px" }}>F</span>
            </Button>
          </Col>
          <Col sm={6} xs={6} className="my-auto" style={{ paddingLeft: "5px" }}>
            <Button
              className="my-1 py-1 ripple"
              style={{
                backgroundColor: "#ffffff",
                width: isTablet || isIPad13 ? "50%" : "100%",
                borderColor: "#000000",
                borderRadius: "10px",
                borderWidth: "2px"
              }}
              value="j"
              onClick={handleClickEvent}
            >
              <span style={{ color: "#000000", fontSize: "40px" }}>J</span>
            </Button>
          </Col>
        </Row>
      ) : null}
    </React.Fragment>
  );
};

export default VerbalMemoryTestDisplay;
