import React, { Component } from "react";
import Shape from "./Shape";

interface Props {
  isMobile?: boolean;
  isMobileOnly?: boolean;
  isIPad13: boolean;
}

export default class TaskTwoShapesRow extends Component<Props> {
  render() {
    const { isMobile, isMobileOnly, isIPad13 } = this.props;
    return isMobile || isIPad13 ? (
      <React.Fragment>
        <div className="shapes-row" style={{ justifyContent: "normal" }}>
          <div>
            <Shape
              shape="rectangle"
              fill="#999"
              isInstructions={true}
              isMobileOnly={isMobileOnly}
            />
          </div>
          <div className="shapes-row-conjuction">Or</div>
          <div>
            <Shape
              shape="trapezoid"
              fill="#999"
              isInstructions={true}
              isMobileOnly={isMobileOnly}
            />
          </div>
          <div className="shapes-row-conjuction">Or</div>
        </div>
        <div className="shapes-row" style={{ justifyContent: "normal" }}>
          <div>
            <Shape
              shape="down trapezoid"
              fill="#999"
              isInstructions={true}
              isMobileOnly={isMobileOnly}
            />
          </div>
          <div className="shapes-row-conjuction">Or</div>
          <div>
            <Shape
              shape="left parallel"
              fill="#999"
              isInstructions={true}
              isMobileOnly={isMobileOnly}
            />
          </div>
        </div>
      </React.Fragment>
    ) : (
      <div className="shapes-row">
        <div>
          <Shape shape="rectangle" fill="#999" isInstructions={true} />
        </div>
        <div className="shapes-row-conjuction">Or</div>
        <div>
          <Shape shape="trapezoid" fill="#999" isInstructions={true} />
        </div>
        <div className="shapes-row-conjuction">Or</div>
        <div>
          <Shape shape="down trapezoid" fill="#999" isInstructions={true} />
        </div>
        <div className="shapes-row-conjuction">Or</div>
        <div>
          <Shape shape="left parallel" fill="#999" isInstructions={true} />
        </div>
      </div>
    );
  }
}
