import React from "react";
import { Row, Form, Col } from "react-bootstrap";
import WindowHeader from "./WindowHeader";
import { letterDoc, reportDoc } from "../CLIKResources/CLIKTexts";

interface WordDocumentInterface {
  docTitle: string;
  minimized: boolean;
  maximized: boolean;
  handleToggleMinimize(windowName: string): void;
  handleToggleMaximize(windowName: string): void;
  handleCloseWindow(windowName: string): void;
  handleDesktopItemClick(windowName: string): void;
  zIndexValue: number;
}

const docToTextMap: { [k: string]: string } = {
  "letter.doc": letterDoc,
  "report.doc": reportDoc
};

const docToFieldMap: { [k: string]: string } = {
  "letter.doc": "letterDoc",
  "report.doc": "reportDoc"
};

const WordDocument = ({
  docTitle,
  maximized,
  minimized,
  handleToggleMaximize,
  handleToggleMinimize,
  handleCloseWindow,
  handleDesktopItemClick,
  zIndexValue
}: WordDocumentInterface) => {
  return (
    <Col
      onClick={() => handleDesktopItemClick(docToFieldMap[docTitle])}
      xl={maximized ? 12 : 7}
      lg={maximized ? 12 : 7}
      md={maximized ? 12 : 6}
      sm={maximized ? 12 : 6}
      xs={maximized ? 12 : 6}
      style={{
        position: "absolute",
        top: maximized ? "0" : "50px",
        left: maximized ? "0" : "200px",
        minHeight: "150px",
        minWidth: "300px",
        height: maximized ? "100%" : "",
        width: maximized ? "100%" : "",
        zIndex: zIndexValue,
        display: minimized ? "none" : ""
      }}
    >
      <WindowHeader
        titleText={`${docTitle} - Microsoft Word`}
        minimized={minimized}
        maximized={maximized}
        handleToggleMinimize={handleToggleMinimize}
        handleToggleMaximize={handleToggleMaximize}
        handleCloseWindow={handleCloseWindow}
      >
        <Row
          noGutters={true}
          style={{
            backgroundColor: "#FFFFFF",
            minHeight: "300px",
            height: maximized ? "100%" : ""
          }}
        >
          <Form.Group>
            <Form.Control
              as="textarea"
              id="word-document-content"
              rows={10}
              cols={80}
              defaultValue={docToTextMap[docTitle]}
            />
          </Form.Group>
        </Row>
      </WindowHeader>
    </Col>
  );
};

export default WordDocument;
