import React from "react";
import { Col } from "react-bootstrap";
import PageContinueButton from "../../../../../Components/PageContinueButton";
import tutorial_correct_answer from "../../GameResources/tutorial_correct_answer.png";
import tutorial_incorrect_answer from "../../GameResources/tutorial_incorrect_answer.png";

interface Props {
  isAnswerCorrect: boolean;
  handleProgressNext: () => void;
  displayText: {
    correctOrIncorrect: string;
    feedbackText: string;
    next: string;
  };
}

const TutorialFeedbackModal = ({
  isAnswerCorrect,
  handleProgressNext,
  displayText
}: Props) => {
  const handleNext = () => {
    handleProgressNext();
  };

  return (
    <Col className="game-tutorial-modal">
      <div className="game-tutorial-feedback">
        <h2>
          <img
            className="game-tutorial-icon-img"
            src={
              isAnswerCorrect
                ? tutorial_correct_answer
                : tutorial_incorrect_answer
            }
            alt={`${
              isAnswerCorrect
                ? tutorial_correct_answer
                : tutorial_incorrect_answer
            }`}
          />
          {displayText.correctOrIncorrect}
        </h2>
        <div dangerouslySetInnerHTML={{ __html: displayText.feedbackText }} />
        <PageContinueButton
          handler={handleNext}
          buttonText={displayText.next}
        />
      </div>
    </Col>
  );
};

export default TutorialFeedbackModal;
