import React from "react";
import Key from "../../ImageComponents/Key";
import { instructionsObjectEnglish } from "../instructionsObject";
import { instructionsObjectSpanish } from "../instructionsObject";
import InstructionsWrapper from "../InstructionsWrapper";
import NavigateButtons from "../NavigateButtons";
import FingerPositions from "../../Images/finger_positions_FJ.jpeg";
import SpatialMemoryExample from "../../ImageComponents/SpatialMemoryExample";
import FingerPositionsMobile from "../../Images/finger_positions_FJ_mobile.png";

interface Props {
  togglePractice(): void;
  language: string;
  isMobile: boolean;
  isMobileOnly?: boolean;
  isIPad13?: boolean;
}

const SpatialMemoryInstructions = ({
  togglePractice,
  language,
  isMobile,
  isMobileOnly,
  isIPad13
}: Props) => {
  const instructionsObject =
    language === "english"
      ? instructionsObjectEnglish
      : instructionsObjectSpanish;

  const isMobileAndEnglish = (isMobile || isIPad13) && language === "english";

  return (
    <InstructionsWrapper>
      {({ instructionPage, handleContinue, handleBack }) => {
        const finalPage = 4;
        if (instructionPage > finalPage) {
          togglePractice();
        }
        return (
          <div>
            {instructionPage === 1 ? (
              <div
                className={
                  isMobileOnly
                    ? "instruction-text-mobile mt-3"
                    : "instruction-text mt-3"
                }
              >
                <p>{instructionsObject[6][instructionPage].text_1}</p>

                <p>
                  {isMobileAndEnglish
                    ? instructionsObject[6][instructionPage].text_2a.slice(
                        0,
                        -5
                      )
                    : instructionsObject[6][instructionPage].text_2a}
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="J" />
                  {isMobileAndEnglish
                    ? instructionsObject[6][instructionPage].text_2b.slice(1)
                    : instructionsObject[6][instructionPage].text_2b}
                </p>
                <p>
                  {isMobileAndEnglish
                    ? instructionsObject[6][instructionPage].text_3.slice(0, -5)
                    : instructionsObject[6][instructionPage].text_3}
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="F" />
                </p>
              </div>
            ) : null}
            {instructionPage === 2 ? (
              <div
                className={
                  isMobileOnly
                    ? "instruction-text-mobile mt-3"
                    : "instruction-text mt-3"
                }
              >
                <p>{instructionsObject[6][instructionPage].text_1}</p>
                <div className="text-center">
                  <SpatialMemoryExample />
                </div>
                <p>
                  {isMobileAndEnglish
                    ? instructionsObject[6][instructionPage].text_2a.slice(
                        0,
                        -5
                      )
                    : instructionsObject[6][instructionPage].text_2a}
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="J" />
                  {isMobileAndEnglish
                    ? instructionsObject[6][instructionPage].text_2b.slice(
                        0,
                        -5
                      )
                    : instructionsObject[6][instructionPage].text_2b}
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="F" />
                </p>
                <p>{instructionsObject[6][instructionPage].text_3}</p>
              </div>
            ) : null}
            {instructionPage === 3 ? (
              <div
                className={
                  isMobileOnly
                    ? "instruction-text-mobile mt-3"
                    : "instruction-text mt-3"
                }
              >
                {isMobileAndEnglish
                  ? "You will see F and J buttons on the screen as shown below"
                  : instructionsObject[6][instructionPage].text_1}
                <div className="text-center mt-3">
                  {isMobile || isIPad13 ? (
                    <img
                      src={FingerPositionsMobile}
                      alt="letters F and J as buttons"
                      width="250px"
                      height="67px"
                    />
                  ) : (
                    <img src={FingerPositions} alt="finger positions" />
                  )}
                </div>
              </div>
            ) : null}
            {instructionPage === 4 ? (
              <div
                className={
                  isMobileOnly
                    ? "instruction-text-mobile mt-3"
                    : "instruction-text mt-3"
                }
              >
                <p>{instructionsObject[6][instructionPage].text_1}</p>
                <p>
                  {isMobileAndEnglish
                    ? "If you make a mistake or do not respond within two seconds(before the next number appears), your screen will display a message."
                    : instructionsObject[6][instructionPage].text_2}
                </p>
                <p>
                  {isMobileAndEnglish
                    ? instructionsObject[6][instructionPage].text_3a.slice(
                        0,
                        -5
                      )
                    : instructionsObject[6][instructionPage].text_3a}
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="F" />
                  {instructionsObject[6][instructionPage].text_3b}
                </p>
                <p>{instructionsObject[6][instructionPage].text_4}</p>
                <p>
                  {isMobileAndEnglish
                    ? instructionsObject[6][instructionPage].text_5.slice(0, -5)
                    : instructionsObject[6][instructionPage].text_5}
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="F" />
                </p>
                <p>
                  {isMobileAndEnglish
                    ? instructionsObject[6][instructionPage].text_6.slice(0, -5)
                    : instructionsObject[6][instructionPage].text_6}
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="J" />
                </p>
              </div>
            ) : null}
            <NavigateButtons
              instructionPage={instructionPage}
              handleBack={handleBack}
              handleContinue={handleContinue}
              finalPage={finalPage}
              language={language}
            />
          </div>
        );
      }}
    </InstructionsWrapper>
  );
};

export default SpatialMemoryInstructions;
