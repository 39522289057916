import React, { useEffect, useContext } from "react";
import { Container, Card, Alert } from "react-bootstrap";
import Header from "../Components/Header";
import LandingPageStyle from "../Interfaces/LandingPageStyle";
import { TestEventContext } from "../Contexts/TestEventContext";

interface Props {
  landingPageStyle: LandingPageStyle;
  handleUpdateComponentShowing(componentName: string): void;
}

const CalendarWarning = ({
  landingPageStyle,
  handleUpdateComponentShowing
}: Props) => {
  const context = useContext(TestEventContext);

  useEffect(() => {
    handleUpdateComponentShowing("Calendar Warning");
  }, [handleUpdateComponentShowing]);

  return (
    <div>
      <Container>
        <Card className="oda-card">
          <Header
            onWelcomePage={false}
            backgroundColor={landingPageStyle.backgroundColor}
          />
          <Card.Body
            style={{
              backgroundColor: landingPageStyle.backgroundColor,
              color: landingPageStyle.textColor
            }}
          >
            <Alert variant="danger" className="text-center">
              <div>
                {context?.testEventData?.translatedText
                  ?.interviewCalendarNotAvailable ? (
                  context.testEventData.translatedText
                    .interviewCalendarNotAvailable
                ) : (
                  <>
                    <p>
                      Hello! The link for this interview calendar is no longer
                      available. Please contact the company that you’re applying
                      to, to get an updated method of scheduling an interview.
                    </p>
                    <p>Thank you,</p>
                    <p>Criteria Support</p>
                  </>
                )}
              </div>
            </Alert>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default CalendarWarning;
