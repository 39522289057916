import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FadeLoader } from "react-spinners";

interface Props {
  path: string;
  eventId?: string;
  fetchTestEventByEventId: (
    eventId: string,
    fromLink: boolean,
    fromExternalTest: boolean
  ) => Promise<boolean>;
}

const Event = ({ path, eventId, fetchTestEventByEventId }: Props) => {
  useEffect(() => {
    if (eventId) {
      const formattedEventId: string = eventId.replace(/-/g, "");
      fetchTestEventByEventId(formattedEventId, false, true)
        .then(() => {
          return true;
        })
        .catch(error => console.log(error));
    }
  }, [eventId, fetchTestEventByEventId]);

  return (
    <Container>
      <Row>
        <Col className="spinner-container">
          <div className="spinner text-center">
            <FadeLoader height={15} color="#425CC7" />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Event;
