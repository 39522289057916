// tslint:disable variable-name
import React from "react";

const F_MultiShapes = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80">
      <g stroke="#000">
        <circle cx="20" cy="20" r="18" fill="#3f3f3f" />
        <circle cx="20" cy="60" r="19" fill="#bfbfbf" />
        <path
          fill="#fff"
          d="M41 16h14l5-14 4 14h14l-11 8 4 14-11-9-12 9 5-14-12-8zM42 42h36v36H42z"
        />
      </g>
    </svg>
  );
};

export default F_MultiShapes;
