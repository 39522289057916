/* eslint-disable array-callback-return */
import { navigate } from "@reach/router";
import React, { Component } from "react";
import { Container, Row } from "react-bootstrap";
import { TestEventContext } from "../../../Contexts/TestEventContext";
import { InitialAdaptiveQuestion } from "../../../Interfaces/InitialAdaptiveQuestion";
import {
  getAdaptiveStartingQuestion,
  postGameTestData
} from "../../../utils/redisHelpers";
import { isEventExpired, preloadImages } from "../../../utils/shared";
import BirdFeather from "./GameContainers/BirdFeather";
import RobotInspector from "./GameContainers/RobotInspector";
import WeighStation from "./GameContainers/WeighStation";
import GameTutorial from "./GameTutorial/GameTutorial";

interface Props {
  testData: any;
  subTestId: string;
  currentGameId: number;
  startingQuestion: number;
  endSubTest: () => void;
  resetStartingQuestion: () => void;
  timeFactor: number;
  forceGameStart?: boolean;
}

interface State {
  testNumber: number;
  startGame: boolean;
  initialQuestionData: InitialAdaptiveQuestion;
}

export default class GameTest extends Component<Props, State> {
  state = {
    testNumber: this.props.currentGameId === 0 ? 1 : this.props.currentGameId,
    startGame: this.props.forceGameStart ?? false,
    initialQuestionData: {
      questionId: "",
      optionA: "",
      optionB: ""
    }
  };

  // define the context type so that we can use this.context throughout the class
  static readonly contextType = TestEventContext;

  /* --------- React LifeCycle Methods ---------- */

  componentDidMount() {
    // preload images used in the tests
    const tests =
      this.context.testEventData.tests[
        this.context.testIdsArray[this.context.testIndex]
      ];
    if (tests) {
      if (this.props.currentGameId < 2) {
        const preloaded = preloadImages(tests.details.questions[1]);
        preloaded.then(() => {
          this.setState({
            startGame: true
          });
        });
      }
      this.setState({ startGame: true });
    }

    // save the game test data for use if they test taker resumes the test (all tests are randomly generated
    // when first issued, so to ensure the same test is retrieved for a returning test taker, we have to do this)
    const testEventId = this.context.testEventId;
    const testData =
      this.context.testEventData.tests[
        this.context.testIdsArray[this.context.testIndex]
      ];
    postGameTestData(
      testEventId,
      this.context.testIdsArray[this.context.testIndex],
      testData,
      this.context.token
    );

    // get first adaptive question if on WeighStation or BirdFeather
    if (this.props.currentGameId > 1) {
      // call getNextAdaptiveQuestion, pass the result into weigh station, bird feather
      const initialQuestionData = getAdaptiveStartingQuestion(
        this.context.testEventId,
        this.context.testIdsArray[this.context.testIndex],
        this.props.currentGameId,
        this.context.token,
        testData.details.initialTheta,
        this.context.isResumingTest
      );
      initialQuestionData.then((data: any) => {
        this.setState({
          initialQuestionData: data.nextQuestion
        });
      });
    }
  }

  // progress to the next game
  progressToNextGame = () => {
    if (!isEventExpired(this.context.eventExpirationDate)) {
      if (this.state.testNumber === 3) {
        this.props.endSubTest();
      } else {
        // when game test is restarted, the startingQuestion prop is used to tell the individual game which question to start at
        // if you restart in the middle of game 2, the startingQuestion prop will be non-zero
        // then, when you start game 3, the startingQuestion prop is passed to game 3, which would result in game 3 starting at a non-zero question number
        // reset the startingQuestion prop to 0 when proceeding to next game
        this.props.resetStartingQuestion();
        // call getNextAdaptiveQuestion, pass the result into weigh station, bird feather
        const initialQuestionData = getAdaptiveStartingQuestion(
          this.context.testEventId,
          this.context.testIdsArray[this.context.testIndex],
          this.state.testNumber + 1,
          this.context.token,
          this.context.testEventData.tests[
            this.context.testIdsArray[this.context.testIndex]
          ].details.initialTheta,
          this.context.isResumingTest
        );
        initialQuestionData.then((data: any) => {
          this.setState({
            initialQuestionData: data.nextQuestion,
            startGame: false
          });
        });
      }
    } else {
      // if the event has expired, we need to update the error message, and
      // navigate to the overview page immediately
      this.context.updateExpiredMessage();
      navigate("/overview");
    }
  };

  // handles the next button in intro page between games.  increments to the next testNumber
  handleNext = () => {
    if (!isEventExpired(this.context.eventExpirationDate)) {
      this.setState(state => ({
        testNumber: state.testNumber + 1,
        startGame: true
      }));
    } else {
      // if the event has expired, we need to update the error message, and
      // navigate to the overview page immediately
      this.context.updateExpiredMessage();
      navigate("/overview");
    }
  };

  render() {
    const { testData, timeFactor } = this.props;
    const pressKeysObject = {
      pressFKey: testData.text.pressFKey,
      pressJKey: testData.text.pressJKey
    };

    return (
      <div style={{ backgroundColor: "#FFF" }}>
        {this.state.startGame && this.state.testNumber === 1 ? (
          <RobotInspector
            questions={testData.questions}
            startingQuestion={this.props.startingQuestion}
            progressToNextGame={this.progressToNextGame}
            timeFactor={timeFactor}
            answerOptions={testData.text.robotInspectorGame.answerOptions}
            pressKeys={pressKeysObject}
            disconnectModalDisplayText={{
              testPaused: testData.text.testPaused,
              errorSavingQuestion: testData.text.errorSavingQuestion,
              pleaseWaitReconnecting: testData.text.pleaseWaitReconnecting,
              unableToReconnectTryAgainLater:
                testData.text.unableToReconnectTryAgainLater
            }}
          />
        ) : null}

        {this.state.startGame && this.state.testNumber === 2 ? (
          <BirdFeather
            initialQuestionData={this.state.initialQuestionData}
            progressToNextGame={this.progressToNextGame}
            startingQuestion={this.props.startingQuestion}
            timeFactor={timeFactor}
            answerOptions={testData.text.wordOfAFeatherGame.answerOptions}
            pressKeys={pressKeysObject}
            disconnectModalDisplayText={{
              testPaused: testData.text.testPaused,
              errorSavingQuestion: testData.text.errorSavingQuestion,
              pleaseWaitReconnecting: testData.text.pleaseWaitReconnecting,
              unableToReconnectTryAgainLater:
                testData.text.unableToReconnectTryAgainLater
            }}
            testQuestions={testData.text.testQuestions}
          />
        ) : null}

        {this.state.startGame && this.state.testNumber === 3 ? (
          <WeighStation
            initialQuestionData={this.state.initialQuestionData}
            progressToNextGame={this.progressToNextGame}
            startingQuestion={this.props.startingQuestion}
            timeFactor={timeFactor}
            answerOptions={testData.text.weightStationGame.answerOptions}
            pressKeys={pressKeysObject}
            disconnectModalDisplayText={{
              testPaused: testData.text.testPaused,
              errorSavingQuestion: testData.text.errorSavingQuestion,
              pleaseWaitReconnecting: testData.text.pleaseWaitReconnecting,
              unableToReconnectTryAgainLater:
                testData.text.unableToReconnectTryAgainLater
            }}
          />
        ) : null}

        {!this.state.startGame ? (
          <Container>
            <Row>
              <GameTutorial
                gameTutorialId={
                  this.state.testNumber === 3
                    ? this.state.testNumber
                    : this.state.testNumber + 1
                }
                handleStartRealTest={this.handleNext}
                timeFactor={timeFactor}
                testData={testData}
              />
            </Row>
          </Container>
        ) : null}
      </div>
    );
  }
}
