import React from "react";
import { PiMagnifyingGlass, PiMicrophone, PiCamera } from "react-icons/pi";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface InputInterface {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  submitInput: (e: React.FormEvent) => void;
  disabled?: boolean;
}

const Input = ({ value, onChange, submitInput, disabled }: InputInterface) => {
  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && submitInput) {
      submitInput(e);
    }
  };

  return (
    <div style={{ position: "relative", maxWidth: "576px", width: "100%" }}>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="popover">Search the web</Tooltip>}
      >
        <PiMagnifyingGlass
          onClick={e => submitInput(e)}
          style={{
            fontSize: "20px",
            position: "absolute",
            left: 18,
            top: 18,
            cursor: "pointer"
          }}
        />
      </OverlayTrigger>
      <div
        className="d-flex align-items-center position-absolute"
        style={{
          gap: "16px",
          right: 16,
          top: 18
        }}
      >
        <PiMicrophone style={{ fontSize: "20px" }} />
        <PiCamera style={{ fontSize: "20px" }} />
      </div>
      <form onSubmit={submitInput}>
        <input
          placeholder="Search the web..."
          style={{
            maxWidth: "576px",
            width: "100%",
            padding: "15px 74px 15px 44px",
            borderRadius: "25px",
            border: "1px solid #ddddde",
            backgroundColor: "#FFFFFF"
          }}
          onKeyDown={onKeyDownHandler}
          value={value}
          onChange={onChange}
          disabled={disabled}
          type="search"
        />
      </form>
    </div>
  );
};

export default Input;
