import React, { Component } from "react";

interface Props {
  path: string;
  contentType: string;
  contentText: string;
  locale?: string;
  fetchContent: (type: string, locale?: string) => Promise<boolean>;
}

export default class Content extends Component<Props> {
  componentDidMount() {
    if (
      (this.props.contentType === "accommodations" ||
        this.props.contentType === "faqs") &&
      this.props.locale
    ) {
      this.props.fetchContent(this.props.contentType, this.props.locale);
    } else {
      this.props.fetchContent(this.props.contentType);
    }
    requestAnimationFrame(() => {
      window.scrollTo(0, 0);
    });
  }

  render() {
    return (
      <div dangerouslySetInnerHTML={{ __html: atob(this.props.contentText) }} />
    );
  }
}
