import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import {
  FaBars,
  FaTrash,
  FaPaperclip,
  FaTable,
  FaPaste,
  FaPaperPlane,
  FaAngleDown
} from "react-icons/fa";

import WindowHeader from "./WindowHeader";
import { letterDoc } from "../CLIKResources/CLIKTexts";

interface ComposeEmailInterface {
  minimized: boolean;
  maximized: boolean;
  handleToggleMinimize(windowName: string): void;
  handleToggleMaximize(windowName: string): void;
  handleCloseWindow(windowName: string): void;
  handleDesktopItemClick(windowName: string): void;
  handleSendEmail(
    toAddress: string,
    ccAddress: string,
    subject: string,
    clicked: boolean
  ): void;
  handleSetSim1Answer(answerNum: number, answerVal: number): void;
  handleUpdateFields(
    toAddress: string | null,
    ccAddress: string | null,
    emailSubject: string | null
  ): void;
  zIndexValue: number;
  disableSendButton: boolean;
}

const navMenuItems = ["Delete", "Attach", "Table", "Paste"];

const getNavItemIcons = (menuItem: string) => {
  switch (menuItem) {
    case "Delete":
      return <FaTrash />;
    case "Attach":
      return <FaPaperclip />;
    case "Table":
      return <FaTable />;
    case "Paste":
      return <FaPaste />;
  }
};

const navMenu = navMenuItems.map(menuItem => {
  const icon = getNavItemIcons(menuItem);
  return (
    <button key={menuItem} className="hover-shade">
      {icon}
      {menuItem}
      {menuItem === "Table" ? <FaAngleDown /> : null}
    </button>
  );
});

const ComposeEmail = ({
  maximized,
  minimized,
  handleToggleMaximize,
  handleToggleMinimize,
  handleCloseWindow,
  handleDesktopItemClick,
  handleSendEmail,
  handleSetSim1Answer,
  zIndexValue,
  handleUpdateFields,
  disableSendButton
}: ComposeEmailInterface) => {
  const [toAddress, setToAddress] = useState("");
  const [ccAddress, setCcAddress] = useState("");
  const [subject, setSubject] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [bodyText, setBodyText] = useState("");

  const onHandleSendEmail = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    handleSendEmail(toAddress, ccAddress, subject, true);
  };

  const handleToInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setToAddress(e.target.value);
    handleUpdateFields(e.target.value, null, null);
  };
  const handleCcInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCcAddress(e.target.value);
    handleUpdateFields(null, e.target.value, null);
  };
  const handleSubjectInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubject(e.target.value);
    handleUpdateFields(null, null, e.target.value);
  };
  const handleBodyInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value === letterDoc) {
      handleSetSim1Answer(4, 1);
    }
    setBodyText(e.target.value);
  };

  return (
    <Col
      onClick={() => handleDesktopItemClick("composeEmail")}
      xl={maximized ? 12 : 7}
      lg={maximized ? 12 : 7}
      md={maximized ? 12 : 6}
      sm={maximized ? 12 : 6}
      xs={maximized ? 12 : 6}
      style={{
        position: "absolute",
        top: maximized ? "0" : "10px",
        left: maximized ? "0" : "400px",
        minWidth: "300px",
        height: maximized ? "100%" : "",
        width: maximized ? "100%" : "",
        zIndex: zIndexValue,
        display: minimized ? "none" : ""
      }}
    >
      <WindowHeader
        titleText="Untitled - Message"
        minimized={minimized}
        maximized={maximized}
        handleToggleMinimize={handleToggleMinimize}
        handleToggleMaximize={handleToggleMaximize}
        handleCloseWindow={handleCloseWindow}
      >
        <Col
          style={{ backgroundColor: "#edeeee", padding: "0px", height: "100%" }}
        >
          <Row
            noGutters={true}
            style={{
              width: "100%",
              position: "relative",
              backgroundColor: "#f7f7f7",
              height: "50px",
              padding: "5px"
            }}
          >
            <Col>
              <Row>
                <Col xs={3}>
                  <button className="outlook-menu-bars">
                    <FaBars />
                  </button>
                  <button
                    className="outlook-action-button"
                    onClick={onHandleSendEmail}
                    disabled={disableSendButton}
                  >
                    Send
                    <FaPaperPlane className="ml-2" />
                  </button>
                </Col>
                <Col xs={9} className="outlook-nav">
                  {navMenu}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            className="pt-2 pl-2 pr-2"
            style={{
              width: "100%",
              margin: "0 auto"
            }}
          >
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Form.Group className="mt-1 mb-1">
                <Form.Label className="outlook-compose-label">To:</Form.Label>
                <Form.Control
                  type="text"
                  onChange={handleToInput}
                  className="outlook-compose-field"
                  style={{ width: "80%" }}
                />
              </Form.Group>
              <Form.Group className="mt-1 mb-1">
                <Form.Label className="outlook-compose-label">Cc:</Form.Label>
                <Form.Control
                  type="text"
                  onChange={handleCcInput}
                  className="outlook-compose-field"
                  style={{ width: "80%" }}
                />
              </Form.Group>
              <Form.Group className="mt-1 mb-1">
                <Form.Label className="outlook-compose-label">
                  Subject:
                </Form.Label>
                <Form.Control
                  type="text"
                  onChange={handleSubjectInput}
                  className="outlook-compose-field"
                  style={{ width: "80%" }}
                />
              </Form.Group>
            </Col>
            <Col
              id="outlook-email-body"
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <Form.Control as="textarea" rows={7} onChange={handleBodyInput} />
            </Col>
          </Row>
        </Col>
      </WindowHeader>
    </Col>
  );
};

export default ComposeEmail;
