import * as configcat from "configcat-js";
import { configCatAppKey } from "../utils/constants";
import { revelianTestToSubTestIdMap } from "../utils/Reference/SubTestDataMaps";
import { GameData } from "../Interfaces/RevelianTestData";

export const setUpConfigCat = (
  testEventData: any,
  country: string,
  isAU: boolean,
  isMobile: boolean,
  updateConfigCatObject: (configCatObject: any) => void
) => {
  const userObject: any = {
    Identifier: testEventData.testTaker.testTakerId,
    country: country,
    custom: {
      companyAccountId: String(testEventData.companyAccountId),
      partnerAccountId: String(testEventData.partnerAccountId),
      locale: isAU ? "AU" : "US",
      mobile: String(isMobile)
    }
  };
  const configCatClient = configcat.createClientWithManualPoll(configCatAppKey);
  configCatClient.forceRefresh(() => {
    configCatClient.getAllValues(values => {
      let isPostAssessmentResearchEnabled: boolean = false;
      let isABTestEventIdEnabled: boolean = false;
      let isMarketingODAResearchEnabled: boolean = false;
      values.forEach(setting => {
        switch (setting.settingKey) {
          case "ispostassessmentresearchenabled":
            isPostAssessmentResearchEnabled = setting.settingValue;
            break;
          case "abtest_eventid":
            isABTestEventIdEnabled = setting.settingValue;
            break;
          case "ismarketingodaresearchenabled":
            isMarketingODAResearchEnabled = setting.settingValue;
            break;
          default:
            break;
        }
      });
      const configCatObject = {
        isPostAssessmentResearchEnabled,
        isABTestEventIdEnabled,
        isMarketingODAResearchEnabled
      };
      updateConfigCatObject(configCatObject);
    }, userObject);
  });
};

const getIsResumingTest = (testType: string, data: any, testId: string) => {
  switch (testType) {
    case "multipleChoiceTest":
      return data.questionNumber > 0;
    case "testMakerTest":
      return data && data.questionNumber > 0;
    case "personalityTest":
      return data.pageNumberToResume > 1;
    case "gameTest":
      return Object.keys(data.gameTestData).length > 0;
    case "mrabCastTest":
      return data.startingTaskNumber > 1;
    case "CLIKTest":
      return data.pageNumberToResume > 0;
    case "RevelianSubTest": {
      const revelianTests = revelianTestToSubTestIdMap.filter(obj =>
        obj.subTestIds.includes(testId)
      );
      const currentGameData = data.games.filter((game: any) =>
        game.url.includes(revelianTests[0].name)
      );
      // once that API call is returned, create an array of the erred games
      const revelianTestResuming: boolean = currentGameData.reduce(
        (prevGameStatus: string | boolean, game: GameData) =>
          prevGameStatus !== false && prevGameStatus !== game.gameStatus
            ? true
            : game.gameStatus,
        false
      );
      return revelianTestResuming === true;
    }
    default:
      return false;
  }
};

export const getStateObjectToUpdate = (
  testId: string,
  data: any,
  responseData: any,
  testType: string,
  stateObject: any
) => {
  let isResumingTest: boolean;
  if (responseData) {
    isResumingTest = getIsResumingTest(testType, responseData, testId);
    stateObject.isResumingTest = isResumingTest;

    if (testType === "multipleChoiceTest") {
      stateObject.expiredTime = Number(responseData.expiredTime);
    }

    if (
      (testType === "multipleChoiceTest" ||
        testType === "testMakerTest" ||
        testType === "personalityTest" ||
        testType === "gameTest") &&
      isResumingTest
    ) {
      stateObject.testIdToResume = testId;
    }

    if (
      testType === "gameTest" &&
      Object.keys(responseData.gameTestData).length > 0
    ) {
      const testEventDataCopy = data;
      testEventDataCopy.tests[testId].details =
        responseData.gameTestData.testData.details;
      stateObject.testEventData = testEventDataCopy;
    }
  }
  return stateObject;
};

export const getInvalidEventErrorMessage = (
  errorType: "link" | "eventId",
  isAU?: boolean,
  translatedText?: string
): string => {
  let errorMessage: string;
  // if no `translatedText` available, fall back to English
  if (translatedText) {
    errorMessage = translatedText;
  } else {
    errorMessage = `<span>It looks like there was a problem, <strong>this ${
      errorType === "link" ? "link" : "event ID"
    } is no longer valid.</strong></span><br /><span>Please contact the ${
      isAU ? "organisation" : "organization"
    } that sent you the invitation for further assistance.</span>`;
  }
  return errorMessage;
};
