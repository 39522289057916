import React from "react";
import RobotInspectorTutorial from "./RobotInspectorTutorial";
import BirdFeatherTutorial from "./BirdFeatherTutorial";
import WeighStationTutorial from "./WeighStationTutorial";

interface Props {
  gameTutorialId: number;
  exampleQuestion: { optionA: string; optionB: string };
  isTutorialTestBegin: boolean;
  handleCheckAnswer: (userInputAnswer: string) => void;
  handleShowTimeExpiredModal: () => void;
  showStartTutorialModal: boolean;
  isAnswerCorrect: boolean | null;
  timeFactor: number;
  answerOptions: { [propName: string]: string };
  pressKeys: { pressFKey: string; pressJKey: string };
  disconnectModalDisplayText: {
    testPaused: string;
    errorSavingQuestion: string;
    pleaseWaitReconnecting: string;
    unableToReconnectTryAgainLater: string;
  };
}

const TutorialComponent = ({
  gameTutorialId,
  exampleQuestion,
  isTutorialTestBegin,
  handleCheckAnswer,
  handleShowTimeExpiredModal,
  showStartTutorialModal,
  isAnswerCorrect,
  timeFactor,
  answerOptions,
  pressKeys,
  disconnectModalDisplayText
}: Props) => {
  const getGameToDisplay = (gameTutorialId: number) => {
    switch (gameTutorialId) {
      case 1:
        return (
          <RobotInspectorTutorial
            isTutorialTestBegin={isTutorialTestBegin}
            exampleQuestion={exampleQuestion}
            handleCheckAnswer={handleCheckAnswer}
            handleShowTimeExpiredModal={handleShowTimeExpiredModal}
            showGameTutorialStartModal={showStartTutorialModal}
            placedAtTutorial
            isAnswerCorrect={isAnswerCorrect}
            timeFactor={timeFactor}
            answerOptions={answerOptions}
            pressKeys={pressKeys}
            disconnectModalDisplayText={disconnectModalDisplayText}
          />
        );
      case 2:
        return (
          <BirdFeatherTutorial
            isTutorialTestBegin={isTutorialTestBegin}
            exampleQuestion={exampleQuestion}
            handleCheckAnswer={handleCheckAnswer}
            handleShowTimeExpiredModal={handleShowTimeExpiredModal}
            showGameTutorialStartModal={showStartTutorialModal}
            placedAtTutorial
            timeFactor={timeFactor}
            answerOptions={answerOptions}
            pressKeys={pressKeys}
            disconnectModalDisplayText={disconnectModalDisplayText}
          />
        );
      case 3:
        return (
          <WeighStationTutorial
            isTutorialTestBegin={isTutorialTestBegin}
            exampleQuestion={exampleQuestion}
            handleCheckAnswer={handleCheckAnswer}
            handleShowTimeExpiredModal={handleShowTimeExpiredModal}
            showGameTutorialStartModal={showStartTutorialModal}
            placedAtTutorial
            timeFactor={timeFactor}
            answerOptions={answerOptions}
            pressKeys={pressKeys}
            disconnectModalDisplayText={disconnectModalDisplayText}
          />
        );
      default:
        break;
    }
  };

  const gameToDisplay = getGameToDisplay(gameTutorialId);
  return <>{gameToDisplay}</>;
};

export default TutorialComponent;
