import React from "react";
import ResultsBox from "./ResultsBox";
import FadeContent from "./FadeContent";
import olympicsImage from "../../Resources/images/olympicsImage.png";
import { Test } from "./types";

interface OlympicsResultsSummaryProps {
  test: Test;
  showFadeContent: boolean;
}

const OlympicsResultsSummary = ({
  test,
  showFadeContent
}: OlympicsResultsSummaryProps) => {
  return (
    <>
      <div
        style={{
          width: "100%",
          border: "1px solid #DDDDDE",
          borderRadius: "8px 8px 0px 0px",
          padding: "16px 15px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <div className="d-flex flex-column justify-content-start">
          <h1 className="mb-0" style={{ fontSize: "40px", fontWeight: 700 }}>
            1896
          </h1>
          <p style={{ fontSize: "12px" }}>According to 4 sources</p>
        </div>
        <img src={olympicsImage} alt="First Olympics" width="127px" />
      </div>
      <div
        style={{
          width: "100%",
          border: "1px solid #DDDDDE",
          borderRadius: "0px 0px 8px 8px",
          backgroundColor: "#EDEEEE",
          padding: "16px",
          display: "flex",
          flex: 1,
          alignItems: "center",
          gap: "16px",
          overflowX: "hidden",
          position: "relative"
        }}
      >
        {test?.resultsBoxContent?.map(content => (
          <ResultsBox
            key={content.linkUrl}
            linkTitle={content.linkTitle}
            linkUrl={content.linkUrl}
            description={content.description}
          />
        ))}
        {showFadeContent && <FadeContent />}
      </div>
    </>
  );
};

export default OlympicsResultsSummary;
