/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  FaTimes,
  FaCheck,
  FaAngleDoubleRight,
  FaAngleDoubleLeft
} from "react-icons/fa";
import { Row, Col } from "react-bootstrap";

interface Props {
  leftLabel: string;
  rightLabel: string;
  isWeighStation: boolean;
  handleAnswerSubmit: (event: any) => void;
  pressKeys: { pressFKey: string; pressJKey: string };
}

const ActionButtons = ({
  leftLabel,
  rightLabel,
  handleAnswerSubmit,
  isWeighStation,
  pressKeys
}: Props) => {
  return (
    <div className="actionButtons">
      <Row noGutters={true}>
        <Col>
          <a
            href="#"
            role="button"
            className="redButton"
            onClick={handleAnswerSubmit}
            data-answer="left"
          >
            <h1>{isWeighStation ? <FaAngleDoubleLeft /> : <FaTimes />}</h1>
            <div>{leftLabel}</div>
            <div className="d-none d-lg-block mt-1">{pressKeys.pressFKey}</div>
          </a>
        </Col>
        <Col>
          <a
            href="#"
            role="button"
            className="blueButton"
            onClick={handleAnswerSubmit}
            data-answer="right"
          >
            <h1>{isWeighStation ? <FaAngleDoubleRight /> : <FaCheck />}</h1>
            <div>{rightLabel}</div>
            <div className="d-none d-lg-block mt-1">{pressKeys.pressJKey}</div>
          </a>
        </Col>
      </Row>
    </div>
  );
};

export default ActionButtons;
