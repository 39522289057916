import React from "react";

interface PeopleWhoAskBoxInterface {
  title: string;
  linkTitle: string;
  linkUrl: string;
  description: string;
}

const PeopleWhoAskBox = ({
  description,
  title,
  linkTitle,
  linkUrl
}: PeopleWhoAskBoxInterface) => {
  return (
    <div
      style={{
        flex: "0 0 263px",
        height: "289px",
        padding: "16px",
        border: "1px solid #DDDDDE",
        display: "flex",
        flexDirection: "column",
        borderRadius: "8px",
        gap: "16px"
      }}
    >
      <span style={{ fontSize: "18px", fontWeight: 700 }}>{title}</span>
      <span
        style={{ fontSize: "14px", fontWeight: 500, lineHeight: "20px" }}
        dangerouslySetInnerHTML={{ __html: description }}
      ></span>
      <div style={{ borderBottom: "1px solid #DDDDDE" }}></div>
      <div className="d-flex flex-column">
        <a
          href={linkUrl}
          style={{
            fontSize: "18px",
            color: "#1E139C",
            textDecoration: "none",
            pointerEvents: "none"
          }}
        >
          {linkTitle}
        </a>
        <span
          style={{
            color: "#006225",
            fontSize: "14px",
            fontWeight: 500
          }}
        >
          {linkUrl}
        </span>
      </div>
    </div>
  );
};

export default PeopleWhoAskBox;
