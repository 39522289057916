import React, { Component } from "react";
import { Alert, Button } from "react-bootstrap";
import { instructionsObjectEnglish } from "../instructionsObject";
import { instructionsObjectSpanish } from "../instructionsObject";
import Key from "../../ImageComponents/Key";
import { FaAngleRight } from "react-icons/fa";

interface Props {
  toggleIncorrectAnswerDisplay(): void;
  language: string;
  isMobile: boolean;
  isMobileOnly?: boolean;
  isIPad13?: boolean;
}

export default class TaskSevenIncorrectDisplay extends Component<Props> {
  componentDidMount() {
    if (this.props.isMobile || this.props.isIPad13) {
      window.addEventListener("click", this.handleClickEvent);
    } else {
      window.addEventListener("keyup", this.handleKeyPress);
    }
  }

  componentWillUnmount() {
    if (this.props.isMobile || this.props.isIPad13) {
      window.removeEventListener("click", this.handleClickEvent);
    } else {
      window.removeEventListener("keyup", this.handleKeyPress);
    }
  }

  handleKeyPress = (event: KeyboardEvent) => {
    if (event.key.toLowerCase() === "f" || event.key.toLowerCase() === "j") {
      this.props.toggleIncorrectAnswerDisplay();
    }
  };

  handleClickEvent = (event: any) => {
    if (event.currentTarget === "f" || event.currentTarget === "j") {
      this.props.toggleIncorrectAnswerDisplay();
    }
  };

  render() {
    const {
      toggleIncorrectAnswerDisplay,
      language,
      isMobile,
      isMobileOnly,
      isIPad13
    } = this.props;
    const instructionsObject =
      language === "english"
        ? instructionsObjectEnglish
        : instructionsObjectSpanish;

    const isMobileAndEnglish = (isMobile || isIPad13) && language === "english";

    return (
      <div>
        <Alert variant="danger" className="mrab-incorrect-warning text-center">
          Incorrect.
        </Alert>
        <div
          className={
            isMobileOnly ? "instruction-text-mobile" : "instruction-text"
          }
        >
          <p>{instructionsObject[7].incorrect.text_1}</p>
          <p>
            {isMobileAndEnglish
              ? instructionsObject[7].incorrect.text_2.slice(0, -5)
              : instructionsObject[7].incorrect.text_2}
            <span style={{ marginBottom: "-20px" }}>
              <Key isMobile={isMobile} isIPad13={isIPad13} text="J" />
            </span>
            {isMobileAndEnglish
              ? instructionsObject[7].incorrect.text_3.slice(1)
              : instructionsObject[7].incorrect.text_3}
          </p>
          <p>
            {isMobileAndEnglish
              ? instructionsObject[7].incorrect.text_2.slice(0, -5)
              : instructionsObject[7].incorrect.text_2}
            <span style={{ marginBottom: "-20px" }}>
              <Key isMobile={isMobile} isIPad13={isIPad13} text="F" />
            </span>
            {isMobileAndEnglish
              ? instructionsObject[7].incorrect.text_4.slice(1)
              : instructionsObject[7].incorrect.text_4}
          </p>
          <p>
            {isMobileAndEnglish
              ? "Press Continue to resume practice."
              : instructionsObject[7].incorrect.text_5}
          </p>
          <div className="first-instruction-button-row">
            <Button
              variant="primary"
              className="instructions-button"
              onClick={toggleIncorrectAnswerDisplay}
            >
              Continue
              <FaAngleRight
                style={{
                  fontSize: "24px",
                  verticalAlign: "middle",
                  marginTop: "-5px"
                }}
              />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
