import React from "react";
import { Col, Alert, Modal } from "react-bootstrap";
import PageContinueButton from "../../../../../Components/PageContinueButton";
import clock_blue from "../../GameResources/clock_blue@2x.png";

interface Props {
  hideStartModal: () => void;
  timeAllowedPerQuestion: number;
  displayText: {
    gameTitle: string;
    instructions: string;
    task: string;
    beginTutorial: string;
    timeYouHaveToAnswer: string;
  };
}

const BeginTutorialModal = ({
  hideStartModal,
  timeAllowedPerQuestion,
  displayText
}: Props) => {
  const onBeginTutorial = () => {
    hideStartModal();
  };

  return (
    <Col className="game-tutorial-modal game-tutorial-start-complete noPadding">
      <Modal.Header className="custom-modal-header-footer tutorial-modal-header">
        <div
          style={{
            backgroundColor: "#425cc7",
            color: "white",
            padding: "20px",
            textAlign: "center",
            borderRadius: "4px 4px 0px 0px"
          }}
        >
          <p style={{ fontSize: "16px" }}>{displayText.task}</p>
          <p style={{ fontSize: "24px" }}>{displayText.gameTitle}</p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="game-tutorial-instruction">
          <div
            dangerouslySetInnerHTML={{
              __html: displayText.instructions
            }}
          />
          <div>
            <Alert className="custom-alert">
              <img
                style={{ marginRight: "10px", width: "24px", color: "#2e408a" }}
                src={clock_blue}
                alt="times-up"
              />
              {displayText.timeYouHaveToAnswer}
            </Alert>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="custom-modal-header-footer tutorial-modal-footer">
        <PageContinueButton
          handler={onBeginTutorial}
          buttonText={displayText.beginTutorial}
        />
      </Modal.Footer>
    </Col>
  );
};

export default BeginTutorialModal;
