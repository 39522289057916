import Language from "../../Interfaces/Language";

export const cs: Language = {
  common: {
    help: "Need Help?",
    helpCenter: "Help Centre",
    helpCenterLink: "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/",
    expect: "What to expect on these tests",
    expectLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/collections/3396642-about-criteria-assessments",
    faq: "Frequently Asked Questions",
    faqLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7853580-odpovedi-na-caste-dotazy",
    copyright: "Autorská práva",
    copyrightNotices: "Informace o autorských právech",
    copyrightNoticesLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994376-informace-o-autorskych-pravech ",
    terms: "Podmínky užití",
    termsLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994304-podminky-uziti",
    privacy: "Prohlášení o ochraně osobních údajů",
    privacyLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6990120-prohlaseni-o-ochrane-osobnich-udaju",
    dmca: "DMCA Policy",
    dmcaLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7857178-zasady-dmca",
    accommodations: "Postižení / úlevy",
    accommodationsLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994441-postizeni-ulevy ",
    technicalSupport: "Technical Support",
    chatSupport:
      "For support, please chat with our team on the bottom right hand corner of this page"
  },
  instruction: {
    eppCopyright:
      "Toto hodnocení zahrnuje materiál reprodukovaný z SalesAP ©1995 a CSAP ©2002, Multi-Health Systems Inc. Všechna práva vyhrazena.",
    salesApCopyright:
      "SalesAP ©1995, Multi-Health Systems Inc. All rights reserved.",
    csapCopyright: "CSAP ©2002, Multi-Health Systems Inc. All rights reserved.",
    wtmaCopyright:
      "Autorská práva WTMA © 1997, 1999 Psychological Assessment Resources, Inc."
  },
  overview: {
    approx: "cca.",
    minutes: "min"
  }
};
