import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Timer from "../../../../Components/Timer";
import { TestEventContext } from "../../../../Contexts/TestEventContext";
import { nodeApiURL } from "../../../../utils/constants";
import CLIKInterruptModal from "../CLIKComponents/CLIKInterruptModal";
import Desktop from "../CLIKComponents/Desktop";
import TopInstruction from "../CLIKComponents/TopInstruction";

import {
  CLIKSim1AnswerData,
  Sim1Answer
} from "../../../../Interfaces/AnswerData";

interface CLIKSimulation1Props {
  handleGoToNextSection: () => void;
  testEventId: number;
  simulationTimeAllowed: number;
  currentSection: number;
  subTestId: string;
}

interface CLIKSimulation1State {
  answer: Sim1Answer;
  timeStarted: Date | null;
  timeTaken: number | null;
  showInterruptModal: boolean;
  pauseTimer: boolean;
  failCounter: number;
  didReconnectFail: boolean;
  showSimulation: boolean;
  disableSendButton: boolean;
  fields: {
    toAddress: string;
    ccAddress: string;
    emailSubject: string;
    clicked: boolean;
  };
  sim1FirstTaskCompleted: boolean;
}

export default class CLIKSimulation1 extends React.PureComponent<
  CLIKSimulation1Props,
  CLIKSimulation1State
> {
  state: CLIKSimulation1State = {
    answer: {
      1: {
        answer: 0
      },
      2: {
        answer: 0
      },
      3: {
        answer: 0
      },
      4: {
        answer: 0
      },
      5: {
        answer: 0
      }
    },
    timeStarted: null,
    timeTaken: null,
    showInterruptModal: false,
    pauseTimer: false,
    failCounter: 0,
    didReconnectFail: false,
    showSimulation: false,
    disableSendButton: false,
    fields: { toAddress: "", ccAddress: "", emailSubject: "", clicked: false },
    sim1FirstTaskCompleted: false
  };

  // define the context type so that we can use this.context throughout the class
  static readonly contextType = TestEventContext;

  componentDidMount() {
    requestAnimationFrame(() => {
      window.scrollTo(0, 0);
    });
  }

  handleStart = () => {
    // start the timer
    if (this.state.timeStarted === null) {
      this.setState({ timeStarted: new Date(), showSimulation: true });
    }
    // show the simulation
  };

  handleSetSim1Answer = (answerNum: number, answerVal: number) => {
    if (answerNum === 1 && !this.state.sim1FirstTaskCompleted) {
      this.setState({
        sim1FirstTaskCompleted: true
      });
    } else if (answerNum === 1 && this.state.sim1FirstTaskCompleted) {
      return;
    }
    this.setState({
      answer: { ...this.state.answer, [answerNum]: { answer: answerVal } }
    });
  };

  handleSendEmail = (
    toAddress: string,
    ccAddress: string,
    emailSubject: string,
    clicked: boolean
  ) => {
    this.setState({ disableSendButton: true });
    // calculate time to complete (in seconds);
    const finishTime: number = Date.now();
    const timeTaken =
      this.state.timeTaken !== null
        ? this.state.timeTaken
        : this.state.timeStarted !== null
        ? (+finishTime - +this.state.timeStarted) / 1000
        : 0;
    this.setState({
      timeTaken,
      fields: { ...this.state.fields, clicked: !!clicked }
    });
    const addressEmailCorrect =
      toAddress.trim().toLowerCase() === "amy@acmecorp.com" &&
      ccAddress.trim().toLowerCase() === "bill@acmecorp.com" &&
      emailSubject.trim().toLowerCase() === "my report";
    // create answerData object and send that data to redis, and then end the subTest
    const answerData: CLIKSim1AnswerData = {
      testEventId: this.props.testEventId,
      subTestId: this.props.subTestId,
      timeTaken,
      sim: {
        ...this.state.answer,
        3: { answer: addressEmailCorrect ? 1 : 0 },
        5: { answer: clicked ? 1 : 0 }
      },
      toAddress,
      emailSubject,
      ccAddress
    };
    this.sendCLIKSim1DataToRedis(answerData);
  };

  handleRetryButton = () => {
    this.setState({ didReconnectFail: false }, () => {
      this.handleRedisFailure();
    });
  };

  handleRedisFailure = () => {
    const { failCounter, fields } = this.state;
    this.setState(state => ({
      showInterruptModal: true,
      pauseTimer: true,
      failCounter: state.failCounter + 1
    }));
    const rootElement = document.getElementById("root");
    if (rootElement !== null) {
      rootElement.style.filter = "blur(10px)";
    }
    if (failCounter < 30) {
      setTimeout(
        () =>
          this.handleSendEmail(
            fields.toAddress,
            fields.ccAddress,
            fields.emailSubject,
            fields.clicked
          ),
        1000
      );
    } else {
      this.setState({ didReconnectFail: true, failCounter: 0 });
    }
  };

  sendCLIKSim1DataToRedis = async (
    answerData: CLIKSim1AnswerData
  ): Promise<boolean> => {
    const rootElement = document.getElementById("root");
    try {
      const response = await fetch(`${nodeApiURL}/sendCLIKSim1Answer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.context.token
        },
        body: JSON.stringify(answerData)
      });
      const responseData = await response.json();
      if (responseData.submitted !== "OK") {
        this.handleRedisFailure();
      } else {
        if (rootElement !== null) {
          rootElement.style.filter = "none";
        }
        this.props.handleGoToNextSection();
      }
    } catch (error) {
      this.handleRedisFailure();
    }
    return true;
  };

  handleUpdateFields = (
    toAddress: string | null,
    ccAddress: string | null,
    emailSubject: string | null
  ) => {
    this.setState({
      fields: {
        toAddress: toAddress !== null ? toAddress : this.state.fields.toAddress,
        ccAddress: ccAddress !== null ? ccAddress : this.state.fields.ccAddress,
        emailSubject:
          emailSubject !== null ? emailSubject : this.state.fields.emailSubject,
        clicked: this.state.fields.clicked
      }
    });
  };

  handleTimerExpire = () => {
    this.handleSendEmail(
      this.state.fields.toAddress,
      this.state.fields.ccAddress,
      this.state.fields.emailSubject,
      false
    );
  };

  render() {
    const { simulationTimeAllowed } = this.props;
    return (
      <Container fluid={true}>
        <Row>
          <TopInstruction
            showSimulation={this.state.showSimulation}
            handleStart={this.handleStart}
            simulationTimeAllowed={simulationTimeAllowed}
          />
          {this.state.showSimulation && (
            <Col style={{ marginTop: "6px" }}>
              <Timer
                seconds={simulationTimeAllowed}
                onCompletion={this.handleTimerExpire}
                startTimer={true}
                color="black"
                pauseTimer={this.state.pauseTimer}
                placedAt="clikv2"
              />
            </Col>
          )}
        </Row>
        <Row>
          <ol>
            <li>
              Open the file <b>letter.doc</b> located in the My Documents folder
            </li>
            <li>
              Using the keyboard shortcut, copy all of the text in the file
            </li>
            <li>
              Minimize the window containing <b>letter.doc</b>
            </li>
            <li>Launch Microsoft Outlook</li>
            <li>
              Using the keyboard shortcut, paste the copied text into a new
              email
            </li>
            <li>
              Address the email to amy@acmecorp.com and Cc bill@acmecorp.com
            </li>
            <li>Enter the subject: My report</li>
            <li>Send the email</li>
          </ol>
        </Row>
        <br />
        {this.state.showSimulation && (
          <div style={{ marginBottom: "50px" }}>
            <Desktop
              handleSendEmail={this.handleSendEmail}
              submitProfileUpdate={() => {}}
              handleSetSim1Answer={this.handleSetSim1Answer}
              handleSetSim2Answer={() => {}}
              handleSetSim3Answer={() => {}}
              handleUpdateBirthYear={() => {}}
              handleSendSalesSummary={() => {}}
              handleCopyActionComplete={() => {}}
              handleUpdateCurrentContact={() => {}}
              handleUpdateSalesSummaryText={() => {}}
              handleUpdateFields={this.handleUpdateFields}
              disableSendButton={this.state.disableSendButton}
              currentSection={this.props.currentSection}
            />
          </div>
        )}
        {this.state.showInterruptModal && (
          <CLIKInterruptModal
            handleRetryButton={this.handleRetryButton}
            didReconnectFail={this.state.didReconnectFail}
          />
        )}
      </Container>
    );
  }
}
