import { useState } from "react";
import { Button } from "react-bootstrap";

interface Props {
  text: string;
}

export default function CopyableText({ text }: Props) {
  const [isCopied, setIsCopied] = useState(false);

  const onCopy = () => {
    navigator.clipboard.writeText(text);
    // set copy to true then after 3 seconds if it has not been set to false, set it to false
    if (isCopied) return;

    setTimeout(() => {
      setIsCopied(false);
    }, 3000);

    setIsCopied(true);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "8px"
      }}
    >
      <p>{text}</p>
      <Button onClick={onCopy} variant="link">
        {isCopied ? "Copied!" : "Copy"}
      </Button>
    </div>
  );
}
