import React, { FunctionComponent, useRef, useState } from "react";
import useResume from "../../CustomHooks/useResume";
import { EventDataWithTestProps } from "../../Interfaces/EventData";
import GameTutorial from "../../Tests/TestDisplays/GameTest/GameTutorial/GameTutorial";
import GameTest from "../../Tests/TestDisplays/GameTest/GameTest";
import { getGameTestStartingQuestion } from "../../utils/redisHelpers";

const Game: FunctionComponent<
  EventDataWithTestProps & {
    resumeMethod: ReturnType<typeof getGameTestStartingQuestion>;
  }
> = ({ resumeMethod: method, ...props }) => {
  const { data, error } = useResume({ method });
  const [isTutorial, setIsTutorial] = useState(true);
  const [currentGameId, setCurrentGameId] = useState(1);
  const startingQuestion = useRef(0);

  const gameStartHandler = () => {
    setIsTutorial(current => !current);
  };

  const gameResetHandler = () => {
    startingQuestion.current = 0;
    setCurrentGameId(current => current + 1);
  };

  if (error) {
    // TODO - determine if we want <ErrorPage /> here
    return error instanceof Error ? (
      <div>
        {error.name} : {error.message}
      </div>
    ) : (
      <div>Unknown Error</div>
    );
  }

  if (!data) {
    return <div>Loading...</div>;
  }

  if (isTutorial) {
    return (
      <GameTutorial
        testData={props.testData}
        timeFactor={props.timeFactor}
        gameTutorialId={currentGameId}
        handleStartRealTest={gameStartHandler}
      />
    );
  }
  return (
    <GameTest
      {...props}
      startingQuestion={startingQuestion.current}
      currentGameId={currentGameId}
      forceGameStart
      resetStartingQuestion={gameResetHandler}
    />
  );
};

export default Game;
