import React from "react";
import { Col, Row } from "react-bootstrap";
import WindowHeader from "./WindowHeader";

interface RecycleBinInterface {
  minimized: boolean;
  maximized: boolean;
  handleToggleMinimize(windowName: string): void;
  handleToggleMaximize(windowName: string): void;
  handleCloseWindow(windowName: string): void;
  handleDesktopItemClick(windowName: string): void;
  zIndexValue: number;
}

const RecycleBin = ({
  maximized,
  minimized,
  handleToggleMaximize,
  handleToggleMinimize,
  handleCloseWindow,
  handleDesktopItemClick,
  zIndexValue
}: RecycleBinInterface) => {
  return (
    <Col
      onClick={() => handleDesktopItemClick("recycle_bin")}
      xl={maximized ? 12 : 7}
      lg={maximized ? 12 : 7}
      md={maximized ? 12 : 6}
      sm={maximized ? 12 : 6}
      xs={maximized ? 12 : 6}
      style={{
        position: "absolute",
        top: maximized ? "0" : "100px",
        right: maximized ? "" : "100px",
        left: maximized ? "0" : "",
        minHeight: "150px",
        minWidth: "300px",
        height: maximized ? "100%" : "",
        width: maximized ? "100%" : "",
        zIndex: zIndexValue,
        display: minimized ? "none" : ""
      }}
    >
      <WindowHeader
        titleText="Recycle Bin"
        minimized={minimized}
        maximized={maximized}
        handleToggleMinimize={handleToggleMinimize}
        handleToggleMaximize={handleToggleMaximize}
        handleCloseWindow={handleCloseWindow}
      >
        <Row
          noGutters={true}
          style={{
            backgroundColor: "#FFFFFF",
            minHeight: "300px",
            height: maximized ? "100%" : ""
          }}
        ></Row>
      </WindowHeader>
    </Col>
  );
};

export default RecycleBin;
