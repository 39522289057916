import Language from "../../Interfaces/Language";

export const es_es: Language = {
  common: {
    help: "¿Necesita ayuda?",
    helpCenter: "Servicio de ayuda",
    expect: "¿Cómo son las pruebas?",
    faq: "Preguntas frecuentes",
    faqLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7842483-preguntas-frecuentes",
    copyright: "Derechos de autor",
    copyrightNotices: "Avisos de derechos de autor",
    copyrightNoticesLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7869765-aviso-de-derechos-de-autor",
    terms: "Términos de uso",
    termsLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994192-terminos-de-uso",
    privacy: "Declaración de privacidad",
    privacyLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6990047-politica-de-privacidad",
    dmca: "Política DMCA",
    dmcaLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7853791-politica-dmca",
    accommodations: "Discapacidad/Adaptaciones",
    accommodationsLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7869767-discapacidad-adaptaciones",
    technicalSupport: "Soporte técnico",
    chatSupport:
      "Si tiene alguna pregunta, chatee con nuestro equipo en la parte inferior derecha de esta página."
  },
  instruction: {
    eppCopyright:
      "Esta evaluación incluye material reproducido de SalesAP ©1995 y CSAP ©2002, Multi-Health Systems Inc. Todos los derechos reservados.",
    salesApCopyright:
      "SalesAP ©1995, Multi-Health Systems Inc. All rights reserved.",
    csapCopyright: "CSAP ©2002, Multi-Health Systems Inc. All rights reserved.",
    wtmaCopyright:
      "Copyright WTMA © 1997, 1999 Psychological Assessment Resources, Inc."
  }
};
