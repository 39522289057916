// tslint:disable jsx-no-lambda
import React, { ReactNode } from "react";
import { Container, Row, Col } from "react-bootstrap";

import my_computer from "../CLIKResources/my_computer.png";
import my_documents from "../CLIKResources/my_documents.png";
import internet from "../CLIKResources/internet.png";
import outlook from "../CLIKResources/outlook.png";
import recycle_bin from "../CLIKResources/recycle_bin.png";
import tasks_buttons_small from "../CLIKResources/tasks_buttons_small.png";
import tasks_email_open_small from "../CLIKResources/tasks_email_open_small.png";
import tasks_internet_small from "../CLIKResources/tasks_internet_small.png";
import tasks_my_documents_open_small from "../CLIKResources/tasks_my_documents_open_small.png";
import word_document from "../CLIKResources/word_document.png";
import messages from "../CLIKResources/messages.png";
import desktop_notification from "../CLIKResources/desktop_notification.png";

import MyComputer from "./MyComputer";
import MyDocuments from "./MyDocuments";
import Internet from "./Internet";
import MicrosoftOutlook from "./MicrosoftOutlook";
import RecycleBin from "./RecycleBin";
import WordDocument from "./WordDocument";
import ComposeEmail from "./ComposeEmail";
import Messages from "./Messages";

interface DesktopItemProps {
  doubleClickHandler(): void;
  children: ReactNode;
}
const DesktopItem = ({ doubleClickHandler, children }: DesktopItemProps) => {
  return (
    <button
      onDoubleClick={doubleClickHandler}
      className="desktop-app-button"
      style={{ cursor: "default" }}
    >
      {children}
    </button>
  );
};

interface DesktopProps {
  handleSendEmail(
    toAddress: string,
    ccAddress: string,
    subject: string,
    clicked: boolean
  ): void;
  submitProfileUpdate(
    answerVal: number,
    birthYear: number,
    searchText: string
  ): void;
  handleSetSim1Answer(answerNum: number, answerVal: number): void;
  handleSetSim2Answer(answerNum: number, answerVal: number): void;
  handleSetSim3Answer(answerNum: number, answerVal: number): void;
  handleUpdateBirthYear(year: string): void;
  handleUpdateFields(
    toAddress: string | null,
    ccAddress: string | null,
    emailSubject: string | null
  ): void;
  handleSendSalesSummary(
    salesSummaryInput: string,
    currentContact: string
  ): void;
  handleCopyActionComplete(): void;
  handleUpdateCurrentContact(contact: string): void;
  handleUpdateSalesSummaryText(text: string): void;
  disableSendButton: boolean;
  currentSection: number;
}
interface DesktopState {
  openWindows: {
    [k: string]: boolean;
  };
  openIconOrder: string[];
  zIndexOrder: string[];
  minimized: {
    [k: string]: boolean;
  };
  maximized: {
    [k: string]: boolean;
  };
  showNotification: boolean;
}
export default class Desktop extends React.PureComponent<
  DesktopProps,
  DesktopState
> {
  state: DesktopState = {
    openWindows: {
      my_computer: false,
      my_documents: false,
      internet: false,
      outlook: false,
      recycle_bin: false,
      letterDoc: false,
      reportDoc: false,
      composeEmail: false,
      messages: false
    },
    openIconOrder: [],
    zIndexOrder: [],
    minimized: {
      my_computer: false,
      my_documents: false,
      internet: false,
      outlook: false,
      recycle_bin: false,
      letterDoc: false,
      reportDoc: false,
      composeEmail: false,
      messages: false
    },
    maximized: {
      my_computer: false,
      my_documents: false,
      internet: false,
      outlook: false,
      recycle_bin: false,
      letterDoc: false,
      reportDoc: false,
      composeEmail: false,
      messages: false
    },
    showNotification: false
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ showNotification: true });
    }, 1000);
  }

  handleDesktopItemClick = (itemName: string) => {
    if (itemName === "letterDoc") {
      this.props.handleSetSim1Answer(1, 1);
    }
    if (itemName === "messages") {
      this.setState({
        showNotification: false
      });
    }
    if (!this.state.openWindows[itemName]) {
      this.setState({
        openWindows: { ...this.state.openWindows, [itemName]: true },
        openIconOrder: [...this.state.openIconOrder, itemName],
        zIndexOrder: [itemName, ...this.state.zIndexOrder]
      });
    } else if (
      this.state.openWindows[itemName] &&
      this.state.minimized[itemName]
    ) {
      const newZIndexOrder = this.state.zIndexOrder.filter(
        val => val !== itemName
      );
      newZIndexOrder.unshift(itemName);
      this.setState({
        zIndexOrder: newZIndexOrder,
        minimized: { ...this.state.minimized, [itemName]: false }
      });
    } else {
      if (this.state.zIndexOrder[0] === "outlook" && itemName === "outlook") {
        return; // This is to make sure the composeEmail window will come before the outlook window when clicking on the New Message button
      }
      const newZIndexOrder = this.state.zIndexOrder.filter(
        val => val !== itemName
      );

      newZIndexOrder.unshift(itemName);
      this.setState({
        zIndexOrder: newZIndexOrder
      });
    }
  };
  handleCloseWindow = (itemName: string) => {
    const { openWindows, openIconOrder, minimized, maximized } = this.state;
    const openIconOrderIndex: number = openIconOrder.indexOf(itemName);
    const newZIndexOrder = this.state.zIndexOrder.filter(
      val => val !== itemName
    );
    this.setState({
      openWindows: { ...openWindows, [itemName]: false },
      openIconOrder: openIconOrder
        .slice(0, openIconOrderIndex)
        .concat(openIconOrder.slice(openIconOrderIndex + 1)),
      minimized: { ...minimized, [itemName]: false },
      maximized: { ...maximized, [itemName]: false },
      zIndexOrder: newZIndexOrder
    });
  };
  handleIconClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    iconName: string
  ) => {
    const newZIndexOrder = this.state.zIndexOrder.filter(
      val => val !== iconName
    );
    newZIndexOrder.unshift(iconName);
    this.setState({
      minimized: { ...this.state.minimized, [iconName]: false },
      zIndexOrder: newZIndexOrder
    });
  };
  handleToggleMinimize = (windowName: string) => {
    if (windowName === "letterDoc") {
      this.props.handleSetSim1Answer(2, 1);
    }
    this.setState({
      minimized: {
        ...this.state.minimized,
        [windowName]: !this.state.minimized[windowName]
      }
    });
  };
  handleToggleMaximize = (windowName: string) => {
    if (windowName === "internet") {
      this.props.handleSetSim2Answer(6, 1);
    }
    this.setState({
      maximized: {
        ...this.state.maximized,
        [windowName]: !this.state.maximized[windowName]
      }
    });
  };

  displayOpenIcon = (appName: string) => {
    const appNameToImageMap: { [k: string]: any } = {
      my_computer,
      my_documents: tasks_my_documents_open_small,
      internet: tasks_internet_small,
      outlook: tasks_email_open_small,
      recycle_bin,
      letterDoc: word_document,
      reportDoc: word_document,
      composeEmail: tasks_email_open_small,
      messages
    };
    const keyName = `${appName}_open_icon`;
    return (
      <button
        className="open-app-icon-button"
        onClick={e => this.handleIconClick(e, appName)}
        key={keyName}
      >
        <img
          src={appNameToImageMap[appName]}
          height={25}
          width={25}
          className="open-app-icon"
          alt="open app icon"
        />
      </button>
    );
  };

  handleNotificationClick = () => {
    this.props.handleSetSim3Answer(11, 1);
    this.handleDesktopItemClick("messages");
  };

  render() {
    const {
      disableSendButton,
      handleCopyActionComplete,
      handleSendEmail,
      handleSendSalesSummary,
      handleSetSim1Answer,
      handleSetSim2Answer,
      handleSetSim3Answer,
      handleUpdateBirthYear,
      handleUpdateCurrentContact,
      handleUpdateFields,
      handleUpdateSalesSummaryText,
      submitProfileUpdate
    } = this.props;

    const {
      minimized,
      maximized,
      openWindows,
      openIconOrder,
      zIndexOrder,
      showNotification
    } = this.state;

    const zIndexValues = [18, 17, 16, 15, 14, 13, 12, 11, 10];

    return (
      <Container fluid={true}>
        <Row>
          <Col
            xl={10}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ backgroundColor: "#202d63", border: "1px solid #202d63" }}
          >
            <Row id="clik-v2-desktop" style={{ position: "relative" }}>
              <Col xl={1} lg={1} md={2} sm={2} xs={2}>
                <DesktopItem
                  doubleClickHandler={() =>
                    this.handleDesktopItemClick("my_computer")
                  }
                >
                  <img
                    src={my_computer}
                    height="30"
                    width="30"
                    alt="my computer"
                  />
                  <div className="desktop-icon">My Computer</div>
                </DesktopItem>
                <DesktopItem
                  doubleClickHandler={() =>
                    this.handleDesktopItemClick("my_documents")
                  }
                >
                  <img
                    src={my_documents}
                    height="30"
                    width="30"
                    alt="my documents"
                  />
                  <div className="desktop-icon">My Documents</div>
                </DesktopItem>
                <DesktopItem
                  doubleClickHandler={() =>
                    this.handleDesktopItemClick("internet")
                  }
                >
                  <img
                    src={internet}
                    height="30"
                    width="30"
                    alt="internet icon"
                  />
                  <div className="desktop-icon">Internet</div>
                </DesktopItem>
                <DesktopItem
                  doubleClickHandler={() =>
                    this.handleDesktopItemClick("outlook")
                  }
                >
                  <img
                    src={outlook}
                    height="30"
                    width="30"
                    alt="outlook icon"
                  />
                  <div className="desktop-icon">Outlook</div>
                </DesktopItem>
                <DesktopItem
                  doubleClickHandler={() =>
                    this.handleDesktopItemClick("recycle_bin")
                  }
                >
                  <img
                    src={recycle_bin}
                    height="30"
                    width="30"
                    alt="recycle bin"
                  />
                  <div className="desktop-icon">Recycle Bin</div>
                </DesktopItem>
              </Col>
              <Col xl={1} lg={1} md={2} sm={2} xs={2}>
                <DesktopItem
                  doubleClickHandler={() =>
                    this.handleDesktopItemClick("messages")
                  }
                >
                  <img src={messages} height="30" width="30" alt="messages" />
                  <div className="desktop-icon">Messages</div>
                </DesktopItem>
              </Col>
              {openWindows.my_computer && (
                <MyComputer
                  handleCloseWindow={this.handleCloseWindow}
                  handleDesktopItemClick={this.handleDesktopItemClick}
                  handleToggleMaximize={this.handleToggleMaximize}
                  handleToggleMinimize={this.handleToggleMinimize}
                  maximized={maximized.my_computer}
                  minimized={minimized.my_computer}
                  zIndexValue={zIndexValues[zIndexOrder.indexOf("my_computer")]}
                />
              )}
              {openWindows.my_documents && (
                <MyDocuments
                  handleToggleMinimize={this.handleToggleMinimize}
                  handleToggleMaximize={this.handleToggleMaximize}
                  handleCloseWindow={this.handleCloseWindow}
                  minimized={minimized.my_documents}
                  maximized={maximized.my_documents}
                  handleDesktopItemClick={this.handleDesktopItemClick}
                  zIndexValue={
                    zIndexValues[zIndexOrder.indexOf("my_documents")]
                  }
                />
              )}
              {openWindows.internet && (
                <Internet
                  currentSection={this.props.currentSection}
                  disableSendButton={disableSendButton}
                  handleCloseWindow={this.handleCloseWindow}
                  handleCopyActionComplete={handleCopyActionComplete}
                  handleDesktopItemClick={this.handleDesktopItemClick}
                  handleSetSim2Answer={handleSetSim2Answer}
                  handleToggleMaximize={this.handleToggleMaximize}
                  handleToggleMinimize={this.handleToggleMinimize}
                  handleUpdateBirthYear={handleUpdateBirthYear}
                  maximized={maximized.internet}
                  minimized={minimized.internet}
                  submitProfileUpdate={submitProfileUpdate}
                  zIndexValue={zIndexValues[zIndexOrder.indexOf("internet")]}
                />
              )}
              {openWindows.outlook && (
                <MicrosoftOutlook
                  handleToggleMinimize={this.handleToggleMinimize}
                  handleToggleMaximize={this.handleToggleMaximize}
                  handleCloseWindow={this.handleCloseWindow}
                  minimized={minimized.outlook}
                  maximized={maximized.outlook}
                  handleDesktopItemClick={this.handleDesktopItemClick}
                  zIndexValue={zIndexValues[zIndexOrder.indexOf("outlook")]}
                />
              )}
              {openWindows.recycle_bin && (
                <RecycleBin
                  handleCloseWindow={this.handleCloseWindow}
                  handleDesktopItemClick={this.handleDesktopItemClick}
                  handleToggleMaximize={this.handleToggleMaximize}
                  handleToggleMinimize={this.handleToggleMinimize}
                  maximized={maximized.recycle_bin}
                  minimized={minimized.recycle_bin}
                  zIndexValue={zIndexValues[zIndexOrder.indexOf("recycle_bin")]}
                />
              )}
              {openWindows.letterDoc && (
                <WordDocument
                  docTitle="letter.doc"
                  handleToggleMinimize={this.handleToggleMinimize}
                  handleToggleMaximize={this.handleToggleMaximize}
                  handleCloseWindow={this.handleCloseWindow}
                  handleDesktopItemClick={this.handleDesktopItemClick}
                  minimized={minimized.letterDoc}
                  maximized={maximized.letterDoc}
                  zIndexValue={zIndexValues[zIndexOrder.indexOf("letterDoc")]}
                />
              )}
              {openWindows.reportDoc && (
                <WordDocument
                  docTitle="report.doc"
                  handleToggleMinimize={this.handleToggleMinimize}
                  handleToggleMaximize={this.handleToggleMaximize}
                  handleCloseWindow={this.handleCloseWindow}
                  minimized={minimized.reportDoc}
                  maximized={maximized.reportDoc}
                  zIndexValue={zIndexValues[zIndexOrder.indexOf("reportDoc")]}
                  handleDesktopItemClick={this.handleDesktopItemClick}
                />
              )}
              {openWindows.composeEmail && (
                <ComposeEmail
                  disableSendButton={disableSendButton}
                  handleCloseWindow={this.handleCloseWindow}
                  handleDesktopItemClick={this.handleDesktopItemClick}
                  handleSendEmail={handleSendEmail}
                  handleSetSim1Answer={handleSetSim1Answer}
                  handleToggleMaximize={this.handleToggleMaximize}
                  handleToggleMinimize={this.handleToggleMinimize}
                  handleUpdateFields={handleUpdateFields}
                  maximized={maximized.composeEmail}
                  minimized={minimized.composeEmail}
                  zIndexValue={
                    zIndexValues[zIndexOrder.indexOf("composeEmail")]
                  }
                />
              )}
              {openWindows.messages && (
                <Messages
                  disableSendButton={disableSendButton}
                  handleCloseWindow={this.handleCloseWindow}
                  handleDesktopItemClick={this.handleDesktopItemClick}
                  handleSendSalesSummary={handleSendSalesSummary}
                  handleSetSim3Answer={handleSetSim3Answer}
                  handleToggleMaximize={this.handleToggleMaximize}
                  handleToggleMinimize={this.handleToggleMinimize}
                  handleUpdateCurrentContact={handleUpdateCurrentContact}
                  handleUpdateSalesSummaryText={handleUpdateSalesSummaryText}
                  maximized={maximized.messages}
                  minimized={minimized.messages}
                  zIndexValue={zIndexValues[zIndexOrder.indexOf("messages")]}
                />
              )}
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                {this.props.currentSection === 3 && showNotification && (
                  <button
                    className="visible"
                    id="chat-notification"
                    onClick={this.handleNotificationClick}
                  >
                    <img
                      src={desktop_notification}
                      height="100"
                      width="300"
                      alt="desktop notification"
                    />
                  </button>
                )}
              </Col>
            </Row>
            <Row className="mb-0">
              <Col style={{ backgroundColor: "#0D0D09", height: "40px" }}>
                <img
                  src={tasks_buttons_small}
                  style={{ marginRight: "12px" }}
                  alt="task buttons"
                />
                {openIconOrder.map(appName => this.displayOpenIcon(appName))}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}
