import React, { useEffect } from "react";
import { navigate } from "@reach/router";
import TestAlreadyCompleted from "../Components/TestAlreadyCompleted";
import TestEventCompleted from "../Tests/TestDisplays/TestEventCompleted";

interface Props {
  path: string;
  showTestCompleted: number;
}

export default function ReuseResults({ showTestCompleted }: Props) {
  // If no showTestCompleted is being passed then it should redirect to "/"
  useEffect(() => {
    if (!showTestCompleted) {
      navigate("/");
    }
  }, [showTestCompleted]);

  switch (showTestCompleted) {
    case 1:
      return <TestAlreadyCompleted />;
    case 2:
      return <TestAlreadyCompleted reusedResults />;
    case 3:
      return <TestEventCompleted numberOfSecondsToDelay={5} />;
    default:
      return null;
  }
}
