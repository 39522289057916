import React, { useEffect, useCallback } from "react";
import { Alert, Button } from "react-bootstrap";
import { instructionsObjectEnglish } from "../instructionsObject";
import { instructionsObjectSpanish } from "../instructionsObject";
import Key from "../../ImageComponents/Key";
import { FaAngleRight } from "react-icons/fa";

interface Props {
  toggleIncorrectAnswerDisplay(): void;
  language: string;
  isMobile: boolean;
  isMobileOnly?: boolean;
  isIPad13?: boolean;
}

const TaskThreeIncorrectDisplay = ({
  toggleIncorrectAnswerDisplay,
  language,
  isMobile,
  isMobileOnly,
  isIPad13
}: Props) => {
  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "4" || event.key === "5" || event.key === "6") {
        toggleIncorrectAnswerDisplay();
      }
    },
    [toggleIncorrectAnswerDisplay]
  );

  const handleClickEvent = useCallback(
    (event: any) => {
      if (
        event.currentTarget.value === "4" ||
        event.currentTarget.value === "5" ||
        event.currentTarget.value === "6"
      ) {
        toggleIncorrectAnswerDisplay();
      }
    },
    [toggleIncorrectAnswerDisplay]
  );

  useEffect(() => {
    if (isMobile || isIPad13) {
      window.addEventListener("click", handleClickEvent);
      return () => window.addEventListener("click", handleClickEvent);
    } else {
      window.addEventListener("keyup", handleKeyPress);
      return () => window.addEventListener("keyup", handleKeyPress);
    }
  }, [handleKeyPress, handleClickEvent, isMobile, isIPad13]);

  const instructionsObject =
    language === "english"
      ? instructionsObjectEnglish
      : instructionsObjectSpanish;

  const isMobileAndEnglish = (isMobile || isIPad13) && language === "english";

  return (
    <div>
      <Alert variant="danger" className="mrab-incorrect-warning text-center">
        Incorrect.
      </Alert>
      <div
        className={
          isMobileOnly ? "instruction-text-mobile" : "instruction-text"
        }
      >
        <p>
          {isMobileAndEnglish
            ? instructionsObject[3].incorrect.text_1a.slice(0, -5)
            : instructionsObject[3].incorrect.text_1a}
          <Key isMobile={isMobile} isIPad13={isIPad13} text="4" />,
          <Key isMobile={isMobile} isIPad13={isIPad13} text="5" />, or
          <Key isMobile={isMobile} isIPad13={isIPad13} text="6" />
          {isMobileAndEnglish
            ? instructionsObject[3].incorrect.text_1b.slice(1)
            : instructionsObject[3].incorrect.text_1b}
        </p>

        <p>
          {isMobileAndEnglish
            ? "Press Continue to resume practice."
            : instructionsObject[3].incorrect.text_2}
        </p>
        <div className="first-instruction-button-row">
          <Button
            variant="primary"
            className="instructions-button"
            onClick={toggleIncorrectAnswerDisplay}
          >
            Continue
            <FaAngleRight
              style={{
                fontSize: "24px",
                verticalAlign: "middle",
                marginTop: "-5px"
              }}
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TaskThreeIncorrectDisplay;
