import { navigate } from "@reach/router";
import React, { useContext, useState } from "react";
import { Container, Row, Col, Card, Image, Form } from "react-bootstrap";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageContinueButton from "../../Components/PageContinueButton";
import { TestEventContext } from "../../Contexts/TestEventContext";
import linkResultsImage from "../../Resources/images/link-results.png";
import { isValidEmail } from "../../utils/shared";
import { linkResults } from "../helpers";

interface Props {
  path: string;
}

export const LinkResults = (props: Props) => {
  /****************************************************************************
   * Context                                                                  *
   ****************************************************************************/
  const testEventContext = useContext(TestEventContext);

  /****************************************************************************
   * State Variables                                                                  *
   ****************************************************************************/
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  /****************************************************************************
   * Handlers                                                               *
   ****************************************************************************/
  const handleContinue = () => {
    if (isValidEmail(email) && testEventContext && testEventContext.token) {
      setErrorMessage("");
      linkResults(
        testEventContext.testEventData?.testTaker?.testTakerId,
        email,
        testEventContext.token
      ).then(success => {
        if (success) {
          const encodedEmail = encodeURIComponent(email);
          navigate(encodeURI(`/link-results-verify?email=${encodedEmail}`));
        }
      });
    } else {
      setErrorMessage("Please enter a valid email address");
    }
  };

  const handleLinkClick = () => {
    navigate("/verify");
  };

  /****************************************************************************
   * Styles                                                                  *
   ****************************************************************************/
  const linkButtonStyle: any = {
    color: "#425cc7",
    textDecoration: "underline",
    fontWeight: "bold",
    backgroundColor: "#FFF",
    border: "0",
    margin: 0,
    padding: 0
  };

  return (
    <Container>
      <Row>
        <Col
          xl={{
            span: 8,
            offset: 2
          }}
          lg={{
            span: 8,
            offset: 2
          }}
          md={{
            span: 10,
            offset: 1
          }}
          sm={12}
        >
          <Card className="oda-card">
            <Header backgroundColor="#FFF" />
            <Card.Body>
              <Row>
                <Col>
                  <div className="text-center mt-3 mb-5">
                    <Image
                      fluid={true}
                      src={linkResultsImage}
                      alt="Insights"
                      height="160px"
                      width="160px"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-center link-results-header">
                    Link Previous Results
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="text-center mt-3 link-results-text">
                    Enter the email address associated with your Criteria
                    personality test results.{" "}
                  </p>
                </Col>
              </Row>
              <Row className="mt-1 mb-3">
                <Col
                  lg={{
                    offset: 3,
                    span: 6
                  }}
                  md={{
                    offset: 3,
                    span: 6
                  }}
                  sm={{
                    offset: 3,
                    span: 6
                  }}
                  xs={{
                    offset: 3,
                    span: 6
                  }}
                >
                  <Form.Control
                    id="link-results-email-input"
                    type="email"
                    value={email}
                    onChange={(event: any) => {
                      setEmail(event.currentTarget.value);
                    }}
                    maxLength={50}
                    isInvalid={errorMessage.length > 0}
                  />
                  <p className="mt-0 error-message">{errorMessage}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <PageContinueButton handler={handleContinue} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="text-center mt-4 link-results-text">
                    Change your mind?{" "}
                    <button style={linkButtonStyle} onClick={handleLinkClick}>
                      Click here
                    </button>{" "}
                    to start the personality test.
                  </p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Footer />
        </Col>
      </Row>
    </Container>
  );
};
