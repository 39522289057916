import React, { useContext, useEffect } from "react";
import { Card } from "react-bootstrap";
import Header from "../Components/Header";
import TestTakerForm from "./TestTakerForm";
import { TestEventContext } from "../Contexts/TestEventContext";
import LandingPageStyle from "../Interfaces/LandingPageStyle";

interface Props {
  continueButtonHandler: () => void;
  noPII: boolean;
  landingPageStyle: LandingPageStyle;
  referrer?: {
    id: number | null;
    url: string;
  };
  handleUpdateComponentShowing(componentName: string): void;
}

const PersonalInformation = ({
  landingPageStyle,
  noPII,
  referrer,
  handleUpdateComponentShowing
}: Props) => {
  const context = useContext(TestEventContext);

  useEffect(() => {
    handleUpdateComponentShowing("Verify Your Information");
  }, [handleUpdateComponentShowing]);

  return (
    <Card className="oda-card">
      <Header
        onWelcomePage={false}
        backgroundColor={landingPageStyle.backgroundColor}
        customLogo={context?.testEventData.logo}
        isVideoInterviewDiscoveryJob={context?.isVideoInterviewDiscoveryJob}
      />
      <Card.Body
        style={{
          backgroundColor: landingPageStyle.backgroundColor,
          color: landingPageStyle.textColor
        }}
      >
        <Card.Title as="h1" className="text-center h5">
          {!noPII ? (
            <>
              {context && context.testEventData.translatedText
                ? context.testEventData.translatedText.enterInfo
                : "Verify your information"}
            </>
          ) : null}
        </Card.Title>
        <div>
          <TestTakerForm
            jobCode={context ? context.jobCode : null}
            testData={context ? context.testEventData : null}
            referrer={referrer}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

export default PersonalInformation;
