import React from "react";

interface SearchResultLinkInterface {
  description: string;
  source: string;
  sourceUrl: string;
  linkTitle: string;
}

const SearchResultLink = ({
  description,
  source,
  sourceUrl,
  linkTitle
}: SearchResultLinkInterface) => {
  return (
    <div className="d-flex flex-column">
      <span style={{ fontSize: "14px", color: "#53565A" }}>{source}</span>
      <span style={{ fontSize: "14px", color: "#86888B" }}>{sourceUrl}</span>
      <div className="d-flex flex-column" style={{ marginTop: "8px" }}>
        <span
          style={{
            fontSize: "18px",
            color: "#1E139C",
            fontWeight: 500,
            marginBottom: "2px"
          }}
        >
          {linkTitle}
        </span>
        <span
          style={{ fontSize: "14px", fontWeight: 500 }}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </div>
  );
};

export default SearchResultLink;
