import { useEffect, useState } from "react";

const useWindowSize = () => {
  // determine if the device is using a browser by checking for the window object
  const hasWindowObject = typeof window === "object";

  const [windowSize, setWindowSize] = useState(
    hasWindowObject ? window.innerWidth : undefined
  );

  useEffect(() => {
    // define the handler that is called each time the screen is resized
    const setSize = () => {
      setWindowSize(window.innerWidth);
    };

    if (hasWindowObject) {
      // register the event listener on the window object for when the screen size is changed.
      window.addEventListener("resize", setSize);

      // unregister the event listener
      return () => window.removeEventListener("resize", setSize);
    }
  }, [hasWindowObject, setWindowSize]);

  return windowSize;
};

export default useWindowSize;
