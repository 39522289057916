import React, { useState, useEffect } from "react";
import BirdFeatherComponent from "./GameTutorialComponents/BirdFeatherComponent";

interface Props {
  exampleQuestion: { optionA: string; optionB: string };
  isTutorialTestBegin: boolean;
  handleCheckAnswer: (userInputAnswer: string) => void;
  handleShowTimeExpiredModal: () => void;
  showGameTutorialStartModal: boolean;
  placedAtTutorial: boolean;
  timeFactor: number;
  answerOptions: { [propName: string]: string };
  pressKeys: { pressFKey: string; pressJKey: string };
  disconnectModalDisplayText: {
    testPaused: string;
    errorSavingQuestion: string;
    pleaseWaitReconnecting: string;
    unableToReconnectTryAgainLater: string;
  };
}

const BirdFeatherTutorial = ({
  exampleQuestion,
  isTutorialTestBegin,
  handleCheckAnswer,
  handleShowTimeExpiredModal,
  showGameTutorialStartModal,
  placedAtTutorial,
  timeFactor,
  answerOptions,
  pressKeys,
  disconnectModalDisplayText
}: Props) => {
  const [startTime, setStartTime] = useState(Date.now());
  const [pauseTimer, setPauseTimer] = useState(false);
  const [showImages, setShowImages] = useState(false);
  const [isBirdFeatherTutorialTestBegin, setIsBirdFeatherTutorialTestBegin] =
    useState(isTutorialTestBegin);

  useEffect(() => {
    setStartTime(Date.now());
    // delay the showing of the next images by 200 ms
    const timerID = setTimeout(() => {
      setShowImages(true);
      setPauseTimer(false);
    }, 200);
    return () => clearTimeout(timerID);
  }, []);

  useEffect(() => {
    setStartTime(Date.now());
    setShowImages(showGameTutorialStartModal ? false : true);
    setPauseTimer(false);
    setIsBirdFeatherTutorialTestBegin(isTutorialTestBegin);
  }, [isTutorialTestBegin, showGameTutorialStartModal]);

  /* ---------- Tutorial Event Handlers ---------- */

  const doPauseTimer = () => {
    setPauseTimer(true);
  };

  const handleAnswerSubmit = (answer: string) => {
    setPauseTimer(true);
    handleCheckAnswer(answer);
  };

  const onExpiration = () => {
    setShowImages(false);
    setPauseTimer(true);
    handleShowTimeExpiredModal();
  };

  return (
    <BirdFeatherComponent
      handleAnswerSubmit={handleAnswerSubmit}
      onExpiration={onExpiration}
      showImages={showImages}
      pauseTimer={pauseTimer}
      placedAtTutorial={placedAtTutorial}
      optionA={showGameTutorialStartModal ? "" : exampleQuestion.optionA}
      optionB={showGameTutorialStartModal ? "" : exampleQuestion.optionB}
      questionStartTime={startTime + 475}
      doPauseTimer={doPauseTimer}
      isTutorialTestBegin={isBirdFeatherTutorialTestBegin}
      showGameTutorialStartModal={showGameTutorialStartModal}
      timeFactor={timeFactor}
      answerOptions={answerOptions}
      pressKeys={pressKeys}
      disconnectModalDisplayText={disconnectModalDisplayText}
    />
  );
};

export default BirdFeatherTutorial;
