import { isTablet, isIPad13 } from "react-device-detect";

export const instructionsObjectEnglish = {
  "1": {
    "1": {
      text_1: "In this task you will see a series of four different shapes:",
      text_2: "Each Shape will be one of four shades below:"
    },
    "2": {
      text_1: "Each time you see a CIRCLE OR a WHITE shape, press the",
      text_2: "Each time you see a TRIANGLE OR a BLACK shape, press the"
    },
    "3": {
      text_1: "So remember, press the",
      text_2: "Press the",
      text_finish: " when you see:"
    },
    "4": {
      text_1:
        "For this task we recommend that you place your index fingers on the F and J keys as shown in the picture."
    },
    "5": {
      text_1: "You will now have a chance to practice.",
      text_2: "If you make a mistake, the computer will display a message.",
      text_3: "Remember, CIRCLE OR a WHITE shape = ",
      text_4: "TRIANGLE OR a BLACK shape = "
    },
    incorrect: {
      text_1: "Press the ",
      text_2: " when you see: ",
      text_3: "Press the F or J key, or click continue to resume the practice"
    },
    final: {
      text_1: "You are now ready for the actual test.",
      text_2:
        "The computer will no longer display a message if you make a mistake.",
      text_3: "Remember, a CIRCLE OR a WHITE shape = ",
      text_4: "A TRIANGLE OR a BLACK shape = "
    }
  },
  "2": {
    "1": {
      text_1:
        "For this task you will see a series of shapes appearing on the screen one at a time.",
      text_2a: "Press the",
      text_2b: " every time you see this shape:",
      text_3b: " every time you see any other shape:"
    },
    "2": {
      text_1: "Remember: ",
      text_2: "Whenever you see: ",
      text_3: " then you should press: "
    },
    "3": {
      text_1:
        "Try to keep your attention focused, because each shape will only remain on the screen for a short time.",
      text_2:
        "Also, the time between the shapes will vary.  The shapes will continue to appear, whether or not you respond."
    },
    "4": {
      text_1:
        "For this task we recommend that you place your index fingers on the F and J keys as shown in the picture."
    },
    "5": {
      text_1: "You will now have a chance to practice.",
      text_2:
        "If you make a mistake or do not respond before the next shape appears, the computer will display a message.",
      text_3a: "Remember, press the ",
      text_3b: " when you see: ",
      text_4a: "Press the ",
      text_4b: " when you see: ",
      text_5:
        "Note: In the practice session, the image will flash very quickly.  You will have approximately 1.5 seconds to answer the practice item."
    },
    incorrect: {
      text_1a: "Remember, press the ",
      text_1b: " when you see:",
      text_2: "Press the ",
      text_3: "Press the F or J key or click Continue to resume practice.",
      text_4:
        "Note: In the practice session the image will flash very quickly.  You will have approximately 1.5 seconds to answer the practice item."
    },
    final: {
      text_1:
        "The actual test will now begin.  The computer will no longer display a message if you make a mistake.",
      text_2a: "Remember, press the ",
      text_2b: " when you see: ",
      text_3a: "Press the ",
      text_3b: " when you see: "
    }
  },
  "3": {
    "1": {
      text_1:
        "In this task you will see groups of numbers appearing on the screen, one group at a time.",
      text_2:
        "In each group, the number 4, 5, or 6 will appear four, five or six times.",
      text_3a: "Press the ",
      text_3b: " to indicate HOW MANY numbers appear on the screen."
    },
    "2": {
      text_1: "For example, when you see: ",
      text_2: "You would press the ",
      text_3: " because there are four numbers in the group.",
      text_4: "Remember, the groups of numbers will be 4's, 5's, and 6's."
    },
    "3": {
      text_1: "Here is another example.  When you see: ",
      text_2: "You would press the ",
      text_3: " because there are six numbers in the group."
    },
    "4": {
      text_1: "Here are some more examples:",
      text_2: " = press the "
    },
    "5": {
      text_1:
        "For this task, place your fingers over the 4, 5, and 6 keys in whichever way feels most comfortable for you.",
      text_2: "Use one or two hands, whichever you prefer."
    },
    "6": {
      text_1: "You will now have a chance to practice.",
      text_2: "If you make a mistake, the computer will display a message.",
      text_3a: "Remember, press the ",
      text_3b: " to indicate HOW MANY numbers appear on the screen."
    },
    incorrect: {
      text_1a: "Remember, press the ",
      text_1b: " to indicate HOW MANY numbers appear on the screen.",
      text_2:
        "Press the 4, 5, or 6 key or click the continue button to resume the practice."
    },
    final: {
      text_1: "The actual test will now begin.",
      text_2:
        "The computer will no longer display a message if you make a mistake.",
      text_3a: "Remember, press the ",
      text_3b: " to indicate HOW MANY numbers appear on the screen."
    }
  },
  "4": {
    "1": {
      text_1:
        "In this task you will see pairs of shapes appearing in different orientations on the screen.",
      text_2:
        "You must mentally rotate the second shape in each pair until the black box is at the top, and then you must decide whether the objects are the SAME or MIRROR images.",
      text_3: "Press the ",
      text_4a: " if they are MIRROR images.",
      text_4b: " if they are the SAME images."
    },
    "2": {
      text_1:
        "For example, if you mentally rotate the figure on the right so that the black box is at the top, you will see these figures are MIRROR images.",
      text_2: "The correct response would be to press the "
    },
    "3": {
      text_1:
        "In this example, if you mentally rotate the figure on the right so that the black box is at the top, you will see that these figures are the same.",
      text_2: "The correct response would be to press the"
    },
    "4": {
      text_1: "Remember, if the figures are MIRROR images, press the ",
      text_2: "If the figures are the SAME press the "
    },
    "5": {
      text_1:
        "For this task we recommend that you place your index fingers on the F and J keys as shown in the picture."
    },
    "6": {
      text_1: "You will now have a chance to practice.",
      text_2: "If you make a mistake, the computer will display a message.",
      text_3:
        'Remember to mentally rotate the figures clockwise or counter-clockwise on the screen. Do NOT "flip" the figures.',
      text_4a: "Press the ",
      text_4b: " if the figures are MIRROR images, or the ",
      text_4c: " if they are the SAME."
    },
    incorrect: {
      text_1a: "Remember, press the ",
      text_1b: " when you see the MIRROR images.",
      text_2a: "Press the ",
      text_2b: " when the images are the SAME",
      text_3:
        "Press the F or J key or click the Continue button to resume the practice."
    },
    final: {
      text_1: "You are now ready for the actual test.",
      text_2:
        "The computer will no longer display a message if you make a mistake.",
      text_3: "Press the ",
      text_3a: " when you see MIRROR images.",
      text_3b: " when the images are the SAME."
    }
  },
  "5": {
    "1": {
      text_1:
        "In this task, you will see a sequence of numbers appearing in the center of the screen, one at a time.",
      text_2:
        'This task is sometimes called the "Two Back" test because it is a memory test in which you must keep track of the number that appeared two earlier ("Two Back") in the sequence.',
      text_3: "Press the ",
      text_3a:
        " each time the number that appears is DIFFERENT from the one that appeared TWO NUMBERS EARLIER.",
      text_3b:
        " each time the number that appears is the SAME NUMBER as appeared TWO NUMBERS EARLIER."
    },
    "2": {
      text_1:
        "For example, you will see numbers appearing in a sequence like the ones below:",
      text_2:
        "The red numbers show the points in the sequence where you would press the ",
      text_3: "On all the others, you would press the "
    },
    "3": {
      text_1:
        "Of course, you will not see all the numbers at once.  You must keep track of the sequence in your head.",
      text_2: "For example, you might see a series of numbers like this: ",
      text_3: "The sequence you just saw was: ",
      text_4: "Therefore you should push: "
    },
    "4": {
      text_1:
        "For this task we recommend that you place your index fingers on the F and J keys as shown in the picture."
    },
    "5": {
      text_1: "You will now have a chance to practice.",
      text_2:
        "If you make a mistake or do not respond within two seconds(before the next number appears), the computer will display a message.",
      text_3a: "Note: press the ",
      text_3b:
        'for the first two numbers in the sequence, since no numbers appear "two back" in the sequence.',
      text_4: "For the rest of the task, respond to the numbers as follows: ",
      text_5: "DIFFERENT than TWO BACK, press the ",
      text_6: "SAME as TWO BACK, press the "
    },
    incorrect: {
      text_1: "You must respond within two seconds.",
      text_2a: "Remember, press the ",
      text_2b:
        "each time the number that appears is DIFFERENT from the one that appeared TWO NUMBERS EARLIER.",
      text_3a: "Press the ",
      text_3b:
        "each time the number that appears is the SAME NUMBER as appeared TWO NUMBERS EARLIER.",
      text_4:
        "Press the F or J key or click the Continue button to resume the practice."
    },
    final: {
      text_1: "The actual test will now begin.",
      text_2:
        "The computer will no longer display a message if you make a mistake.",
      text_3a: "Remember, press the ",
      text_3b:
        " only when the number that appears is the SAME as the one that appeared TWO NUMBERS previously.",
      text_4: "Otherwise, press the ",
      text_5: "DIFFERENT than TWO BACK = ",
      text_6: "SAME as TWO BACK = "
    }
  },
  "6": {
    "1": {
      text_1:
        'This task is another version of the "Two Back" test you just finished. In this task, instead of seeing different numbers appear, you will see the same number appear in different locations on the screen.',
      text_2a:
        "You must keep track of the locations and respond by pressing the ",
      text_2b:
        " each time the number appears in the SAME LOCATION as it did TWO TRIALS before.",
      text_3: "Otherwise, press the "
    },
    "2": {
      text_1:
        "For example, each box below represents the location of the 5 in a sample sequence.",
      text_2a: "The blue arrows indicate on which trials you would press the ",
      text_2b: ". On all others, you would press the ",
      text_3:
        "Of course, you will not see all the locations at once. You must keep track of the sequence in your head."
    },
    "3": {
      text_1:
        "For this task we recommend that you place your index fingers on the F and J keys as shown in the picture."
    },
    "4": {
      text_1: "You will now have a chance to practice.",
      text_2:
        "If you make a mistake or do not respond within two seconds(before the next number appears), the computer will display a message.",
      text_3a: "Note: press the ",
      text_3b:
        'for the first two items in the sequence, since nothing appeared "two back" in the sequence.',
      text_4:
        "For the rest of the task, respond to the location of the 5 as follows: ",
      text_5: "DIFFERENT location than TWO BACK, press the ",
      text_6: "SAME location as TWO BACK, press the "
    },
    incorrect: {
      text_1: "You must respond within two seconds.",
      text_2a: "Remember, press the ",
      text_2b:
        "each time the number appears in the SAME LOCATION as it did TWO TRIALS before.",
      text_3: "Otherwise, press the ",
      text_4:
        "Press the F or J key or click the Continue button to resume the practice."
    },
    final: {
      text_1: "The actual test will now begin.",
      text_2:
        "The computer will no longer display a message if you make a mistake.",
      text_3: "Remember, DIFFERENT location than TWO BACK = ",
      text_4: "SAME location as TWO BACK = "
    }
  },
  "7": {
    "1": {
      text_1:
        "In this task, you will see groups of three statements appearing on the screen.",
      text_2:
        "The first two statements in each group are true.  Based on the first two statements, you must decide if the third statement is true or false.",
      text_3a: "Press the ",
      text_3b: " if the third statement is true.",
      text_4a: "Press the ",
      text_4b: " if the third statement is false."
    },
    "2": {
      text_1: "For example: ",
      text_2: "Vincent is older than Ashley.",
      text_3: "Vincent is younger than Josh.",
      text_4: "Josh is not oldest.",
      text_5a: "The correct response to the question above is the ",
      text_5b: ", since the third statement is false.",
      text_6: "(Josh is, in fact, the oldest.)"
    },
    "3": {
      text_1:
        "For this task we recommend that you place your index fingers on the F and J keys as shown in the picture."
    },
    "4": {
      text_1: "You will now have a chance to practice.",
      text_2: "If you make a mistake, the computer will display a message.",
      text_3: "Remember, the first two statements are true.",
      text_4a: "Press the ",
      text_4b: " if the third statement is TRUE.",
      text_5a: "Press the ",
      text_5b: " if the third statement is FALSE."
    },
    incorrect: {
      text_1: "Remember, the first two statements are true.",
      text_2: "Press the ",
      text_3: " if the third statement is TRUE.",
      text_4: " if the third statement is FALSE",
      text_5:
        "Press the F or J key or click the Continue button to resume the practice."
    },
    final: {
      text_1: "The actual test will now begin.",
      text_2:
        "The computer will no longer display a message if you make a mistake.",
      text_3: "Remember, the first two statements are true.",
      text_4: "Press the ",
      text_5: " if the third statement is TRUE.",
      text_6: " if the third statement is FALSE."
    }
  },
  "8": {
    "1": {
      text_1:
        "In this task, you will see rows of four letters appearing on the screen.",
      text_2: "Three of the letters will have something in common.",
      text_3: "You must choose the letter that does not belong."
    },
    "2": {
      text_1: "Use the ",
      text_2: "to indicate which of the four letters does not belong.",
      text_3: " = first letter in the row",
      text_4: " = second letter in the row",
      text_5: " = third letter in the row",
      text_6: " = fourth letter in the row"
    },
    "3": {
      text_1: "For example: ",
      text_2: "P b B P",
      text_3: "In the row above there are three capital letters.",
      text_4: "There is only one lowercase letter -- the 'b'.",
      text_5: "So the 'b' does not belong.",
      text_6: "The correct response is the "
    },
    "4": {
      text_1: "Here is another example:",
      text_2: "b B b P",
      text_3:
        "In the row above, the letter 'B' appears three times (two lowercase and one capital).",
      text_4: "There is only one 'P', so the 'P' does not belong.",
      text_5: "The correct response is the "
    },
    "5": {
      text_1:
        "For this task we recommend that you place your index fingers on the D, F, J, and K keys as shown in the picture."
    },
    "6": {
      text_1: "You will now have a chance to practice.",
      text_2: "If you make a mistake, the computer will display a message.",
      text_3: "Remember, use the ",
      text_4: " to indicate which letter in the row does not belong."
    },
    incorrect: {
      text_1a: "Use the ",
      text_1b: " to indicate which of the four letters does not belong.",
      text_2: " = first letter in the row",
      text_3: " = second letter in the row",
      text_4: " = third letter in the row",
      text_5: " = fourth letter in the row",
      text_6:
        "Press the D, F, J, or K key or click the Continue button to resume the practice."
    },
    final: {
      text_1: "The actual test will now begin.",
      text_2:
        "The computer will no longer display a message if you make a mistake.",
      text_3: "Remember, use the ",
      text_4: " to indicate which letter in each row does not belong."
    }
  },
  "9": {
    "1": {
      text_1: "In this task, you will see the four numbers below.",
      text_2: `<br><div class='row text-center'> <div class='col-md-2'></div><div class='col-md-8'><div ${
        isTablet || isIPad13 ? "id='one-tablet'" : "id='one'"
      }>1</div><div ${
        isTablet || isIPad13 ? "id='two-tablet'" : "id='two'"
      }>2</div><div ${
        isTablet || isIPad13 ? "id='nine-tablet'" : "id='nine'"
      }>9</div><div ${
        isTablet || isIPad13 ? "id='eight-tablet'" : "id='eight'"
      }>8</div></div></div><br><br>`,
      text_3: "Press the ",
      text_4: " when the corresponding number changes color."
    },
    "2": {
      text_1: "Here is an example.",
      text_2: `<div class='row text-center'><div class='col-md-2'></div><div class='col-md-8'><div ${
        isTablet || isIPad13 ? "id='one-tablet'" : "id='one'"
      } class='highlight'>1</div><div ${
        isTablet || isIPad13 ? "id='two-tablet'" : "id='two'"
      }>2</div><div ${
        isTablet || isIPad13 ? "id='nine-tablet'" : "id='nine'"
      }>9</div><div ${
        isTablet || isIPad13 ? "id='eight-tablet'" : "id='eight'"
      }>8</div></div></div><br>`,
      text_3: "The correct response is the "
    },
    "3": {
      text_1:
        "For this task we recommend that you place your index fingers on the 1, 2, 8, and 9 keys as shown in the picture."
    },
    "4": {
      text_1: "You will now have a chance to practice.",
      text_2: "If you make a mistake, the computer will display a message.",
      text_3:
        "Remember, press the key that corresponds to the number that changes color."
    },
    incorrect: {
      text_1:
        "Remember, press the key that corresponds to the number that changes color.",
      text_2:
        "Press the 1, 2, 8, or 9 key or click the Continue button to resume the practice."
    },
    final: {
      text_1: "The actual test will now begin.",
      text_2:
        "The computer will no longer display a message if you make a mistake.",
      text_3:
        "Remember, press the key that corresponds to the number that changes color"
    }
  }
};

export const instructionsObjectSpanish = {
  "1": {
    "1": {
      text_1:
        "En esta actividad observará una serie de cuatro figuras diferentes:",
      text_2: "Cada una de ellas será de los siguientes colores:"
    },
    "2": {
      text_1:
        "Cada vez que vea un CÍRCULO o una figura BLANCA, presione la tecla ",
      text_2:
        "Cada vez que vea un TRIÁNGULO o una figura  NEGRA, presione la tecla "
    },
    "3": {
      text_1: "Recuerde: debe presionar la tecla ",
      text_2: "Presione la tecla ",
      text_finish: " cuando vea:"
    },
    "4": {
      text_1:
        "Para esta actividad le recomendamos que coloque los dedos índice en las teclas como se muestra en la imagen."
    },
    "5": {
      text_1: "Ahora tendrá la posibilidad de practicar. ",
      text_2: "Si comete un error, la computadora le mostrará un mensaje.",
      text_3: "Recuerde, un CÍRCULO  una figura BLANCA = tecla ",
      text_4: "un TRIÁNGULO o una figura NEGRA = tecla "
    },
    incorrect: {
      text_1: "Presione la tecla ",
      text_2: " cuando vea: ",
      text_3:
        'Presione la tecla F o J o haga clic en el botón "Continuar" para retomar la práctica.'
    },
    final: {
      text_1: "Ahora está listo para la prueba verdadera.",
      text_2:
        ">La computadora ya no le mostrará un mensaje si comete un error.",
      text_3: "Recuerde, un CÍRCULO  una figura BLANCA = tecla ",
      text_4: "un TRIÁNGULO o una figura NEGRA = tecla "
    }
  },
  "2": {
    "1": {
      text_1:
        "Para esta actividad observará una serie de figuras que aparecen de a una en la pantalla.",
      text_2a: "Presione la tecla ",
      text_2b: "cada vez que vea esta figura:",
      text_3b: " cada vez que vea cualquier otra figura:"
    },
    "2": {
      text_1: "Recuerde:",
      text_2: "Cuando vea:",
      text_3: " debe presionar: "
    },
    "3": {
      text_1:
        "Intente concentrarse, ya que cada figura permanecerá en la la pantalla solamente durante poco tiempo.",
      text_2:
        "Además, el tiempo entre la aparición de las figuras será diferente. Las figuras no dejarán de aparecer, independientemente de si responde o no."
    },
    "4": {
      text_1:
        "Para esta actividad le recomendamos que coloque los dedos índice en las teclas como se muestra en la imagen."
    },
    "5": {
      text_1: "Ahora tendrá la posibilidad de practicar.",
      text_2:
        "Si comete un error o no responde antes de que aparezca la próxima figura, la computadora mostrará un mensaje. ",
      text_3a: "Recuerde: debe presionar la tecla ",
      text_3b: " cuando vea: ",
      text_4a: "Presione la tecla ",
      text_4b: " cuando vea: ",
      text_5:
        "Note: In the practice session, the image will flash very quickly.  You will have approximately 1.5 seconds to answer the practice item."
    },
    incorrect: {
      text_1a: "Recuerde: debe presionar la tecla  ",
      text_1b: " cuando vea:",
      text_2: "Presione la tecla ",
      text_3:
        'Presione la tecla F o J o haga clic en el botón "Continuar" para retomar la práctica. ',
      text_4:
        "Note: In the practice session the image will flash very quickly.  You will have approximately 1.5 seconds to answer the practice item."
    },
    final: {
      text_1:
        "Ahora comenzará la prueba verdadera. La computadora ya no le avisará si comete un error.",
      text_2a: ">Recuerde: debe presionar la tecla ",
      text_2b: " cuando vea: ",
      text_3a: "Presione la tecla ",
      text_3b: " cuando vea: "
    }
  },
  "3": {
    "1": {
      text_1:
        "En esta actividad observará grupos de números que van apareciendo de a uno en la pantalla.",
      text_2:
        "En cada grupo, los números 4, 5 ó 6 aparecerán cuatro, cinco o seis veces.",
      text_3a: "Presione la tecla ",
      text_3b: " para indicar CUÁNTOS números aparecen en la pantalla."
    },
    "2": {
      text_1: "Por ejemplo, cuando vea: ",
      text_2: "Deberá presionar la tecla ",
      text_3: " porque hay cuatro números en el grupo.",
      text_4: "Recuerde, los grupos de números serán de 4, 5 ó 6."
    },
    "3": {
      text_1: "Aquí se muestra otro ejemplo. Cuando vea:",
      text_2: "Deberá presionar la tecla ",
      text_3: " porque hay seis números en el grupo."
    },
    "4": {
      text_1: "He aquí más ejemplos:",
      text_2: " = presione la tecla  "
    },
    "5": {
      text_1:
        "Para esta actividad, coloque los dedos sobre las teclas de la forma que sea más cómoda para usted.",
      text_2: "Utilice una o las dos manos cuando lo prefiera."
    },
    "6": {
      text_1: "Ahora tendrá la posibilidad de practicar.",
      text_2: "Si comete un error, la computadora le mostrará un mensaje.",
      text_3a: "Recuerde, presione la tecla ",
      text_3b: " para indicar CUÁNTOS números aparecen en la pantalla."
    },
    incorrect: {
      text_1a: "Recuerde, presione la tecla ",
      text_1b: " para indicar CUÁNTOS números aparecen en la pantalla.",
      text_2:
        'Presione la tecla 4, 5, o 6 o haga clic en el botón "Continuar" para retomar la práctica.'
    },
    final: {
      text_1: "Ahora comenzará la prueba verdadera.",
      text_2: "La computadora ya no le mostrará un mensaje si comete un error.",
      text_3a: "Recuerde, presione la tecla ",
      text_3b: " para indicar CUÁNTOS números aparecen en la pantalla."
    }
  },
  "4": {
    "1": {
      text_1:
        "En esta actividad, observará pares de figuras que aparecen en diferentes orientaciones en la pantalla.",
      text_2:
        "Mentalmente, deberá girar la segunda figura en cada par hasta que el recuadro negro esté en la parte superior, y luego debe decidir si se trata de IMÁGENES IGUALES o de IMÁGENES reflejadas.",
      text_3: "Presione la tecla ",
      text_4a: " si son IMÁGENES REFLEJADAS.",
      text_4b: "si son IGUALES."
    },
    "2": {
      text_1:
        "Por ejemplo, si mentalmente gira la figura de la derecha para que el recuadro negro esté en la parte superior, observará que estas figuras son imágenes REFLEJADAS.",
      text_2: "La respuesta correcta sería presionar la tecla  "
    },
    "3": {
      text_1:
        "En este ejemplo, si mentalmente gira la figura de la derecha para que el recuadro negro esté en la parte superior, observará que estas figuras son IGUALES.",
      text_2: "La respuesta correcta sería presionar la tecla "
    },
    "4": {
      text_1: "Recuerde: si son imágenes REFLEJADAS, presione la tecla ",
      text_2: "Si las figuras son IGUALES, presione la tecla "
    },
    "5": {
      text_1:
        "Para esta actividad le recomendamos que coloque los dedos índice en las teclas como se muestra en la imagen."
    },
    "6": {
      text_1: "Ahora tendrá la posibilidad de practicar.",
      text_2: "Si comete un error, la computadora le mostrará un mensaje.",
      text_3:
        'Recuerde girar mentalmente las figuras hacia la derecha o izquierda en la pantalla. NO "voltee" las figuras.',
      text_4a: "Presione la tecla ",
      text_4b: " si son imágenes REFLEJADAS O la tecla  ",
      text_4c: " si son IGUALES."
    },
    incorrect: {
      text_1a: "Recuerde: debe presionar la tecla  ",
      text_1b: " cuando vea imágenes REFLEJADAS.",
      text_2a: "Presione la tecla ",
      text_2b: " cuando las imágenes sean IGUALES.",
      text_3:
        'Presione la tecla F o J o haga clic en el botón "Continuar" para retomar la práctica.'
    },
    final: {
      text_1: "Ahora está listo para la prueba verdadera.",
      text_2: "La computadora ya no le mostrará un mensaje si comete un error.",
      text_3: "Recuerde: debe presionar la tecla ",
      text_3a: " cuando vea imágenes REFLEJADAS.",
      text_3b: " cuando las imágenes sean IGUALES."
    }
  },
  "5": {
    "1": {
      text_1:
        "En esta actividad observará una secuencia de números que aparecen de a uno en el centro de la pantalla.",
      text_2:
        'Algunas veces, esta actividad se denomina "dos hacia atrás" porque es una prueba de memoria en la que debe recordar los números que aparecieron dos lugares anteriores ("dos hacia atrás") en la secuencia.',
      text_3: "Presione la tecla ",
      text_3a:
        " cada vez que el número que aparece sea DIFERENTE del que apareció DOS NÚMEROS ATRÁS.",
      text_3b:
        " cada vez que el número que aparece sea el MISMO NÚMERO que el que apareció DOS NÚMEROS HACIA ATRÁS."
    },
    "2": {
      text_1:
        "Por ejemplo, observará los números que aparecen en una secuencia como la que se muestra a continuación.",
      text_2:
        "Los números de color rojo muestran los puntos en la secuencia en los que debería presionar la tecla ",
      text_3: "En todos los demás, debería presionar la tecla "
    },
    "3": {
      text_1:
        "Obviamente, no verá todos los números al mismo tiempo. Debe memorizar la secuencia.",
      text_2: "Por ejemplo, es probable que observe una serie como esta: ",
      text_3: "La sequencia que acaba de observar es: ",
      text_4: "Por lo tanto, debe presionar: "
    },
    "4": {
      text_1:
        "Para esta actividad le recomendamos que coloque los dedos índice en las teclas como se muestra en la imagen."
    },
    "5": {
      text_1: "Ahora tendrá la posibilidad de practicar",
      text_2:
        "Si comete un error o no responde en el transcurso de dos segundos (antes de que aparezca el siguiente número), la computadora mostrará un mensaje. ",
      text_3a: "Importante: presione la tecla ",
      text_3b:
        "para los dos primeros números en la secuencia, ya que no había números en dos posiciones anteriores de la secuencia.",
      text_4:
        "Para el resto de la actividad, responda a los números de la siguiente manera: ",
      text_5:
        "Si es DIFERENTE del que está DOS HACIA ATRÁS, presione la tecla ",
      text_6: "Si es IGUAL al que está DOS HACIA ATRÁS, presione la tecla "
    },
    incorrect: {
      text_1: "You must respond within two seconds.",
      text_2a: "Recuerde: Presione la tecla ",
      text_2b: "si es DIFERENTE del que aparece DOS HACIA ATRÁS.",
      text_3a: "Presione la tecla ",
      text_3b: "Si es IGUAL al que está DOS HACIA ATRÁS.",
      text_4:
        'Presione la tecla F o J o haga clic en el botón "Continuar" para retomar la práctica.'
    },
    final: {
      text_1: "Ahora comenzará la prueba verdadera.",
      text_2: "La computadora ya no le mostrará un mensaje si comete un error.",
      text_3a: "Recuerde: presione la tecla ",
      text_3b:
        " solamente cuando el número que aparezca sea el MISMO que el que apareció DOS NÚMEROS atrás.",
      text_4: "De lo contrario, presione la tecla ",
      text_5: ">Si es DIFERENTE del que está DOS HACIA ATRÁS = tecla ",
      text_6: "Si es IGUAL al que está DOS HACIA ATRÁS =  tecla "
    }
  },
  "6": {
    "1": {
      text_1:
        'Esta actividad es otra versión de "Dos hacia atrás" que acaba de terminar. En este caso, en lugar de números diferentes, observará el mismo número en distintas posiciones de la pantalla.',
      text_2a:
        "Debe llevar el control de esas posiciones y responder presionando la tecla ",
      text_2b:
        " cada vez que el número aparece en la MISMA POSICIÓN, como sucedió en las DOS PRUEBAS anteriores.",
      text_3: "De lo contrario, presione la tecla "
    },
    "2": {
      text_1:
        "Por ejemplo, cada uno de los siguientes cuadros representa la posición del número 5 en una secuencia de muestra.",
      text_2a:
        "Las flechas azules indican en qué pruebas debe presionar la tecla ",
      text_2b: ". En todos los demás, debería presionar la tecla ",
      text_3:
        "Obviamente, no verá todas las posiciones al mismo tiempo. Debe realizar el seguimiento mental de la secuencia."
    },
    "3": {
      text_1:
        "Para esta actividad le recomendamos que coloque los dedos índice en las teclas como se muestra en la imagen."
    },
    "4": {
      text_1: "Ahora tendrá la posibilidad de practicar.",
      text_2:
        "Si comete un error o no responde con la suficiente rapidez, la computadora le mostrará un mensaje.",
      text_3a: "Importante: presione la tecla ",
      text_3b:
        'para los dos primeros elementos de la secuencia, ya que nada apareció en dos posiciones anteriores ("dos hacia atrás") en la secuencia.',
      text_4:
        "Para el resto de la tarea, recuerde responder a la posición del 5 de la siguiente manera: ",
      text_5:
        "Si es DIFERENTE de la que está DOS HACIA ATRÁS, presione la tecla ",
      text_6: "Si es IGUAL a la que está DOS HACIA ATRÁS, presione la tecla "
    },
    incorrect: {
      text_1: "You must respond within two seconds.",
      text_2a: "Recuerde: presione la tecla ",
      text_2b: "si la posición es DIFERENTE de la que está DOS HACIA ATRÁS.",
      text_3: "Si la posición es IGUAL a la que está DOS HACIA ATRÁS = ",
      text_4:
        'Presione la tecla F o J o haga clic en el botón "Continuar" para retomar la práctica.'
    },
    final: {
      text_1: "Ahora comenzará la prueba verdadera.",
      text_2: "La computadora ya no le mostrará un mensaje si comete un error.",
      text_3:
        "Recuerde: si la posición es DIFERENTE de la que está DOS HACIA ATRÁS = tecla ",
      text_4: "Si la ubicación es IGUAL a la que está DOS HACIA ATRÁS = tecla "
    }
  },
  "7": {
    "1": {
      text_1:
        "En esta actividad, observará grupos de tres afirmaciones que aparecen en la pantalla.",
      text_2:
        "Las dos primeras de cada grupo son verdaderas. Según las dos primeras debe decidir si la tercera es verdadera o falsa.",
      text_3a: "Presione la tecla ",
      text_3b: " si la tercera afirmación es verdadera.",
      text_4a: "Presione la tecla ",
      text_4b: " si la tercera afirmación es falsa."
    },
    "2": {
      text_1: "Por ejemplo: ",
      text_2: "Vincent es mayor que Ashley.",
      text_3: "Vincent es menor que Josh.",
      text_4: "Josh no es el mayor.",
      text_5a: "La respuesta correcta a la pregunta anterior es la tecla ",
      text_5b: ", ya que la tercera afirmación es falsa.",
      text_6: "(Josh es, en realidad, el mayor de todos.)"
    },
    "3": {
      text_1:
        "For this task we recommend that you place your index fingers on the F and J keys as shown in the picture."
    },
    "4": {
      text_1: "You will now have a chance to practice.",
      text_2: "If you make a mistake, the computer will display a message.",
      text_3: "Remember, the first two statements are true.",
      text_4a: "Press the ",
      text_4b: " if the third statement is TRUE.",
      text_5a: "Press the ",
      text_5b: " if the third statement is FALSE."
    },
    incorrect: {
      text_1: "Recuerde que las dos primeras afirmaciones son verdaderas.",
      text_2: "Presione la tecla ",
      text_3: " si la tercera afirmación es VERDADERA.",
      text_4: " si la tercera afirmación es FALSA.",
      text_5:
        'Presione la tecla F o J o haga clic en el botón "Continuar" para retomar la práctica.'
    },
    final: {
      text_1: "Ahora comenzará la prueba verdadera",
      text_2: "La computadora ya no le mostrará un mensaje si comete un error.",
      text_3: "Recuerde que las dos primeras afirmaciones son verdaderas.",
      text_4: "Presione la tecla ",
      text_5: " si la tercera afirmación es VERDADERA.",
      text_6: " si la tercera afirmación es FALSA."
    }
  },
  "8": {
    "1": {
      text_1:
        "En esta actividad, observará filas de cuatro letras que aparecen en la pantalla.",
      text_2: "Tres de las letras tendrán algo en común.",
      text_3: "Debe elegir la letra que no pertenece al grupo."
    },
    "2": {
      text_1: "Use las teclas ",
      text_2: "para indicar cuál de las cuatro letras no pertenece al grupo.",
      text_3: " = primera letra de la fila",
      text_4: " = segunda letra de la fila",
      text_5: " = tercera letra de la fila",
      text_6: " = cuarta letra de la fila"
    },
    "3": {
      text_1: "Por ejemplo: ",
      text_2: "P b B P",
      text_3: "En la fila anterior se observan tres letras mayúsculas.",
      text_4: "Hay una sola letra minúscula: la 'b'.",
      text_5: "Por lo tanto, la 'b' no pertenece al grupo.",
      text_6: "La respuesta correcta es la tecla "
    },
    "4": {
      text_1: "Aquí se muestra otro ejemplo.",
      text_2: "b B b P",
      text_3:
        "En la fila anterior, la letra 'B' aparece tres veces (dos en minúscula y una en mayúscula).",
      text_4: "Hay una sola letra minúscula: la 'b'.",
      text_5: "La respuesta correcta es la tecla "
    },
    "5": {
      text_1:
        "Para esta actividad le recomendamos que coloque los dedos índice en las teclas como se muestra en la imagen."
    },
    "6": {
      text_1: "Ahora tendrá la posibilidad de practicar. ",
      text_2: "Si comete un error, la computadora le mostrará un mensaje.",
      text_3: "Recuerde usar las teclas ",
      text_4:
        " para indicar cuál es la letra de la fila que no pertenece al grupo."
    },
    incorrect: {
      text_1a: "Use las teclas ",
      text_1b:
        "para indicar cuál de las cuatro letras de la fila no pertenece al grupo.",
      text_2: " = primera letra de la fila",
      text_3: " = segunda letra de la fila",
      text_4: " = tercera letra de la fila",
      text_5: " = cuarta letra de la fila",
      text_6:
        'Presione la tecla F, D, J o K o haga clic en el botón "Continuar" para retomar la práctica.'
    },
    final: {
      text_1: "Ahora comenzará la prueba verdadera.",
      text_2: "La computadora ya no le mostrará un mensaje si comete un error.",
      text_3: "Recuerde usar las teclas ",
      text_4:
        " para indicar cuál es la letra de cada fila que no pertenece al grupo."
    }
  },
  "9": {
    "1": {
      text_1:
        "Esta es la última actividad. Observará los cuatro números siguientes.",
      text_2:
        "<br><div class='row text-center'> <div class='col-md-2'></div><div class='col-md-8'><div id='one'>1</div><div id='two'>2</div><div id='nine'>9</div><div id='eight'>8</div></div></div><br><br>",
      text_3: "Presione las teclas ",
      text_4: " cuando el número correspondiente cambie de color."
    },
    "2": {
      text_1: "Por ejemplo,",
      text_2:
        "<div class='row text-center'><div class='col-md-2'></div><div class='col-md-8'><div id='one' class='highlight'>1</div><div id='two'>2</div><div id='nine'>9</div><div id='eight'>8</div></div></div><br>",
      text_3: "La respuesta correcta es la tecla "
    },
    "3": {
      text_1:
        "Para esta actividad le recomendamos que coloque los dedos en las teclas 1, 2, 8, 9 como se muestra en la siguiente imagen."
    },
    "4": {
      text_1: "Ahora tendrá la posibilidad de practicar.",
      text_2: "Si comete un error, la computadora le mostrará un mensaje.",
      text_3:
        "Recuerde: presione la tecla que corresponda al número que cambia de col."
    },
    incorrect: {
      text_1:
        "Recuerde: presione la tecla que corresponda al número que cambia de color.",
      text_2:
        'Presione la tecla 1, 2, 8, o 9 o haga clic en el botón "Continuar" para retomar la práctica.'
    },
    final: {
      text_1: "Ahora comenzará la prueba verdadera.",
      text_2: "La computadora ya no le mostrará un mensaje si comete un error.",
      text_3:
        "Recuerde: presione la tecla que corresponda al número que cambia de color."
    }
  }
};
