// url for redis
export const nodeApiURL = process.env.REACT_APP_NODE_API_URL as string;

// url for onDemandAssessment
export const odaURL = process.env.REACT_APP_ODA_URL as string;

// url for back end api
export const apiURL = process.env.REACT_APP_API_URL as string;

// url for talent insights api
export const talentInsightsURL = process.env
  .REACT_APP_TALENT_INSIGHTS_API_URL as string;

// talent insights link
export const talentInsightsLink = process.env
  .REACT_APP_TALENT_INSIGHTS_LINK as string;

export const sskowk = process.env.REACT_APP_SSKOWK as string;

// "environment" variable
export const environment = process.env.REACT_APP_ENVIRONMENT as string;

// environment variable for the FingerprintJS API
export const FPKey = process.env.REACT_APP_FP_KEY as string;

// environment variable for ConfigCat
export const configCatAppKey = process.env
  .REACT_APP_CONFIG_CAT_APP_KEY as string;
