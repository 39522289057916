import React from "react";
import Key from "../../ImageComponents/Key";
import { instructionsObjectEnglish } from "../instructionsObject";
import { instructionsObjectSpanish } from "../instructionsObject";
import InstructionsWrapper from "../InstructionsWrapper";
import NavigateButtons from "../NavigateButtons";
import Shape from "../../ImageComponents/Shape";
import FingerPositionsMobile from "../../Images/finger_positions_456_mobile.png";

interface Props {
  togglePractice(): void;
  language: string;
  isMobile: boolean;
  isMobileOnly?: boolean;
  isIPad13?: boolean;
}

const TaskThreeInstructions = ({
  togglePractice,
  language,
  isMobile,
  isMobileOnly,
  isIPad13
}: Props) => {
  const instructionsObject =
    language === "english"
      ? instructionsObjectEnglish
      : instructionsObjectSpanish;

  const isMobileAndEnglish = (isMobile || isIPad13) && language === "english";

  return (
    <InstructionsWrapper>
      {({ instructionPage, handleContinue, handleBack }) => {
        const finalPage = 6;
        if (instructionPage > finalPage) {
          togglePractice();
        }
        return (
          <div>
            {instructionPage === 1 ? (
              <div>
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile mt-3"
                      : "instruction-text mt-3"
                  }
                >
                  {instructionsObject[3][instructionPage].text_1}
                </p>
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile mt-3"
                      : "instruction-text mt-3"
                  }
                >
                  {instructionsObject[3][instructionPage].text_2}
                </p>
                <div
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile mt-3"
                      : "instruction-text mt-3"
                  }
                >
                  {isMobileAndEnglish
                    ? instructionsObject[3][instructionPage].text_3a.slice(
                        0,
                        -5
                      )
                    : instructionsObject[3][instructionPage].text_3a}
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="4" />,
                  <span>
                    <Key isMobile={isMobile} isIPad13={isIPad13} text="5" />
                  </span>
                  or
                  <span>
                    <Key isMobile={isMobile} isIPad13={isIPad13} text="6" />
                  </span>
                  {isMobileAndEnglish
                    ? instructionsObject[3][instructionPage].text_3b.slice(1)
                    : instructionsObject[3][instructionPage].text_3b}
                </div>
              </div>
            ) : null}
            {instructionPage === 2 ? (
              <div>
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile mt-3"
                      : "instruction-text mt-3"
                  }
                >
                  {instructionsObject[3][instructionPage].text_1}
                </p>
                <div className="numbers-row">
                  <Shape
                    shape="four_sixes"
                    fill="#000"
                    isInstructions={true}
                    isMobileOnly={isMobileOnly}
                  />
                </div>
                <div
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile"
                      : "instruction-text"
                  }
                >
                  {isMobileAndEnglish
                    ? instructionsObject[3][instructionPage].text_2.slice(0, -5)
                    : instructionsObject[3][instructionPage].text_2}
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="4" />
                  {isMobileAndEnglish
                    ? instructionsObject[3][instructionPage].text_3.slice(1)
                    : instructionsObject[3][instructionPage].text_3}
                </div>
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile mt-3"
                      : "instruction-text mt-3"
                  }
                >
                  {instructionsObject[3][instructionPage].text_4}
                </p>
              </div>
            ) : null}
            {instructionPage === 3 ? (
              <div>
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile mt-3"
                      : "instruction-text mt-3"
                  }
                >
                  {instructionsObject[3][instructionPage].text_1}
                </p>
                <div className="numbers-row">
                  <Shape
                    shape="six_fours"
                    fill="#000"
                    isInstructions={true}
                    isMobileOnly={isMobileOnly}
                  />
                </div>
                <div
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile"
                      : "instruction-text"
                  }
                >
                  {isMobileAndEnglish
                    ? instructionsObject[3][instructionPage].text_2.slice(0, -5)
                    : instructionsObject[3][instructionPage].text_2}
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="6" />
                  {isMobileAndEnglish
                    ? instructionsObject[3][instructionPage].text_3.slice(1)
                    : instructionsObject[3][instructionPage].text_3}
                </div>
              </div>
            ) : null}
            {instructionPage === 4 ? (
              <div>
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile mt-3"
                      : "instruction-text mt-3"
                  }
                >
                  {instructionsObject[3][instructionPage].text_1}
                </p>
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile mt-3"
                      : "instruction-text mt-3"
                  }
                >
                  <span style={{ fontSize: "48px", textAlign: "center" }}>
                    5 5 5 5
                  </span>{" "}
                  {isMobileAndEnglish
                    ? instructionsObject[3][instructionPage].text_2.slice(0, -5)
                    : instructionsObject[3][instructionPage].text_2}
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="4" />
                </p>
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile mt-3"
                      : "instruction-text mt-3"
                  }
                >
                  <span style={{ fontSize: "48px", textAlign: "center" }}>
                    5 5 5 5 5
                  </span>{" "}
                  {isMobileAndEnglish
                    ? instructionsObject[3][instructionPage].text_2.slice(0, -5)
                    : instructionsObject[3][instructionPage].text_2}
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="5" />
                </p>
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile mt-3"
                      : "instruction-text mt-3"
                  }
                >
                  <span style={{ fontSize: "48px", textAlign: "center" }}>
                    5 5 5 5 5 5
                  </span>{" "}
                  {isMobileAndEnglish
                    ? instructionsObject[3][instructionPage].text_2.slice(0, -5)
                    : instructionsObject[3][instructionPage].text_2}
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="6" />
                </p>
              </div>
            ) : null}
            {instructionPage === 5 ? (
              <div>
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile mt-3"
                      : "instruction-text mt-3"
                  }
                >
                  {isMobileAndEnglish
                    ? "For this task, hover your fingers over the 4, 5, and 6 buttons in whichever way feels most comfortable for you."
                    : instructionsObject[3][instructionPage].text_1}
                </p>
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile mt-3"
                      : "instruction-text mt-3"
                  }
                >
                  {instructionsObject[3][instructionPage].text_2}
                </p>
                {isMobile || isIPad13 ? (
                  <div className="text-center mt-3">
                    <img
                      src={FingerPositionsMobile}
                      alt="numbers 4, 5, and 6 as buttons"
                      width="250px"
                      height="67px"
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
            {instructionPage === 6 ? (
              <div>
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile mt-3"
                      : "instruction-text mt-3"
                  }
                >
                  {instructionsObject[3][instructionPage].text_1}
                </p>
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile"
                      : "instruction-text"
                  }
                >
                  {isMobileAndEnglish
                    ? "If you make a mistake, your screen will display a message."
                    : instructionsObject[3][instructionPage].text_2}
                </p>
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile mt-3"
                      : "instruction-text mt-3"
                  }
                >
                  {instructionsObject[3][instructionPage].text_3a}
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="4" />,
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="5" /> or
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="6" />
                  {instructionsObject[3][instructionPage].text_3b}
                </p>
              </div>
            ) : null}
            <NavigateButtons
              instructionPage={instructionPage}
              handleBack={handleBack}
              handleContinue={handleContinue}
              finalPage={finalPage}
              language={language}
            />
          </div>
        );
      }}
    </InstructionsWrapper>
  );
};

export default TaskThreeInstructions;
