import React from "react";
import { Row, Col, Button } from "react-bootstrap";

interface ContinuePageInterface {
  handleContinue(): void;
  text1: string;
  text2: string;
}

const ContinuePage = ({
  handleContinue,
  text1,
  text2
}: ContinuePageInterface) => {
  return (
    <Row>
      <Col className="text-center">
        <br />
        <br />
        <div>You have completed the {text1}.</div>
        <br />
        <div>The next section consists of {text2}.</div>
        <br />
        <div>
          <Button variant="primary" onClick={handleContinue}>
            Continue
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default ContinuePage;
