import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import NoResults from "./NoResults";
import browseHopLogo from "../../Resources/images/browseHopLogo.png";
import menuGrid from "../../Resources/images/menu-grid.svg";
import userCircle from "../../Resources/images/user-circle.png";
import chevronDown from "../../Resources/images/chevron-down.png";

import FadeContent from "./FadeContent";
import PeopleWhoAskBox from "./PeopleWhoAskBox";
import SearchResultLink from "./SearchResultLink";
import SecondaryNav from "./SecondaryNav";
import OlympicsResultsSummary from "./OlympicsResultsSummary";
import LincolnResultsSummary from "./LincolnResultsSummary";
import Input from "./Input";
import { Test } from "./types";

interface BrowseHopResultsPageInterface {
  input: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  submitInput: (e: React.FormEvent) => void;
  showQueryError: boolean;
  test: Test;
}

const BrowseHopResultsPage = ({
  input,
  onChange,
  submitInput,
  showQueryError,
  test
}: BrowseHopResultsPageInterface) => {
  const observedDiv = useRef<HTMLInputElement>(null);
  const [showFadeContent, setShowFadeContent] = useState(true);

  useEffect(() => {
    if (!observedDiv.current) return;

    const resizeObserver = new ResizeObserver(() => {
      if (observedDiv.current && observedDiv.current.offsetWidth > 1172) {
        setShowFadeContent(false);
      } else {
        setShowFadeContent(true);
      }
    });

    resizeObserver.observe(observedDiv.current);

    return function cleanup() {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div ref={observedDiv} style={{ fontFamily: "Lato", marginBottom: "32px" }}>
      <Row
        className="justify-content-between align-items-center"
        style={{ padding: "16px 24px" }}
      >
        <Col xl={9} lg={9} md={9} sm={9} xs={9}>
          <div className="d-flex align-items-center" style={{ gap: "24px" }}>
            <img src={browseHopLogo} alt="BrowseHop Logo" />
            <Input
              value={input}
              onChange={onChange}
              submitInput={submitInput}
            />
          </div>
        </Col>
        <Col xl={3} lg={3} md={3} sm={3} xs={3}>
          <div
            className="d-flex align-items-center justify-content-end"
            style={{ gap: "32px" }}
          >
            <div className="d-flex align-items-center" style={{ gap: "16px" }}>
              <img src={menuGrid} alt="Grid Menu" height="20px" width="20px" />
              <img
                src={userCircle}
                alt="Grid Menu"
                height="20px"
                width="20px"
              />
            </div>
          </div>
        </Col>
      </Row>
      <SecondaryNav />

      {showQueryError ? (
        <NoResults />
      ) : (
        <div
          className="d-flex flex-column mt-3"
          style={{ padding: "0px 50px" }}
        >
          {test?.testType === "lincoln" ? (
            <LincolnResultsSummary showFadeContent={showFadeContent} />
          ) : (
            <OlympicsResultsSummary
              test={test}
              showFadeContent={showFadeContent}
            />
          )}

          <p
            style={{
              fontSize: "18px",
              color: "#53565A",
              textAlign: "start",
              margin: "16px 0px"
            }}
          >
            People Also Ask
          </p>
          <div
            className="d-flex flex-row justify-content-start align-items-center"
            style={{
              width: "100%",
              overflowX: "hidden",
              flex: 1,
              gap: "16px",
              position: "relative"
            }}
          >
            {test?.peopleWhoAskBoxContent?.map((content, index) => (
              <PeopleWhoAskBox
                key={content.linkUrl}
                title={content.title}
                linkTitle={content.linkTitle}
                linkUrl={content.linkUrl}
                description={content.description}
              />
            ))}
            {showFadeContent && <FadeContent />}
          </div>

          <div
            className="d-flex flex-column justify-content-start my-3"
            style={{ gap: "32px", lineHeight: "20px" }}
          >
            {test?.searchResultLinkContent?.map((content, index) => (
              <SearchResultLink
                key={content.sourceUrl}
                source={content.source}
                sourceUrl={content.sourceUrl}
                linkTitle={content.linkTitle}
                description={content.description}
              />
            ))}
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ margin: "32px 0px" }}
            >
              <div
                style={{
                  backgroundColor: "#EDEEEE",
                  padding: "8px 60px",
                  borderRadius: "50px",
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "8px"
                }}
              >
                More Results <img src={chevronDown} alt="Chevron Down" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BrowseHopResultsPage;
