import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Alert
} from "react-bootstrap";
import Header from "../Components/Header";
import { apiURL } from "../utils/constants";

interface State {
  countries: Array<String>;
  states: Array<String>;
  showStates: Boolean;
  firstName: String;
  lastName: String;
  emailAddress: String;
  countryCode: String;
  stateCode: String;
  showSuccess: Boolean;
  hasErrors: Boolean;
}

interface Props {
  path: string;
}

export default class CandidateRequestForm extends Component<Props> {
  state: State = {
    countries: [],
    states: [],
    showStates: false,
    firstName: "",
    lastName: "",
    emailAddress: "",
    countryCode: "",
    stateCode: "",
    showSuccess: false,
    hasErrors: false
  };

  componentDidMount() {
    this.fetchDropdowns();
  }

  fetchDropdowns = async (): Promise<boolean> => {
    try {
      const fetchURL = `${apiURL}/privacyDropdowns`;
      const response = await fetch(fetchURL);
      const data = await response.json();
      this.setState({
        countries: data.countries,
        states: data.states
      });
    } catch (error) {
      this.setState({
        countries: [],
        states: []
      });
    }
    return true;
  };

  handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ firstName: event.target.value });
  };

  handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ lastName: event.target.value });
  };

  handleEmailAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ emailAddress: event.target.value });
  };

  handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      showStates: false,
      countryCode: event.target.value
    });
    if (event.target.value === "US") {
      this.setState({ showStates: true });
    }
  };

  handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ stateCode: event.target.value });
  };

  handleSubmit = async (): Promise<boolean> => {
    this.setState({ hasErrors: false });
    if (
      this.state.firstName === "" ||
      this.state.lastName === "" ||
      this.state.emailAddress === "" ||
      this.state.countryCode === ""
    ) {
      this.setState({ hasErrors: true });
      return false;
    }
    if (this.state.countryCode === "US" && this.state.stateCode === "") {
      this.setState({ hasErrors: true });
      return false;
    }

    if (!this.state.hasErrors) {
      try {
        const formData = {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          emailAddress: this.state.emailAddress,
          countryCode: this.state.countryCode,
          stateCode: this.state.stateCode
        };
        const response = await fetch(`${apiURL}/privacyRequest`, {
          method: "POST",
          body: JSON.stringify(formData)
        });
        const data = await response.json();
        if (!data.error) {
          this.setState({ showSuccess: true });
          return true;
        }
        return false;
      } catch {
        return false;
      }
    }

    return false;
  };

  render() {
    return (
      <Container>
        <Row>
          <Col
            xl={{ span: 8, offset: 2 }}
            lg={{ span: 8, offset: 2 }}
            md={{ span: 10, offset: 1 }}
            sm={12}
          >
            <Card className="oda-card">
              <Header
                onWelcomePage={false}
                backgroundColor="#ffffff"
                customLogo=""
              />
              <Card.Body>
                <Card.Title className="text-center">Privacy Policy</Card.Title>
                {this.state.showSuccess ? (
                  <div>
                    <p>
                      Thank you for submitting the request. We have sent an
                      email to the address you provided to confirm your request.
                      Further instructions will be provided in the email.
                    </p>
                  </div>
                ) : (
                  <div>
                    <p>
                      Under the{" "}
                      <strong>
                        EU General Data Protection Regulation 2016/679 of the
                        European Parliament and of the Council ("GDPR")
                      </strong>{" "}
                      consumers have certain rights (
                      <strong>"GDPR Rights"</strong>
                      ), including the right to (1) access the personal
                      information that may have been collected by Criteria Corp;
                      (2) portability; (3) rectify errors in the personal
                      information that may have been collected by Criteria Corp,
                      (4) erase/delete personal information that may have been
                      collected by Criteria Corp (5) limit or restrict the
                      processing of personal information that may have been
                      collected by Criteria Corp; and (6) object to (in certain
                      circumstances) processing activities relating to personal
                      information that may have been collected by Criteria
                      Corp. The GDPR Rights are subject to certain limitations
                      as more specifically described in the GDPR.
                    </p>

                    <p>
                      Under the GDPR, Criteria Corp acts as a{" "}
                      <strong>"Data Processor"</strong> of personal information
                      collected from users and customers of Criteria assessment
                      services; and therefore, Criteria Corp can not directly
                      respond to requests regarding GDPR Rights.  Criteria
                      Corp's customers (the test taker's employer or potential
                      employer) are considered the{" "}
                      <strong>"Data Controller"</strong> of the personal
                      information collected by Criteria as part of the
                      assessment services for purposes of GDPR and are the only
                      one(s) that can respond to a request regarding GDPR
                      Rights.
                    </p>

                    <p>
                      Under the{" "}
                      <strong>
                        California Consumer Privacy Act of 2018 ("CCPA")
                      </strong>{" "}
                      California consumers (each a <strong>"Consumer"</strong>)
                      also have certain rights (<strong>"CCPA Rights"</strong>),
                      including the right to (i) disclosure/access from Criteria
                      about the categories of personal information Criteria
                      collected about the Consumer, and the categories of
                      sources from which Criteria collected such information;
                      (ii) the specific pieces of personal information Criteria
                      collected about the Consumer; (iii) the business or
                      commercial purpose for collecting personal information
                      about the Consumer; and (iv) the categories of personal
                      information about the Consumer that Criteria shared or
                      disclosed with third parties and the categories of third
                      parties with whom we shared or to whom we disclosed such
                      information in the preceding 12 months. A Consumer also
                      has the right to request that we delete personal
                      information we collected from the Consumer, subject to
                      certain exceptions.
                    </p>

                    <p>
                      Under CCPA, Criteria Corp acts as a "Service Provider" for
                      the customers that purchase the Criteria assessment
                      services; and therefore, Criteria can not directly respond
                      to requests regarding CCPA Rights.  Criteria Corp's
                      customers (the test taker's employer or potential
                      employer) in requesting that we administer certain
                      assessment(s) to you control the information obtained and
                      they are the only one(s) that can respond to your request.
                    </p>

                    <p>
                      <strong>
                        If you wish to proceed with a request under current
                        regulations, please enter the information below so we
                        can authenticate you as a valid user or test taker in
                        our system and attempt to identify the appropriate
                        company that should receive your request.
                      </strong>
                    </p>

                    <hr />

                    {this.state.hasErrors && (
                      <Alert variant="danger">
                        Please fill out all of the form fields before submitting
                        your request.
                      </Alert>
                    )}

                    <Form>
                      <Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>First name:</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="First name"
                              onChange={this.handleFirstNameChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Last name:</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Last name"
                              onChange={this.handleLastNameChange}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Form.Group>
                        <Form.Label>Email address:</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Email address"
                          onChange={this.handleEmailAddressChange}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Resident of:</Form.Label>
                        <Form.Control
                          as="select"
                          onChange={this.handleCountryChange}
                        >
                          <option value="">Select a country</option>
                          {this.state.countries.map((option: any) => {
                            const key = String(Object.keys(option)[0]);
                            return (
                              <option key={key} value={key}>
                                {option[key]}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Form.Group>
                      {this.state.showStates ? (
                        <Form.Group>
                          <Form.Label>State:</Form.Label>
                          <Form.Control
                            as="select"
                            onChange={this.handleStateChange}
                          >
                            <option value="">Select a state</option>
                            {this.state.states.map((option: any) => {
                              const key = String(Object.keys(option)[0]);
                              return (
                                <option key={key} value={key}>
                                  {option[key]}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                      ) : null}
                      <div className="text-center">
                        <Button
                          onClick={this.handleSubmit}
                          style={{ height: "40px" }}
                        >
                          Submit Request
                        </Button>
                      </div>
                    </Form>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
