import React from "react";
import { Row, Col, Button } from "react-bootstrap";

interface Props {
  handler: () => void;
  buttonLabel: string;
  disableNextButton?: boolean;
}
const NextButton = ({ handler, buttonLabel, disableNextButton }: Props) => {
  return (
    <Row>
      <Col className="text-center">
        <Button
          variant="primary"
          className={
            disableNextButton ? "button-block disabled-button" : "button-block"
          }
          onClick={handler}
          disabled={disableNextButton}
        >
          {buttonLabel}
        </Button>
      </Col>
    </Row>
  );
};

export default NextButton;
