import React from "react";
import { Row, Col } from "react-bootstrap";
import aptitudeIcon from "../../Resources/images/aptitude@3x.png";
import personalityIcon from "../../Resources/images/personality@3x.png";
import skillsIcon from "../../Resources/images/skills@3x.png";
import emotionalIntelligenceIcon from "../../Resources/images/emotionalIntelligence@3x.png";
import riskIcon from "../../Resources/images/risk@3x.png";
import testmakerIcon from "../../Resources/images/testmaker@3x.png";

interface Props {
  testTitle: string;
  testType: string;
}

const TestHeader = ({ testTitle, testType }: Props) => {
  let testIcon: string = "";
  switch (testType) {
    case "aptitude":
      testIcon = aptitudeIcon;
      break;
    case "personality":
      testIcon = personalityIcon;
      break;
    case "skills":
      testIcon = skillsIcon;
      break;
    case "emotional":
      testIcon = emotionalIntelligenceIcon;
      break;
    case "risk":
      testIcon = riskIcon;
      break;
    default:
      testIcon = testmakerIcon;
      break;
  }
  return (
    <Row className={`${testType}-test-header test-header mb-3`} noGutters>
      <Col
        lg={4}
        md={4}
        sm={4}
        xs={4}
        className="text-left"
        style={{ alignSelf: "center" }}
      >
        <img src={testIcon} height="60px" alt="" className="ml-3" />
      </Col>
      <Col
        lg={8}
        md={8}
        sm={8}
        xs={8}
        className="text-right"
        style={{
          alignSelf: "center",
          fontSize: "20px",
          color: "#001f4e",
          fontWeight: "bold"
        }}
      >
        <h1 className="mr-3 h5 font-weight-bold">{testTitle}</h1>
      </Col>
    </Row>
  );
};

export default TestHeader;
