import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  Alert,
  Form,
  Image
} from "react-bootstrap";
import { apiURL } from "../../utils/constants";
import { TestEventContext } from "../../Contexts/TestEventContext";
import veryPoor from "../../Resources/images/very_poor.png";
import veryPoorActive from "../../Resources/images/very_poor_active.png";
import poor from "../../Resources/images/poor.png";
import poorActive from "../../Resources/images/poor_active.png";
import fair from "../../Resources/images/fair.png";
import fairActive from "../../Resources/images/fair_active.png";
import good from "../../Resources/images/good.png";
import goodActive from "../../Resources/images/good_active.png";
import excellent from "../../Resources/images/excellent.png";
import excellentActive from "../../Resources/images/excellent_active.png";
import checkCircle from "../../Resources/images/check_circle.png";
import Header from "../../Components/Header";
import PageContinueButton from "../../Components/PageContinueButton";
import { JoinResearch } from "../../Components/JoinResearch";

interface Props {
  numberOfSecondsToDelay: number;
  incomingTestEventData?: object;
  fromRevelianEventComplete?: boolean;
}

const TestEventCompleted = ({
  numberOfSecondsToDelay,
  incomingTestEventData,
  fromRevelianEventComplete
}: Props) => {
  const [seconds, setSeconds] = useState(
    numberOfSecondsToDelay ? numberOfSecondsToDelay : 5
  );
  const [numberOfAttempts, setNumberOfAttempts] = useState(2);
  const [showURL, setShowURL] = useState(false);
  const [ratingValue, setRating] = useState<number | null>(null);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [showTalentInsightsLink, setShowTalentInsightsLink] = useState(false);
  const [reportLink, setReportLink] = useState<string>("");
  const [experimentDataSubmitted, setExperimentDataSubmitted] = useState(false);

  // define the context type so that we can use this.context
  const context: any = useContext(TestEventContext);

  const testData = incomingTestEventData
    ? incomingTestEventData
    : context
    ? context.testEventData
    : null;
  const landingPageStyle = context.generateLandingPageStyle(
    testData.landingPage
  );

  let redirectUrl: any = null;

  if (
    context !== null &&
    context.optionalReturnUrl &&
    context.optionalReturnUrl !== null
  ) {
    redirectUrl = `${context.optionalReturnUrl}`;
  }

  // some fairly dense conditional here, but it is basically checking whether or not the redirectUrl needs to
  // contain the eventId and/or the orderId, and if so, determines where to get those values.
  if (redirectUrl === null) {
    if (testData?.exitPage?.customExitPageType === "redirect") {
      redirectUrl = testData.exitPage.redirectUrl;
      if (
        context &&
        testData.exitPage.includeEventId &&
        !testData.exitPage.includeOrderId
      ) {
        redirectUrl = `${redirectUrl}?event=${context.eventId}`;
      }
      if (
        context &&
        !testData.exitPage.includeEventId &&
        testData.exitPage.includeOrderId
      ) {
        redirectUrl = `${redirectUrl}?orderId=${
          context.testEventData.orderId !== null
            ? context.testEventData.orderId
            : context.orderId
        }`;
      }
      if (
        context &&
        testData.exitPage.includeEventId &&
        testData.exitPage.includeOrderId
      ) {
        redirectUrl = `${redirectUrl}?event=${context.eventId}&orderId=${
          context.testEventData.orderId !== null
            ? context.testEventData.orderId
            : context.orderId
        }`;
      }
    }
  }

  useEffect(() => {
    if (testData.teamingUser?.setupAccount === 0) {
      setShowTalentInsightsLink(true);
      setReportLink(testData.teamingUser?.reportLink);
    }
  }, [testData]);

  /****************************************************************************
   * Redirect URL Hooks                                                       *
   ****************************************************************************/

  useEffect(() => {
    if (redirectUrl && seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    }
  }, [seconds, redirectUrl]);

  useEffect(() => {
    if (redirectUrl && seconds === 0) {
      const decodedURI = decodeURIComponent(redirectUrl);
      window.location.href =
        decodedURI.slice(0, 7) === "http://" ||
        decodedURI.slice(0, 8) === "https://"
          ? decodedURI
          : `https://${decodedURI}`;
    }
  }, [seconds, redirectUrl]);

  useEffect(() => {
    if (redirectUrl && seconds === 0 && numberOfAttempts > 0) {
      const decodedURI = decodeURIComponent(redirectUrl);
      window.location.href =
        decodedURI.slice(0, 7) === "http://" ||
        decodedURI.slice(0, 8) === "https://"
          ? decodedURI
          : `https://${decodedURI}`;
      setTimeout(() => {
        setNumberOfAttempts(numberOfAttempts - 1);
      }, numberOfSecondsToDelay * 1000);
    }
    if (numberOfAttempts === 0) {
      setShowURL(true);
    }
  }, [numberOfAttempts, redirectUrl, seconds, numberOfSecondsToDelay]);

  const handleViewInsightsClick = () => {
    if (reportLink) {
      window.location.href = reportLink;
    }
  };

  const handleRatingInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const ratingInputValue: number = Number(event.target.value);
    setRating(ratingInputValue);
  };

  interface FormData {
    testEventId: number;
    surveyQuestionId: number;
    response: number | null;
  }

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();

    const formData: FormData = {
      testEventId: context.testEventId,
      surveyQuestionId: 1,
      response: ratingValue
    };

    try {
      const response = await fetch(`${apiURL}/surveyResponse`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(formData)
      });
      const responseData = await response;
      if (responseData.status === 200) {
        setFormSubmitted(true);
      }
    } catch (error) {
      console.log("Error occured when submitting survey: ", error);
      setFormSubmitted(true);
    }
  };

  const veryPoorImageToShow = ratingValue === 1 ? veryPoorActive : veryPoor;
  const poorImageToShow = ratingValue === 2 ? poorActive : poor;
  const fairImageToShow = ratingValue === 3 ? fairActive : fair;
  const goodImageToShow = ratingValue === 4 ? goodActive : good;
  const excellentImageToShow = ratingValue === 5 ? excellentActive : excellent;

  const surveyQuestionJSON =
    context && context.testEventData.odaSurveyQuestion
      ? JSON.parse(context.testEventData.odaSurveyQuestion.question)
      : null;

  const ratingsList = surveyQuestionJSON
    ? [
        {
          id: 1,
          image: veryPoorImageToShow,
          text: surveyQuestionJSON.options[0],
          backgroundColor: "#ffceb1"
        },
        {
          id: 2,
          image: poorImageToShow,
          text: surveyQuestionJSON.options[1],
          backgroundColor: "#ffda99"
        },
        {
          id: 3,
          image: fairImageToShow,
          text: surveyQuestionJSON.options[2],
          backgroundColor: "#d7ebb3"
        },
        {
          id: 4,
          image: goodImageToShow,
          text: surveyQuestionJSON.options[3],
          backgroundColor: "#acebcd"
        },
        {
          id: 5,
          image: excellentImageToShow,
          text: surveyQuestionJSON.options[4],
          backgroundColor: "#abebed"
        }
      ]
    : [];

  const ratingInputs = ratingsList.map(rating => {
    const textToShow =
      rating.id === ratingValue ? <strong>{rating.text}</strong> : rating.text;
    return (
      <td
        className="text-center"
        style={{
          width: "20%",
          verticalAlign: "top",
          border: 0,
          padding: 0,
          margin: 0
        }}
      >
        <Form.Check
          type="radio"
          id={`${rating.text}-radio`}
          style={{ padding: 0, margin: 0 }}
        >
          <Form.Check.Input
            value={rating.id}
            onChange={handleRatingInput}
            type="radio"
            style={{ opacity: "0" }}
            checked={rating.id === ratingValue}
          />
          <Form.Check.Label style={{ cursor: "pointer" }}>
            <div
              aria-hidden="true"
              style={{
                margin: "auto",
                height: "56px",
                width: "56px",
                borderRadius: "50%",
                backgroundColor:
                  rating.id === ratingValue ? rating.backgroundColor : ""
              }}
            >
              <Image
                fluid={true}
                className="mb-2"
                src={rating.image}
                alt=""
                style={{ paddingTop: "8px" }}
              />
            </div>
            {textToShow}
          </Form.Check.Label>
        </Form.Check>
      </td>
    );
  });

  const isExitPageDefault =
    !testData.exitPage || testData.exitPage.isDefault === 2;

  const isPostAssessmentResearchEnabled: boolean =
    context.configCatObject &&
    context.configCatObject.isPostAssessmentResearchEnabled &&
    isExitPageDefault &&
    redirectUrl === null &&
    !context.testEventData.hasOwnProperty("isTeamingEvent");

  const isMarketingODAResearchEnabled: boolean =
    context.configCatObject &&
    context.configCatObject.isMarketingODAResearchEnabled &&
    isExitPageDefault &&
    redirectUrl === null &&
    !context.testEventData.hasOwnProperty("isTeamingEvent");

  // showJoinResearch is true if isPostAssessmentResearchEnabled or isMarketingODAResearchEnabled is true but not both.
  const showJoinResearch: boolean =
    (isPostAssessmentResearchEnabled || isMarketingODAResearchEnabled) &&
    !(isPostAssessmentResearchEnabled && isMarketingODAResearchEnabled);

  const researchLink: string = isMarketingODAResearchEnabled
    ? "https://go.criteriacorp.com/candidate-survey"
    : "https://landing.research.revelian.com/?eventId=" + context.eventId;

  const sendExperimentData = async (
    testEventId: number,
    experimentVersion: string
  ) => {
    setExperimentDataSubmitted(true);
    const formData = {
      testEventId,
      experimentVersion
    };
    const response = await fetch(`${apiURL}/experiments`, {
      method: "POST",
      headers: {
        Authorization: context.token
      },
      body: JSON.stringify(formData)
    });

    return response.json();
  };

  useEffect(() => {
    if (showJoinResearch && !experimentDataSubmitted) {
      sendExperimentData(
        context.testEventId,
        isPostAssessmentResearchEnabled
          ? "PostAssessmentResearch"
          : "MarketingODAResearch"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Row>
        <Col
          xl={{ span: 8, offset: 2 }}
          lg={{ span: 8, offset: 2 }}
          md={{ span: 10, offset: 1 }}
          sm={12}
        >
          <Card className="oda-card">
            <Header
              onWelcomePage
              backgroundColor={landingPageStyle.backgroundColor}
              customLogo={testData?.logo}
              fromRevelianEventComplete={!!fromRevelianEventComplete}
              isVideoInterviewDiscoveryJob={
                testData?.isVideoInterviewDiscoveryJob
              }
            />
            <Card.Body>
              <div
                className="mt-5"
                style={{
                  borderBottom: isExitPageDefault ? "1px solid #eef0f0" : ""
                }}
              >
                {testData &&
                testData.isVideoInterviewDiscoveryJob &&
                testData.videoInterviewDiscovery ? (
                  <React.Fragment>
                    <p>{testData.videoInterviewDiscovery?.completionMsg}</p>
                    <p>
                      {testData.videoInterviewDiscovery?.reviewSampleCandidates}
                    </p>
                    <p className="text-center" style={{ fontWeight: "bold" }}>
                      <a href={testData.videoInterviewDiscovery.evaluationLink}>
                        {testData.videoInterviewDiscovery.beginEvaluation}
                      </a>
                    </p>
                  </React.Fragment>
                ) : redirectUrl ? (
                  <React.Fragment>
                    <Card.Title className="text-center mb-1">
                      Thank You.
                    </Card.Title>
                    <p>
                      You have completed the last assessment and your responses
                      have been successfully submitted.
                    </p>
                    <p>No further action is required.</p>
                    <p>
                      {context?.testEventData?.translatedText?.redirect
                        ? context.testEventData.translatedText.redirect.replace(
                            "{seconds}",
                            seconds
                          )
                        : `Please wait. You will be redirected in ${seconds} seconds...`}
                    </p>
                  </React.Fragment>
                ) : isExitPageDefault ? (
                  showTalentInsightsLink ? (
                    <div className="mb-5">
                      <Card.Title className="text-center mb-1">
                        Thank You.
                      </Card.Title>
                      <p className="text-center">
                        You have completed the assessment.
                      </p>
                      <p className="text-center">
                        Click the button below to login and view your insights:
                      </p>
                      <span className="mb-3">
                        <PageContinueButton
                          handler={handleViewInsightsClick}
                          buttonText="View Insights"
                        />
                      </span>
                    </div>
                  ) : (
                    <div
                      className="text-center"
                      dangerouslySetInnerHTML={{
                        __html: testData.translatedText?.testCompletionMsg
                      }}
                    />
                  )
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: testData.exitPage.customMessage
                    }}
                  />
                )}
                {showURL ? (
                  <Col xl={6} lg={6} md={10} sm={12}>
                    <Alert variant="info">
                      Your event has been successfully completed, but we are
                      having trouble redirecting you. Please continue to wait or
                      click <a href={decodeURIComponent(redirectUrl)}>here</a>{" "}
                      to finish redirecting.
                    </Alert>
                  </Col>
                ) : null}
              </div>
              {showJoinResearch && context.testEventData.freeTrial === 0 ? (
                <JoinResearch
                  researchLink={researchLink}
                  isMarketingODAResearchEnabled={isMarketingODAResearchEnabled}
                />
              ) : null}
              {isExitPageDefault && ratingsList.length > 0 ? (
                !showJoinResearch || context.testEventData.freeTrial === 1 ? (
                  !formSubmitted ? (
                    <React.Fragment>
                      <Row className="mb-1 pt-4">
                        <Col>
                          {surveyQuestionJSON ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: surveyQuestionJSON.questionText
                              }}
                            />
                          ) : (
                            <React.Fragment>
                              <strong>
                                Overall, how would you rate your experience
                                during this assessment process?
                              </strong>{" "}
                              <em>
                                (Responses are anonymous and won't impact your
                                test results in any way.)
                              </em>
                            </React.Fragment>
                          )}
                        </Col>
                      </Row>
                      <Row className="text-center mr-0 mb-1">
                        <Col>
                          <Form onSubmit={handleSubmit}>
                            <Row className="text-center mb-1">
                              <Col className="text-center">
                                <table className="table">
                                  <tr>{ratingInputs}</tr>
                                </table>
                              </Col>
                            </Row>
                            <Row>
                              <Col
                                xl={{ span: 6, offset: 3 }}
                                lg={{ span: 6, offset: 3 }}
                                md={{ span: 8, offset: 2 }}
                                sm={12}
                              >
                                <Button
                                  variant="primary"
                                  type="submit"
                                  style={{
                                    pointerEvents:
                                      ratingValue === null ? "none" : "auto"
                                  }}
                                  disabled={ratingValue === null}
                                  block={true}
                                >
                                  {context &&
                                  context.testEventData &&
                                  context.testEventData.translatedText.submit
                                    ? context.testEventData.translatedText
                                        .submit
                                    : `Submit`}
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Row className="text-center mt-5">
                        <Col className="pt-5 pb-5">
                          <h6
                            className="mb-4"
                            style={{
                              fontSize: "16px",
                              fontWeight: "bold"
                            }}
                          >
                            {context?.testEventData?.translatedText?.feedbackMsg
                              ? context.testEventData.translatedText.feedbackMsg
                              : "Thank you for your feedback!"}
                          </h6>
                          <img
                            src={checkCircle}
                            alt="checkmark inside circle"
                            className="mb-4"
                          />
                        </Col>
                      </Row>
                    </React.Fragment>
                  )
                ) : null
              ) : null}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default TestEventCompleted;
