import React from "react";
import PageVisibility from "react-page-visibility";
import CLIKSimulation1 from "./CLIKSections/CLIKSimulation1";
import CLIKSimulation2 from "./CLIKSections/CLIKSimulation2";
import CLIKSimulation3 from "./CLIKSections/CLIKSimulation3";
import CLIKMultipleChoice from "./CLIKSections/CLIKMultipleChoice";
import ContinuePage from "./CLIKComponents/ContinuePage";

interface CLIKProps {
  testData: any;
  subTestId: string;
  testEventId: number;
  startingSection: number;
  startingQuestion: number;
  endSubTest: () => void;
  dataLoaded: boolean;
  timeRemaining: number;
  handleVisibilityChange: (isVisible: boolean) => void;
  timeFactor: number;
  originalSimulationTimeAllowed: number;
}

interface CLIKState {
  startingSection: number;
  currentSection: number;
  continueSection2: boolean;
  continueSection3: boolean;
  continueSection4: boolean;
}

export default class CLIKTest extends React.PureComponent<
  CLIKProps,
  CLIKState
> {
  state: CLIKState = {
    startingSection: 1,
    currentSection: 1,
    continueSection2: false,
    continueSection3: false,
    continueSection4: false
  };

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    return nextProps.startingSection !== prevState.startingSection
      ? {
          startingSection: nextProps.startingSection,
          currentSection: nextProps.startingSection
        }
      : {};
  }

  handleGoToNextSection = () => {
    if (this.state.currentSection === 4) {
      this.props.endSubTest();
    } else {
      this.setState({ currentSection: this.state.currentSection + 1 });
    }
  };

  handleContinue2Button = () => {
    this.setState({ continueSection2: true });
  };

  handleContinue3Button = () => {
    this.setState({ continueSection3: true });
  };

  handleContinue4Button = () => {
    this.setState({ continueSection4: true });
  };

  onHandleVisibilityChange = (isVisible: boolean) => {
    if (
      (this.state.currentSection === 2 && !this.state.continueSection2) ||
      (this.state.currentSection === 3 && !this.state.continueSection3) ||
      (this.state.currentSection === 4 && !this.state.continueSection4)
    ) {
      return;
    } else {
      this.props.handleVisibilityChange(isVisible);
    }
  };

  render() {
    const {
      testData,
      testEventId,
      subTestId,
      startingQuestion,
      dataLoaded,
      timeRemaining,
      timeFactor,
      originalSimulationTimeAllowed
    } = this.props;
    const simulationTimeAllowed = originalSimulationTimeAllowed * timeFactor;
    return (
      <PageVisibility onChange={this.onHandleVisibilityChange}>
        <div>
          {this.state.currentSection === 1 && (
            <CLIKSimulation1
              currentSection={this.state.currentSection}
              handleGoToNextSection={this.handleGoToNextSection}
              simulationTimeAllowed={simulationTimeAllowed}
              subTestId={subTestId}
              testEventId={testEventId}
            />
          )}
          {this.state.currentSection === 2 && !this.state.continueSection2 && (
            <ContinuePage
              handleContinue={this.handleContinue2Button}
              text1="first simulation"
              text2={`the second simulation. You will have ${
                simulationTimeAllowed / 60
              } minutes`}
            />
          )}
          {this.state.currentSection === 2 && this.state.continueSection2 && (
            <CLIKSimulation2
              currentSection={this.state.currentSection}
              handleGoToNextSection={this.handleGoToNextSection}
              simulationTimeAllowed={simulationTimeAllowed}
              subTestId={subTestId}
              testEventId={testEventId}
            />
          )}
          {this.state.currentSection === 3 && !this.state.continueSection3 && (
            <ContinuePage
              handleContinue={this.handleContinue3Button}
              text1="second simulation"
              text2={`the third simulation. You will have ${
                simulationTimeAllowed / 60
              } minutes`}
            />
          )}
          {this.state.currentSection === 3 && this.state.continueSection3 && (
            <CLIKSimulation3
              currentSection={this.state.currentSection}
              handleGoToNextSection={this.handleGoToNextSection}
              simulationTimeAllowed={simulationTimeAllowed}
              subTestId={subTestId}
              testEventId={testEventId}
            />
          )}
          {this.state.currentSection === 4 && !this.state.continueSection4 && (
            <ContinuePage
              handleContinue={this.handleContinue4Button}
              text1="third simulation"
              text2={`10 multiple choice questions. You will have ${
                4 * timeFactor
              } minutes`}
            />
          )}
          {this.state.currentSection === 4 && this.state.continueSection4 && (
            <CLIKMultipleChoice
              dataLoaded={dataLoaded}
              handleGoToNextSection={this.handleGoToNextSection}
              subTestId={subTestId}
              startingQuestion={startingQuestion}
              simulationTimeAllowed={simulationTimeAllowed}
              testData={testData}
              timeRemaining={timeRemaining}
            />
          )}
        </div>
      </PageVisibility>
    );
  }
}
