import React from "react";

interface Props {
  shape: string;
  fill: string;
  isInstructions: boolean;
  isMobile?: boolean;
  isMobileOnly?: boolean;
  isIPad13?: boolean;
}

const Shape = ({
  shape,
  fill,
  isInstructions,
  isMobile,
  isMobileOnly,
  isIPad13
}: Props) => {
  // set the default values for the size of the shape svg (based on the size needed in the ShapesRow Component)
  let viewBox = "0 0 80 80";
  let width = "80";
  let height = "80";
  let polygon = "";

  // in the ShapesRow component, smaller copies of the shapes are required, and some shapes
  // need separate values than the default ones.
  if (isInstructions) {
    switch (shape) {
      case "triangle":
        width = isMobile || isIPad13 ? "90" : "120";
        height = isMobile || isIPad13 ? "60" : "80";
        viewBox = "0 0 120 80";
        break;
      case "right parallel":
        polygon = "75 40 0 40 25 0 100 0 75 40";
        viewBox = "0 0 100 40";
        width = isMobileOnly ? "75" : "100";
        height = isMobileOnly ? "30" : "40";
        break;
      case "left parallel":
        polygon = "100 40 25 40 0 0 75 0 100 40";
        viewBox = "0 0 100 40";
        width = isMobileOnly ? "75" : "100";
        height = isMobileOnly ? "30" : "40";
        break;
      case "rectangle":
        width = isMobileOnly ? "75" : "100";
        height = isMobileOnly ? "30" : "40";
        viewBox = "0 0 100 40";
        break;
      case "trapezoid":
        polygon = "100 40 0 40 25 0 75 0 100 40";
        viewBox = "0 0 100 30";
        width = isMobileOnly ? "75" : "100";
        height = isMobileOnly ? "30" : "40";
        break;
      case "down trapezoid":
        polygon = "75 40 25 40 0 0 100 0 75 40";
        width = isMobileOnly ? "75" : "100";
        height = isMobileOnly ? "30" : "40";
        viewBox = "0 0 100 40";
        break;
      case "four_fours":
      case "four_fives":
      case "four_sixes":
      case "five_fours":
      case "five_fives":
      case "five_sixes":
      case "six_fours":
      case "six_fives":
      case "six_sixes":
        width = "350";
        height = "80";
        viewBox = "0 0 100 100";
        break;
      default:
        viewBox = "0 0 80 80";
        width = isMobile || isIPad13 ? "60" : "80";
        height = isMobile || isIPad13 ? "60" : "80";
    }
  }

  // for the actual display of the shape images in the test, they need to be slightly larger than they are
  // in the instructions
  if (!isInstructions) {
    switch (shape) {
      case "square":
      case "star":
      case "circle":
        height = "180";
        width = "180";
        viewBox = "0 0 80 80";
        break;
      case "triangle":
        height = "240";
        width = "180";
        viewBox = "0 0 120 180";
        break;
      case "right parallel":
        polygon = "150 80 0 80 50 0 200 0 150 80";
        viewBox = "0 0 200 80";
        width = "200";
        height = "80";
        break;
      case "left parallel":
        polygon = "200 80 50 80 0 0 150 0 200 80";
        viewBox = "0 0 200 80";
        width = "200";
        height = "80";
        break;
      case "rectangle":
        width = "200";
        height = "80";
        viewBox = "0 0 200 80";
        break;
      case "trapezoid":
        polygon = "200 80 0 80 50 0 150 0 200 80";
        viewBox = "0 0 200 80";
        width = "200";
        height = "80";
        break;
      case "down trapezoid":
        polygon = "150 80 50 80 0 0 200 0 150 80";
        width = "200";
        height = "80";
        viewBox = "0 0 200 80";
        break;
      case "four_fours":
      case "four_fives":
      case "four_sixes":
      case "five_fours":
      case "five_fives":
      case "five_sixes":
      case "six_fours":
      case "six_fives":
      case "six_sixes":
        width = "350";
        height = "80";
        viewBox = "0 0 100 100";
        break;
    }
  }

  return (
    <span>
      {shape === "square" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox={viewBox}
          preserveAspectRatio="xMinYMid meet"
        >
          <path fill={fill} stroke="#000" strokeWidth="2" d="M0 0h80v80H0z" />
        </svg>
      ) : null}
      {shape === "triangle" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height={height}
          width={width}
          viewBox={viewBox}
          preserveAspectRatio="xMidYMid meet"
        >
          <path
            fill={fill}
            stroke="#000"
            strokeWidth="1"
            d="M5 116L61 3l56 113z"
          />
        </svg>
      ) : null}
      {shape === "circle" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height={height}
          width={width}
          viewBox={viewBox}
          preserveAspectRatio="xMidYMid meet"
        >
          <circle
            cx="39"
            cy="39"
            r="37"
            fill={fill}
            stroke="#000"
            strokeWidth="1"
          />
        </svg>
      ) : null}
      {shape === "star" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height={height}
          width={width}
          viewBox={viewBox}
          preserveAspectRatio="xMidYMid meet"
        >
          <path
            stroke="#000"
            strokeWidth="1"
            d="M0 30h30l9-30 9 30h30L54 49l9 29-24-18-24 18 9-29L0 30z"
            fill={fill}
          />
        </svg>
      ) : null}
      {shape === "right parallel" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox={viewBox}
          width={width}
          height={height}
        >
          <polygon points={polygon} fill="#999" />
        </svg>
      ) : null}
      {shape === "left parallel" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox={viewBox}
        >
          <polygon points={polygon} fill="#999" />
        </svg>
      ) : null}
      {shape === "rectangle" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox={viewBox}
          preserveAspectRatio="xMidYMid meet"
        >
          <rect width={width} height={height} fill="#999" />
        </svg>
      ) : null}
      {shape === "down trapezoid" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox={viewBox}
          preserveAspectRatio="xMidYMid meet"
        >
          <polygon points={polygon} fill="#999" />
        </svg>
      ) : null}
      {shape === "trapezoid" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox={viewBox}
          preserveAspectRatio="xMidYMid meet"
        >
          <polygon points={polygon} fill="#999" />
        </svg>
      ) : null}
      {shape === "four_fours" ? (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
          <g fontFamily="sans-serif" fontSize={isMobileOnly ? "48" : "65"}>
            <text x="10" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              4
            </text>
            <text x="100" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              4
            </text>
            <text x="190" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              4
            </text>
            <text x="280" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              4
            </text>
          </g>
        </svg>
      ) : null}
      {shape === "four_fives" ? (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
          <g fontFamily="sans-serif" fontSize={isMobileOnly ? "48" : "65"}>
            <text x="10" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              5
            </text>
            <text x="100" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              5
            </text>
            <text x="190" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              5
            </text>
            <text x="280" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              5
            </text>
          </g>
        </svg>
      ) : null}
      {shape === "four_sixes" ? (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
          <g fontFamily="sans-serif" fontSize={isMobileOnly ? "48" : "65"}>
            <text x="10" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              6
            </text>
            <text x="100" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              6
            </text>
            <text x="190" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              6
            </text>
            <text x="280" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              6
            </text>
          </g>
        </svg>
      ) : null}
      {shape === "five_fours" ? (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
          <g fontFamily="sans-serif" fontSize={isMobileOnly ? "48" : "65"}>
            <text x="10" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              4
            </text>
            <text x="80" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              4
            </text>
            <text x="145" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              4
            </text>
            <text x="210" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              4
            </text>
            <text x="275" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              4
            </text>
          </g>
        </svg>
      ) : null}
      {shape === "five_fives" ? (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
          <g fontFamily="sans-serif" fontSize={isMobileOnly ? "48" : "65"}>
            <text x="10" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              5
            </text>
            <text x="80" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              5
            </text>
            <text x="145" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              5
            </text>
            <text x="210" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              5
            </text>
            <text x="275" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              5
            </text>
          </g>
        </svg>
      ) : null}
      {shape === "five_sixes" ? (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
          <g fontFamily="sans-serif" fontSize={isMobileOnly ? "48" : "65"}>
            <text x="10" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              6
            </text>
            <text x="80" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              6
            </text>
            <text x="145" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              6
            </text>
            <text x="210" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              6
            </text>
            <text x="275" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              6
            </text>
          </g>
        </svg>
      ) : null}
      {shape === "six_fours" ? (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
          <g fontFamily="sans-serif" fontSize={isMobileOnly ? "48" : "65"}>
            <text x="10" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              4
            </text>
            <text x="65" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              4
            </text>
            <text x="120" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              4
            </text>
            <text x="175" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              4
            </text>
            <text x="230" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              4
            </text>
            <text x="285" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              4
            </text>
          </g>
        </svg>
      ) : null}
      {shape === "six_fives" ? (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
          <g fontFamily="sans-serif" fontSize={isMobileOnly ? "48" : "65"}>
            <text x="10" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              5
            </text>
            <text x="65" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              5
            </text>
            <text x="120" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              5
            </text>
            <text x="175" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              5
            </text>
            <text x="230" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              5
            </text>
            <text x="285" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              5
            </text>
          </g>
        </svg>
      ) : null}
      {shape === "six_sixes" ? (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
          <g fontFamily="sans-serif" fontSize={isMobileOnly ? "48" : "65"}>
            <text x="10" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              6
            </text>
            <text x="65" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              6
            </text>
            <text x="120" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              6
            </text>
            <text x="175" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              6
            </text>
            <text x="230" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              6
            </text>
            <text x="285" y="60" transform="matrix(1.1 0 0 1 -1 0)">
              6
            </text>
          </g>
        </svg>
      ) : null}
      {shape === "mr_01" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isMobileOnly ? "350" : "440"}
          height="240"
        >
          <g fill="#fff" stroke="#000" transform="translate(10)">
            <path d="M0 50h25v25H0z" />
            <path fill="#000" d="M25 50h25v25H25z" />
            <path d="M25 75h25v25H25zM50 75h25v25H50zM75 75h25v25H75zM25 100h25v25H25zM25 125h25v25H25zM0 150h25v25H0zM25 150h25v25H25zM25 175h25v25H25z" />
          </g>
          <g fill="#fff" stroke="#000">
            <path d="M300 59l23 8-8 24-24-9z" />
            <path fill="#000" d="M276 50l24 9-9 23-23-8z" />
            <path d="M268 74l23 8-8 24-24-9z" />
            <path d="M244 65l24 9-9 23-23-8z" />
            <path d="M221 57l23 8-8 24-24-9zM259 97l24 9-9 23-23-8z" />
            <path d="M251 121l23 8-8 24-24-9zM266 153l23 8-8 24-24-9z" />
            <path d="M242 144l24 9-9 23-23-8z" />
            <path d="M234 168l23 8-8 24-24-9z" />
          </g>
        </svg>
      ) : null}
      {shape === "mr_02" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isMobileOnly ? "350" : "440"}
          height="240"
        >
          <g fill="#fff" stroke="#000" transform="translate(10)">
            <path d="M0 50h25v25H0z" />
            <path fill="#000" d="M25 50h25v25H25z" />
            <path d="M25 75h25v25H25zM50 75h25v25H50zM75 75h25v25H75zM25 100h25v25H25zM25 125h25v25H25zM0 150h25v25H0zM25 150h25v25H25zM25 175h25v25H25z" />
          </g>
          <g fill="#fff" stroke="#000">
            <path d="M282 43l19 17-16 19-19-16z" />
            <path fill="#000" d="M301 60l19 16-16 19-19-16z" />
            <path d="M285 79l19 16-16 19-19-16zM304 95l19 16-16 19-19-16z" />
            <path d="M323 111l20 16-17 19-19-16zM269 98l19 16-16 19-19-16z" />
            <path d="M253 117l19 16-16 19-19-16zM218 120l19 16-16 19-19-16z" />
            <path d="M237 136l19 16-16 19-19-16z" />
            <path d="M221 155l19 16-16 19-19-16z" />
          </g>
        </svg>
      ) : null}
      {shape === "mr_03" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isMobileOnly ? "350" : "440"}
          height="240"
        >
          <g fill="#fff" stroke="#000" transform="translate(10)">
            <path d="M0 50h25v25H0z" />
            <path fill="#000" d="M25 50h25v25H25z" />
            <path d="M25 75h25v25H25zM50 75h25v25H50zM75 75h25v25H75zM25 100h25v25H25zM25 125h25v25H25zM0 150h25v25H0zM25 150h25v25H25zM25 175h25v25H25z" />
          </g>
          <g fill="#fff" stroke="#000">
            <path d="M334 98l12 22-21 12-13-21z" />
            <path fill="#000" d="M321 77l13 21-22 13-12-22z" />
            <path d="M300 89l12 22-22 12-12-21z" />
            <path d="M287 68l13 21-22 13-13-22z" />
            <path d="M275 46l12 22-22 12-12-22zM278 102l12 21-21 13-13-22z" />
            <path d="M256 114l13 22-22 12-12-21zM247 148l13 22-22 12-13-21z" />
            <path d="M235 127l12 21-22 13-12-22z" />
            <path d="M213 139l12 22-21 12-13-21z" />
          </g>
        </svg>
      ) : null}
      {shape === "mr_04" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isMobileOnly ? "350" : "440"}
          height="240"
        >
          <g fill="#fff" stroke="#000" transform="translate(10)">
            <path d="M0 50h25v25H0z" />
            <path fill="#000" d="M25 50h25v25H25z" />
            <path d="M25 75h25v25H25zM50 75h25v25H50zM75 75h25v25H75zM25 100h25v25H25zM25 125h25v25H25zM0 150h25v25H0zM25 150h25v25H25zM25 175h25v25H25z" />
          </g>
          <g fill="#fff" stroke="#000">
            <path d="M330 75l4 25-24 4-5-25z" />
            <path fill="#000" d="M334 100l5 24-25 5-4-25z" />
            <path d="M310 104l4 25-25 4-4-25z" />
            <path d="M314 129l4 24-24 5-5-25zM318 153l5 25-25 4-4-24zM285 108l4 25-24 4-5-24z" />
            <path d="M260 113l5 24-25 5-4-25zM231 92l5 25-25 4-4-24z" />
            <path d="M236 117l4 25-25 4-4-25z" />
            <path d="M211 121l4 25-24 4-5-24z" />
          </g>
        </svg>
      ) : null}
      {shape === "mr_05" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isMobileOnly ? "350" : "440"}
          height="240"
        >
          <g fill="#fff" stroke="#000" transform="translate(10)">
            <path d="M0 50h25v25H0z" />
            <path fill="#000" d="M25 50h25v25H25z" />
            <path d="M25 75h25v25H25zM50 75h25v25H50zM75 75h25v25H75zM25 100h25v25H25zM25 125h25v25H25zM0 150h25v25H0zM25 150h25v25H25zM25 175h25v25H25z" />
          </g>
          <g fill="#fff" stroke="#000">
            <path d="M334 150l-4 25-25-4 5-25z" />
            <path fill="#000" d="M339 126l-5 24-24-4 4-25z" />
            <path d="M314 121l-4 25-25-4 4-25z" />
            <path d="M318 97l-4 24-25-4 5-25z" />
            <path d="M323 72l-5 25-24-5 4-24zM289 117l-4 25-25-5 5-24z" />
            <path d="M265 113l-5 24-24-4 4-25zM236 133l-5 25-24-5 4-24z" />
            <path d="M240 108l-4 25-25-4 4-25z" />
            <path d="M215 104l-4 25-25-5 5-24z" />
          </g>
        </svg>
      ) : null}
      {shape === "mr_06" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isMobileOnly ? "350" : "440"}
          height="240"
        >
          <g fill="#fff" stroke="#000" transform="translate(10)">
            <path d="M0 50h25v25H0z" />
            <path fill="#000" d="M25 50h25v25H25z" />
            <path d="M25 75h25v25H25zM50 75h25v25H50zM75 75h25v25H75zM25 100h25v25H25zM25 125h25v25H25zM0 150h25v25H0zM25 150h25v25H25zM25 175h25v25H25z" />
          </g>
          <g fill="#fff" stroke="#000">
            <path d="M346 130l-12 22-22-13 13-21z" />
            <path fill="#000" d="M334 152l-13 21-21-12 12-22z" />
            <path d="M312 139l-12 22-22-13 12-21z" />
            <path d="M300 161l-13 21-22-12 13-22z" />
            <path d="M287 182l-12 22-22-12 12-22zM290 127l-12 21-22-12 13-22z" />
            <path d="M269 114l-13 22-21-13 12-21zM260 80l-13 22-22-13 13-21z" />
            <path d="M247 102l-12 21-22-12 12-22z" />
            <path d="M225 89l-12 22-22-13 13-21z" />
          </g>
        </svg>
      ) : null}
      {shape === "mr_07" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isMobileOnly ? "350" : "440"}
          height="240"
        >
          <g fill="#fff" stroke="#000" transform="translate(10)">
            <path d="M0 50h25v25H0z" />
            <path fill="#000" d="M25 50h25v25H25z" />
            <path d="M25 75h25v25H25zM50 75h25v25H50zM75 75h25v25H75zM25 100h25v25H25zM25 125h25v25H25zM0 150h25v25H0zM25 150h25v25H25zM25 175h25v25H25z" />
          </g>
          <g fill="#fff" stroke="#000">
            <path d="M301 190l-19 17-16-20 19-16z" />
            <path fill="#000" d="M320 174l-19 16-16-19 19-16z" />
            <path d="M304 155l-19 16-16-19 19-16z" />
            <path d="M323 139l-19 16-16-19 19-16z" />
            <path d="M343 123l-20 16-16-19 19-16zM288 136l-19 16-16-19 19-16z" />
            <path d="M272 117l-19 16-16-19 19-16zM237 114l-19 16-16-19 19-16z" />
            <path d="M256 98l-19 16-16-19 19-16z" />
            <path d="M240 79l-19 16-16-19 19-16z" />
          </g>
        </svg>
      ) : null}
      {shape === "mr_08" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isMobileOnly ? "350" : "440"}
          height="240"
        >
          <g fill="#fff" stroke="#000" transform="translate(10)">
            <path d="M0 50h25v25H0z" />
            <path fill="#000" d="M25 50h25v25H25z" />
            <path d="M25 75h25v25H25zM50 75h25v25H50zM75 75h25v25H75zM25 100h25v25H25zM25 125h25v25H25zM0 150h25v25H0zM25 150h25v25H25zM25 175h25v25H25z" />
          </g>
          <g fill="#fff" stroke="#000">
            <path d="M323 183l-23 8-9-23 24-9z" />
            <path fill="#000" d="M300 191l-24 9-8-24 23-8z" />
            <path d="M291 168l-23 8-9-23 24-9z" />
            <path d="M268 176l-24 9-8-24 23-8z" />
            <path d="M244 185l-23 8-9-23 24-9zM283 144l-24 9-8-24 23-8z" />
            <path d="M274 121l-23 8-9-23 24-9zM289 89l-23 8-9-23 24-9z" />
            <path d="M266 97l-24 9-8-24 23-8z" />
            <path d="M257 74l-23 8-9-23 24-9z" />
          </g>
        </svg>
      ) : null}
      {shape === "mr_09" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isMobileOnly ? "350" : "440"}
          height="240"
        >
          <g fill="#fff" stroke="#000" transform="translate(10)">
            <path fill="#000" d="M25 50h25v25H25z" />
            <path d="M25 75h25v25H25zM50 75h25v25H50zM75 75h25v25H75zM0 100h25v25H0zM25 100h25v25H25zM25 125h25v25H25zM25 150h25v25H25zM0 175h25v25H0zM25 175h25v25H25z" />
          </g>
          <g fill="#fff" stroke="#000" transform="translate(-20)">
            <path fill="#000" d="M300 59l23 8-8 24-24-9z" />
            <path d="M291 82l24 9-9 23-23-8z" />
            <path d="M315 91l23 8-8 24-24-9zM338 99l24 9-9 23-23-8zM259 97l24 9-9 23-23-8z" />
            <path d="M283 106l23 8-8 24-24-9z" />
            <path d="M274 129l24 9-9 23-23-8z" />
            <path d="M266 153l23 8-8 24-24-9zM234 168l23 8-8 24-24-9z" />
            <path d="M257 176l24 9-9 23-23-8z" />
          </g>
        </svg>
      ) : null}
      {shape === "mr_10" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isMobileOnly ? "350" : "440"}
          height="240"
        >
          <g fill="#fff" stroke="#000" transform="translate(10)">
            <path fill="#000" d="M25 50h25v25H25z" />
            <path d="M25 75h25v25H25zM50 75h25v25H50zM75 75h25v25H75zM0 100h25v25H0zM25 100h25v25H25zM25 125h25v25H25zM25 150h25v25H25zM0 175h25v25H0zM25 175h25v25H25z" />
          </g>
          <g fill="#fff" stroke="#000">
            <path fill="#000" d="M320 76l19 16-16 19-19-16z" />
            <path d="M304 95l19 16-16 19-19-16z" />
            <path d="M285 79l19 16-16 19-19-16z" />
            <path d="M266 63l19 16-16 19-19-16zM288 114l19 16-16 19-19-16zM307 130l19 16-16 19-19-16z" />
            <path d="M272 133l19 16-16 19-19-16z" />
            <path d="M256 152l19 16-16 19-19-16zM259 187l19 16-16 20-19-16z" />
            <path d="M240 171l19 16-16 20-19-17z" />
          </g>
        </svg>
      ) : null}
      {shape === "mr_11" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isMobileOnly ? "350" : "440"}
          height="240"
        >
          <g fill="#fff" stroke="#000" transform="translate(10)">
            <path fill="#000" d="M25 50h25v25H25z" />
            <path d="M25 75h25v25H25zM50 75h25v25H50zM75 75h25v25H75zM0 100h25v25H0zM25 100h25v25H25zM25 125h25v25H25zM25 150h25v25H25zM0 175h25v25H0zM25 175h25v25H25z" />
          </g>
          <g fill="#fff" stroke="#000">
            <path fill="#000" d="M334 98l12 22-21 12-13-21z" />
            <path d="M312 111l13 21-22 13-13-22zM325 132l12 22-22 13-12-22zM337 154l13 22-22 12-13-21zM278 102l12 21-21 13-13-22z" />
            <path d="M290 123l13 22-22 12-12-21z" />
            <path d="M269 136l12 21-21 13-13-22z" />
            <path d="M247 148l13 22-22 12-13-21zM213 139l12 22-21 12-13-21z" />
            <path d="M225 161l13 21-22 13-12-22z" />
          </g>
        </svg>
      ) : null}
      {shape === "mr_12" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isMobileOnly ? "350" : "440"}
          height="240"
        >
          <g fill="#fff" stroke="#000" transform="translate(10)">
            <path fill="#000" d="M25 50h25v25H25z" />
            <path d="M25 75h25v25H25zM50 75h25v25H50zM75 75h25v25H75zM0 100h25v25H0zM25 100h25v25H25zM25 125h25v25H25zM25 150h25v25H25zM0 175h25v25H0zM25 175h25v25H25z" />
          </g>
          <g fill="#fff" stroke="#000">
            <path fill="#000" d="M339 124l4 25-25 4-4-24z" />
            <path d="M314 129l4 24-24 5-5-25z" />
            <path d="M310 104l4 25-25 4-4-25z" />
            <path d="M305 79l5 25-25 4-4-24zM289 133l5 25-25 4-4-25z" />
            <path d="M294 158l4 24-25 5-4-25zM265 137l4 25-25 4-4-24z" />
            <path d="M240 142l4 24-24 5-5-25zM220 171l4 24-25 5-4-25z" />
            <path d="M215 146l5 25-25 4-4-25z" />
          </g>
        </svg>
      ) : null}
      {shape === "mr_13" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isMobileOnly ? "350" : "440"}
          height="240"
        >
          <g fill="#fff" stroke="#000" transform="translate(10)">
            <path fill="#000" d="M25 50h25v25H25z" />
            <path d="M25 75h25v25H25zM50 75h25v25H50zM75 75h25v25H75zM0 100h25v25H0zM25 100h25v25H25zM25 125h25v25H25zM25 150h25v25H25zM0 175h25v25H0zM25 175h25v25H25z" />
          </g>
          <g fill="#fff" stroke="#000">
            <path fill="#000" d="M334 150l-4 25-25-4 5-25z" />
            <path d="M310 146l-5 25-24-5 4-24z" />
            <path d="M305 171l-4 24-25-4 5-25z" />
            <path d="M301 195l-4 25-25-4 4-25zM289 117l-4 25-25-5 5-24z" />
            <path d="M285 142l-4 24-25-4 4-25z" />
            <path d="M260 137l-4 25-25-4 5-25z" />
            <path d="M236 133l-5 25-24-5 4-24zM215 104l-4 25-25-5 5-24z" />
            <path d="M211 129l-4 24-25-4 4-25z" />
          </g>
        </svg>
      ) : null}
      {shape === "mr_14" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isMobileOnly ? "350" : "440"}
          height="240"
        >
          <g fill="#fff" stroke="#000" transform="translate(10)">
            <path fill="#000" d="M25 50h25v25H25z" />
            <path d="M25 75h25v25H25zM50 75h25v25H50zM75 75h25v25H75zM0 100h25v25H0zM25 100h25v25H25zM25 125h25v25H25zM25 150h25v25H25zM0 175h25v25H0zM25 175h25v25H25z" />
          </g>
          <g fill="#fff" stroke="#000">
            <path fill="#000" d="M321 173l-12 22-22-13 13-21z" />
            <path d="M300 161l-13 21-22-12 13-22z" />
            <path d="M312 139l-12 22-22-13 12-21z" />
            <path d="M325 118l-13 21-22-12 13-22zM278 148l-13 22-21-13 12-21z" />
            <path d="M265 170l-12 22-22-13 13-22zM256 136l-12 21-22-12 13-22z" />
            <path d="M235 123l-13 22-22-13 13-21zM200 132l-12 22-22-12 13-22z" />
            <path d="M213 111l-13 21-21-12 12-22z" />
          </g>
        </svg>
      ) : null}
      {shape === "mr_15" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isMobileOnly ? "350" : "440"}
          height="240"
        >
          <g fill="#fff" stroke="#000" transform="translate(10)">
            <path fill="#000" d="M25 50h25v25H25z" />
            <path d="M25 75h25v25H25zM50 75h25v25H50zM75 75h25v25H75zM0 100h25v25H0zM25 100h25v25H25zM25 125h25v25H25zM25 150h25v25H25zM0 175h25v25H0zM25 175h25v25H25z" />
          </g>
          <g fill="#fff" stroke="#000">
            <path fill="#000" d="M301 190l-19 17-16-20 19-16z" />
            <path d="M285 171l-19 16-16-19 19-16z" />
            <path d="M266 187l-19 16-16-19 19-16z" />
            <path d="M247 203l-19 17-16-20 19-16zM288 136l-19 16-16-19 19-16z" />
            <path d="M269 152l-19 16-16-19 19-16z" />
            <path d="M253 133l-19 16-16-19 19-16z" />
            <path d="M237 114l-19 16-16-19 19-16zM240 79l-19 16-16-19 19-16z" />
            <path d="M221 95l-19 16-16-19 19-16z" />
          </g>
        </svg>
      ) : null}
      {shape === "mr_16" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isMobileOnly ? "350" : "440"}
          height="240"
        >
          <g fill="#fff" stroke="#000" transform="translate(10)">
            <path fill="#000" d="M25 50h25v25H25z" />
            <path d="M25 75h25v25H25zM50 75h25v25H50zM75 75h25v25H75zM0 100h25v25H0zM25 100h25v25H25zM25 125h25v25H25zM25 150h25v25H25zM0 175h25v25H0zM25 175h25v25H25z" />
          </g>
          <g fill="#fff" stroke="#000">
            <path fill="#000" d="M276 200l-23 8-9-23 24-9z" />
            <path d="M268 176l-24 9-8-24 23-8z" />
            <path d="M291 168l-23 8-9-23 24-9z" />
            <path d="M315 159l-24 9-8-24 23-8zM259 153l-23 8-9-23 24-9z" />
            <path d="M236 161l-24 9-8-24 23-8zM251 129l-24 9-8-24 23-8z" />
            <path d="M242 106l-23 8-9-23 24-9zM210 91l-23 8-9-23 24-9z" />
            <path d="M234 82l-24 9-8-24 23-8z" />
          </g>
        </svg>
      ) : null}
      {shape === "mr_17" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isMobileOnly ? "350" : "440"}
          height="240"
        >
          <g fill="#fff" stroke="#000" transform="translate(10)">
            <path fill="#000" d="M25 50h25v25H25z" />
            <path d="M25 75h25v25H25zM50 75h25v25H50zM75 75h25v25H75zM0 100h25v25H0zM25 100h25v25H25zM25 125h25v25H25zM25 150h25v25H25zM25 175h25v25H25zM50 175h25v25H50z" />
          </g>
          <g fill="#fff" stroke="#000">
            <path fill="#000" d="M300 59l23 8-8 24-24-9z" />
            <path d="M291 82l24 9-9 23-23-8z" />
            <path d="M268 74l23 8-8 24-24-9z" />
            <path d="M244 65l24 9-9 23-23-8zM306 114l24 9-9 23-23-8z" />
            <path d="M283 106l23 8-8 24-24-9z" />
            <path d="M274 129l24 9-9 23-23-8z" />
            <path d="M266 153l23 8-8 24-24-9z" />
            <path d="M257 176l24 9-9 23-23-8z" />
            <path d="M234 168l23 8-8 24-24-9z" />
          </g>
        </svg>
      ) : null}
      {shape === "mr_18" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isMobileOnly ? "350" : "440"}
          height="240"
        >
          <g fill="#fff" stroke="#000" transform="translate(10)">
            <path fill="#000" d="M25 50h25v25H25z" />
            <path d="M25 75h25v25H25zM50 75h25v25H50zM75 75h25v25H75zM0 100h25v25H0zM25 100h25v25H25zM25 125h25v25H25zM25 150h25v25H25zM25 175h25v25H25zM50 175h25v25H50z" />
          </g>
          <g fill="#fff" stroke="#000" transform="translate(-20)">
            <path fill="#000" d="M320 76l19 16-16 19-19-16z" />
            <path d="M304 95l19 16-16 19-19-16z" />
            <path d="M323 111l20 16-17 19-19-16z" />
            <path d="M343 127l19 16-16 19-20-16zM269 98l19 16-16 19-19-16zM288 114l19 16-16 19-19-16z" />
            <path d="M272 133l19 16-16 19-19-16z" />
            <path d="M256 152l19 16-16 19-19-16z" />
            <path d="M240 171l19 16-16 20-19-17zM259 187l19 16-16 20-19-16z" />
          </g>
        </svg>
      ) : null}
      {shape === "mr_19" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isMobileOnly ? "350" : "440"}
          height="240"
        >
          <g fill="#fff" stroke="#000" transform="translate(10)">
            <path fill="#000" d="M25 50h25v25H25z" />
            <path d="M25 75h25v25H25zM50 75h25v25H50zM75 75h25v25H75zM0 100h25v25H0zM25 100h25v25H25zM25 125h25v25H25zM25 150h25v25H25zM25 175h25v25H25zM50 175h25v25H50z" />
          </g>
          <g fill="#fff" stroke="#000">
            <path fill="#000" d="M334 98l12 22-21 12-13-21z" />
            <path d="M312 111l13 21-22 13-13-22z" />
            <path d="M300 89l12 22-22 12-12-21z" />
            <path d="M287 68l13 21-22 13-13-22zM303 145l12 22-21 12-13-22z" />
            <path d="M290 123l13 22-22 12-12-21z" />
            <path d="M269 136l12 21-21 13-13-22z" />
            <path d="M247 148l13 22-22 12-13-21z" />
            <path d="M225 161l13 21-22 13-12-22z" />
            <path d="M213 139l12 22-21 12-13-21z" />
          </g>
        </svg>
      ) : null}
      {shape === "mr_20" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isMobileOnly ? "350" : "440"}
          height="240"
        >
          <g fill="#fff" stroke="#000" transform="translate(10)">
            <path fill="#000" d="M25 50h25v25H25z" />
            <path d="M25 75h25v25H25zM50 75h25v25H50zM75 75h25v25H75zM0 100h25v25H0zM25 100h25v25H25zM25 125h25v25H25zM25 150h25v25H25zM25 175h25v25H25zM50 175h25v25H50z" />
          </g>
          <g fill="#fff" stroke="#000">
            <path fill="#000" d="M339 124l4 25-25 4-4-24z" />
            <path d="M314 129l4 24-24 5-5-25zM318 153l5 25-25 4-4-24z" />
            <path d="M323 178l4 24-25 5-4-25zM285 108l4 25-24 4-5-24z" />
            <path d="M289 133l5 25-25 4-4-25z" />
            <path d="M265 137l4 25-25 4-4-24z" />
            <path d="M240 142l4 24-24 5-5-25z" />
            <path d="M215 146l5 25-25 4-4-25z" />
            <path d="M220 171l4 24-25 5-4-25z" />
          </g>
        </svg>
      ) : null}
      {shape === "mr_21" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isMobileOnly ? "350" : "440"}
          height="240"
        >
          <g fill="#fff" stroke="#000" transform="translate(10)">
            <path fill="#000" d="M25 50h25v25H25z" />
            <path d="M25 75h25v25H25zM50 75h25v25H50zM75 75h25v25H75zM0 100h25v25H0zM25 100h25v25H25zM25 125h25v25H25zM25 150h25v25H25zM25 175h25v25H25zM50 175h25v25H50z" />
          </g>
          <g fill="#fff" stroke="#000">
            <path fill="#000" d="M334 150l-4 25-25-4 5-25z" />
            <path d="M310 146l-5 25-24-5 4-24z" />
            <path d="M314 121l-4 25-25-4 4-25z" />
            <path d="M318 97l-4 24-25-4 5-25zM281 166l-5 25-24-4 4-25z" />
            <path d="M285 142l-4 24-25-4 4-25z" />
            <path d="M260 137l-4 25-25-4 5-25z" />
            <path d="M236 133l-5 25-24-5 4-24z" />
            <path d="M211 129l-4 24-25-4 4-25z" />
            <path d="M215 104l-4 25-25-5 5-24z" />
          </g>
        </svg>
      ) : null}
      {shape === "mr_22" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isMobileOnly ? "350" : "440"}
          height="240"
        >
          <g fill="#fff" stroke="#000" transform="translate(10)">
            <path fill="#000" d="M25 50h25v25H25z" />
            <path d="M25 75h25v25H25zM50 75h25v25H50zM75 75h25v25H75zM0 100h25v25H0zM25 100h25v25H25zM25 125h25v25H25zM25 150h25v25H25zM25 175h25v25H25zM50 175h25v25H50z" />
          </g>
          <g fill="#fff" stroke="#000">
            <path fill="#000" d="M321 173l-12 22-22-13 13-21z" />
            <path d="M300 161l-13 21-22-12 13-22z" />
            <path d="M287 182l-12 22-22-12 12-22z" />
            <path d="M275 204l-13 22-22-13 13-21zM290 127l-12 21-22-12 13-22z" />
            <path d="M278 148l-13 22-21-13 12-21z" />
            <path d="M256 136l-12 21-22-12 13-22z" />
            <path d="M235 123l-13 22-22-13 13-21z" />
            <path d="M213 111l-13 21-21-12 12-22z" />
            <path d="M200 132l-12 22-22-12 13-22z" />
          </g>
        </svg>
      ) : null}
      {shape === "mr_23" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isMobileOnly ? "350" : "440"}
          height="240"
        >
          <g fill="#fff" stroke="#000" transform="translate(10)">
            <path fill="#000" d="M25 50h25v25H25z" />
            <path d="M25 75h25v25H25zM50 75h25v25H50zM75 75h25v25H75zM0 100h25v25H0zM25 100h25v25H25zM25 125h25v25H25zM25 150h25v25H25zM25 175h25v25H25zM50 175h25v25H50z" />
          </g>
          <g fill="#fff" stroke="#000">
            <path fill="#000" d="M301 190l-19 17-16-20 19-16z" />
            <path d="M285 171l-19 16-16-19 19-16z" />
            <path d="M304 155l-19 16-16-19 19-16z" />
            <path d="M323 139l-19 16-16-19 19-16zM250 168l-19 16-16-19 19-16z" />
            <path d="M269 152l-19 16-16-19 19-16z" />
            <path d="M253 133l-19 16-16-19 19-16z" />
            <path d="M237 114l-19 16-16-19 19-16z" />
            <path d="M221 95l-19 16-16-19 19-16z" />
            <path d="M240 79l-19 16-16-19 19-16z" />
          </g>
        </svg>
      ) : null}
      {shape === "mr_24" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isMobileOnly ? "350" : "440"}
          height="240"
        >
          <g fill="#fff" stroke="#000" transform="translate(10)">
            <path fill="#000" d="M25 50h25v25H25z" />
            <path d="M25 75h25v25H25zM50 75h25v25H50zM75 75h25v25H75zM0 100h25v25H0zM25 100h25v25H25zM25 125h25v25H25zM25 150h25v25H25zM25 175h25v25H25zM50 175h25v25H50z" />
          </g>
          <g fill="#fff" stroke="#000">
            <path fill="#000" d="M276 200l-23 8-9-23 24-9z" />
            <path d="M268 176l-24 9-8-24 23-8z" />
            <path d="M244 185l-23 8-9-23 24-9z" />
            <path d="M221 193l-24 9-8-24 23-8zM283 144l-24 9-8-24 23-8z" />
            <path d="M259 153l-23 8-9-23 24-9z" />
            <path d="M251 129l-24 9-8-24 23-8z" />
            <path d="M242 106l-23 8-9-23 24-9z" />
            <path d="M234 82l-24 9-8-24 23-8z" />
            <path d="M210 91l-23 8-9-23 24-9z" />
          </g>
        </svg>
      ) : null}
      {shape === "mr_25" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isMobileOnly ? "350" : "440"}
          height="240"
        >
          <g fill="#fff" stroke="#000" transform="translate(10)">
            <path fill="#000" d="M25 50h25v25H25z" />
            <path d="M25 75h25v25H25zM50 75h25v25H50zM75 75h25v25H75zM25 100h25v25H25zM0 125h25v25H0zM25 125h25v25H25zM25 150h25v25H25zM25 175h25v25H25zM50 175h25v25H50z" />
          </g>
          <g fill="#fff" stroke="#000">
            <path fill="#000" d="M276 50l24 9-9 23-23-8z" />
            <path d="M268 74l23 8-8 24-24-9zM291 82l24 9-9 23-23-8z" />
            <path d="M315 91l23 8-8 24-24-9zM259 97l24 9-9 23-23-8zM227 112l24 9-9 23-23-8z" />
            <path d="M251 121l23 8-8 24-24-9z" />
            <path d="M242 144l24 9-9 23-23-8z" />
            <path d="M234 168l23 8-8 24-24-9zM257 176l24 9-9 23-23-8z" />
          </g>
        </svg>
      ) : null}
      {shape === "mr_26" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isMobileOnly ? "350" : "440"}
          height="240"
        >
          <g fill="#fff" stroke="#000" transform="translate(10)">
            <path fill="#000" d="M25 50h25v25H25z" />
            <path d="M25 75h25v25H25zM50 75h25v25H50zM75 75h25v25H75zM25 100h25v25H25zM0 125h25v25H0zM25 125h25v25H25zM25 150h25v25H25zM25 175h25v25H25zM50 175h25v25H50z" />
          </g>
          <g fill="#fff" stroke="#000">
            <path fill="#000" d="M301 60l19 16-16 19-19-16z" />
            <path d="M285 79l19 16-16 19-19-16z" />
            <path d="M266 63l19 16-16 19-19-16z" />
            <path d="M247 47l19 16-16 19-19-16zM269 98l19 16-16 19-19-16zM272 133l19 16-16 19-19-16z" />
            <path d="M253 117l19 16-16 19-19-16z" />
            <path d="M237 136l19 16-16 19-19-16z" />
            <path d="M221 155l19 16-16 19-19-16z" />
            <path d="M202 139l19 16-16 19-19-16z" />
          </g>
        </svg>
      ) : null}
      {shape === "mr_27" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isMobileOnly ? "350" : "440"}
          height="240"
        >
          <g fill="#fff" stroke="#000" transform="translate(10)">
            <path fill="#000" d="M25 50h25v25H25z" />
            <path d="M25 75h25v25H25zM50 75h25v25H50zM75 75h25v25H75zM25 100h25v25H25zM0 125h25v25H0zM25 125h25v25H25zM25 150h25v25H25zM25 175h25v25H25zM50 175h25v25H50z" />
          </g>
          <g fill="#fff" stroke="#000">
            <path fill="#000" d="M321 77l13 21-22 13-12-22z" />
            <path d="M300 89l12 22-22 12-12-21z" />
            <path d="M312 111l13 21-22 13-13-22zM325 132l12 22-22 13-12-22zM278 102l12 21-21 13-13-22zM244 93l12 21-21 13-13-22z" />
            <path d="M256 114l13 22-22 12-12-21z" />
            <path d="M235 127l12 21-22 13-12-22z" />
            <path d="M213 139l12 22-21 12-13-21z" />
            <path d="M225 161l13 21-22 13-12-22z" />
          </g>
        </svg>
      ) : null}
      {shape === "mr_28" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isMobileOnly ? "350" : "440"}
          height="240"
        >
          <g fill="#fff" stroke="#000" transform="translate(10)">
            <path fill="#000" d="M25 50h25v25H25z" />
            <path d="M25 75h25v25H25zM50 75h25v25H50zM75 75h25v25H75zM25 100h25v25H25zM0 125h25v25H0zM25 125h25v25H25zM25 150h25v25H25zM25 175h25v25H25zM50 175h25v25H50z" />
          </g>
          <g fill="#fff" stroke="#000">
            <path fill="#000" d="M334 100l5 24-25 5-4-25z" />
            <path d="M310 104l4 25-25 4-4-25z" />
            <path d="M305 79l5 25-25 4-4-24z" />
            <path d="M301 55l4 24-24 5-5-25zM285 108l4 25-24 4-5-24zM265 137l4 25-25 4-4-24z" />
            <path d="M260 113l5 24-25 5-4-25z" />
            <path d="M236 117l4 25-25 4-4-25z" />
            <path d="M211 121l4 25-24 4-5-24z" />
            <path d="M207 97l4 24-25 5-4-25z" />
          </g>
        </svg>
      ) : null}
      {shape === "mr_29" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isMobileOnly ? "350" : "440"}
          height="240"
        >
          <g fill="#fff" stroke="#000" transform="translate(10)">
            <path fill="#000" d="M25 50h25v25H25z" />
            <path d="M25 75h25v25H25zM50 75h25v25H50zM75 75h25v25H75zM25 100h25v25H25zM0 125h25v25H0zM25 125h25v25H25zM25 150h25v25H25zM25 175h25v25H25zM50 175h25v25H50z" />
          </g>
          <g fill="#fff" stroke="#000">
            <path fill="#000" d="M339 126l-5 24-24-4 4-25z" />
            <path d="M314 121l-4 25-25-4 4-25z" />
            <path d="M310 146l-5 25-24-5 4-24z" />
            <path d="M305 171l-4 24-25-4 5-25zM289 117l-4 25-25-5 5-24zM269 88l-4 25-25-5 4-24z" />
            <path d="M265 113l-5 24-24-4 4-25z" />
            <path d="M240 108l-4 25-25-4 4-25z" />
            <path d="M215 104l-4 25-25-5 5-24z" />
            <path d="M211 129l-4 24-25-4 4-25z" />
          </g>
        </svg>
      ) : null}
      {shape === "mr_30" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isMobileOnly ? "350" : "440"}
          height="240"
        >
          <g fill="#fff" stroke="#000" transform="translate(10)">
            <path fill="#000" d="M25 50h25v25H25z" />
            <path d="M25 75h25v25H25zM50 75h25v25H50zM75 75h25v25H75zM25 100h25v25H25zM0 125h25v25H0zM25 125h25v25H25zM25 150h25v25H25zM25 175h25v25H25zM50 175h25v25H50z" />
          </g>
          <g fill="#fff" stroke="#000">
            <path fill="#000" d="M334 152l-13 21-21-12 12-22z" />
            <path d="M312 139l-12 22-22-13 12-21z" />
            <path d="M325 118l-13 21-22-12 13-22z" />
            <path d="M337 96l-12 22-22-13 12-22zM290 127l-12 21-22-12 13-22zM256 136l-12 21-22-12 13-22z" />
            <path d="M269 114l-13 22-21-13 12-21z" />
            <path d="M247 102l-12 21-22-12 12-22z" />
            <path d="M225 89l-12 22-22-13 13-21z" />
            <path d="M238 68l-13 21-21-12 12-22z" />
          </g>
        </svg>
      ) : null}
      {shape === "mr_31" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isMobileOnly ? "350" : "440"}
          height="240"
        >
          <g fill="#fff" stroke="#000" transform="translate(10)">
            <path fill="#000" d="M25 50h25v25H25z" />
            <path d="M25 75h25v25H25zM50 75h25v25H50zM75 75h25v25H75zM25 100h25v25H25zM0 125h25v25H0zM25 125h25v25H25zM25 150h25v25H25zM25 175h25v25H25zM50 175h25v25H50z" />
          </g>
          <g fill="#fff" stroke="#000">
            <path fill="#000" d="M320 174l-19 16-16-19 19-16z" />
            <path d="M304 155l-19 16-16-19 19-16z" />
            <path d="M285 171l-19 16-16-19 19-16z" />
            <path d="M266 187l-19 16-16-19 19-16zM288 136l-19 16-16-19 19-16zM291 101l-19 16-16-19 19-16z" />
            <path d="M272 117l-19 16-16-19 19-16z" />
            <path d="M256 98l-19 16-16-19 19-16z" />
            <path d="M240 79l-19 16-16-19 19-16z" />
            <path d="M221 95l-19 16-16-19 19-16z" />
          </g>
        </svg>
      ) : null}
      {shape === "mr_32" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isMobileOnly ? "350" : "440"}
          height="240"
        >
          <g fill="#fff" stroke="#000" transform="translate(10)">
            <path fill="#000" d="M25 50h25v25H25z" />
            <path d="M25 75h25v25H25zM50 75h25v25H50zM75 75h25v25H75zM25 100h25v25H25zM0 125h25v25H0zM25 125h25v25H25zM25 150h25v25H25zM25 175h25v25H25zM50 175h25v25H50z" />
          </g>
          <g fill="#fff" stroke="#000">
            <path fill="#000" d="M300 191l-24 9-8-24 23-8z" />
            <path d="M291 168l-23 8-9-23 24-9z" />
            <path d="M315 159l-24 9-8-24 23-8z" />
            <path d="M338 151l-23 8-9-23 24-9zM283 144l-24 9-8-24 23-8zM251 129l-24 9-8-24 23-8z" />
            <path d="M274 121l-23 8-9-23 24-9z" />
            <path d="M266 97l-24 9-8-24 23-8z" />
            <path d="M257 74l-23 8-9-23 24-9z" />
            <path d="M281 65l-24 9-8-24 23-8z" />
          </g>
        </svg>
      ) : null}
    </span>
  );
};

export default Shape;
