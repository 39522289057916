import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import Avatar from "./Avatar";
import { FaRegPaperPlane } from "react-icons/fa";

interface MessagesConversationInterface {
  contacts: any;
  currentContact: string;
  handleSetSim3Answer(answerNum: number, answerVal: number): void;
  handleAddMessage(contactName: string, messageInput: object): void;
  handleUpdateSalesSummaryText(text: string): void;
  handleSendSalesSummary(
    salesSummaryInput: string,
    currentContact: string
  ): void;
  disableSendButton: boolean;
}

const MessagesConversation = ({
  contacts,
  currentContact,
  handleSetSim3Answer,
  handleSendSalesSummary,
  handleAddMessage,
  handleUpdateSalesSummaryText,
  disableSendButton
}: MessagesConversationInterface) => {
  const currentContactData = contacts.find(
    (contact: any) => contact.name === currentContact
  );

  const [messageEntries, setMessageEntries] = useState(
    currentContactData.conversation
  );
  const [userInput, setUserInput] = useState("");

  useEffect(() => {
    setMessageEntries(currentContactData.conversation);
  }, [currentContact, currentContactData.conversation]);

  // clear the input when switching contact
  useEffect(() => {
    setUserInput("");
  }, [currentContact]);

  // always scroll to bottom when new message is added to the chat conversation window
  useEffect(() => {
    const chatWindow: HTMLElement | null =
      document.getElementById("contact-chat");
    if (chatWindow) {
      chatWindow.scrollTop = chatWindow.scrollHeight;
    }
  }, [messageEntries.length]);

  const handleUserInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput(e.target.value);
    handleUpdateSalesSummaryText(e.target.value);
  };

  const handleAutoReply = () => {
    const newMessageInput: { [k: string]: number } = {};
    newMessageInput[
      "Can you also copy the Sales Summary text from the report and send it to Dominic Gonzalez?"
    ] = 1;
    handleAddMessage(currentContact, newMessageInput);
  };

  const handleMessageSubmit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    // the newMessageInput is an object with a key-value pair. key is the user input.
    // value 0 = message from the user. value 1 = message from the contact
    // value is used to determine the styling rules
    const newMessageInput: { [k: string]: number } = {};
    newMessageInput[userInput] = 0;
    handleAddMessage(currentContact, newMessageInput);
    // handle the contact's reply to the user's answer input
    if (
      currentContact === "Asako Satoshi" &&
      userInput === "www.acme-intranet.com/sales-report"
    ) {
      handleSetSim3Answer(12, 1);
      setTimeout(handleAutoReply, 500);
    }

    if (currentContact === "Dominic Gonzalez") {
      handleSendSalesSummary(userInput, currentContact);
    }

    setUserInput("");
  };

  const conversation = messageEntries.map(
    (messageEntry: { [k: string]: number }, i: number) => {
      const isMessageFromUser =
        messageEntry[Object.keys(messageEntry)[0]] === 0;
      return (
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ marginTop: "10px" }}
          key={i}
        >
          <div
            style={{
              display: isMessageFromUser ? "block" : "flex",
              float: isMessageFromUser ? "right" : "none"
            }}
          >
            {!isMessageFromUser && (
              <Avatar
                name={currentContact}
                backgroundColor={currentContactData.avatarColor}
              />
            )}
            <div
              style={{
                backgroundColor: isMessageFromUser ? "#d9def4" : "white",
                fontSize: "14px",
                padding: "10px 6px",
                lineHeight: "20px",
                maxWidth: "300px"
              }}
            >
              {Object.keys(messageEntry)[0]}
            </div>
          </div>
        </Col>
      );
    }
  );

  return (
    <Col xl={12} lg={12} md={12} sm={12} xs={12} style={{ height: "100%" }}>
      <Row
        style={{
          height: "45px",
          fontSize: "16px",
          padding: "0px 10px",
          borderBottom: "1px solid #ddddde"
        }}
      >
        <button
          style={{
            display: "flex",
            height: "100%"
          }}
        >
          <Avatar
            name={currentContact}
            backgroundColor={currentContactData.avatarColor}
          />
          <div style={{ lineHeight: "40px" }}>{currentContact}</div>
        </button>
      </Row>
      <Row id="contact-chat" style={{ overflowY: "scroll", height: "220px" }}>
        {conversation}
      </Row>
      <Row style={{ position: "absolute", bottom: "0px", width: "100%" }}>
        <Form style={{ width: "100%", display: "flex" }}>
          <Form.Control
            type="text"
            placeholder="Type a new message"
            value={userInput}
            onChange={handleUserInput}
            style={{
              display: "inline",
              padding: "20px",
              margin: "10px 0px 10px 10px"
            }}
          />
          <Button
            type="submit"
            onClick={handleMessageSubmit}
            disabled={!userInput || disableSendButton}
            style={{ backgroundColor: "inherit" }}
          >
            <FaRegPaperPlane color="#53565a" />
          </Button>
        </Form>
      </Row>
    </Col>
  );
};

export default MessagesConversation;
