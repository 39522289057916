import React, { useContext } from "react";
import Countdown from "../../Countdown";
import TaskComplete from "../../TaskComplete";
import { TestEventContext } from "../../../../../Contexts/TestEventContext";
import Instructions from "../../Instructions/Instructions";
import FinalInstructions from "../../Instructions/FinalInstructions";
import IncorrectAnswers from "../../Instructions/IncorrectAnswers";
import VerbalMemoryTestDisplay from "./VerbalMemoryTestDisplay";
import SpatialMemoryTestDisplay from "./SpatialMemoryTestDisplay";
import TasksWrapper from "../TasksWrapper";

interface Props {
  toggleProgressBar(): void;
  proceedToNextTask(): void;
  toggleIsIncorrectDisplay(): void;
  taskId: number;
  language: string;
  isMobile: boolean;
  isMobileOnly: boolean;
  isTablet: boolean;
  isIPad13: boolean;
}

const MemoryTask = ({
  toggleProgressBar,
  proceedToNextTask,
  toggleIsIncorrectDisplay,
  taskId,
  language,
  isMobile,
  isMobileOnly,
  isTablet,
  isIPad13
}: Props) => {
  // define the context
  const contextObject = useContext(TestEventContext);

  // define practiceArrays
  const practiceArray = [1, 3, 2, 3, 4, 4, 2, 4];

  return (
    <TasksWrapper
      practiceArray={practiceArray}
      toggleProgressBar={toggleProgressBar}
      toggleIsIncorrectDisplay={toggleIsIncorrectDisplay}
      context={contextObject}
      taskId={taskId}
    >
      {({
        isPractice,
        showIncorrectAnswerDisplay,
        practiceCounter,
        showFinalInstructions,
        doShowCountdown,
        showFinishTask,
        updateStartTask,
        togglePractice,
        updatePracticeCounter,
        toggleIncorrectAnswerDisplay,
        showCountdown,
        startTask,
        finishTask,
        taskDataSaved,
        enableButton,
        context
      }) => (
        <React.Fragment>
          {!startTask &&
          !isPractice &&
          !showFinalInstructions &&
          !doShowCountdown &&
          !showFinishTask ? (
            <Instructions
              taskId={taskId}
              togglePractice={togglePractice}
              language={language}
              isMobile={isMobile}
              isMobileOnly={isMobileOnly}
              isIPad13={isIPad13}
            />
          ) : null}
          {!isPractice &&
          !startTask &&
          !doShowCountdown &&
          showFinalInstructions ? (
            <FinalInstructions
              taskId={taskId}
              showCountdown={showCountdown}
              language={language}
              isMobile={isMobile}
              isMobileOnly={isMobileOnly}
              isIPad13={isIPad13}
            />
          ) : null}
          {!startTask &&
          !isPractice &&
          !showFinalInstructions &&
          doShowCountdown ? (
            <Countdown updateStartTask={updateStartTask} language={language} />
          ) : null}
          {(isPractice || startTask) &&
          !showIncorrectAnswerDisplay &&
          !doShowCountdown &&
          taskId === 5 ? (
            <VerbalMemoryTestDisplay
              isPractice={isPractice}
              practiceCounter={practiceCounter}
              toggleIncorrectAnswerDisplay={toggleIncorrectAnswerDisplay}
              updatePracticeCounter={updatePracticeCounter}
              finishTask={finishTask}
              practiceArray={practiceArray}
              isMobile={isMobile}
              isTablet={isTablet}
              isIPad13={isIPad13}
            />
          ) : null}
          {(isPractice || startTask) &&
          !showIncorrectAnswerDisplay &&
          !doShowCountdown &&
          taskId === 6 ? (
            <SpatialMemoryTestDisplay
              isPractice={isPractice}
              practiceCounter={practiceCounter}
              toggleIncorrectAnswerDisplay={toggleIncorrectAnswerDisplay}
              updatePracticeCounter={updatePracticeCounter}
              finishTask={finishTask}
              practiceArray={practiceArray}
              isMobile={isMobile}
              isTablet={isTablet}
              isIPad13={isIPad13}
            />
          ) : null}
          {!startTask && !isPractice && showFinishTask ? (
            <TaskComplete
              task={String(taskId)}
              proceedToNextTask={proceedToNextTask}
              language={language}
              testId={context.testIdsArray[context.testIndex]}
              taskDataSaved={taskDataSaved}
              enableButton={enableButton}
            />
          ) : null}
          {isPractice && showIncorrectAnswerDisplay ? (
            <IncorrectAnswers
              taskId={taskId}
              toggleIncorrectAnswerDisplay={toggleIncorrectAnswerDisplay}
              language={language}
              isMobile={isMobile}
              isMobileOnly={isMobileOnly}
              isIPad13={isIPad13}
            />
          ) : null}
        </React.Fragment>
      )}
    </TasksWrapper>
  );
};

export default MemoryTask;
