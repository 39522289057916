import React, { Component } from "react";
import { FaPlus } from "react-icons/fa";
import { Progress } from "react-sweet-progress";
import { TestEventConsumer } from "../Contexts/TestEventContext";
import { apiURL } from "../utils/constants";
import { Row, Col, Form } from "react-bootstrap";
import UploadedDocument from "./UploadedDocument";
import { getBase64FromFile } from "../utils/shared";

export default class Uploader extends Component {
  state = {
    percentComplete: 0,
    uploadStarted: false,
    uploadError: false,
    fileName: "",
    showCompleted: false,
    showRemove: false,
    uploadedDocuments: []
  };

  // define the context type so that we can use this.context
  static contextType = TestEventConsumer;

  handleChooseFile = e => {
    const file = e.target.files[0];
    const data = {
      testEventId: this.context.testEventId,
      fileName: file.name,
      resume: this.props.isResume,
      ...(!this.props.isResume && { documentId: this.props.documentId })
    };
    this.setState({ uploadStarted: true });
    getBase64FromFile(file)
      .then(base64File => {
        const base64withouMeta = base64File.split("base64,")[1];
        data.file = base64withouMeta;
      })
      .then(async () => {
        try {
          const response = await fetch(`${apiURL}/uploadCandidateFile`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: this.context.token
            },
            body: JSON.stringify(data)
          });
          const responseData = await response.json();
          if (responseData.s3StatusCode === 200) {
            const fileType = file.type.replace(/\s/g, "");
            const acceptedDocuments = [
              "application/pdf",
              "application/msword",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            ];

            if (
              (this.props.isResume && acceptedDocuments.includes(fileType)) ||
              !this.props.isResume
            ) {
              this.setState({ fileName: file.name });
              this.props.updateInvalidTypeError(false);
              this.props.clearValidationError();
            } else {
              this.props.updateInvalidTypeError(true);
            }

            if (this.props.isResume) {
              this.setState({
                showCompleted: true,
                percentComplete: 100,
                uploadError: false,
                showRemove: true,
                uploadedDocuments: [
                  ...this.state.uploadedDocuments,
                  this.state.fileName
                ]
              });
              this.props.receivedResume(true);
              this.context.updateResumeSubmitted(1);
            } else {
              const uploadedDocuments = [
                ...this.state.uploadedDocuments,
                this.state.fileName
              ];
              this.setState({
                showCompleted: true,
                percentComplete: 100,
                uploadError: false,
                showRemove: true,
                uploadedDocuments,
                uploadStarted: false
              });
              this.props.receivedOtherDocuments(true);
              this.context.updateOtherDocsSubmitted(
                this.props.documentId,
                true
              );
            }
          } else {
            this.setState({ uploadError: true, percentComplete: 100 });
          }
        } catch (error) {
          console.log("error in uploading file");
          this.setState({ uploadError: true, percentComplete: 100 });
        }
      });
  };

  removeDoc = async formData => {
    const response = await fetch(`${apiURL}/removeUploadedFile`, {
      method: "DELETE",
      body: JSON.stringify(formData)
    });
    await response.json();
    if (response.ok) {
      if (this.props.isResume) {
        this.context.updateResumeSubmitted(0);
        this.props.receivedResume(false);
      } else if (this.state.uploadedDocuments.length === 0) {
        this.context.updateOtherDocsSubmitted(this.props.documentId, false);
        this.props.receivedOtherDocuments(false);
      }
      this.setState({
        percentComplete: 0,
        uploadStarted: false,
        uploadError: false,
        fileName: "",
        showCompleted: false,
        showRemove: false
      });
    }
  };

  handleRemoveDoc = index => {
    const fileName = this.state.uploadedDocuments[index];
    const formData = {
      fileName: fileName,
      testTakerId: this.context.testEventData.testTaker.testTakerId,
      isResume: this.props.isResume ? 1 : 0
    };
    this.removeDoc(formData);
    const newDocumentsArray = this.state.uploadedDocuments.filter((doc, i) => {
      if (index !== i) {
        return doc;
      }
      return false;
    });
    this.setState({ uploadedDocuments: newDocumentsArray });
  };

  render() {
    const { documentId, name, required } = this.props;

    const uploadedDocuments = this.state.uploadedDocuments.map(
      (fileName, index) => (
        <UploadedDocument
          fileName={fileName}
          index={index}
          handleRemoveDoc={this.handleRemoveDoc}
          key={index}
        />
      )
    );

    const acceptTypes = this.props.isResume
      ? "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      : "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/jpeg,image/jpg,image/png,video/quicktime,audio/mpeg,video/mp4,video/mpeg,video/x-ms-wmv";

    return (
      <React.Fragment>
        {documentId ? (
          <p>
            <strong>
              {name}
              {required ? "*" : null}
            </strong>
          </p>
        ) : null}

        {uploadedDocuments}

        {!this.props.isResume ||
        (this.props.isResume && !this.state.showCompleted) ? (
          <div
            className="upload-resume-btn-wrapper"
            id="document-upload-button"
          >
            <div className="upload-button">
              <Row>
                <Col lg={1} md={1} sm={1} xs={1}>
                  <FaPlus aria-hidden="true" />
                </Col>
                <Col
                  lg={9}
                  md={9}
                  sm={8}
                  xs={8}
                  style={{ maxWidth: this.context.isMobile ? "60%" : null }}
                  className="text-left"
                >
                  <span id="select-file-button-text">
                    {`${this.context.testEventData.translatedText.selectFile}`}
                  </span>
                </Col>
              </Row>
              <Form.Control
                id="formControlsFile"
                type="file"
                onChange={this.handleChooseFile}
                accept={acceptTypes}
              />
            </div>
          </div>
        ) : null}
        {this.state.uploadStarted ? (
          <>
            <span aria-live="polite" className="sr-only">
              {(this.state.percentComplete && !this.state.uploadError) < 100
                ? "Uploading"
                : "Upload Complete"}
              {this.state.uploadError && "error in uploading file"}
            </span>

            <div aria-hidden="true">
              <Progress
                percent={this.state.percentComplete}
                status={this.state.uploadError ? "error" : "success"}
              />
            </div>
          </>
        ) : null}
      </React.Fragment>
    );
  }
}
