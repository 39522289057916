import React from "react";
import { ProgressBar } from "react-bootstrap";

interface Props {
  width: number;
  text?: string;
}

const ProgressBarComponent = ({ width, text }: Props) => {
  return <ProgressBar now={width} label={text} />;
};

export default ProgressBarComponent;
