// tslint:disable variable-name
import React from "react";

const J_MultiShapes = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80">
      <g stroke="#000">
        <path d="M41 16h14l5-14 4 14h14l-11 8 4 14-11-9-12 9 5-14-12-8zM42 42h36v36H42z" />
        <path fill="#3f3f3f" d="M2 39L20 3l18 36z" />
        <path fill="#bfbfbf" d="M2 78l17-36 18 36z" />
      </g>
    </svg>
  );
};

export default J_MultiShapes;
