import React from "react";
import { Row, Col } from "react-bootstrap";
import {
  FaDesktop,
  FaTrashAlt,
  FaRegFileAlt,
  FaRegFile,
  FaRegWindowMinimize,
  FaRegSquare,
  FaTimes,
  FaRegEnvelope
} from "react-icons/fa";
import { BiCommentDetail } from "react-icons/bi";

interface FileSystemWindowProps {
  titleText: string;
  children: React.ReactNode;
  minimized: boolean;
  maximized: boolean;
  handleToggleMinimize(windowName: string): void;
  handleToggleMaximize(windowName: string): void;
  handleCloseWindow(windowName: string): void;
}

const titleTextWindowNameMap: { [k: string]: string } = {
  "My Computer": "my_computer",
  Documents: "my_documents",
  Internet: "internet",
  Email: "outlook",
  "Recycle Bin": "recycle_bin",
  "letter.doc - Microsoft Word": "letterDoc",
  "report.doc - Microsoft Word": "reportDoc",
  "Untitled - Message": "composeEmail",
  Messages: "messages"
};

const getIcons = (titleText: string) => {
  const iconStyle = {
    marginRight: "3px",
    verticalAlign: "text-bottom"
  };
  switch (titleText) {
    case "My Computer":
      return <FaDesktop style={iconStyle} />;
    case "Documents":
      return <FaRegFileAlt style={iconStyle} />;
    case "Email":
      return <FaRegEnvelope style={iconStyle} />;
    case "Recycle Bin":
      return <FaTrashAlt style={iconStyle} />;
    case "letter.doc - Microsoft Word":
    case "report.doc - Microsoft Word":
      return <FaRegFile style={iconStyle} />;
    case "Untitled - Message":
      return <FaRegEnvelope style={iconStyle} />;
    case "Messages":
      return <BiCommentDetail style={iconStyle} />;
  }
};

const FileSystemWindow = ({
  titleText,
  children,
  minimized,
  maximized,
  handleToggleMinimize,
  handleToggleMaximize,
  handleCloseWindow
}: FileSystemWindowProps) => {
  const onHandleToggleMinimize = () => {
    handleToggleMinimize(titleTextWindowNameMap[titleText]);
  };
  const onHandleToggleMaximize = () => {
    handleToggleMaximize(titleTextWindowNameMap[titleText]);
  };
  const onHandleCloseWindow = () => {
    handleCloseWindow(titleTextWindowNameMap[titleText]);
  };

  return (
    <div
      style={{
        width: "100%",
        display: minimized ? "none" : "",
        height: maximized ? "100%" : ""
      }}
      className="clik-window"
    >
      <Row
        noGutters={true}
        className="pl-2"
        style={
          titleText === "Internet"
            ? { backgroundColor: "#ddddde" }
            : {
                backgroundColor: "#425cc7",
                height: "35px"
              }
        }
      >
        <Col
          xl={5}
          lg={5}
          md={5}
          sm={5}
          xs={5}
          style={{ color: "white", paddingTop: "5px" }}
        >
          {getIcons(titleText)}
          {"   "}
          {titleText}
        </Col>
        <Col xl={4} lg={4} md={4} sm={4} xs={4} />
        <Col
          xl={3}
          lg={3}
          md={3}
          sm={3}
          xs={3}
          style={{
            width: "100%",
            position: "relative",
            backgroundColor: titleText === "Internet" ? "#ddddde" : "#425cc7",
            height: "29px"
          }}
        >
          <button
            onClick={onHandleToggleMinimize}
            className="button-primary-text overlay-button"
            style={{
              right: "111px",
              top: "-5px",
              verticalAlign: "text-bottom"
            }}
          >
            <FaRegWindowMinimize
              style={{ fill: titleText === "Internet" ? "#53565a" : "white" }}
            />
          </button>
          <button
            onClick={onHandleToggleMaximize}
            className="button-primary-text overlay-button"
            style={{ right: "61px", top: "1px", verticalAlign: "text-bottom" }}
          >
            <FaRegSquare
              style={{ fill: titleText === "Internet" ? "#53565a" : "white" }}
            />
          </button>
          <button
            onClick={onHandleCloseWindow}
            className="button-primary-text overlay-button"
            style={{ right: "16px", top: "1px", verticalAlign: "text-bottom" }}
          >
            <FaTimes
              style={{ fill: titleText === "Internet" ? "#53565a" : "white" }}
            />
          </button>
        </Col>
      </Row>
      {children}
    </div>
  );
};

export default FileSystemWindow;
