import React, { Component } from "react";
import ReactDOM from "react-dom";

const modalRoot: HTMLElement | null = document.getElementById("modal-root");

export default class InterruptModal extends Component {
  modalElement = document.createElement("div");

  componentDidMount() {
    if (modalRoot !== null) {
      modalRoot.appendChild(this.modalElement);
    }
  }

  componentWillUnmount() {
    if (modalRoot !== null) {
      modalRoot.removeChild(this.modalElement);
    }
  }
  render() {
    return ReactDOM.createPortal(
      <div
        style={{
          position: "absolute",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          display: "grid",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.3)"
        }}
      >
        <div className="card">{this.props.children}</div>
      </div>,
      this.modalElement
    );
  }
}
