export function onBeginTutorial() {
  return {
    type: "onBeginTutorial"
  };
}

export function onCheckAnswer(isAnswerCorrect: boolean) {
  return {
    type: "onCheckAnswer",
    isAnswerCorrect
  };
}

export function onTimeExpired() {
  return {
    type: "onTimeExpired"
  };
}

export function onProgressNext() {
  return {
    type: "onProgressNext"
  };
}

export function onRedoTutorial() {
  return {
    type: "onRedoTutorial"
  };
}

export function onBeginAssessment() {
  return {
    type: "onBeginAssessment"
  };
}
