import Language from "../../Interfaces/Language";

export const de: Language = {
  common: {
    help: "Benötigen Sie Hilfe?",
    helpCenter: "Hilfebereich",
    expect: "Was Sie bei diesen Tests erwartet",
    faq: "Häufig gestellte Fragen",
    faqLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7853521-haufig-gestellte-fragen",
    copyright: "Copyright",
    copyrightNotices: "Copyright-Hinweise",
    copyrightNoticesLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994365-copyright-hinweis",
    terms: "Nutzungsbedingungen",
    termsLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994197-nutzungsbedingungen",
    privacy: "Datenschutzerklärung",
    privacyLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6990052-datenschutzrichtlinie",
    dmca: "DMCA-Richtlinie",
    dmcaLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7856931-politik-des-digital-millenium-copyright-act",
    accommodations: "Behinderung / Spezielle Vorkehrungen",
    accommodationsLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994432-behinderung-barrierefreiheit",
    technicalSupport: "Technischer Support",
    chatSupport:
      "Falls Sie Unterstützung benötigen, chatten Sie bitte mit unserem Team rechts unten auf dieser Seite"
  },
  instruction: {
    eppCopyright:
      "Diese Bewertung beinhaltet reproduziertes Material von SalesAP ©1995 und CSAP ©2002, Multi-Health Systems Inc. Alle Rechte vorbehalten.",
    salesApCopyright:
      "SalesAP ©1995, Multi-Health Systems Inc. All rights reserved.",
    csapCopyright: "CSAP ©2002, Multi-Health Systems Inc. All rights reserved.",
    wtmaCopyright:
      "WTMA Copyright © 1997, 1999 Psychological Assessment Resources, Inc."
  }
};
