import React, { FunctionComponent } from "react";
import useResume from "../../CustomHooks/useResume";
import { EventDataWithTestProps } from "../../Interfaces/EventData";
import MultipleChoiceTest from "../../Tests/TestDisplays/MultipleChoice/MultipleChoiceTest";
import { getMultipleChoiceStartingQuestion } from "../../utils/redisHelpers";
import Loader from "../utilty/loader/Loader";
import NetworkError from "../utilty/error/NetworkError";

const MultipleChoice: FunctionComponent<
  EventDataWithTestProps & {
    resumeMethod: ReturnType<typeof getMultipleChoiceStartingQuestion>;
  }
> = ({ resumeMethod: method, ...props }) => {
  const { data, error } = useResume({ method });

  if (error) {
    return <NetworkError />;
  }

  if (!data) {
    return <Loader />;
  }

  return (
    <MultipleChoiceTest
      timeRemaining={props.timeAllowed - Number(data.expiredTime)}
      startingQuestion={data.questionNumber}
      {...props}
    />
  );
};

export default MultipleChoice;
