import { Test } from "./types";

/* ---------- Static Content for UI ---------- */

export const olympicsResultsBoxContent = [
  {
    linkTitle: "The History of the Moder...",
    linkUrl: "olympichistoryhub.com",
    description: `The first modern Olympics were held in <strong>1896</strong> in
            Athens, Greece. This historic event marked the revival of the
            Olympic Games, which had been dormant for centuries. The 1896
            Olympics featured 280 participants from 13 countries...`
  },
  {
    linkTitle: "1896 Athens: The Birth of...",
    linkUrl: "sportschronicles.org",
    description: `The inaugural modern Olympic Games took place in
            <strong>1896</strong> in Athens! Organized by the International
            Olympic Committee (IOC), these games celebrated the spirit of global
            competition and sportsmanship. The year 1896 is...`
  },
  {
    linkTitle: "When Were the First Mod...",
    linkUrl: "sportshistorytoday.com",
    description: `According to most sports historians, the first modern Olympic Games
            were held in <strong>1896</strong> in Athens, Greece. This pivotal
            event was organized by Pierre de Coubertin and marked the beginning
            of the Olympic Games as we know them...`
  },
  {
    linkTitle: "Exploring the 1896 Athen...",
    linkUrl: "globalolympicarchives.com",
    description: `In <strong>1896</strong>, Athens hosted the first modern Olympics,
            heralding a new era in international sports. With 43 events and
            athletes from 13 nations, the <strong>1896</strong> Games laid the
            foundation for the modern Olympic movement that continues to...`
  }
];

export const olympicsPeopleWhoAskBoxContent = [
  {
    title: "Who organized the first modern Olympics?",
    linkTitle: "Pierre de Coubertin: the Fo...",
    linkUrl: "olympichistoryhub.com",
    description: `Pierre de Coubertin, a French educator and historian, is credited
            with organizing the first modern Olympic Games in
            <strong>1896</strong>. His vision was to promote international peace
            and unity through sports.`
  },
  {
    title: "What countries were in the 1896 Olympics?",
    linkTitle: "Countries and Athletes of t...",
    linkUrl: "sportschronicles.org",
    description: `The <strong>1896</strong> Olympics in Athens saw participation from
            13 countries. Athletes from around the world competed in a variety
            of events, marking the beginning of a global sporting tradition.`
  },
  {
    title: "Which sports were in the first modern Olympics?",
    linkTitle: "Sports and Events of the 1...",
    linkUrl: "sportshistorytoday.com",
    description: `The first modern Olympic Games in <strong>1896</strong> featured 43 events in nine sports, including athletics, cycling, fencing, gymnastics, shooting, swimming, tennis, weightlifting, and wrestling.`
  },
  {
    title: "Why were the first modern Olympics held in Athens?",
    linkTitle: "The Significance of Athens...",
    linkUrl: "globalolympicarchives.com",
    description: `Athens was chosen to host the first modern Olympics in
            <strong>1896</strong> to honor the origins of the ancient Olympic
            Games, which were held in Olympia, Greece. This decision symbolized
            the connection between ancient and...`
  }
];

export const olympicsSearchResultLinkContent = [
  {
    source: "Wikipedia",
    sourceUrl: "https://en.wikipedia.org/wiki/1896_Summer_Olympics",
    linkTitle: "1896 Summer Olympics",
    description: `The 1896 Summer Olympics, officially known as the Games of the I
            Olympiad and commonly known as Athens <strong>1896</strong> was the
            first international Olympic Games held...`
  },
  {
    source: "Olympics",
    sourceUrl: "https://olympics.com/en/news/the-history-of-the-olympic-games",
    linkTitle: "The History of the Olympic Games",
    description: `The first Olympic Games of the modern era took place in Athens, in
            the country where the original Games took place in Antiquity,
            in April <strong>1896</strong>.`
  },
  {
    source: "Britannica",
    sourceUrl: "https://www.britannica.com/event/Athens-1896-Olympic-Games",
    linkTitle: "Athens 1896 Olympic Games | Venue, Events, & Winners",
    description: `The Athens 1896 Olympic Games were an athletic festival held in
            Athens that took place April 6-15, <strong>1896</strong>.`
  },
  {
    source: "Penn Museum",
    sourceUrl: "https://www.penn.museum/sites/olympics/olympicorigins.shtml",
    linkTitle: "The Games | The Real Story of the Ancient Olympic Games",
    description: `Although the ancient Games were staged in Olympia, Greece, from 776
            BC through 393 AD, it took 1503 years for the Olympics to be
            reborn in Athens in <strong>1896</strong>.`
  },
  {
    source: "National Endowment For The Humanities (.gov)",
    sourceUrl:
      "https://www.neh.gov/divisions/preservation/featured-project/the-first-modern-olympics",
    linkTitle: "The First Modern Olympics",
    description: `A unanimous vote decided that the games would return to its
            birthplace in Athens, Greece, in 1896. In the run-up to the event,
            one reporter from`
  }
];

export const lincolnPeopleWhoAskBoxContent = [
  {
    title: "Who was Abraham Lincoln and what did he do?",
    linkTitle: "Abraham Lincoln: The Man...",
    linkUrl: "whitehouse.gov",
    description: `Abraham Lincoln was born on <strong>February 12, 1809</strong>, in a one-room log cabin in Hardin County, Kentucky. His humble beginnings were marked by the hardships of frontier life. Growing up, he had limited access to...`
  },
  {
    title: "Why is Abraham Lincoln's birthday a national holiday?",
    linkTitle: "Lincoln's Birthday: History...",
    linkUrl: "timeanddate.com",
    description: `Lincoln's birthday is not a federal public holiday, but it is observed in several states as a day to honor the 16th President of the United States and his contributions to the nation. The day often includes educational...`
  },
  {
    title: "How is Abraham Lincoln's birthday celebrated?",
    linkTitle: "Celebrating Lincoln's Birtd...",
    linkUrl: "lincolnbirthday.org",
    description: `In states where Lincoln's birthday is recognized, celebrations can include reenactments, educational programs, and public readings of the Gettysburg Address. Schools often organize activities that highlight Lincoln's ac...`
  },
  {
    title: "Did Abraham Lincoln have any siblings?",
    linkTitle: "The Lincoln Family: Ancestr...",
    linkUrl: "encyclopedia.com",
    description: `Abraham Lincoln had an older sister, Sarah Lincoln Grigsby, and a younger brother, Thomas Lincoln Jr., who died in infancy. Sarah, who was born in 1807, played a significant role in Lincoln's early life, especially after t...`
  }
];

export const lincolnSearchResultLinkContent = [
  {
    source: "History",
    sourceUrl: "https://www.history.com/topics/us-presidents/abraham-lincoln",
    linkTitle: "Abraham Lincoln: Facts, Birthday & Assassination | HISTORY",
    description: `Learn about the life and legacy of Abraham Lincoln, the 16th president of the United States, who was born on February 12, 1809. Explore his achievements, challenge…`
  },
  {
    source: "Britannica",
    sourceUrl: "https://www.britannica.com/biography/Abraham-Lincoln",
    linkTitle: "Abraham Lincoln | Biography, Childhood, Quotes, Death, & Facts",
    description: `Abraham Lincoln (born February 12, 1809, near Hodgenville, Kentucky, U.S.—died April 15, 1865, Washington, D.C.) was the 16th president of the United States…`
  },
  {
    source: "The National Constitution Center",
    sourceUrl:
      "https://constitutioncenter.org/blog/how-abraham-lincoln-lost-his-birthday-holiday-2",
    linkTitle: "How Abraham Lincoln lost his birthday holiday",
    description: `Learn how Lincoln's birthday became a less popular holiday than Black Friday or Presidents' Day in most states. Find out the history and significance of …`
  },
  {
    source: "Biography",
    sourceUrl: "https://www.biography.com/political-figures/abraham-lincoln",
    linkTitle: "Abraham Lincoln: Biography, U.S. President",
    description: `Abraham Lincoln was the 16th president of the United States, serving from 1861 to 1865, and is regarded as one of America's greatest heroes...`
  },
  {
    source: "HistoryNet",
    sourceUrl: "https://www.historynet.com/abraham-lincoln",
    linkTitle:
      "Abraham Lincoln - Facts, Information and History on the Life of the 16th U.S. President",
    description: `Learn about the life and achievements of Abraham Lincoln, the 16th president of the United States who led the nation through the Civil War and abolished slavery. Find out...`
  }
];

/* ---------- Word Search Arrays ---------- */

export const olympicWords = [
  "olympic",
  "olimpik",
  "olmpic",
  "olmypic",
  "olymic",
  "olymipc",
  "olymipic",
  "olympik",
  "olypic",
  "olypmic",
  "olypmpic",
  "olumpic",
  "olimpic",
  "olympick",
  "olympicks",
  "olimpick",
  "olimpicks",
  "olympics",
  "olimpiks",
  "olmpics",
  "olmypics",
  "olymics",
  "olymipcs",
  "olymipics",
  "olympiks",
  "olypics",
  "olypmics",
  "olypmpics",
  "olumpics",
  "olimpics",
  "oilympics",
  "olyumpics",
  "oluympics",
  "alympics",
  "elympics",
  "ollympics",
  "allympics",
  "ellympics",
  "olllimpics",
  "allimpics",
  "ellimpics",
  "ulympics",
  "alimpics",
  "elimpics",
  "alympic",
  "alimpic",
  "elimpic",
  "elympic",
  "allympic",
  "ellympic",
  "ollympic",
  "allimpic",
  "olllimpic",
  "ellimpic",
  "oympics",
  "lmypics"
];

export const firstWords = [
  "began",
  "begenning",
  "beggining",
  "begin",
  "beginer",
  "beginers",
  "begining",
  "beginining",
  "beginn",
  "beginnig",
  "beginnin",
  "beginning",
  "beginnning",
  "begins",
  "begun",
  "bgin",
  "bgining",
  "bginning",
  "bginnning",
  "comenc",
  "comence",
  "comencing",
  "comens",
  "commen",
  "commenc",
  "commence",
  "commenced",
  "commencer",
  "commencers",
  "commences",
  "commencing",
  "commencement",
  "commencements",
  "comencement",
  "comencment",
  "comencements",
  "comenced",
  "debu",
  "debuat",
  "debuated",
  "debue",
  "debued",
  "debueting",
  "debut",
  "debuted",
  "debuting",
  "debuts",
  "earlier",
  "earliest",
  "earlist",
  "earliyest",
  "earlyest",
  "erlest",
  "erliest",
  "1",
  "1rst",
  "1st",
  "firs",
  "firsst",
  "first",
  "firsts",
  "firt",
  "firts",
  "frst",
  "frist",
  "fst",
  "one",
  "foremose",
  "foremost",
  "foremoust",
  "foremst",
  "foremster",
  "foremust",
  "formeost",
  "formost",
  "found",
  "foundded",
  "foundding",
  "founded",
  "founder",
  "founders",
  "founding",
  "foundings",
  "foundinng",
  "inaguart",
  "inagural",
  "inagurate",
  "inagurul",
  "inaugral",
  "inaugural",
  "inaugurate",
  "inaugurated",
  "inaugurating",
  "inauguration",
  "inaugurations",
  "inaugurl",
  "inital",
  "initate",
  "initial",
  "initialed",
  "initialization",
  "initializes",
  "initialling",
  "initials",
  "initiate",
  "initiates",
  "initiation",
  "initiations",
  "introduce",
  "introduced",
  "introductary",
  "introducter",
  "introducters",
  "introduction",
  "introductions",
  "introductor",
  "introductory",
  "introductry",
  "introduse",
  "maden",
  "maiden",
  "maidenest",
  "maidenly",
  "maidens",
  "maidin",
  "maidining",
  "maidinly",
  "mayden",
  "open",
  "opened",
  "opener",
  "openers",
  "opening",
  "openingly",
  "openings",
  "openned",
  "openning",
  "opennings",
  "opned",
  "opning",
  "origen",
  "origenal",
  "origenate",
  "origin",
  "original",
  "originale",
  "originals",
  "originate",
  "origination",
  "originations",
  "originator",
  "originators",
  "permier",
  "preimier",
  "premier",
  "premierd",
  "premiere",
  "premiered",
  "premieres",
  "premiering",
  "premierly",
  "premired",
  "primal",
  "primary",
  "primaryed",
  "primarying",
  "primarys",
  "prime",
  "primry",
  "starrt",
  "start",
  "started",
  "starter",
  "starters",
  "starting",
  "startinged",
  "startinges",
  "startingly",
  "startings",
  "starts",
  "strt",
  "strted",
  "strting",
  "genesis",
  "genasis",
  "gensis",
  "genesys",
  "genasys",
  "inception",
  "inseption",
  "unception",
  "onception",
  "conceive",
  "conception",
  "concieved",
  "concieve",
  "concieption",
  "launch",
  "launched",
  "lunch",
  "lunched",
  "lanched",
  "lanch",
  "launches",
  "launching",
  "unveil",
  "unveiling",
  "unveiled",
  "unviels",
  "unviel",
  "unveils",
  "unvieling",
  "unvieled",
  "dawn",
  "danw",
  "dawns",
  "danws",
  "dawned",
  "danwed"
];

export const abrahamPresidentWords = [
  "abaham",
  "aboham",
  "abraahm",
  "abraam",
  "abrabam",
  "abragam",
  "abrahak",
  "abrahal",
  "abraham",
  "abrahan",
  "abrahem",
  "abrahim",
  "abrahm",
  "abrahma",
  "abrahom",
  "abrahqm",
  "abrahsm",
  "abrahum",
  "abrahwm",
  "abrahzm",
  "abrajam",
  "abram",
  "abream",
  "abreham",
  "abrem",
  "abrhaam",
  "abrham",
  "abrhm",
  "abriham",
  "abrihan",
  "abroham",
  "abrohan",
  "abrsham",
  "abe",
  "abahams",
  "abohams",
  "abraahms",
  "abraams",
  "abrabams",
  "abragams",
  "abrahaks",
  "abrahals",
  "abrahams",
  "abrahans",
  "abrahems",
  "abrahims",
  "abrahms",
  "abrahmas",
  "abrahoms",
  "abrahqms",
  "abrahsms",
  "abrahums",
  "abrahwms",
  "abrahzms",
  "abrajams",
  "abrams",
  "abreams",
  "abrehams",
  "abrems",
  "abrhaams",
  "abrhams",
  "abrhms",
  "abrihams",
  "abrihans",
  "abrohams",
  "abrohans",
  "abrshams",
  "abes",
  "abrahamLincoln",
  "lresidant",
  "oresident",
  "preaident",
  "predisent",
  "presadent",
  "presdent",
  "presedint",
  "presidant",
  "presidemt",
  "president",
  "presidenr",
  "presidet",
  "presidetn",
  "presidint",
  "presidnet",
  "presidnt",
  "presodent",
  "pressdent",
  "pressident",
  "presudent",
  "prisident",
  "prrsident",
  "prsedent",
  "prwsident",
  "ptesident",
  "pres",
  "presidentabe",
  "presidentabraham",
  "presidentabriham"
];

export const lincolnWords = [
  "lincoln",
  "lincon",
  "licoln",
  "lincan",
  "linccoln",
  "linckon",
  "linclon",
  "lincloon",
  "lincnoln",
  "lincobn",
  "lincolln",
  "lincolon",
  "lincomn",
  "linconln",
  "linconm",
  "linculn",
  "linkoln",
  "linkon",
  "linocln",
  "linocn",
  "linoln",
  "linqln",
  "linkin",
  "linkcin",
  "lincons",
  "licolns",
  "lincans",
  "linccolns",
  "linckons",
  "linclons",
  "lincloons",
  "lincnolns",
  "lincobns",
  "lincollns",
  "lincolons",
  "lincomns",
  "linconlns",
  "linconms",
  "linculns",
  "linkolns",
  "linkons",
  "linoclns",
  "linocns",
  "linolns",
  "linqlns",
  "linkins",
  "linkcins",
  "lincolns"
];

export const birthdayWords = [
  "birtday",
  "berthday",
  "bierthday",
  "birday",
  "birfday",
  "birhday",
  "birhtday",
  "birtaay",
  "birtaday",
  "birtbay",
  "birtbday",
  "birtcay",
  "birtcday",
  "birtchday",
  "birtdayy",
  "birtdeay",
  "birtdey",
  "birtdhay",
  "birtfay",
  "birtfday",
  "birtgay",
  "birtgday",
  "birthady",
  "birthay",
  "birthbay",
  "birthbday",
  "birthcay",
  "birthcday",
  "birthda",
  "birthday",
  "birthdasy",
  "birthdayy",
  "birthdday",
  "birthdeay",
  "birthdey",
  "birthdqay",
  "birthdqy",
  "birthdsay",
  "birthdsy",
  "birthdway",
  "birthdwy",
  "birthdy",
  "birthdya",
  "birthfay",
  "birthfday",
  "birthgday",
  "birthhday",
  "birthjday",
  "birthjy",
  "birthkay",
  "birthkday",
  "birthky",
  "birthmay",
  "birthmday",
  "birthmy",
  "birthnay",
  "birthnday",
  "birthsay",
  "birthtay",
  "birthuday",
  "birthvay",
  "birthvday",
  "birthxay",
  "birthxday",
  "birthzay",
  "birthzday",
  "birtjday",
  "birtkay",
  "birtkday",
  "birtnday",
  "birtthday",
  "birtuday",
  "birtvay",
  "birtzay",
  "birtzday",
  "bithday",
  "borthday",
  "brithday",
  "brthday",
  "burthday",
  "birth day",
  "brith day",
  "bday",
  "dob",
  "db",
  "birth date",
  "brith date",
  "brithdate",
  "birthdate",
  "nameday",
  "name day",
  "namedy",
  "born",
  "birth",
  "brtih"
];

export const olympicsTest: Test = {
  testType: "olympics",
  resultsBoxContent: olympicsResultsBoxContent,
  peopleWhoAskBoxContent: olympicsPeopleWhoAskBoxContent,
  searchResultLinkContent: olympicsSearchResultLinkContent,
  searchWords: [olympicWords, firstWords]
};

export const lincolnTest: Test = {
  testType: "lincoln",
  peopleWhoAskBoxContent: lincolnPeopleWhoAskBoxContent,
  searchResultLinkContent: lincolnSearchResultLinkContent,
  searchWords: [lincolnWords, abrahamPresidentWords, birthdayWords]
};
