import Language from "../../Interfaces/Language";

export const pl: Language = {
  common: {
    help: "Need Help?",
    helpCenter: "Help Centre",
    helpCenterLink: "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/",
    expect: "What to expect on these tests",
    expectLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/collections/3396642-about-criteria-assessments",
    faq: "Frequently Asked Questions",
    faqLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7853737-czesto-zadawane-pytania",
    copyright: "Prawa autorskie",
    copyrightNotices: "Informacje o prawach autorskich",
    copyrightNoticesLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994395-informacje-o-prawach-autorskich",
    terms: "Warunki korzystania",
    termsLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994328-warunki-korzystania",
    privacy: "Oświadczenie o ochronie prywatności",
    privacyLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6990127-oswiadczenie-o-ochronie-prywatnosci",
    dmca: "DMCA Policy",
    dmcaLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7857172-polityka-dmca",
    accommodations: "Niepełnosprawność/Udogodnienia",
    accommodationsLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994464-niepelnosprawnosc-udogodnienia",
    technicalSupport: "Technical Support",
    chatSupport:
      "For support, please chat with our team on the bottom right hand corner of this page"
  },
  instruction: {
    eppCopyright:
      "Niniejsza ocena zawiera materiał zreprodukowany z SalesAP ©1995 i CSAP ©2002, Multi-Health Systems Inc. Wszelkie prawa zastrzeżone.",
    salesApCopyright:
      "SalesAP ©1995, Multi-Health Systems Inc. All rights reserved.",
    csapCopyright: "CSAP ©2002, Multi-Health Systems Inc. All rights reserved.",
    wtmaCopyright:
      "WTMA Copyright © 1997, 1999 Psychological Assessment Resources, Inc."
  },
  overview: {
    approx: "ok.",
    minutes: "min"
  }
};
