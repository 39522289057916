export const TestType = {
  MULTIPLE_CHOICE: "multipleChoiceTest",
  PERSONALITY: "personalityTest",
  GAME: "gameTest",
  TYPING: "typingTest",
  TEN_KEY: "tenKeyTest",
  MRAB_CAST: "mrabCastTest",
  CLIK: "CLIKTest",
  REVELIAN: "revelianSubTest"
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type TestType = (typeof TestType)[keyof typeof TestType];
