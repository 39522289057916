import React from "react";
import rightChevron from "../../Resources/images/circle-chevron-right.png";

interface ResultsBoxInterface {
  linkTitle: string;
  linkUrl: string;
  description: string;
  fade?: boolean;
}

const ResultsBox = ({
  description,
  linkTitle,
  linkUrl,
  fade
}: ResultsBoxInterface) => {
  return (
    <div
      className="d-flex flex-column linear-gradient"
      style={{
        flex: "0 0 255px",
        padding: "16px",
        background: "#FFFFFF",
        position: "relative",
        height: "226px",
        borderRadius: "8px",
        fontFamily: "Lato"
      }}
    >
      <div
        style={{
          fontSize: "14px",
          lineHeight: "20px"
        }}
        dangerouslySetInnerHTML={{ __html: description }}
      />

      <a
        href={linkUrl}
        target="_blank"
        rel="noreferrer"
        style={{
          fontSize: "18px",
          color: "#1E139C",
          textDecoration: "none",
          pointerEvents: "none"
        }}
      >
        {linkTitle}
      </a>
      <span
        style={{
          color: "#006225",
          fontSize: "14px",
          fontWeight: 500
        }}
      >
        {linkUrl}
      </span>
      {fade && (
        <>
          <div
            style={{
              position: "absolute",
              height: "226px",
              width: "100px",
              top: 0,
              right: 0,
              borderRadius: "0px 8px 8px 0px",
              background: "linear-gradient(to right, transparent, white)"
            }}
          />
          <div
            style={{
              width: "32px",
              height: "32px",
              borderRadius: "50px",
              backgroundColor: "white",
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              right: 0,
              top: "50%",
              transform: "translateY(-50%)"
            }}
          >
            <img src={rightChevron} alt="right-chevron" />
          </div>
        </>
      )}
    </div>
  );
};

export default ResultsBox;
