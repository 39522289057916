/* eslint-disable react/jsx-pascal-case */
import React from "react";
import TaskOneShapesRow from "../../ImageComponents/TaskOneShapesRow";
import ColorDisplayRow from "../../ImageComponents/ColorDisplayRow";
import Key from "../../ImageComponents/Key";
import { instructionsObjectEnglish } from "../instructionsObject";
import { instructionsObjectSpanish } from "../instructionsObject";
import InstructionsWrapper from "../InstructionsWrapper";
import NavigateButtons from "../NavigateButtons";
import F_MultiShapes from "../../ImageComponents/F_MultiShapes";
import J_MultiShapes from "../../ImageComponents/J_MultiShapes";
import FingerPositions from "../../Images/finger_positions_FJ.jpeg";
import FingerPositionsMobile from "../../Images/finger_positions_FJ_mobile.png";

interface Props {
  togglePractice(): void;
  language: string;
  isMobile: boolean;
  isMobileOnly?: boolean;
  isIPad13: boolean;
}

const TaskOneInstructions = ({
  togglePractice,
  language,
  isMobile,
  isMobileOnly,
  isIPad13
}: Props) => {
  const instructionsObject =
    language === "english"
      ? instructionsObjectEnglish
      : instructionsObjectSpanish;

  const isMobileAndEnglish = (isMobile || isIPad13) && language === "english";

  return (
    <InstructionsWrapper>
      {({ instructionPage, handleContinue, handleBack }) => {
        const finalPage = 5;
        if (instructionPage > finalPage) {
          togglePractice();
        }
        return (
          <div>
            {instructionPage === 1 ? (
              <div>
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile mt-3"
                      : "instruction-text mt-3"
                  }
                >
                  {instructionsObject[1][instructionPage].text_1}
                </p>
                <div
                  style={{
                    marginTop: isMobile || isIPad13 ? "20px" : "50px",
                    marginBottom: "20px"
                  }}
                >
                  <TaskOneShapesRow isMobile={isMobile} isIPad13={isIPad13} />
                </div>
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile"
                      : "instruction-text"
                  }
                >
                  {instructionsObject[1][instructionPage].text_2}
                </p>
                <div
                  style={{ marginTop: isMobile || isIPad13 ? "20px" : "30px" }}
                >
                  <ColorDisplayRow
                    language={language}
                    isMobile={isMobile}
                    isIPad13={isIPad13}
                  />
                </div>
              </div>
            ) : null}
            {instructionPage === 2 ? (
              <div
                className={
                  isMobileOnly
                    ? "instruction-text-mobile mt-3"
                    : "instruction-text mt-3"
                }
              >
                {isMobileAndEnglish
                  ? instructionsObject[1][instructionPage].text_1.slice(0, -4)
                  : instructionsObject[1][instructionPage].text_1}
                <span>
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="F" />
                </span>
                <br />
                {isMobile || isIPad13 ? <br /> : null}
                {isMobileAndEnglish
                  ? instructionsObject[1][instructionPage].text_2.slice(0, -4)
                  : instructionsObject[1][instructionPage].text_2}
                <span>
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="J" />
                </span>
              </div>
            ) : null}
            {instructionPage === 3 ? (
              <div
                className={
                  isMobileOnly
                    ? "instruction-text-mobile mt-3"
                    : "instruction-text mt-3"
                }
              >
                {isMobileAndEnglish
                  ? instructionsObject[1][instructionPage].text_1.slice(0, -4)
                  : instructionsObject[1][instructionPage].text_1}
                <span>
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="F" />
                </span>
                {isMobileAndEnglish
                  ? instructionsObject[1][instructionPage].text_finish.slice(1)
                  : instructionsObject[1][instructionPage].text_finish}
                <br />
                <div className="text-center">
                  <F_MultiShapes />
                </div>
                {isMobile || isIPad13 ? <br /> : null}
                {isMobileAndEnglish
                  ? instructionsObject[1][instructionPage].text_2.slice(0, -4)
                  : instructionsObject[1][instructionPage].text_2}
                <span>
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="J" />
                </span>
                {isMobileAndEnglish
                  ? instructionsObject[1][instructionPage].text_finish.slice(1)
                  : instructionsObject[1][instructionPage].text_finish}
                <br />
                <div className="text-center">
                  <J_MultiShapes />
                </div>
              </div>
            ) : null}
            {instructionPage === 4 ? (
              <div
                className={
                  isMobileOnly
                    ? "instruction-text-mobile mt-3"
                    : "instruction-text mt-3"
                }
              >
                {isMobileAndEnglish
                  ? "You will see F and J buttons on the screen as shown below"
                  : instructionsObject[1][instructionPage].text_1}
                <div className="text-center mt-3">
                  {isMobile || isIPad13 ? (
                    <img
                      src={FingerPositionsMobile}
                      alt="letters F and J as buttons"
                      width="250px"
                      height="67px"
                    />
                  ) : (
                    <img src={FingerPositions} alt="finger positions" />
                  )}
                </div>
              </div>
            ) : null}
            {instructionPage === 5 ? (
              <div
                className={
                  isMobileOnly
                    ? "instruction-text-mobile mt-3"
                    : "instruction-text mt-3"
                }
              >
                <p>{instructionsObject[1][instructionPage].text_1}</p>
                <p>
                  {isMobileAndEnglish
                    ? instructionsObject[1][instructionPage].text_2
                    : "If you make a mistake, your screen will display a message."}
                </p>
                <p>
                  {instructionsObject[1][instructionPage].text_3}
                  <span>
                    <Key isMobile={isMobile} isIPad13={isIPad13} text="F" />
                  </span>
                </p>
                <p>
                  {instructionsObject[1][instructionPage].text_4}
                  <span>
                    <Key isMobile={isMobile} isIPad13={isIPad13} text="J" />
                  </span>
                </p>
              </div>
            ) : null}
            <NavigateButtons
              instructionPage={instructionPage}
              handleBack={handleBack}
              handleContinue={handleContinue}
              finalPage={finalPage}
              language={language}
            />
          </div>
        );
      }}
    </InstructionsWrapper>
  );
};

export default TaskOneInstructions;
