import React, { FunctionComponent } from "react";
import NetworkErrorIcon from "./icons/network-error";

import styles from "./NetworkError.module.css";

const NetworkError: FunctionComponent = (): JSX.Element => {
  return (
    <article className={styles.container}>
      <section className={styles.content}>
        <NetworkErrorIcon />
        <h1 className={styles["top-text"]}>Uh Oh!</h1>
        <p className={styles["bottom-text"]}>
          Unable to load due to network issues. Please check your internet
          connection and refresh the page in a few moments.
        </p>
        <p className={styles["bottom-text"]}>
          If the problem persists, please check our&nbsp;
          <a href="https://www.criteriacorp.com.au/candidates/candidate-faqs">
            Support FAQs
          </a>
          .
        </p>
      </section>
    </article>
  );
};

export default NetworkError;
