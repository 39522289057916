import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { FaEdit, FaAngleDown } from "react-icons/fa";
import { FiFilter } from "react-icons/fi";
import WindowHeader from "./WindowHeader";
import MessagesContactList from "./MessagesContactList";
import MessagesConversation from "./MessagesConversation";

interface MessagesContactInfoType {
  name: string;
  conversation: { [key: string]: number }[];
  avatarColor: string;
  boldMessage: boolean;
}

const MessagesContactInfo: MessagesContactInfoType[] = [
  {
    name: "Asako Satoshi",
    conversation: [
      { "Can you please send me the link to the Sales report here?": 1 }
    ],
    avatarColor: "#ff5c39",
    boldMessage: false
  },
  {
    name: "Lily Wang",
    conversation: [{ "OK just wanted to make sure that we…": 1 }],
    avatarColor: "#2dccd3",
    boldMessage: false
  },
  {
    name: "Madison Byers",
    conversation: [{ "Looks good to me. You can go ahead a...": 1 }],
    avatarColor: "#ffa300",
    boldMessage: false
  },
  {
    name: "Dominic Gonzalez",
    conversation: [
      { "Shouldn’t be a problem, we can reschedule for next week.": 1 }
    ],
    avatarColor: "#d50057",
    boldMessage: false
  },
  {
    name: "Simone Khan",
    conversation: [{ "I've received your email earlier...": 1 }],
    avatarColor: "#00a9e0",
    boldMessage: false
  }
];

interface MessagesInterface {
  minimized: boolean;
  maximized: boolean;
  handleToggleMinimize(windowName: string): void;
  handleToggleMaximize(windowName: string): void;
  handleCloseWindow(windowName: string): void;
  handleDesktopItemClick(windowName: string): void;
  handleSetSim3Answer(answerNum: number, answerVal: number): void;
  handleSendSalesSummary(
    salesSummaryInput: string,
    currentContact: string
  ): void;
  handleUpdateCurrentContact(contact: string): void;
  handleUpdateSalesSummaryText(text: string): void;
  zIndexValue: number;
  disableSendButton: boolean;
}

const Messages = ({
  minimized,
  maximized,
  handleToggleMinimize,
  handleToggleMaximize,
  handleCloseWindow,
  handleDesktopItemClick,
  handleSetSim3Answer,
  zIndexValue,
  handleSendSalesSummary,
  handleUpdateCurrentContact,
  handleUpdateSalesSummaryText,
  disableSendButton
}: MessagesInterface) => {
  const [currentContact, setCurrentContact] = useState("Asako Satoshi");
  const [messagesContact, setMessagesContact] = useState(MessagesContactInfo);

  const getContactIndex = (contactName: string) => {
    const contactIndex = messagesContact.findIndex(
      contactInfo => contactInfo.name === contactName
    );
    return contactIndex;
  };

  const handleContactClick = (contactName: string) => {
    // when click away, set the current contact's bold message status to false
    if (contactName !== currentContact) {
      const currentContactIndex = getContactIndex(currentContact);
      const messagesContactCopy = [...messagesContact];
      messagesContactCopy[currentContactIndex].boldMessage = false;
      setMessagesContact(messagesContactCopy);
    }
    if (contactName === "Asako Satoshi" || contactName === "Dominic Gonzalez") {
      setCurrentContact(contactName);
      handleUpdateCurrentContact(contactName);
    } else {
      return; // don't do anything if users click on irrelevant contacts
    }
  };

  const handleAddMessage = (
    contactName: string,
    messageInput: { [key: string]: number }
  ) => {
    const currentContactIndex = getContactIndex(contactName);
    const messagesContactCopy = [...messagesContact];
    messagesContactCopy[currentContactIndex].conversation.push(messageInput);
    // only bold the new message when the new message is from the contact
    if (messageInput[Object.keys(messageInput)[0]] === 1) {
      messagesContactCopy[currentContactIndex].boldMessage = true;
    } else {
      messagesContactCopy[currentContactIndex].boldMessage = false;
    }
    setMessagesContact(messagesContactCopy);
  };

  return (
    <Col
      onClick={() => handleDesktopItemClick("messages")}
      xl={maximized ? 12 : 7}
      lg={maximized ? 12 : 7}
      md={maximized ? 12 : 6}
      sm={maximized ? 12 : 6}
      xs={maximized ? 12 : 6}
      style={{
        position: "absolute",
        top: maximized ? "0" : "45px",
        left: maximized ? "0" : "250px",
        minWidth: "750px",
        width: "100%",
        height: maximized ? "100%" : "",
        display: minimized ? "none" : "",
        zIndex: zIndexValue,
        maxHeight: "350px"
      }}
    >
      <WindowHeader
        titleText="Messages"
        minimized={minimized}
        maximized={maximized}
        handleToggleMinimize={handleToggleMinimize}
        handleToggleMaximize={handleToggleMaximize}
        handleCloseWindow={handleCloseWindow}
      >
        <Row
          noGutters={true}
          style={{
            width: "100%",
            minHeight: "300px",
            height: "100%",
            backgroundColor: "white"
          }}
        >
          <Col xl={4} lg={4} md={4} sm={4} xs={4}>
            <Row
              style={{
                height: "45px",
                lineHeight: "45px",
                fontSize: "16px",
                padding: "0px 10px"
              }}
            >
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <button style={{ backgroundColor: "white" }}>
                  Recent
                  <FaAngleDown style={{ marginLeft: "5px" }} />
                </button>
              </Col>
              <Col
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={6}
                style={{ textAlign: "right" }}
              >
                <button style={{ backgroundColor: "white" }}>
                  <FiFilter color="#425cc7" />
                </button>
                <button style={{ backgroundColor: "white" }}>
                  <FaEdit color="#425cc7" />
                </button>
              </Col>
            </Row>
            <MessagesContactList
              contacts={messagesContact}
              currentContact={currentContact}
              handleContactClick={handleContactClick}
              maximized={maximized}
            />
          </Col>
          <Col
            xl={8}
            lg={8}
            md={8}
            sm={8}
            xs={8}
            style={{ backgroundColor: "#edeeee" }}
          >
            <MessagesConversation
              handleSetSim3Answer={handleSetSim3Answer}
              contacts={messagesContact}
              currentContact={currentContact}
              handleAddMessage={handleAddMessage}
              handleSendSalesSummary={handleSendSalesSummary}
              handleUpdateSalesSummaryText={handleUpdateSalesSummaryText}
              disableSendButton={disableSendButton}
            />
          </Col>
        </Row>
      </WindowHeader>
    </Col>
  );
};

export default Messages;
