import React from "react";

interface Props {
  leftImage: string;
  rightImage: string;
  isWeighStation: boolean;
  showImages: boolean;
}

const WordImages = ({
  leftImage,
  rightImage,
  isWeighStation,
  showImages
}: Props) => {
  return (
    <React.Fragment>
      <tr>
        <td
          id={isWeighStation ? "red-truck" : "top-feather"}
          className={isWeighStation ? "truck" : "feather"}
        >
          {showImages ? (
            <div
              className={
                isWeighStation
                  ? "truck-question red-question"
                  : "feather-question"
              }
              dangerouslySetInnerHTML={{ __html: leftImage }}
            />
          ) : null}
        </td>
      </tr>
      <tr>
        <td
          id={isWeighStation ? "blue-truck" : "bottom-feather"}
          className={isWeighStation ? "truck" : "feather"}
        >
          {showImages ? (
            <div
              className={
                isWeighStation
                  ? "truck-question blue-question"
                  : "feather-question"
              }
              dangerouslySetInnerHTML={{ __html: rightImage }}
            />
          ) : null}
        </td>
      </tr>
    </React.Fragment>
  );
};
export default WordImages;
