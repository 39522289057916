import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import ProgressBar from "../../../Components/ProgressBar";
import VigilanceTask from "./Tasks/VigilanceTask/VigilanceTask";
import BaseTask from "./Tasks/BaseTask/BaseTask";
import MemoryTask from "./Tasks/MemoryTask/MemoryTask";
import {
  isMobile,
  isTablet,
  isIPad13,
  isMobileOnly
} from "react-device-detect";

interface Props {
  subTestId: string;
  startingTaskNumber: number;
  endSubTest: () => void;
}

const MrabCast = ({ subTestId, endSubTest, startingTaskNumber }: Props) => {
  const [taskNumber, setTaskNumber] = useState(startingTaskNumber);
  const [showProgressBar, setShowProgressBar] = useState(true);
  const [isIncorrectDisplay, setIsIncorrectDisplay] = useState(false);

  useEffect(() => {
    localStorage.clear();
  }, []);

  const toggleProgressBar = () => {
    setShowProgressBar(!showProgressBar);
  };

  const toggleIsIncorrectDisplay = () => {
    setIsIncorrectDisplay(!isIncorrectDisplay);
  };

  const proceedToNextTask = () => {
    if (taskNumber === 9) {
      endSubTest();
    } else if ((subTestId === "13" || subTestId === "18") && taskNumber === 3) {
      localStorage.clear();
      setShowProgressBar(true);
      setTaskNumber(9);
    } else {
      localStorage.clear();
      setTaskNumber(taskNumber + 1);
      setShowProgressBar(true);
    }
  };

  const language =
    subTestId === "10" || subTestId === "13" ? "english" : "spanish";

  return (
    <div
      className="mrab-container"
      style={{ height: "100vh", touchAction: "manipulation" }}
    >
      {showProgressBar ? (
        <Row className="mb-3">
          <Col
            xl={{ span: 6, offset: 3 }}
            lg={{ span: 6, offset: 3 }}
            md={{ span: 8, offset: 2 }}
            sm={12}
          >
            {subTestId === "13" || subTestId === "18" ? (
              <ProgressBar
                width={(taskNumber / 4) * 100}
                text={
                  language === "english"
                    ? `Task ${taskNumber === 9 ? "4" : taskNumber} of 4`
                    : `Tarea ${taskNumber} of 4`
                }
              />
            ) : (
              <ProgressBar
                width={(taskNumber / 9) * 100}
                text={
                  language === "english"
                    ? `Task ${taskNumber} of 9`
                    : `Tarea ${taskNumber} of 9`
                }
              />
            )}
          </Col>
        </Row>
      ) : null}
      <Row
        className="test-display-container"
        style={{
          height: isMobile || isIPad13 ? "100%" : "",
          marginRight: "-16px",
          marginLeft: "-16px",
          marginTop: isMobile || isIPad13 ? "-16px" : ""
        }}
      >
        <Col
          xl={{ span: 6, offset: 3 }}
          lg={
            (isTablet || isIPad13) && !showProgressBar && !isIncorrectDisplay
              ? 12
              : { span: 6, offset: 3 }
          }
          md={
            (isTablet || isIPad13) && !showProgressBar && !isIncorrectDisplay
              ? 12
              : { span: 8, offset: 2 }
          }
          sm={12}
          xs={12}
        >
          {taskNumber === 1 ||
          taskNumber === 3 ||
          taskNumber === 4 ||
          taskNumber === 7 ||
          taskNumber === 8 ||
          taskNumber === 9 ? (
            <BaseTask
              toggleProgressBar={toggleProgressBar}
              proceedToNextTask={proceedToNextTask}
              taskId={taskNumber}
              subTestId={subTestId}
              language={language}
              toggleIsIncorrectDisplay={toggleIsIncorrectDisplay}
              isMobile={isMobile}
              isMobileOnly={isMobileOnly}
              isTablet={isTablet}
              isIPad13={isIPad13}
            />
          ) : null}
          {taskNumber === 2 ? (
            <VigilanceTask
              toggleProgressBar={toggleProgressBar}
              proceedToNextTask={proceedToNextTask}
              taskId={taskNumber}
              language={language}
              toggleIsIncorrectDisplay={toggleIsIncorrectDisplay}
              isMobile={isMobile}
              isMobileOnly={isMobileOnly}
              isTablet={isTablet}
              isIPad13={isIPad13}
            />
          ) : null}
          {taskNumber === 5 || taskNumber === 6 ? (
            <MemoryTask
              toggleProgressBar={toggleProgressBar}
              proceedToNextTask={proceedToNextTask}
              taskId={taskNumber}
              language={language}
              toggleIsIncorrectDisplay={toggleIsIncorrectDisplay}
              isMobile={isMobile}
              isMobileOnly={isMobileOnly}
              isTablet={isTablet}
              isIPad13={isIPad13}
            />
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export default MrabCast;
