import React from "react";
import { Button } from "react-bootstrap";
import { instructionsObjectEnglish } from "../instructionsObject";
import { instructionsObjectSpanish } from "../instructionsObject";
import Key from "../../ImageComponents/Key";
import { FaAngleRight } from "react-icons/fa";

interface Props {
  showCountdown(): void;
  language: string;
  isMobile: boolean;
  isMobileOnly?: boolean;
  isIPad13?: boolean;
}

const SpatialMemoryFinalInstructions = ({
  showCountdown,
  language,
  isMobile,
  isMobileOnly,
  isIPad13
}: Props) => {
  const instructionsObject =
    language === "english"
      ? instructionsObjectEnglish
      : instructionsObjectSpanish;

  const isMobileAndEnglish = (isMobile || isIPad13) && language === "english";

  return (
    <div>
      <p
        className={
          isMobileOnly
            ? "instruction-text-mobile mt-3"
            : "instruction-text mt-3"
        }
      >
        {instructionsObject[6].final.text_1}
      </p>
      <p
        className={
          isMobileOnly
            ? "instruction-text-mobile mt-3"
            : "instruction-text mt-3"
        }
      >
        {isMobileAndEnglish
          ? "Your screen will no longer display a message if you make a mistake."
          : instructionsObject[6].final.text_2}
      </p>
      <p
        className={
          isMobileOnly
            ? "instruction-text-mobile mt-3"
            : "instruction-text mt-3"
        }
      >
        {isMobileAndEnglish
          ? instructionsObject[6].final.text_3.slice(0, -1)
          : instructionsObject[6].final.text_3}
        <span>
          <Key isMobile={isMobile} isIPad13={isIPad13} text="F" />
        </span>
      </p>
      <p
        className={
          isMobileOnly ? "instruction-text-mobile" : "instruction-text"
        }
      >
        {isMobileAndEnglish
          ? instructionsObject[6].final.text_4.slice(0, -1)
          : instructionsObject[6].final.text_4}
        <span>
          <Key isMobile={isMobile} isIPad13={isIPad13} text="J" />
        </span>
      </p>
      <div className="text-center" style={{ marginTop: "15px" }}>
        <Button
          variant="primary"
          className="instructions-button"
          onClick={showCountdown}
        >
          {language === "english"
            ? "Click here to begin the test"
            : "Comenzar la evaluación"}
          <FaAngleRight
            style={{
              fontSize: "24px",
              verticalAlign: "middle",
              marginTop: "-5px"
            }}
          />
        </Button>
      </div>
    </div>
  );
};

export default SpatialMemoryFinalInstructions;
