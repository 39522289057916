import React, { useState } from "react";
import { Container, Row, Col, Card, Image } from "react-bootstrap";
import queryString from "query-string";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import resultsFoundImage from "../../Resources/images/results-found.png";
import PageContinueButton from "../../Components/PageContinueButton";
import { talentInsightsLink } from "../../utils/constants";

export const LinkResultsSuccess = (props: any) => {
  /****************************************************************************
   * State Variables                                                          *
   ****************************************************************************/
  const [userAccountId] = useState(
    queryString.parse(props.location.search).uid
  );
  const [setupAccount] = useState(
    queryString.parse(props.location.search).setup
  );

  /****************************************************************************
   * Handlers                                                                 *
   ****************************************************************************/
  const handleViewWorkplaceInsights = () => {
    window.location.href = `${talentInsightsLink}/my_summary/${userAccountId}`;
  };

  return (
    <Container>
      <Row>
        <Col
          xl={{
            span: 8,
            offset: 2
          }}
          lg={{
            span: 8,
            offset: 2
          }}
          md={{
            span: 10,
            offset: 1
          }}
          sm={12}
        >
          <Card className="oda-card">
            <Header backgroundColor="#FFF" />
            <Card.Body>
              <Row>
                <Col>
                  <div className="text-center mt-3 mb-5">
                    <Image
                      fluid={true}
                      src={resultsFoundImage}
                      alt="Insights"
                      height="160px"
                      width="160px"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div
                    className={`text-center link-results-header ${
                      setupAccount !== "0" ? "" : "mb-5"
                    }`}
                  >
                    Results Successfully Linked
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  {setupAccount !== "0" ? (
                    <p className="text-center mt-3 link-results-text">
                      Please check your email for a link to view your results in
                      Talent Insights.
                    </p>
                  ) : (
                    <div className="Mt-5">
                      <PageContinueButton
                        handler={handleViewWorkplaceInsights}
                        buttonText={"View Workplace Insights"}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Footer />
        </Col>
      </Row>
    </Container>
  );
};
