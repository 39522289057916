import React, { useEffect } from "react";
import { Alert, Button } from "react-bootstrap";
import { instructionsObjectEnglish } from "../instructionsObject";
import { instructionsObjectSpanish } from "../instructionsObject";
import Key from "../../ImageComponents/Key";
import { FaAngleRight } from "react-icons/fa";

interface Props {
  toggleIncorrectAnswerDisplay(): void;
  language: string;
  isMobile: boolean;
  isMobileOnly?: boolean;
  isIPad13?: boolean;
}

const TaskFourIncorrectDisplay = ({
  toggleIncorrectAnswerDisplay,
  language,
  isMobile,
  isMobileOnly,
  isIPad13
}: Props) => {
  useEffect(() => {
    if (isMobile || isIPad13) {
      window.addEventListener("click", handleClickEvent);
      return () => window.addEventListener("click", handleClickEvent);
    } else {
      window.addEventListener("keyup", handleKeyPress);
      return () => window.addEventListener("keyup", handleKeyPress);
    }
  });

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key.toLowerCase() === "f" || event.key.toLowerCase() === "j") {
      toggleIncorrectAnswerDisplay();
    }
  };

  const handleClickEvent = (event: any) => {
    if (
      event.currentTarget.value === "f" ||
      event.currentTarget.value === "j"
    ) {
      toggleIncorrectAnswerDisplay();
    }
  };

  const instructionsObject =
    language === "english"
      ? instructionsObjectEnglish
      : instructionsObjectSpanish;

  return (
    <div>
      <Alert variant="danger" className="mrab-incorrect-warning text-center">
        Incorrect.
      </Alert>
      <div
        className={
          isMobileOnly ? "instruction-text-mobile" : "instruction-text"
        }
      >
        <p>
          {instructionsObject[4].incorrect.text_1a}
          <Key isMobile={isMobile} isIPad13={isIPad13} text="F" />
          {instructionsObject[4].incorrect.text_1b}
        </p>
        <p>
          {instructionsObject[4].incorrect.text_2a}
          <Key isMobile={isMobile} isIPad13={isIPad13} text="J" />
          {instructionsObject[4].incorrect.text_2b}
        </p>
        <p>
          {(isMobile || isIPad13) && language === "english"
            ? "Press Continue to resume practice."
            : instructionsObject[4].incorrect.text_3}
        </p>
        <div className="first-instruction-button-row">
          <Button
            variant="primary"
            className="instructions-button"
            onClick={toggleIncorrectAnswerDisplay}
          >
            Continue
            <FaAngleRight
              style={{
                fontSize: "24px",
                verticalAlign: "middle",
                marginTop: "-5px"
              }}
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TaskFourIncorrectDisplay;
