import React from "react";
import TaskOneFinalInstructions from "./FinalInstructions/TaskOneFinalInstructions";
import VigilanceFinalInstructions from "./FinalInstructions/VigilanceFinalInstructions";
import TaskThreeFinalInstructions from "./FinalInstructions/TaskThreeFinalInstructions";
import TaskFourFinalInstructions from "./FinalInstructions/TaskFourFinalInstructions";
import VerbalMemoryFinalInstructions from "./FinalInstructions/VerbalMemoryFinalInstructions";
import SpatialMemoryFinalInstructions from "./FinalInstructions/SpatialMemoryFinalInstructions";
import TaskSevenFinalInstructions from "./FinalInstructions/TasKSevenFinalInstructions";
import TaskEightFinalInstructions from "./FinalInstructions/TaskEightFinalInstructions";
import TaskNineFinalInstructions from "./FinalInstructions/TaskNineFinalInstructions";

interface Props {
  taskId: number;
  language: string;
  showCountdown(): void;
  isMobile: boolean;
  isMobileOnly?: boolean;
  isIPad13: boolean;
}

const FinalInstructions = ({
  taskId,
  showCountdown,
  language,
  isMobile,
  isMobileOnly,
  isIPad13
}: Props) => {
  return (
    <div>
      {taskId === 1 ? (
        <TaskOneFinalInstructions
          showCountdown={showCountdown}
          language={language}
          isMobile={isMobile}
          isMobileOnly={isMobileOnly}
          isIPad13={isIPad13}
        />
      ) : null}
      {taskId === 2 ? (
        <VigilanceFinalInstructions
          showCountdown={showCountdown}
          language={language}
          isMobile={isMobile}
          isMobileOnly={isMobileOnly}
          isIPad13={isIPad13}
        />
      ) : null}
      {taskId === 3 ? (
        <TaskThreeFinalInstructions
          showCountdown={showCountdown}
          language={language}
          isMobile={isMobile}
          isMobileOnly={isMobileOnly}
          isIPad13={isIPad13}
        />
      ) : null}
      {taskId === 4 ? (
        <TaskFourFinalInstructions
          showCountdown={showCountdown}
          language={language}
          isMobile={isMobile}
          isMobileOnly={isMobileOnly}
          isIPad13={isIPad13}
        />
      ) : null}
      {taskId === 5 ? (
        <VerbalMemoryFinalInstructions
          showCountdown={showCountdown}
          language={language}
          isMobile={isMobile}
          isMobileOnly={isMobileOnly}
          isIPad13={isIPad13}
        />
      ) : null}
      {taskId === 6 ? (
        <SpatialMemoryFinalInstructions
          showCountdown={showCountdown}
          language={language}
          isMobile={isMobile}
          isMobileOnly={isMobileOnly}
          isIPad13={isIPad13}
        />
      ) : null}
      {taskId === 7 ? (
        <TaskSevenFinalInstructions
          showCountdown={showCountdown}
          language={language}
          isMobile={isMobile}
          isMobileOnly={isMobileOnly}
          isIPad13={isIPad13}
        />
      ) : null}
      {taskId === 8 ? (
        <TaskEightFinalInstructions
          showCountdown={showCountdown}
          language={language}
          isMobile={isMobile}
          isMobileOnly={isMobileOnly}
          isIPad13={isIPad13}
        />
      ) : null}
      {taskId === 9 ? (
        <TaskNineFinalInstructions
          showCountdown={showCountdown}
          language={language}
          isMobile={isMobile}
          isMobileOnly={isMobileOnly}
          isIPad13={isIPad13}
        />
      ) : null}
    </div>
  );
};

export default FinalInstructions;
