import React, { FunctionComponent, useContext, useState } from "react";
import { TestEventContext } from "../Contexts/TestEventContext";
import useGetData from "../CustomHooks/useGetData";
import useGetToken from "../CustomHooks/useGetToken";
import { EventDataWithTestProps } from "../Interfaces/EventData";
import TenKeyTests from "../Tests/TestDisplays/TenKeyTests";
import TypingTest from "../Tests/TestDisplays/TypingTest";
import {
  getCLIKStartingQuestion,
  getGameTestStartingQuestion,
  getMrabStartingTask,
  getMultipleChoiceStartingQuestion,
  getPersonalityTestStartingQuestion
} from "../utils/redisHelpers";
import { getAllTests } from "../utils/Reference/SubTestDataMaps";
import { TestType } from "../utils/Reference/TestType";
import { Clik, Game, MrabCast, MultipleChoice, Personality } from "./displays";
import NetworkError from "./utilty/error/NetworkError";
import Loader from "./utilty/loader/Loader";

const Direct: FunctionComponent = () => {
  const { subTestId, eventId, returnUrl } = useGetToken();
  const testLibrary = getAllTests();
  const testType = testLibrary[subTestId];
  const [isComplete, setIsComplete] = useState(false);
  const { data, isLoading, error } = useGetData({
    eventId,
    subTestId,
    testType
  });
  const testEventContext = useContext(TestEventContext);

  const endTestHandler = () => {
    setIsComplete(() => true);
    window.location.replace(returnUrl);
  };

  if (error) {
    return <NetworkError />;
  }

  if (isLoading || isComplete) {
    return <Loader />;
  }

  if (data) {
    testEventContext.testEventData = data;
    testEventContext.eventId = data.eventId;
    testEventContext.testEventId = data.testEventId;
    testEventContext.token = data.token;
    testEventContext.testIndex = 0;
    testEventContext.testIdsArray = [subTestId];

    return (
      <div style={{ padding: "1rem" }}>
        {determineAssessment(testType, {
          ...data,
          subTestId,
          dataLoaded: true,
          returnUrl,
          testData: data.tests[subTestId].details,
          timeFactor: data.timeFactor ?? 1,
          timeAllowed: data.tests[subTestId].details.timeAllowed,
          endSubTest: endTestHandler
        })}
      </div>
    );
  }

  return <NetworkError />;
};

function determineAssessment(
  testType: string,
  props: EventDataWithTestProps
): JSX.Element {
  switch (testType) {
    case TestType.CLIK:
      return (
        <Clik
          {...props}
          resumeMethod={getCLIKStartingQuestion(
            props.testEventId,
            props.subTestId,
            props.token
          )}
        />
      );
    case TestType.GAME:
      return (
        <Game
          {...props}
          resumeMethod={getGameTestStartingQuestion(
            props.testEventId,
            props.subTestId,
            props.token
          )}
        />
      );
    case TestType.MRAB_CAST:
      return (
        <MrabCast
          {...props}
          resumeMethod={getMrabStartingTask(
            props.testEventId,
            props.subTestId,
            props.token
          )}
        />
      );
    case TestType.MULTIPLE_CHOICE:
      return (
        <MultipleChoice
          {...props}
          resumeMethod={getMultipleChoiceStartingQuestion(
            props.testEventId,
            props.subTestId,
            props.token
          )}
        />
      );
    case TestType.PERSONALITY:
      return (
        <Personality
          {...props}
          resumeMethod={getPersonalityTestStartingQuestion(
            props.testEventId,
            props.subTestId,
            props.token
          )}
        />
      );
    case TestType.TEN_KEY:
      return <TenKeyTests {...props} />;
    case TestType.TYPING:
      return (
        <TypingTest
          {...props}
          testMakerTestId=""
          timeAllowed={60}
          title="Criteria Typing Test"
        />
      );
    default:
      return <NetworkError />;
  }
}

export default Direct;
