/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { Alert } from "react-bootstrap";
import ApplicationQuestions from "../Interfaces/ApplicationQuestions";
import ApplicationHeader from "./ApplicationComponents/ApplicationHeader";
import ApplicationInput from "./ApplicationComponents/ApplicationInput";
import ApplicationSelect from "./ApplicationComponents/ApplicationSelect";
import ApplicationCheckbox from "./ApplicationComponents/ApplicationCheckbox";
import ApplicationEssay from "./ApplicationComponents/ApplicationEssay";
import ApplicationInstructions from "./ApplicationComponents/ApplicationInstructions";
import { TestEventConsumer } from "../Contexts/TestEventContext";
interface Props {
  questions: any;
  eventHandlerObject: {
    inputHandler: (
      event:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement>
    ) => void;
    selectHandler: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    handleCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => void;
  };
  missingQuestions: string[];
}

export default class ApplicationForm extends Component<Props> {
  // define the context type so that we can use this.context throughout the class
  static contextType: any = TestEventConsumer;

  render() {
    const { questions, eventHandlerObject, missingQuestions } = this.props;
    let ErrorMessage;
    if (missingQuestions.length > 0) {
      ErrorMessage = questions.map((question: ApplicationQuestions) => {
        if (missingQuestions.includes(`q_${question.applicationQuestionId}`)) {
          return (
            <li key={question.applicationQuestionId}>
              {question.label.replace(":", "")}
            </li>
          );
        }
      });
    }

    // using the applicationQuestionType for each question, we determine what kind of question it is, and
    // from there, determine which component to display (either an input, checkbox, select, or textares),
    // and display it
    const ComponentToDisplay = questions.map(
      (question: ApplicationQuestions) => {
        const {
          label,
          applicationQuestionType,
          applicationSelectListId,
          selectList
        } = question;
        if (applicationQuestionType === "header") {
          return <ApplicationHeader name={label} key={question.id} />;
        }
        if (
          (applicationQuestionType === "text" ||
            applicationQuestionType === "numeric" ||
            applicationQuestionType === "telephone" ||
            (applicationQuestionType === "country" &&
              !applicationSelectListId) ||
            applicationQuestionType === "mmyyyy") &&
          !applicationSelectListId
        ) {
          return (
            <ApplicationInput
              question={question}
              key={question.id}
              onChangeHandler={eventHandlerObject.inputHandler}
              missingQuestions={missingQuestions}
            />
          );
        }
        if (
          (applicationQuestionType === "text" ||
            applicationQuestionType === "country") &&
          applicationSelectListId &&
          selectList &&
          selectList.checkall
        ) {
          return (
            <ApplicationCheckbox
              question={question}
              key={question.id}
              missingQuestions={missingQuestions}
              onChangeHandler={eventHandlerObject.handleCheckbox}
            />
          );
        }
        if (
          applicationQuestionType === "state" ||
          applicationQuestionType === "yn" ||
          applicationQuestionType === "tf" ||
          (applicationQuestionType === "telephone" &&
            applicationSelectListId &&
            selectList &&
            !selectList.checkall) ||
          (applicationQuestionType === "text" &&
            applicationSelectListId &&
            selectList &&
            !selectList.checkall) ||
          (applicationQuestionType === "country" && applicationSelectListId) ||
          (applicationQuestionType === "mmyyyy" && applicationSelectListId)
        ) {
          return (
            <ApplicationSelect
              question={question}
              key={question.id}
              onChangeHandler={eventHandlerObject.selectHandler}
              missingQuestions={missingQuestions}
            />
          );
        }
        if (applicationQuestionType === "instructions") {
          return (
            <ApplicationInstructions label={question.label} key={question.id} />
          );
        }
        if (applicationQuestionType === "br") {
          return (
            <div>
              <br />
              <hr />
              <br />
            </div>
          );
        }
        if (applicationQuestionType === "essay") {
          return (
            <ApplicationEssay
              question={question}
              key={question.id}
              onChangeHandler={eventHandlerObject.inputHandler}
              missingQuestions={missingQuestions}
            />
          );
        }
      }
    );
    return (
      <div>
        {missingQuestions.length > 0 ? (
          <Alert variant="danger" className="application-error-message">
            {this.context?.testEventData?.translatedText?.missingFields
              ? this.context.testEventData.translatedText.missingFields
              : "The following fields are required:"}
            <ul>{ErrorMessage}</ul>
          </Alert>
        ) : null}
        {ComponentToDisplay}
      </div>
    );
  }
}
