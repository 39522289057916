import React, { Component } from "react";
import ApplicationQuestions from "../../Interfaces/ApplicationQuestions";
import { Form } from "react-bootstrap";

interface Props {
  question: ApplicationQuestions;
  missingQuestions: string[];
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface SelectOption {
  id: number;
  value: string;
  order: number;
}

export default class ApplicationCheckbox extends Component<Props> {
  render() {
    const { missingQuestions, onChangeHandler } = this.props;
    const { applicationQuestionId, label, selectList } = this.props.question;
    // if the question was required, and the application was submitted without an answer, change the
    // background color as an indication.
    const backgroundColor = missingQuestions.includes(
      `q_${applicationQuestionId}`
    )
      ? "#F7CECD"
      : "";

    return (
      <Form.Group>
        <React.Fragment>
          <Form.Label
            style={{ backgroundColor }}
            htmlFor={`${applicationQuestionId}`}
          >
            {label}
          </Form.Label>
          <Form.Check type="checkbox" id={`q_${applicationQuestionId}`}>
            {selectList.options.map((option: SelectOption) => (
              <React.Fragment>
                <Form.Check
                  type="checkbox"
                  name={`q_${applicationQuestionId}`}
                  id={`q_${applicationQuestionId}`}
                  value={option.id}
                  onChange={onChangeHandler}
                  label={option.value}
                />
              </React.Fragment>
            ))}
          </Form.Check>
        </React.Fragment>
      </Form.Group>
    );
  }
}
