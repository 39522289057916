import { apiURL, sskowk } from "../utils/constants";
import Encryption from "../utils/Encryption";
import tutorial_robot_left from "./TestDisplays/GameTest/GameResources/tutorial_robot_left@2x.png";
import tutorial_robot_right from "./TestDisplays/GameTest/GameResources/tutorial_robot_right@2x.png";

export const determineNextGame = async (eventId: string) => {
  const response = await fetch(`${apiURL}/revelianTestStatus/${eventId}`);
  const data = await response.json();

  // if network requests fail on the client side
  if (!response) {
    throw new Error("Network request failed while determining next game");
  }

  // If the response is anything other than 200, then throw error
  if (response.status !== 200) {
    throw new Error(data.error);
  }

  return data;
};

export const fetchTestEventData = async (eventId: string) => {
  try {
    const response = await fetch(`${apiURL}/event/${eventId}`);
    const data = await response.json();

    // test data comes in as an encrypted string, so we need to decrypt the string, parse it and put in to state.
    const encryption = new Encryption();
    const decryptedData = data.data
      ? JSON.parse(encryption.decrypt(data.data, sskowk))
      : null;
    // even if the returned response does not include the `data` property
    // we still want to return the raw data and take the next action based on the returned fields
    const returnVal = decryptedData || data;
    returnVal.responseStatus = response.status;
    returnVal.hasDecryptedData = decryptedData ? true : false;
    return returnVal;
  } catch (error) {
    console.log("error: ", error);
    return;
  }
};

export const getTestIdsArray = (tests: any) => {
  const sortedArray: string[] = [];
  const testIdsArray: string[] = [];
  if (tests) {
    Object.keys(tests)
      .sort((a, b) => tests[a].testOrder - tests[b].testOrder)
      .map(subTestId => {
        sortedArray.push(subTestId);
        if (!tests[subTestId].completed) {
          testIdsArray.push(subTestId);
        }
        return true;
      });
  }

  return testIdsArray;
};

export const sendTestErrorData = async (
  testEventId: string,
  testEventData: any,
  context: any
) => {
  const testEventDataFactory = ({
    companyName,
    eventStarted,
    jobCode,
    testTaker
  }: any) => ({
    companyName,
    eventStarted,
    jobCode,
    testTaker
  });
  const testEventContextFactory = ({
    applicationIdsArray,
    completedSubTestIds,
    eventId,
    eventIdCompleted,
    expiredTime,
    invalidEventId,
    invalidJobCode,
    isMobile,
    isResumingTest,
    restartCounter,
    testIdsArray,
    testIndex,
    usedEventId,
    userDisqualified
  }: any) => ({
    applicationIdsArray,
    completedSubTestIds,
    eventId,
    eventIdCompleted,
    expiredTime,
    invalidEventId,
    invalidJobCode,
    isMobile,
    isResumingTest,
    restartCounter,
    testIdsArray,
    testIndex,
    usedEventId,
    userDisqualified
  });

  const eventData = testEventDataFactory(testEventData);
  const contextObject = testEventContextFactory(context);
  const testEventObject = { ...contextObject, ...eventData };

  const formData = {
    testEventId: testEventId,
    testEventObject: testEventObject
  };
  try {
    const response = await fetch(`${apiURL}/timeErrorEvent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(formData)
    });
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.log("error: ", error);
  }
};

export const getGameTutorialData = (testData: any) => {
  return [
    {
      gameTitle: testData.text.robotInspectorGame.gameTitle,
      exampleQuestion: {
        optionA: tutorial_robot_left,
        optionB: tutorial_robot_right
      },
      answerOptions: testData.text.robotInspectorGame.answerOptions,
      exampleAnswer: "left",
      numberOfQuestions: 40,
      timeAllowedPerQuestion: 6,
      task: testData.text.robotInspectorGame.taskOne,
      instructions: testData.text.robotInspectorGame.instructions,
      feedbackText: testData.text.robotInspectorGame.feedbackText
    },
    {
      gameTitle: testData.text.wordOfAFeatherGame.gameTitle,
      exampleQuestion: testData.text.wordOfAFeatherGame.exampleQuestion,
      answerOptions: testData.text.wordOfAFeatherGame.answerOptions,
      exampleAnswer: "right",
      numberOfQuestions: 40,
      timeAllowedPerQuestion: 6,
      task: testData.text.wordOfAFeatherGame.taskTwo,
      instructions: testData.text.wordOfAFeatherGame.instructions,
      feedbackText: testData.text.wordOfAFeatherGame.feedbackText
    },
    {
      gameTitle: testData.text.weightStationGame.gameTitle,
      exampleQuestion: {
        optionA: "7 + 2",
        optionB: "8 x 1"
      },
      answerOptions: testData.text.weightStationGame.answerOptions,
      exampleAnswer: "left",
      numberOfQuestions: 40,
      timeAllowedPerQuestion: 6,
      task: testData.text.weightStationGame.taskThree,
      instructions: testData.text.weightStationGame.instructions,
      feedbackText: testData.text.weightStationGame.feedbackText
    }
  ];
};
// ACCAT Practice Question Data

interface QuestionData {
  questionStem: string;
  answers: { [key: string]: string };
  correctAnswer: string;
  questionTimeLimit: number;
}

export const ACCATPracticeQuestionData: QuestionData[] = [
  {
    questionStem: "<p>Which of the following is the smallest value?</p>",
    answers: {
      A: "200",
      B: "2",
      C: "40",
      D: "0.10",
      E: "0.03"
    },
    correctAnswer: "E",
    questionTimeLimit: 40
  },
  {
    questionStem:
      "<p>Which of the following boxes should replace the question mark (?) to complete the pattern?</p>",
    answers: {
      A: "A",
      B: "B",
      C: "C",
      D: "D",
      E: "E"
    },
    correctAnswer: "A",
    questionTimeLimit: 40
  }
];

// ACCAT function that checks to see if the answer value options are the same as the answer key values or if they include 1 - 5
export const getAnsweredOptionsOrdered = (
  answerOptionsData: string[],
  answerOptionValues: string[]
) => {
  return answerOptionValues.every((answerItem: string) => {
    const leftRightColumnComparisonAnswers = ["1", "2", "3", "4", "5"];
    const trueFalseUncertainAnswers = ["True", "False", "Uncertain"];

    return (
      answerOptionsData.includes(answerItem) ||
      leftRightColumnComparisonAnswers.includes(answerItem) ||
      trueFalseUncertainAnswers.includes(answerItem)
    );
  });
};

export const getRevelianTestTitle = (subTestId: string) => {
  switch (subTestId) {
    case "110":
    case "121":
    case "122":
    case "123":
    case "138":
    case "139":
    case "140":
    case "141":
    case "143":
    case "144":
    case "145":
    case "146":
    case "150":
    case "151":
    case "153":
    case "154":
    case "158":
    case "159":
    case "160":
    case "161":
    case "162":
    case "163":
    case "164":
    case "166":
    case "167":
    case "168":
    case "169":
    case "170":
    case "173":
    case "174":
    case "183":
    case "184":
      // LAST LINE - DO NOT MOVE THIS COMMENT cognifyGetRevelianTestTitle
      return "Cognify";
    case "109":
    case "112":
    case "137":
      // LAST LINE - DO NOT MOVE THIS COMMENT emotifyGetRevelianTestTitle
      return "Emotional Intelligence Test";
    case "119":
    case "120":
      // LAST LINE - DO NOT MOVE THIS COMMENT safetyGetRevelianTestTitle
      return "Safety Assessment";
    case "132":
    case "136":
      // LAST LINE - DO NOT MOVE THIS COMMENT personalityGetRevelianTestTitle
      return "Personality Test";
    case "179":
    case "180":
      // LAST LINE - DO NOT MOVE THIS COMMENT excelGetRevelianTestTitle
      return "Excel 365";
    case "181":
      // LAST LINE - DO NOT MOVE THIS COMMENT wordGetRevelianTestTitle
      return "Word 365";
    case "182":
      // LAST LINE - DO NOT MOVE THIS COMMENT powerpointGetRevelianTestTitle
      return "PowerPoint 365";
    case "188":
    case "189":
      return "Cognitive Ability Test";
    case "190":
    case "191":
    case "192":
    case "193":
    case "194":
    case "195":
    case "196":
    case "197":
    case "198":
    case "199":
    case "200":
    case "201":
    case "202":
    case "203":
    case "204":
    case "205":
    case "206":
    case "207":
    case "208":
    case "209":
    case "210":
    case "211":
    case "212":
    case "213":
    case "214":
    case "215":
      // LAST LINE - DO NOT MOVE THIS COMMENT abilityGetRevelianTestTitle
      return "English Language Proficiency Test";
    // LAST LINE - DO NOT MOVE THIS COMMENT newGetRevelianTestTitle
    default:
      return "";
  }
};

export const getRevelianTestType = (subTestId: string) => {
  switch (subTestId) {
    case "110":
    case "121":
    case "122":
    case "123":
    case "138":
    case "139":
    case "140":
    case "141":
    case "143":
    case "144":
    case "145":
    case "146":
    case "150":
    case "151":
    case "153":
    case "154":
    case "158":
    case "159":
    case "160":
    case "161":
    case "162":
    case "163":
    case "164":
    case "166":
    case "167":
    case "168":
    case "169":
    case "170":
    case "173":
    case "174":
    case "183":
    case "184":
    case "188":
    case "189":
      // LAST LINE - DO NOT MOVE THIS COMMENT cognifyGetRevelianTestType
      return "aptitude";
    case "109":
    case "112":
    case "137":
      // LAST LINE - DO NOT MOVE THIS COMMENT emotifyGetRevelianTestType
      return "emotional";
    case "119":
    case "120":
      // LAST LINE - DO NOT MOVE THIS COMMENT safetyGetRevelianTestType
      return "risk";
    case "132":
    case "136":
      // LAST LINE - DO NOT MOVE THIS COMMENT personalityGetRevelianTestType
      return "personality";
    case "179":
    case "180":
    case "181":
    case "182":
    case "190":
    case "191":
    case "192":
    case "193":
    case "194":
    case "195":
    case "196":
    case "197":
    case "198":
    case "199":
    case "200":
    case "201":
    case "202":
    case "203":
    case "204":
    case "205":
    case "206":
    case "207":
    case "208":
    case "209":
    case "210":
    case "211":
    case "212":
    case "213":
    case "214":
    case "215":
      // LAST LINE - DO NOT MOVE THIS COMMENT skillsGetRevelianTestType
      return "skills";
    // LAST LINE - DO NOT MOVE THIS COMMENT newGetRevelianTestType
    default:
      return "";
  }
};
