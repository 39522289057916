// eslint-disable-entire-file
import React from "react";

const NetworkError = () => (
  <svg
    width="340"
    height="200"
    viewBox="0 0 340 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_429_23031"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="65"
      y="40"
      width="210"
      height="140"
    >
      <rect
        x="65"
        y="40"
        width="210"
        height="140"
        fill="url(#paint0_linear_429_23031)"
      />
    </mask>
    <g mask="url(#mask0_429_23031)">
      <circle
        cx="100"
        cy="100"
        r="100"
        transform="matrix(1 0 0 -1 70 245)"
        fill="url(#paint1_linear_429_23031)"
      />
      <mask
        id="mask1_429_23031"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="70"
        y="45"
        width="200"
        height="200"
      >
        <circle
          cx="100"
          cy="100"
          r="100"
          transform="matrix(1 0 0 -1 70 245)"
          fill="url(#paint2_linear_429_23031)"
        />
      </mask>
      <g mask="url(#mask1_429_23031)">
        <path
          d="M75.449 62.6029L70.3747 57.7021"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M93.3459 79.8887L76.6453 63.7649"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M104.691 90.8464L94.5424 81.0506"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M110.755 96.7027L105.894 92.0082"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M121.465 107.047L111.952 97.8645"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M159.654 143.93L122.668 108.209"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M164.447 148.56L160.851 145.092"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M178.327 161.971L165.65 149.722"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M194.961 178.03L179.53 163.133"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M199.119 182.052L196.163 179.192"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M84.3337 77.4277L70.8633 64.4176"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M90.4098 83.2959L85.6346 78.678"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M95.8263 88.5269L91.7046 84.546"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M113.87 105.954L97.1267 89.777"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M128.006 119.601L115.171 107.204"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M134.229 125.617L129.301 120.857"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M158.788 149.337L135.529 126.873"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M168.424 158.643L160.083 150.587"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M178.737 168.604L169.718 159.893"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M185.71 175.339L180.031 169.854"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M200.213 189.346L187.005 176.589"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M171.434 174.026L180.942 183.209"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M133.245 137.143L170.231 172.864"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M128.452 132.513L132.043 135.981"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M114.566 119.108L127.249 131.351"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M97.9396 103.043L113.37 117.946"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M93.7806 99.0268L96.7361 101.881"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M78.6245 84.3951L92.5772 97.865"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M72.2982 78.2793L77.4275 83.2333"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M63.9634 70.2289L71.0955 77.1173"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M179.03 175.119L195.774 191.296"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M164.895 161.473L177.73 173.869"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M158.666 155.457L163.6 160.222"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M134.112 131.743L157.371 154.207"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M124.476 122.437L132.811 130.487"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M114.164 112.475L123.176 121.18"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M107.19 105.741L112.863 111.219"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M92.6879 91.7278L105.89 104.484"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M78.8449 78.364L91.3872 90.4776"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M73.7152 73.41L77.5499 77.1137"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M56.2574 56.5491L72.4208 72.16"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M71.3537 83.6032L61.5105 74.1023"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M81.0568 92.9804L72.6485 84.8594"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M86.4728 98.2116L82.357 94.2306"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M104.517 115.639L87.7732 99.4616"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M118.653 129.286L105.818 116.889"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M124.881 135.301L119.947 130.542"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M149.435 159.015L126.176 136.551"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M159.07 168.328L150.729 160.272"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M169.384 178.282L160.371 169.578"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M176.357 185.018L170.678 179.539"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M123.892 146.828L160.878 182.549"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M119.099 142.198L122.689 145.666"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M105.219 128.787L117.895 141.036"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M88.5861 112.728L104.017 127.625"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M84.4271 108.712L87.3825 111.566"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M69.278 94.0736L83.2248 107.55"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M74.3086 92.6957L65.9004 84.5807"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M83.358 101.436L75.6031 93.9519"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M101.402 118.863L84.653 102.692"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M115.533 132.516L102.697 120.119"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M121.761 138.532L116.833 133.766"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M139.054 155.233L123.055 139.782"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M155.956 171.552L140.349 156.484"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M166.263 181.513L157.251 172.808"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M245.476 20.9405L282.462 56.662"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M246.078 58.9516L222.819 36.4878"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M255.714 68.2642L247.373 60.2081"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M266.027 78.2192L257.014 69.5144"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M273 84.9541L267.322 79.4753"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M239.844 65.4118L216.585 42.948"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M249.479 74.7183L241.138 66.6622"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M259.793 84.6792L250.774 75.9686"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M266.766 91.4144L261.087 85.9297"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M281.269 105.421L268.061 92.6646"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M260.08 91.2004L276.829 107.371"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M245.95 77.5472L258.786 89.9439"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M239.722 71.532L244.65 76.2973"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M215.168 47.8178L238.427 70.2816"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M205.533 38.5113L213.868 46.5615"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M268.187 55.3494L252.585 40.2812"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M278.494 65.3106L269.481 56.6058"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M261.538 61.4088L274.374 73.8055"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M255.31 55.3933L260.244 60.1585"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M230.756 31.6792L254.015 54.1431"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M255.431 49.2729L232.172 26.8091"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M265.066 58.5795L256.725 50.5234"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M275.38 68.5404L266.361 59.8297"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M266.32 84.74L283.064 100.917"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M252.185 71.093L265.02 83.4897"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M245.955 65.0777L250.889 69.8369"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M221.402 41.3636L244.661 63.8274"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M264.205 45.2722L260.614 41.7984"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M278.091 58.6772L265.408 46.4339"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M209.051 48.1464L204.123 43.3871"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M233.61 71.8663L210.352 49.4025"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M243.246 81.1726L234.905 73.1166"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M253.559 91.1336L244.54 82.4229"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M260.533 97.8687L254.854 92.3839"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M278.662 115.385L261.827 99.119"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M246.945 53.5511L209.959 17.8296"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M251.737 58.1809L248.147 54.7131"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M265.617 71.5921L252.94 59.3427"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M282.25 87.651L266.819 72.7478"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M263.031 112.757L276.807 126.062"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M256.968 106.906L261.828 111.601"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M246.252 96.5563L255.765 105.745"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M208.068 59.6789L245.054 95.3943"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M203.276 55.0431L206.866 58.5168"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M189.39 41.638L202.072 53.8813"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M253.846 97.6548L270.596 113.826"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M239.717 84.0017L252.552 96.3984"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M233.488 77.9862L238.416 82.7515"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M208.929 54.2724L232.188 76.7362"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M199.294 44.966L207.635 53.022"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M193.468 51.8155L180.633 39.4188"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M199.697 57.8311L194.77 53.0718"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M224.257 81.551L200.998 59.0872"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M233.893 90.8575L225.551 82.8014"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M244.206 100.818L235.187 92.1078"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M251.179 107.553L245.5 102.069"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M269.315 125.069L252.474 108.804"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M265.022 133.399L281.717 149.529"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M253.677 122.441L263.82 132.243"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M247.613 116.585L252.474 121.279"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M236.903 106.241L246.411 115.423"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M198.714 69.3572L235.699 105.079"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M193.921 64.7276L197.512 68.2013"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M180.035 51.3226L192.718 63.5658"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M163.408 35.2576L178.832 50.1609"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M190.355 55.0453L177.52 42.6487"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M196.583 61.0609L191.649 56.3016"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M213.876 77.7627L197.877 62.3111"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M230.772 94.0813L215.171 79.013"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M241.086 104.042L232.073 95.3376"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M248.059 110.777L242.386 105.298"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M258.934 121.281L249.359 112.034"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M276.399 138.154L260.229 122.531"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M267.37 179.341L278.575 190.163"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M261.098 173.284L266.167 178.179"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M250.461 163.01L259.896 172.122"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M231.849 145.04L249.258 161.848"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M225.792 139.184L230.652 143.878"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M215.076 128.834L224.589 138.022"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M176.894 91.9563L213.873 127.672"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M172.094 87.3207L175.69 90.7943"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M158.214 73.9156L170.896 86.1648"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M141.587 57.8565L157.012 72.7537"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M137.422 53.8347L140.384 56.695"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M122.273 39.2027L136.22 52.6728"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M266.973 172.719L275.314 180.775"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M259.468 165.471L265.678 171.469"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M246.132 152.591L258.174 164.221"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M240.716 147.36L244.838 151.34"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M222.672 129.932L239.415 146.104"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M208.542 116.28L221.378 128.676"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M202.314 110.27L207.241 115.029"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M177.754 86.5501L201.013 109.014"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M168.119 77.2438L176.46 85.2998"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M157.805 67.2826L166.824 75.9933"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M150.831 60.5477L156.51 66.0325"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M129.069 39.5289L149.537 59.2975"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M148.334 45.6596L134.558 32.3547"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M154.398 51.5158L149.537 46.8154"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M165.108 61.8602L155.6 52.6718"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M203.296 98.7374L166.31 63.0219"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M208.09 103.373L204.499 99.8994"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M221.975 116.778L209.293 104.535"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M238.603 132.843L223.178 117.94"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M242.767 136.859L239.806 133.999"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M257.917 151.491L243.97 138.021"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M267.15 160.408L259.12 152.653"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M272.578 165.651L268.353 161.57"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M157.518 60.7619L140.768 44.5908"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M171.648 74.4146L158.813 62.0179"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M177.876 80.4302L172.948 75.6649"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M202.43 104.144L179.171 81.6805"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M212.071 113.451L203.73 105.395"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M222.379 123.412L213.366 114.707"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M229.352 130.147L223.679 124.662"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M243.86 144.153L230.653 131.397"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M257.698 157.517L245.155 145.41"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M262.826 162.477L258.992 158.773"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M280.284 179.338L264.127 163.727"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M103.041 58.0586L89.5703 45.0486"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M109.117 63.9266L104.336 59.3147"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M114.533 69.1577L110.412 65.1769"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M132.577 86.585L115.828 70.4139"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M146.708 100.238L133.872 87.8413"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M152.936 106.247L148.008 101.488"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M177.495 129.967L154.236 107.504"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M187.131 139.274L178.79 131.218"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M197.444 149.235L188.425 140.524"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M204.417 155.97L198.739 150.485"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M218.92 169.976L205.712 157.22"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M232.757 183.34L220.215 171.233"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M82.0361 50.2526L73.7009 42.1965"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M89.5407 57.5008L83.3366 51.5029"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M102.877 70.381L90.8411 58.757"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M108.299 75.6181L104.177 71.6372"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M126.343 93.0394L109.593 76.8684"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M140.473 106.692L127.638 94.2955"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M146.702 112.708L141.768 107.943"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M171.255 136.422L147.996 113.958"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M180.89 145.728L172.555 137.678"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M191.204 155.689L182.191 146.984"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M198.177 162.424L192.504 156.945"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M212.686 176.431L199.478 163.68"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M226.523 189.795L213.981 177.687"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M268.822 155.786L275.032 161.784"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M255.486 142.906L267.527 154.536"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M250.069 137.675L254.191 141.656"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M232.025 120.248L248.769 136.419"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M217.89 106.601L230.731 118.998"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M211.667 100.585L216.595 105.345"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M187.107 76.8654L210.366 99.3293"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M177.471 67.5591L185.812 75.6151"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M167.159 57.5981L176.178 66.3088"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M160.185 50.8629L165.864 56.3477"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M149.676 40.7134L158.891 49.6128"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M209.542 179.638L213.664 183.619"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M191.498 162.21L208.241 178.382"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M177.362 148.558L190.204 160.96"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M171.14 142.548L176.068 147.307"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M146.58 118.828L169.839 141.292"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M136.945 109.522L145.286 117.578"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M126.631 99.5608L135.65 108.271"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M119.657 92.8256L125.336 98.3103"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M97.8952 71.807L118.363 91.5755"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M91.3182 65.4552L96.5941 70.5507"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M86.183 60.5013L90.0176 64.199"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M68.7312 43.64L84.8884 59.2449"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M117.95 47.509L109.542 39.3881"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M127 56.2494L119.251 48.7594"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M145.044 73.6766L128.301 57.4994"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M159.18 87.3236L146.345 74.9269"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M165.408 93.339L160.475 88.5798"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M182.701 110.041L166.703 94.5893"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M199.598 126.365L183.996 111.297"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M209.911 136.32L200.898 127.616"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M216.884 143.056L211.205 137.577"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M227.759 153.559L218.179 144.312"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M245.224 170.432L229.054 154.809"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M250.359 175.386L246.525 171.682"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M267.816 192.247L251.653 176.642"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M230.545 168.73L242.587 180.36"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M225.129 163.499L229.251 167.479"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M207.085 146.072L223.828 162.243"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M192.955 132.418L205.791 144.821"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M186.727 126.409L191.655 131.168"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M162.167 102.689L185.426 125.153"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M152.532 93.3825L160.873 101.439"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M142.218 83.4215L151.237 92.1322"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M135.244 76.6865L140.923 82.1712"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M124.735 66.537L133.949 75.4364"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M106.905 49.3162L123.441 65.2867"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M101.776 44.3562L105.61 48.0599"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M108.762 44.8708L98.9247 35.3638"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M118.47 54.2421L110.062 46.1211"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M123.887 59.479L119.765 55.4982"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M141.931 76.9005L125.181 60.7295"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M156.061 90.5533L143.226 78.1566"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M162.289 96.569L157.362 91.8036"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M186.843 120.283L163.584 97.8191"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M196.484 129.589L188.143 121.533"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M206.792 139.55L197.779 130.845"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M213.765 146.285L208.092 140.806"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M231.9 163.801L215.065 147.536"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M242.11 173.656L233.201 165.051"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M247.239 178.616L243.405 174.912"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M264.697 195.477L248.54 179.866"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M221.192 178.414L233.233 190.044"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M215.776 173.183L219.897 177.158"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M197.731 155.756L214.48 171.927"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M183.602 142.103L196.437 154.5"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M177.373 136.088L182.301 140.853"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M152.813 112.374L176.072 134.837"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M143.178 103.067L151.52 111.123"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M132.865 93.1062L141.884 101.811"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M125.891 86.3712L131.57 91.856"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M115.382 76.2216L124.596 85.121"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M97.5515 59.0006L114.087 74.9713"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M92.4222 54.0409L96.2569 57.7445"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M74.9646 37.1796L91.1219 52.7905"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M174.461 52.1758L164.954 42.9933"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M212.65 89.0592L175.664 53.3377"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M217.443 93.6888L213.853 90.221"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M231.329 107.094L218.646 94.8507"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M247.956 123.159L232.532 108.256"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M252.115 127.175L249.16 124.321"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M267.271 141.807L253.318 128.337"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M276.503 150.724L268.468 142.969"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M124.521 47.6107L107.82 31.4868"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M135.866 58.5684L125.723 48.7726"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M141.93 64.4247L137.069 59.7302"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M152.64 74.7688L143.133 65.5863"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M190.828 111.652L153.843 75.9307"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M195.623 116.282L192.032 112.814"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M209.502 129.693L196.825 117.444"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M226.135 145.752L210.704 130.849"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M230.294 149.768L227.338 146.914"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M245.444 164.406L231.497 150.93"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M254.676 173.323L246.647 165.562"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M260.105 178.566L255.879 174.479"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M275.682 193.605L261.308 179.728"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M75.8026 56.7072L67.4615 48.6571"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M86.9406 67.4643L77.0974 57.9634"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M96.6427 76.8417L88.2344 68.7207"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M102.066 82.0725L97.9438 78.0917"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M120.104 99.4998L103.36 83.3228"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M134.24 113.147L121.405 100.75"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M140.468 119.162L135.534 114.403"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M165.022 142.876L141.763 120.413"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M174.657 152.183L166.322 144.133"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M184.97 162.144L175.958 153.439"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M191.944 168.879L186.271 163.4"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M210.079 186.394L193.244 170.135"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M104.786 53.5075L95.352 44.3957"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M123.392 71.4833L105.989 54.6692"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M129.456 77.3336L124.596 72.6391"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M140.172 87.6838L130.659 78.4955"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M178.355 124.561L141.369 88.8457"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M183.148 129.197L179.558 125.723"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M197.034 142.602L184.351 130.353"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M213.661 158.661L198.237 143.764"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M217.826 162.683L214.864 159.823"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M232.976 177.315L219.029 163.845"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M242.209 186.232L234.179 178.477"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M166.865 51.0774L150.122 34.9063"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M181.002 64.7302L168.166 52.3335"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M187.23 70.7458L182.296 65.9806"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M211.783 94.4598L188.524 71.996"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M221.419 103.766L213.084 95.7159"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M231.732 113.727L222.719 105.022"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M238.706 120.462L233.034 114.983"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M260.475 141.487L240.007 121.718"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M267.051 147.839L261.769 142.737"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M272.181 152.793L268.346 149.089"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M71.7027 102.659L66.9276 98.0473"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M77.1251 107.896L73.0034 103.915"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M95.1631 125.317L78.4197 109.146"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M109.299 138.97L96.464 126.574"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M115.528 144.986L110.594 140.221"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M140.081 168.7L116.822 146.236"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M149.717 178.006L141.382 169.956"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M160.03 187.967L151.017 179.263"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M70.8849 114.35L66.7692 110.369"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M88.9288 131.777L72.1853 115.6"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M103.065 145.424L90.2297 133.028"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M109.293 151.44L104.359 146.681"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M133.847 175.154L110.588 152.69"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M143.482 184.466L135.141 176.41"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M109.172 157.566L132.431 180.03"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M99.5359 148.254L107.871 156.31"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M89.2232 138.299L98.2361 147.004"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M82.2494 131.564L87.9221 137.043"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M60.4802 110.539L80.9484 130.308"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M139.965 174.825L144.893 179.585"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M115.405 151.106L138.664 173.569"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M105.769 141.799L114.11 149.855"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M95.4565 131.838L104.475 140.549"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M88.4826 125.103L94.1615 130.588"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M77.9744 114.953L87.1887 123.853"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M60.1433 97.7326L76.6791 113.703"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M82.6953 138.232L65.9519 122.061"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M96.8317 151.879L83.9903 139.482"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M103.054 157.894L98.1262 153.135"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M127.613 181.614L104.354 159.151"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M102.071 169.421L139.057 205.142"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M97.2785 164.791L100.869 168.259"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M83.3924 151.386L96.075 163.63"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M66.7649 135.321L82.1895 150.224"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M102.937 164.02L126.196 186.484"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M93.3025 154.714L101.638 162.764"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M82.9888 144.753L92.0017 153.458"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M76.0151 138.018L81.6878 143.497"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M61.5071 124.011L74.7149 136.762"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M73.3419 147.917L56.5984 131.739"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M87.4782 161.563L74.6427 149.167"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M93.7063 167.579L88.7725 162.82"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M118.26 191.299L95.001 168.835"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M92.7187 179.105L129.704 214.827"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M87.925 174.476L91.5155 177.943"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M74.0389 161.065L86.7216 173.314"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M57.4113 145.006L72.8418 159.909"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M70.2276 151.141L53.4781 134.97"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M84.358 164.794L71.5226 152.397"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M90.5863 170.809L85.6524 166.044"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M107.879 187.511L91.8805 172.059"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M97.2991 208.486L60.3194 172.764"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M71.4762 177.307L79.8174 185.363"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M61.1625 167.346L70.1815 176.057"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M106.653 198.801L69.6669 163.086"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M75.0045 174.478L62.1691 162.082"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M81.2328 180.494L76.3049 175.728"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
    </g>
    <rect
      x="111.5"
      y="77.5"
      width="117"
      height="101"
      rx="4.18533"
      fill="white"
      stroke="#001F4E"
      strokeWidth="3"
    />
    <path
      d="M129.261 85.2985H227.261V173.298C227.261 175.508 225.47 177.298 223.261 177.298H129.261V85.2985Z"
      fill="#D5F5F6"
    />
    <path
      d="M150.327 130.825L150.327 130.825L150.323 130.827C146.754 132.081 144.25 135.551 144.25 139.5C144.25 144.605 148.298 148.75 153.5 148.75H187.719V148.75L187.73 148.75C192.179 148.556 195.75 144.787 195.75 140.25C195.75 136.772 193.625 133.777 190.63 132.52C190.629 132.52 190.629 132.52 190.628 132.519L187.621 131.193L188.153 127.915L188.156 127.895V127.875C188.156 127.706 188.177 127.534 188.201 127.344L188.202 127.337C188.225 127.154 188.25 126.953 188.25 126.75C188.25 122.956 185.044 119.75 181.25 119.75C180.173 119.75 179.2 120.044 178.337 120.428C178.336 120.428 178.336 120.428 178.336 120.428L175.504 121.667L173.375 119.272C173.375 119.272 173.375 119.272 173.375 119.272C171.29 116.903 168.26 115.279 164.752 115.25L164.704 115.25L164.702 115.25L164.654 115.25C158.279 115.302 153.25 120.457 153.25 126.75V129.851L150.327 130.825ZM192.065 128.59L192.038 128.778L192.212 128.854C196.627 130.785 199.75 135.199 199.75 140.25C199.75 146.958 194.514 152.379 187.993 152.75H153.5C146.138 152.75 140.25 146.862 140.25 139.5C140.25 133.795 143.931 128.828 149.08 127.08L149.25 127.023V126.844V126.75C149.25 118.262 156.17 111.25 164.75 111.25C169.36 111.25 173.605 113.371 176.467 116.694L176.59 116.838L176.762 116.758C178.138 116.116 179.602 115.75 181.25 115.75C187.299 115.75 192.25 120.701 192.25 126.75C192.25 127.312 192.179 127.803 192.097 128.371C192.091 128.41 192.085 128.449 192.079 128.489C192.075 128.522 192.07 128.556 192.065 128.59Z"
      fill="#001F4E"
      stroke="#D5F5F6"
      strokeWidth="0.5"
    />
    <path
      d="M111.5 81.6853C111.5 79.3738 113.374 77.5 115.685 77.5H224.315C226.626 77.5 228.5 79.3738 228.5 81.6853V90.5H111.5V81.6853Z"
      fill="white"
      stroke="#001F4E"
      strokeWidth="3"
    />
    <path
      d="M143.232 112.134C141.816 111.316 139.952 111.816 139.134 113.232C138.261 114.744 138.839 116.526 140.232 117.33L203.885 154.08C205.374 154.94 207.124 154.471 207.983 152.982C208.787 151.589 208.397 149.757 206.885 148.884L143.232 112.134Z"
      fill="#D50057"
      stroke="#D5F5F6"
      strokeWidth="2"
    />
    <ellipse
      cx="116.787"
      cy="83.8851"
      rx="1.35744"
      ry="1.31418"
      fill="#001F4E"
    />
    <ellipse
      cx="122.217"
      cy="83.8851"
      rx="1.35744"
      ry="1.31418"
      fill="#001F4E"
    />
    <ellipse
      cx="127.646"
      cy="83.8851"
      rx="1.35744"
      ry="1.31418"
      fill="#001F4E"
    />
    <defs>
      <linearGradient
        id="paint0_linear_429_23031"
        x1="170"
        y1="19"
        x2="170"
        y2="180"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C4C4C4" />
        <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_429_23031"
        x1="100"
        y1="88"
        x2="100"
        y2="240"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#425CC7" stopOpacity="0" />
        <stop offset="1" stopColor="#425CC7" stopOpacity="0.13" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_429_23031"
        x1="96.875"
        y1="-32.0313"
        x2="96.875"
        y2="221.875"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#425CC7" stopOpacity="0" />
        <stop offset="1" stopColor="#425CC7" />
      </linearGradient>
    </defs>
  </svg>
);

export default NetworkError;
