import React from "react";
import { Row, Col } from "react-bootstrap";
import menuGrid from "../../Resources/images/menu-grid.svg";
import userCircle from "../../Resources/images/user-circle.png";
import browseHopLogo from "../../Resources/images/browseHopLogo.png";

const Navbar = () => {
  const links = ["Images", "Videos", "Shopping", "Maps", "News"];

  return (
    <Row
      className="justify-content-between"
      style={{ padding: "16px 24px", margin: "0" }}
    >
      <Col>
        <div className="d-flex align-items-center">
          <img src={browseHopLogo} alt="BrowseHop Logo" />
        </div>
      </Col>
      <Col>
        <div
          className="d-flex align-items-center justify-content-end"
          style={{ gap: "32px" }}
        >
          {links.map((link: string, index: number) => (
            <span key={link} style={{ fontSize: "14px", color: "#53565A" }}>
              {link}
            </span>
          ))}

          <div className="d-flex align-items-center" style={{ gap: "16px" }}>
            <img src={menuGrid} alt="Grid Menu" height="20px" width="20px" />
            <img src={userCircle} alt="Grid Menu" height="20px" width="20px" />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Navbar;
