import { apiURL } from "../utils/constants";

interface ApplicationFormData {
  answersObject: any;
  applicationId: number;
  testEventId: number;
  testTakerId: number;
}

export const sendAnswers = async (
  formData: ApplicationFormData
): Promise<void> => {
  try {
    const response = await fetch(`${apiURL}/application`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(formData)
    });
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.log("error:", error);
  }
};
