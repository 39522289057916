import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Alert
} from "react-bootstrap";
import { FadeLoader } from "react-spinners";
import Header from "../Components/Header";
import { apiURL } from "../utils/constants";

interface State {
  showSuccess: boolean;
  candidate: CandidateObject;
  track: string;
  processRequest: boolean | null;
  sendCopy: boolean;
  deleteData: boolean;
  invalidRequest: boolean;
  isLoading: boolean;
  processingForm: boolean;
  errorMessage: string;
}
interface CandidateObject {
  firstName: String;
  lastName: String;
  emailAddress: String;
}
interface Props {
  path: string;
  track?: string;
  requestId?: string;
}

export default class CandidateVerificationForm extends Component<Props> {
  state: State = {
    showSuccess: false,
    candidate: {
      firstName: "",
      lastName: "",
      emailAddress: ""
    },
    track: "gdpr",
    processRequest: null,
    sendCopy: false,
    deleteData: false,
    invalidRequest: false,
    isLoading: true,
    processingForm: false,
    errorMessage: ""
  };

  componentDidMount() {
    this.fetchRequestData();
    this.setState({ track: this.props.track });
  }

  fetchRequestData = async (): Promise<boolean> => {
    try {
      const fetchURL = `${apiURL}/privacyRequest/${this.props.requestId}`;
      const response = await fetch(fetchURL);
      if (response.status === 404) {
        this.setState({ invalidRequest: true, isLoading: false });
      } else {
        const data = await response.json();
        this.setState({
          candidate: data,
          isLoading: false
        });
      }
    } catch (error) {}
    return true;
  };

  handleRadioSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "0") {
      this.setState({
        sendCopy: false,
        deleteData: false
      });
    }
    this.setState({
      processRequest: event.target.value === "1" ? true : false
    });
  };

  handleSendCopySelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ sendCopy: event.target.checked });
  };

  handleDeleteDataSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ deleteData: event.target.checked });
  };

  handleSubmit = async (): Promise<boolean> => {
    this.setState({ processingForm: true, errorMessage: "" });
    if (this.state.processRequest === null) {
      this.setState({
        errorMessage: "You must select whether you made this request.",
        processingForm: false
      });
      return false;
    }
    if (
      this.state.processRequest &&
      !this.state.sendCopy &&
      !this.state.deleteData
    ) {
      this.setState({
        errorMessage:
          "You must choose whether you want to receive your data, delete your data, or both.",
        processingForm: false
      });
      return false;
    }
    try {
      const formData = {
        processRequest: this.state.processRequest,
        sendCopy: this.state.sendCopy,
        deleteData: this.state.deleteData
      };
      const response = await fetch(
        `${apiURL}/privacyRequest/${this.props.requestId}`,
        {
          method: "PUT",
          body: JSON.stringify(formData)
        }
      );
      const data = await response.json();
      if (!data.error) {
        this.setState({ showSuccess: true, processingForm: false });
        return true;
      }
      return false;
    } catch {
      return false;
    }
  };

  render() {
    if (this.state.isLoading) {
      return (
        <Row>
          <Col className="spinner-container">
            <div className="spinner text-center">
              <FadeLoader height={15} color="#425CC7" />
            </div>
          </Col>
        </Row>
      );
    } else {
      return (
        <Container>
          <Row>
            <Col
              xl={{ span: 8, offset: 2 }}
              lg={{ span: 8, offset: 2 }}
              md={{ span: 10, offset: 1 }}
              sm={12}
            >
              <Card className="oda-card">
                <Header
                  onWelcomePage={false}
                  backgroundColor="#ffffff"
                  customLogo=""
                />
                <Card.Body>
                  <Card.Title className="text-center">
                    Privacy Policy
                  </Card.Title>
                  {this.state.errorMessage !== "" && (
                    <Alert variant="danger" className="mb-3">
                      {this.state.errorMessage}
                    </Alert>
                  )}
                  {this.state.invalidRequest && (
                    <p>This request has already been processed.</p>
                  )}
                  {this.state.showSuccess && (
                    <div>
                      <p>Thank you for confirming your request.</p>
                      {(this.state.deleteData || this.state.sendCopy) && (
                        <p>
                          We have notified the companies for which you have
                          completed assessments.
                        </p>
                      )}
                    </div>
                  )}
                  {!this.state.invalidRequest && !this.state.showSuccess && (
                    <div>
                      <p>
                        We received a request from{" "}
                        <strong>
                          {this.state.candidate.firstName}{" "}
                          {this.state.candidate.lastName} (
                          {this.state.candidate.emailAddress})
                        </strong>{" "}
                        to exercise certain personal information access rights
                        under the {this.state.track}.
                      </p>
                      <p>Please select the appropriate response below:</p>
                      <Form>
                        <Form.Check type="radio" id="process-request-yes">
                          <Form.Check.Input
                            type="radio"
                            name="processRequest"
                            onChange={this.handleRadioSelection}
                            value="1"
                          />
                          <Form.Check.Label>
                            <strong>Yes, this was me</strong>, please process
                            the following request(s):
                          </Form.Check.Label>
                        </Form.Check>
                        <div className="ml-5 mb-3">
                          <Form.Check type="checkbox" id="process-options-send">
                            <Form.Check.Input
                              type="checkbox"
                              name="processOptions"
                              onChange={this.handleSendCopySelection}
                              value="send"
                              checked={this.state.sendCopy}
                              disabled={!this.state.processRequest}
                            />
                            <Form.Check.Label>
                              Please send me a copy of all personal information
                              you have collected from or about me
                            </Form.Check.Label>
                          </Form.Check>
                          <Form.Check
                            type="checkbox"
                            id="process-options-delete"
                          >
                            <Form.Check.Input
                              type="checkbox"
                              name="processOptions"
                              onChange={this.handleDeleteDataSelection}
                              value="delete"
                              checked={this.state.deleteData}
                              disabled={!this.state.processRequest}
                            />
                            <Form.Check.Label>
                              Please delete all personal information you have
                              collected from or about me (Please carefully
                              consider how this may affect any job applications)
                            </Form.Check.Label>
                          </Form.Check>
                        </div>
                        <Form.Check type="radio" id="process-request-no">
                          <Form.Check.Input
                            type="radio"
                            name="processRequest"
                            onChange={this.handleRadioSelection}
                            value="0"
                          />
                          <Form.Check.Label>
                            <strong>No</strong>, this was not me, I did not make
                            any request, or I have changed my mind.
                          </Form.Check.Label>
                        </Form.Check>

                        <div className="text-center mt-3">
                          <Button
                            onClick={this.handleSubmit}
                            style={{ height: "40px" }}
                            disabled={this.state.processingForm}
                          >
                            {this.state.processingForm
                              ? "Please wait... "
                              : "Submit Request"}
                          </Button>
                        </div>
                      </Form>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      );
    }
  }
}
