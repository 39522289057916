import React, { Fragment } from "react";
import { Button } from "react-bootstrap";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";

interface Props {
  instructionPage: number;
  handleBack(): void;
  handleContinue(): void;
  finalPage: number;
  language: string;
  isMobile?: boolean;
}
const NavigateButtons = ({
  instructionPage,
  handleBack,
  handleContinue,
  finalPage,
  language,
  isMobile
}: Props) => {
  // determine the language of the test and display the correct language on the buttons
  let continueButtonText = "";
  if (language === "english") {
    continueButtonText =
      instructionPage !== finalPage ? "Continue" : "Begin Practice";
  } else {
    continueButtonText =
      instructionPage !== finalPage ? "Continuar" : "Comenzar la práctica";
  }
  const backButtonText = language === "english" ? "Back" : "Atrás";
  return (
    <Fragment>
      <br />
      <hr />
      <br />
      <div
        className={`row ${
          instructionPage === 1
            ? "first-instruction-button-row"
            : "instruction-button-row"
        }`}
      >
        {instructionPage > 1 ? (
          <div>
            <Button
              variant="primary"
              className={
                !isMobile ? "instructions-button ml-3" : "instructions-button"
              }
              onClick={handleBack}
            >
              <FaAngleLeft
                style={{
                  fontSize: "24px",
                  verticalAlign: "middle",
                  marginTop: "-5px"
                }}
              />
              {backButtonText}
            </Button>
          </div>
        ) : null}
        <div>
          <Button
            variant="primary"
            className={
              !isMobile ? "instructions-button mr-3" : "instructions-button"
            }
            onClick={handleContinue}
          >
            {continueButtonText}
            <FaAngleRight
              style={{
                fontSize: "24px",
                verticalAlign: "middle",
                marginTop: "-5px"
              }}
            />
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default NavigateButtons;
