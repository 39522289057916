import React from "react";
import rightChevron from "../../Resources/images/circle-chevron-right.png";

interface FadeContentProps {
  showIcon?: boolean;
  width?: string;
  height?: string;
}

const FadeContent = ({
  showIcon = true,
  width = "32px",
  height = "32px"
}: FadeContentProps) => {
  return (
    <>
      <div
        style={{
          position: "absolute",
          height: "100%",
          width: "100px",
          top: 0,
          right: 0,
          background: "linear-gradient(to right, transparent, white)"
        }}
      />
      {showIcon && (
        <div
          style={{
            width: width,
            height: height,
            borderRadius: "50px",
            backgroundColor: "white",
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            right: 0,
            top: "50%",
            transform: "translateY(-50%)"
          }}
        >
          <img src={rightChevron} alt="right-chevron" />
        </div>
      )}
    </>
  );
};

export default FadeContent;
