import React, { useState } from "react";
import { Col, Alert, Row, Button, Collapse } from "react-bootstrap";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";

interface TopInstructionProps {
  showSimulation: boolean;
  handleStart: () => void;
  simulationTimeAllowed: number;
}

const TopInstruction = ({
  showSimulation,
  handleStart,
  simulationTimeAllowed
}: TopInstructionProps) => {
  const [instructionExpand, setInstructionExpand] = useState(false);

  return (
    <Col lg={10} md={8} sm={8}>
      <Alert
        variant="primary"
        style={{ backgroundColor: "#eceef9", color: "#202d63" }}
      >
        <Row>
          <Col>
            <h3
              style={{
                marginBottom: "0px",
                lineHeight: "1.5",
                paddingLeft: "15px"
              }}
            >
              Instructions
            </h3>
          </Col>
          {showSimulation ? (
            <Col
              style={{
                textAlign: "right"
              }}
            >
              <Button
                onClick={() => setInstructionExpand(!instructionExpand)}
                aria-controls="instruction-collapse-text"
                aria-expanded={instructionExpand}
                style={{
                  backgroundColor: "#eceef9",
                  color: "#202d63",
                  borderColor: "#eceef9",
                  fontSize: "16px",
                  padding: "0px"
                }}
              >
                {instructionExpand ? "Collapse" : "Expand"}
                {"  "}
                {instructionExpand ? <FaCaretUp /> : <FaCaretDown />}
              </Button>
            </Col>
          ) : null}
        </Row>
        <Col sm={12}>
          <Collapse in={instructionExpand || !showSimulation}>
            <div
              className="magnify"
              id="instruction-collapse-text"
              style={{ paddingTop: "8px" }}
            >
              <p>
                Please perform all of the tasks in the simulated desktop that
                will appear below. This is only a simulation. You do not need to
                have any of the actual applications installed on your computer;
                only use the applications on the simulated desktop below. The
                simulated desktop will appear when you click the "Start
                Simulation" button.
              </p>
              <p>
                You will have {simulationTimeAllowed / 60} minutes to complete
                the tasks below. The instructions will remain on the screen
                throughout the test for your reference.
              </p>
              <p>Your test will begin when you click the Start button.</p>
              <Col lg={3} style={{ paddingLeft: "0px", marginBottom: "10px" }}>
                <Button
                  variant="primary"
                  id="CLIK-simulation1-start-button"
                  onClick={handleStart}
                  size="lg"
                  block
                  disabled={showSimulation}
                >
                  Start Simulation
                </Button>
              </Col>
            </div>
          </Collapse>
        </Col>
      </Alert>
    </Col>
  );
};

export default TopInstruction;
