import React from "react";

interface Props {
  name: string;
}
const ApplicationHeader = (props: Props) => {
  return (
    <div className="text-center pt-4">
      <h4>
        <strong>{props.name}</strong>
      </h4>
    </div>
  );
};

export default ApplicationHeader;
