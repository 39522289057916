/**
 * Checks if a string contains at leaset one word from all word arrays provided
 *
 * @param str - The string to check.
 * @param arrayOfWordArrays - An array of word arrays.
 * @returns A boolean indicating whether the string contains words from all arrays.
 */
export const containsWordsFromAllArrays = (
  str: string,
  arrayOfWordArrays: string[][]
) => {
  const wordsInString = new Set(
    str
      .toLowerCase()
      .replace(/[^a-zA-Z0-9\s]/g, "")
      .split(/\s+/)
  );
  return arrayOfWordArrays.every(category => {
    return category.some(word => wordsInString.has(word));
  });
};
