import React, { useContext } from "react";
import { Card } from "react-bootstrap";
import { TestEventContext } from "../Contexts/TestEventContext";
import Uploader from "./Uploader";

interface Props {
  title: string;
  instructions: string;
  file?: any;
  isResume?: boolean;
  updateInvalidTypeError: (value: boolean) => void;
  receivedResume?: (value: boolean) => void;
  receivedOtherDocuments?: (value: boolean) => void;
  clearValidationError: () => void;
}
const DocumentUploadCard = ({
  title,
  instructions,
  isResume,
  file,
  receivedResume,
  receivedOtherDocuments,
  clearValidationError,
  updateInvalidTypeError
}: Props) => {
  const context = useContext(TestEventContext);

  return (
    <React.Fragment>
      <Card.Title as="h1" className="text-center h5">
        {title}
      </Card.Title>
      <div dangerouslySetInnerHTML={{ __html: instructions }} />

      {isResume && context ? (
        <p>
          <strong>
            {context.testEventData.requireResume === 1
              ? context.testEventData.translatedText.mandatory
              : context.testEventData.translatedText.optionalMsg}
          </strong>{" "}
        </p>
      ) : null}

      {!file ? (
        <Uploader
          isResume={true}
          receivedResume={receivedResume}
          clearValidationError={clearValidationError}
          updateInvalidTypeError={updateInvalidTypeError}
        />
      ) : (
        <Uploader
          documentId={file.documentId}
          name={file.name}
          required={file.required}
          isResume={false}
          key={file.documentId}
          receivedOtherDocuments={receivedOtherDocuments}
          clearValidationError={clearValidationError}
          updateInvalidTypeError={updateInvalidTypeError}
          receivedResume={receivedResume}
        />
      )}
    </React.Fragment>
  );
};

export default DocumentUploadCard;
