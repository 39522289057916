import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { TestEventConsumer } from "../../Contexts/TestEventContext";
import ApplicationQuestions from "../../Interfaces/ApplicationQuestions";

interface Props {
  question: ApplicationQuestions;
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  missingQuestions: string[];
}

interface State {
  inputValue: string;
}
export default class ApplicationInput extends Component<Props, State> {
  state = {
    inputValue: ""
  };

  static contextType: any = TestEventConsumer;

  handleInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newEvent: string = event.target.value;
    this.setState({ inputValue: newEvent });
    this.props.onChangeHandler(event);
  };

  render() {
    const { missingQuestions } = this.props;
    const {
      applicationQuestionId,
      label,
      required,
      applicationQuestionType,
      applicationQuestionCategory
    } = this.props.question;

    const getInputType = (questionType: string): string => {
      switch (questionType) {
        /* Commenting out because 'Zip Code' is the only one that has 'questionType' set to 'numeric' in the 'applicationQuestions' table, 
            but some zip codes include letters per PRO-13255. */
        // case "numeric":
        //   return "number";
        case "telephone":
          return "tel";
        case "mmyyyy":
          return "date";
        default:
          return "text";
      }
    };

    const telephoneMaxLength =
      applicationQuestionType === "numeric"
        ? 12
        : applicationQuestionType === "telephone"
        ? 14
        : 7;

    // if the question was required, and the application was submitted without an answer, change the
    // background color as an indication.
    const backgroundColor = missingQuestions.includes(
      `q_${applicationQuestionId}`
    )
      ? "#F7CECD"
      : "";
    return (
      <Form.Group>
        <Form.Label
          style={{ backgroundColor }}
          htmlFor={`${applicationQuestionId}`}
        >
          {required ? (
            <>
              <i aria-hidden="true">*</i> {label}{" "}
              <span className="sr-only">Required</span>
            </>
          ) : (
            label
          )}
        </Form.Label>
        {applicationQuestionType === "text" ||
        applicationQuestionType === "country" ? (
          <Form.Control
            type="text"
            id={`q_${applicationQuestionId}`}
            onChange={this.handleInput}
            value={this.state.inputValue}
            // limiting short answers since only 255 characters get saved in database for those questions
            maxLength={
              applicationQuestionType === "text" &&
              applicationQuestionCategory === "custom"
                ? 255
                : 524288
            }
          />
        ) : null}
        {applicationQuestionType === "numeric" ||
        applicationQuestionType === "telephone" ||
        applicationQuestionType === "mmyyyy" ? (
          <Form.Control
            style={{ backgroundColor }}
            type={getInputType(applicationQuestionType)}
            value={this.state.inputValue}
            id={`q_${applicationQuestionId}`}
            maxLength={
              this.context && this.context?.testEventData.locale !== "AU"
                ? telephoneMaxLength
                : undefined
            }
            onChange={this.handleInput}
          />
        ) : null}
        {applicationQuestionType === "telephone" &&
        this.context &&
        this.context?.testEventData.locale !== "AU" ? (
          <div aria-hidden="true" className="secondary-text">
            (###) ###-####
          </div>
        ) : null}
      </Form.Group>
    );
  }
}
