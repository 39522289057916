import React, { Component } from "react";
import Shape from "./Shape";

interface Props {
  isMobile: boolean;
  isIPad13: boolean;
}

export default class TaskOneShapesRow extends Component<Props> {
  render() {
    const { isMobile, isIPad13 } = this.props;
    return (
      <div className="shapes-row">
        <div>
          <Shape
            shape="square"
            fill="#000"
            isInstructions={true}
            isMobile={isMobile}
            isIPad13={isIPad13}
          />
        </div>
        <div>
          <Shape
            shape="triangle"
            fill="#373737"
            isInstructions={true}
            isMobile={isMobile}
            isIPad13={isIPad13}
          />
        </div>
        <div>
          <Shape
            shape="circle"
            fill="#B7B7B7"
            isInstructions={true}
            isMobile={isMobile}
            isIPad13={isIPad13}
          />
        </div>
        <div className="ml-3">
          <Shape
            shape="star"
            fill="#FFF"
            isInstructions={true}
            isMobile={isMobile}
            isIPad13={isIPad13}
          />
        </div>
      </div>
    );
  }
}
