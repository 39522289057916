import React from "react";
import { MrabShapes } from "../../../../Interfaces/MrabShapes";

const Rectangle = ({ fill, text, isMobile, isIPad13 }: MrabShapes) => {
  return isMobile || isIPad13 ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="65"
      height="68"
      viewBox="0 0 107 44"
    >
      <path
        fill={fill}
        stroke="#000"
        d="M0 24h107v44H0z"
        transform="translate(0, -35)"
      />
      <text
        x="54"
        y="60"
        fontFamily="sans-serif"
        fontSize="21"
        textAnchor="middle"
      >
        {text}
      </text>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="108" height="105">
      <path fill={fill} stroke="#000" d="M0 24h107v44H0z" />
      <text
        x="54"
        y="100"
        fontFamily="sans-serif"
        fontSize="21"
        textAnchor="middle"
      >
        {text}
      </text>
    </svg>
  );
};

export default Rectangle;
