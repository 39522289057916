import { useEffect, useState } from "react";
import { ErrorMessage } from "../utils/Reference/ErrorMessage";

const useResume = <T extends Promise<Awaited<T>>>({
  method
}: {
  method: T;
}) => {
  const [data, setData] = useState<Awaited<T>>();
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    async function resume() {
      try {
        const resumeData = await method;
        if (!resumeData) {
          throw new Error(ErrorMessage.INTERNAL_SERVER_ERROR);
        }
        setData(() => resumeData);
      } catch (e) {
        console.error(e);
        setError(new Error(ErrorMessage.INTERNAL_SERVER_ERROR));
      }
    }
    resume();
  }, [method]);

  return {
    data,
    error
  };
};

export default useResume;
