import React, { useEffect, useCallback } from "react";
import { Alert, Button } from "react-bootstrap";
import { instructionsObjectEnglish } from "../instructionsObject";
import { instructionsObjectSpanish } from "../instructionsObject";
import Key from "../../ImageComponents/Key";
import { FaAngleRight } from "react-icons/fa";
import Shape from "../../ImageComponents/Shape";
import TaskTwoShapesRow from "../../ImageComponents/TaskTwoShapesRow";

interface Props {
  toggleIncorrectAnswerDisplay(): void;
  language: string;
  isMobile: boolean;
  isMobileOnly?: boolean;
  isIPad13: boolean;
}

const VigilanceIncorrectDisplay = ({
  toggleIncorrectAnswerDisplay,
  language,
  isMobile,
  isMobileOnly,
  isIPad13
}: Props) => {
  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key.toLowerCase() === "f" || event.key.toLowerCase() === "j") {
        toggleIncorrectAnswerDisplay();
      }
    },
    [toggleIncorrectAnswerDisplay]
  );

  const handleClickEvent = useCallback(
    (event: any) => {
      if (
        event.currentTarget.value === "f" ||
        event.currentTarget.value === "j"
      ) {
        toggleIncorrectAnswerDisplay();
      }
    },
    [toggleIncorrectAnswerDisplay]
  );

  useEffect(() => {
    if (isMobile || isIPad13) {
      window.addEventListener("click", handleClickEvent);

      return () => window.removeEventListener("click", handleClickEvent);
    } else {
      window.addEventListener("keyup", handleKeyPress);

      return () => window.removeEventListener("keyup", handleKeyPress);
    }
  }, [handleClickEvent, handleKeyPress, isMobile, isIPad13]);

  const instructionsObject =
    language === "english"
      ? instructionsObjectEnglish
      : instructionsObjectSpanish;

  const isMobileAndEnglish = (isMobile || isIPad13) && language === "english";

  return (
    <div>
      <Alert variant="danger" className="mrab-incorrect-warning text-center">
        Incorrect.
      </Alert>
      <div
        className={
          isMobileOnly ? "instruction-text-mobile" : "instruction-text"
        }
      >
        <p>
          {isMobileAndEnglish
            ? instructionsObject[2].incorrect.text_1a.slice(0, -5)
            : instructionsObject[2].incorrect.text_1a}
          <span style={{ marginBottom: "-20px" }}>
            <Key isMobile={isMobile} isIPad13={isIPad13} text="J" />
          </span>
          {isMobileAndEnglish
            ? instructionsObject[2].incorrect.text_1b.slice(1)
            : instructionsObject[2].incorrect.text_1b}
        </p>
        <div className="text-center">
          <Shape shape="right parallel" fill="#999" isInstructions={true} />
        </div>
        <p>
          {isMobileAndEnglish
            ? instructionsObject[2].incorrect.text_2.slice(0, -5)
            : instructionsObject[2].incorrect.text_2}
          <span>
            <Key isMobile={isMobile} isIPad13={isIPad13} text="F" />
          </span>
          {isMobileAndEnglish
            ? instructionsObject[2].incorrect.text_1b.slice(1)
            : instructionsObject[2].incorrect.text_1b}
        </p>
        <div className="text-center">
          <TaskTwoShapesRow isMobile={isMobile} isIPad13={isIPad13} />
        </div>
        <p className={isMobile || isIPad13 ? "mt-3" : "mt-1"}>
          {isMobileAndEnglish
            ? "Press Continue to resume practice."
            : instructionsObject[2].incorrect.text_3}
        </p>
        <p> {instructionsObject[2].incorrect.text_4}</p>
        <div className="first-instruction-button-row">
          <Button
            variant="primary"
            className="instructions-button"
            onClick={toggleIncorrectAnswerDisplay}
          >
            Continue
            <FaAngleRight
              style={{
                fontSize: "24px",
                verticalAlign: "middle",
                marginTop: "-5px"
              }}
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default VigilanceIncorrectDisplay;
