import { useEffect, useState } from "react";
import { EventData } from "../Interfaces/EventData";
import { apiURL, sskowk } from "../utils/constants";
import Encryption from "../utils/Encryption";
import { ErrorMessage } from "../utils/Reference/ErrorMessage";
import { Hook } from "../utils/Reference/Hook";

type GetDataProps = {
  eventId: string | null;
  subTestId: string;
  testType: string;
};

type GetDataResult = {
  data: EventData | null;
  isLoading: boolean;
  error: Error | null;
};

const useGetData: Hook<GetDataProps, GetDataResult> = ({
  eventId,
  subTestId,
  testType
}) => {
  const [data, setData] = useState<GetDataResult["data"]>(null);
  const [error, setError] = useState<GetDataResult["error"]>(null);

  useEffect(() => {
    async function getData() {
      try {
        if (!eventId) {
          throw new Error(ErrorMessage.EVENT_ID_NOT_PROVIDED);
        }
        const response = await fetch(`${apiURL}/event/${eventId}`);
        if (!response.ok) {
          throw new Error(
            response.status > 499
              ? ErrorMessage.INTERNAL_SERVER_ERROR
              : ErrorMessage.NOT_FOUND
          );
        }
        const data = (await response.json()) as { data: EventData };
        if (!("data" in data)) {
          throw new Error(ErrorMessage.INTERNAL_SERVER_ERROR);
        }
        const encryption = new Encryption();
        const decryptedData = encryption.decrypt(data.data, sskowk);
        const parsedData = JSON.parse(decryptedData) as EventData;
        setData(() => parsedData);
      } catch (e) {
        console.error(e);
        e instanceof Error
          ? setError(e)
          : setError(new Error(ErrorMessage.INTERNAL_SERVER_ERROR));
      }
    }
    getData();
  }, [subTestId, eventId, testType]);

  return {
    data,
    isLoading: !data,
    error
  };
};

export default useGetData;
