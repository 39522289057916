import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

interface Props {
  questionStartTime: number;
  onExpiration: () => void;
  pauseTimer: boolean;
  doPauseTimer: () => void;
  showGameTutorialStartModal?: boolean;
  timeFactor: number;
}
interface State {
  timerStartTime: number;
  currentTime: number;
  interval: number;
}

export default class TimerBar extends Component<Props, State> {
  state = {
    timerStartTime: this.props.questionStartTime,
    currentTime: Date.now(),
    interval: 100
  };
  // declare class property type
  timerID: any;

  /* ---------- React LifeCycle Methods ---------- */
  // set the interval to countdown the clock, if the startTimer prop was initially set to true
  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), this.state.interval);
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (nextProps.questionStartTime !== prevState.timerStartTime) {
      return { timerStartTime: nextProps.questionStartTime };
    }
    return null;
  }

  // clean up the setInterval
  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  componentDidUpdate(prevProps: Props) {
    // check if the pauseTimer prop has been set to true, and if so, clear the timer interval so that
    // the timer stops
    if (
      this.props.pauseTimer !== prevProps.pauseTimer &&
      this.props.pauseTimer
    ) {
      clearInterval(this.timerID);
    }
    // check if the pauseTimer prop has been set to false, and if so, restart the timer
    if (
      this.props.pauseTimer !== prevProps.pauseTimer &&
      !this.props.pauseTimer
    ) {
      this.startTimer();
    }
  }

  // method to start the timer
  startTimer = () => {
    this.timerID = setInterval(() => this.tick(), this.state.interval);
  };

  // method to decrement the seconds value is state whenever it is called
  tick = () => {
    // when on GAME tutorial, the time bar should freeze until the test taker moves from the start modal to the next step
    if (this.props.showGameTutorialStartModal) {
      return;
    }

    const currentTime = Date.now();
    const timeAllowedEachQuestion = this.props.timeFactor
      ? 6000 * this.props.timeFactor
      : 6000;
    this.setState({ currentTime }, () => {
      if (
        this.state.currentTime - this.state.timerStartTime >=
        timeAllowedEachQuestion
      ) {
        this.props.doPauseTimer();
        this.setState({ timerStartTime: Date.now() });
        this.props.onExpiration();
      }
    });
  };

  render() {
    const { showGameTutorialStartModal, timeFactor } = this.props;
    const timeAllowedEachQuestion = timeFactor ? 6000 * timeFactor : 6000;
    return (
      <Container>
        <Row className="noMargin">
          <Col lg={{ span: 6, offset: 3 }} md={{ span: 8, offset: 2 }}>
            <div className="progress" id="timer-bar-progress-bar">
              <span
                className="progress-bar"
                style={
                  showGameTutorialStartModal
                    ? { width: "0%" }
                    : {
                        width: `${
                          this.state.currentTime <= this.state.timerStartTime
                            ? `100%`
                            : this.state.currentTime -
                                (this.state.timerStartTime - 150) >=
                              timeAllowedEachQuestion
                            ? `0%`
                            : `${
                                (1 -
                                  (this.state.currentTime -
                                    this.state.timerStartTime) /
                                    timeAllowedEachQuestion) *
                                100
                              }%`
                        }`,
                        transition: `${
                          this.state.currentTime <= this.state.timerStartTime
                            ? ""
                            : `width ${this.state.interval / 1000}s linear 0s`
                        }`
                      }
                }
              />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
