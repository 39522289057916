import React from "react";

const getInstructions = (label: string) => {
  return { __html: label };
};

const ApplicationInstructions = ({ label }: { label: string }) => {
  return (
    <React.Fragment>
      <div
        className={label === "-- Page Break --" ? "text-center" : ""}
        dangerouslySetInnerHTML={getInstructions(label)}
      />
      <br />
    </React.Fragment>
  );
};

export default ApplicationInstructions;
