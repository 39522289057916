import React from "react";
import { Col, Button } from "react-bootstrap";
import InterruptModal from "../../../../Components/InterruptModal";
import { BarLoader } from "react-spinners";
import { MdSignalWifiOff } from "react-icons/md";

interface CLIKInterruptModalInterface {
  didReconnectFail: boolean;
  handleRetryButton(): void;
}

const CLIKInterruptModal = ({
  didReconnectFail,
  handleRetryButton
}: CLIKInterruptModalInterface) => {
  return (
    <InterruptModal>
      <Col className="text-center">
        <h1 style={{ fontSize: "150px", margin: "50px 0" }}>
          <MdSignalWifiOff />
        </h1>
        <h3>
          <strong>Test Paused</strong>
        </h3>
        {!didReconnectFail ? (
          <div className="text-left">
            <p>
              There was an error while trying to save your answer. Your timer
              will be paused until the test is resumed.
            </p>
            <p>Please wait while we try to reconnect.</p>
          </div>
        ) : (
          <div className="text-left">
            <p>
              We were unable to reconnect. Please try again later or from a
              different device.
            </p>
          </div>
        )}
        {!didReconnectFail ? (
          <BarLoader height={6} width={150} color={"#428bca"} />
        ) : (
          <div className="text-center">
            <Button
              variant="success"
              id="retry-connection-button"
              onClick={handleRetryButton}
            >
              Retry
            </Button>
          </div>
        )}
      </Col>
    </InterruptModal>
  );
};

export default CLIKInterruptModal;
