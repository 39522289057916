import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { FaCheckCircle } from "react-icons/fa";
import { TestEventContext } from "../Contexts/TestEventContext";
import trashCanIcon from "../Resources/images/trash@3x.png";

interface Props {
  fileName: string;
  index: number;
  handleRemoveDoc: (index: number) => void;
}

const UploadedDocument = ({ fileName, index, handleRemoveDoc }: Props) => {
  const context = useContext(TestEventContext);
  return (
    <div className="upload-resume-btn-wrapper" id="document-upload-button">
      <div className="upload-button">
        <Row>
          <Col lg={1} md={1} sm={1} xs={1}>
            <FaCheckCircle aria-hidden="true" className="text-success" />
          </Col>
          <Col
            lg={10}
            md={9}
            sm={8}
            xs={8}
            style={{
              maxWidth: `${context && context.isMobile ? "60%" : null}`
            }}
            className="text-left"
          >
            <span id="select-file-button-text">{fileName}</span>
          </Col>
          <Col
            lg={1}
            md={2}
            sm={3}
            xs={3}
            className="text-right"
            style={{
              maxWidth: `${context && context.isMobile ? "30%" : null}`
            }}
          >
            <button
              className="remove-upload-icon text-right"
              onClick={() => handleRemoveDoc(index)}
              aria-label={`Remove uploaded document ${fileName}}`}
            >
              <img src={trashCanIcon} height="16px" alt="" />
            </button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default UploadedDocument;
