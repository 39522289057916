import React, { Component } from "react";
import { Alert, Button } from "react-bootstrap";
import { instructionsObjectEnglish } from "../instructionsObject";
import { instructionsObjectSpanish } from "../instructionsObject";
import Key from "../../ImageComponents/Key";
import { FaAngleRight } from "react-icons/fa";

interface Props {
  toggleIncorrectAnswerDisplay(): void;
  language: string;
  isMobile: boolean;
  isMobileOnly?: boolean;
  isIPad13?: boolean;
}

export default class TaskEightIncorrectDisplay extends Component<Props> {
  componentDidMount() {
    if (this.props.isMobile || this.props.isIPad13) {
      window.addEventListener("click", this.handleClickEvent);
    } else {
      window.addEventListener("keyup", this.handleKeyPress);
    }
  }

  componentWillUnmount() {
    if (this.props.isMobile || this.props.isIPad13) {
      window.removeEventListener("click", this.handleClickEvent);
    } else {
      window.removeEventListener("keyup", this.handleKeyPress);
    }
  }

  handleKeyPress = (event: KeyboardEvent) => {
    const keyPressed = event.key.toLowerCase();
    if (
      keyPressed === "f" ||
      keyPressed === "j" ||
      keyPressed === "d" ||
      keyPressed === "k"
    ) {
      this.props.toggleIncorrectAnswerDisplay();
    }
  };

  handleClickEvent = (event: any) => {
    const keyPressed = event.currentTarget.value;
    if (
      keyPressed === "f" ||
      keyPressed === "j" ||
      keyPressed === "d" ||
      keyPressed === "k"
    ) {
      this.props.toggleIncorrectAnswerDisplay();
    }
  };

  render() {
    const {
      toggleIncorrectAnswerDisplay,
      language,
      isMobile,
      isMobileOnly,
      isIPad13
    } = this.props;
    const instructionsObject =
      language === "english"
        ? instructionsObjectEnglish
        : instructionsObjectSpanish;

    const isMobileAndEnglish = (isMobile || isIPad13) && language === "english";

    return (
      <div>
        <Alert variant="danger" className="mrab-incorrect-warning text-center">
          Incorrect.
        </Alert>
        <div
          className={
            isMobileOnly ? "instruction-text-mobile" : "instruction-text"
          }
        >
          <p>
            {isMobileAndEnglish
              ? instructionsObject[8].incorrect.text_1a.slice(0, -1)
              : instructionsObject[8].incorrect.text_1a}
            <Key isMobile={isMobile} isIPad13={isIPad13} text="D" />,
            <Key isMobile={isMobile} isIPad13={isIPad13} text="F" />,
            <Key isMobile={isMobile} isIPad13={isIPad13} text="J" />, and
            <Key isMobile={isMobile} isIPad13={isIPad13} text="K" />
            {!isMobile ? "s" : "buttons"}
            {instructionsObject[8].incorrect.text_1b}
          </p>
          <div className={isMobile || isIPad13 ? "" : "text-center"}>
            <p>
              <Key isMobile={isMobile} isIPad13={isIPad13} text="D" />
              {instructionsObject[8].incorrect.text_2}
            </p>
            <p>
              <Key isMobile={isMobile} isIPad13={isIPad13} text="F" />
              {instructionsObject[8].incorrect.text_3}
            </p>
            <p>
              <Key isMobile={isMobile} isIPad13={isIPad13} text="J" />
              {instructionsObject[8].incorrect.text_4}
            </p>
            <p>
              <Key isMobile={isMobile} isIPad13={isIPad13} text="K" />
              {instructionsObject[8].incorrect.text_5}
            </p>
          </div>
          <p>
            {isMobileAndEnglish
              ? "Press Continue to resume practice."
              : instructionsObject[8].incorrect.text_6}
          </p>
        </div>
        <div className="first-instruction-button-row">
          <Button
            variant="primary"
            className="instructions-button"
            onClick={toggleIncorrectAnswerDisplay}
          >
            Continue
            <FaAngleRight
              style={{
                fontSize: "24px",
                verticalAlign: "middle",
                marginTop: "-5px"
              }}
            />
          </Button>
        </div>
      </div>
    );
  }
}
