import React, { useContext, useState, useEffect } from "react";
import { Row, Col, Dropdown, Button } from "react-bootstrap";
import { FaRegQuestionCircle } from "react-icons/fa";
import externalLinkIcon from "../Resources/images/gray-100@3x.png";
import { TestEventContext } from "../Contexts/TestEventContext";
import useWindowSize from "../CustomHooks/useWindowSize";
import { getTranslationLanguage, translate } from "../utils/translationHelpers";
import { isNeedHelpMenuDisabled } from "../utils/customConfig";

interface Props {
  backgroundColor?: string;
  fromRevelianEventComplete?: boolean;
  testEventData?: any;
}

const HelpMenu = ({
  backgroundColor,
  fromRevelianEventComplete,
  testEventData
}: Props) => {
  const context = useContext(TestEventContext);

  const [language, setLanguage] = useState("");
  const [helpText, setHelpText] = useState("");
  const [helpTopics, setHelpTopics] = useState<
    { [key: string]: string }[] | null
  >(null);
  const [chatSupport, setChatSupport] = useState("");

  useEffect(() => {
    if (testEventData) {
      setLanguage(getTranslationLanguage(testEventData, testEventData.isAU));
    } else if (context && context.testEventData) {
      setLanguage(getTranslationLanguage(context.testEventData, context.isAU));
    }
  }, [context, testEventData]);

  useEffect(() => {
    if (!fromRevelianEventComplete && context?.testEventData?.translatedText) {
      setHelpText(context.testEventData.translatedText.help);
      setHelpTopics(
        JSON.parse(context.testEventData.translatedText.dropdown).Help
      );
      setChatSupport(context.testEventData.translatedText.chatSupport);
    } else if (testEventData?.translatedText) {
      setHelpText(testEventData.translatedText.help);
      setHelpTopics(JSON.parse(testEventData.translatedText.dropdown).Help);
      setChatSupport(testEventData.translatedText.chatSupport);
    }
  }, [helpText, context, fromRevelianEventComplete, testEventData]);

  const windowSize = useWindowSize();

  const handleDropdown = (e: string) => {
    // Open localized help menu link
    window.open(translate("common", `${e}Link`, language), "_blank");
  };

  const handleExitClick = () => {
    const buttonEl = document.getElementById("help-dropdown");
    if (buttonEl) {
      buttonEl.click();
    }
  };

  const getDropdownItems = () => {
    const HelpMenuDropdownItems = [
      "helpCenter",
      "expect",
      "faq",
      "copyrightNotices",
      "terms",
      "privacy",
      "dmca",
      "accommodations"
    ];

    return HelpMenuDropdownItems.map((dropdownItemEventKey, i) => (
      <Dropdown.Item
        key={dropdownItemEventKey}
        eventKey={dropdownItemEventKey}
        style={{ borderBottom: "1px solid #b1b3b3" }}
      >
        <Row>
          <Col className="my-1" lg={10} md={10} sm={10} xs={10}>
            {helpTopics !== null && helpTopics[i] !== null
              ? helpTopics[i][Object.keys(helpTopics[i])[0]]
              : translate("common", dropdownItemEventKey)}
          </Col>
          <Col className="my-1" lg={2} md={2} sm={2} xs={2}>
            <img src={externalLinkIcon} height="16px" alt="external link" />
          </Col>
        </Row>
      </Dropdown.Item>
    ));
  };

  if (isNeedHelpMenuDisabled()) {
    return <></>;
  }

  return (
    <Row>
      <Col className="text-right">
        <Dropdown
          alignRight
          style={{ border: "none" }}
          onSelect={handleDropdown}
        >
          <Dropdown.Toggle
            id="help-dropdown"
            className="caret-off shadow-none dropdown-btn"
            style={{
              backgroundColor
            }}
          >
            {windowSize && windowSize > 767 ? (
              <span
                style={{
                  color: "#425CC7",
                  fontSize: "14px",
                  fontWeight: "bold",
                  marginRight: "5px"
                }}
              >
                {helpText || "Need help?"}
              </span>
            ) : null}
            <FaRegQuestionCircle
              style={{ fontSize: "24px", color: "#425CC7" }}
              className="secondary-text"
            />
          </Dropdown.Toggle>
          <Dropdown.Menu
            style={{
              boxShadow: `3px 3px 5px 0px #BCBCBC`,
              fontSize: "14px",
              width: "275px"
            }}
          >
            {context && context.isMobile ? (
              <Row>
                <Col>
                  <Button
                    style={{
                      backgroundColor: backgroundColor,
                      color: "black",
                      border: "none"
                    }}
                    onClick={handleExitClick}
                  >
                    X
                  </Button>
                </Col>
              </Row>
            ) : null}
            <Row className="tech-support-text">
              <Col id="need-help">{helpText || "Need help?"}</Col>
            </Row>
            <Row noGutters={true}>
              <Col>{getDropdownItems()}</Col>
            </Row>
            <Row className="tech-support-text">
              <Col>
                <h6>
                  {helpTopics !== null && helpTopics.length > 0
                    ? Object.values(helpTopics[helpTopics.length - 1])[0]
                    : "Technical Support"}
                </h6>
                <p className="my-0">
                  {chatSupport ||
                    "For support, please chat with our team on the bottom right hand corner of this page"}
                </p>
              </Col>
            </Row>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Row>
  );
};

export default HelpMenu;
