import React from "react";
import ellipsisVertical from "../../Resources/images/ellipsis-vertical.png";

const SecondaryNav = () => {
  const links: string[] = [
    "All",
    "Images",
    "Videos",
    "Shopping",
    "Maps",
    "News",
    "More"
  ];

  return (
    <div style={{ marginTop: "16px" }}>
      <div
        className="d-flex align-items-end"
        style={{ padding: "0px 50px", gap: "32px" }}
      >
        {links.map((link: string, index: number) => (
          <div
            className="d-flex align-items-center justify-content-center"
            key={link}
            style={{
              borderBottom: `3px solid ${
                index === 0 ? "#425cc7" : "transparent"
              }`,
              padding: "0px 10px",
              color: "#53565A",
              gap: "8px"
            }}
          >
            {link === "More" && (
              <img src={ellipsisVertical} alt="ellipsis-vertical" />
            )}
            {link}
          </div>
        ))}
      </div>
      <div style={{ borderBottom: "1px solid #CBCCCD" }}></div>
    </div>
  );
};

export default SecondaryNav;
