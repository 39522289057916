import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import NextButton from "../../../../../Components/NextButton";

interface Props {
  handleBeginAssessment: () => void;
  handleRedoTutorial: () => void;
  numberOfQuestions: number;
  timeAllowedPerQuestion: number;
  displayText: {
    task: string;
    tutorialCompleted: string;
    clickBeginOrRedoTutorial: string;
    numberOfQuestions: string;
    timePerQuestion: string;
    questions: string;
    seconds: string;
    beginAssessment: string;
    redoTutorial: string;
  };
}

const CompleteTutorialModal = ({
  handleBeginAssessment,
  handleRedoTutorial,
  numberOfQuestions,
  timeAllowedPerQuestion,
  displayText
}: Props) => {
  const onBeginAssessment = () => {
    handleBeginAssessment();
  };

  const onRedoTutorial = () => {
    handleRedoTutorial();
  };

  return (
    <Col className="game-tutorial-modal game-tutorial-start-complete noPadding">
      <Modal.Header className="custom-modal-header-footer">
        <div
          style={{
            borderRadius: "4px 4px 0px 0px"
          }}
        >
          <p style={{ fontSize: "16px" }}>{displayText.task}</p>
          <p style={{ fontSize: "24px", fontWeight: "bold" }}>
            {displayText.tutorialCompleted}
          </p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div style={{ padding: "10px 20px 36px" }}>
          <p
            style={{ marginBottom: "2em" }}
            className="complete-tutorial-modal-instructions"
            dangerouslySetInnerHTML={{
              __html: displayText.clickBeginOrRedoTutorial
            }}
          ></p>
          <div style={{ fontSize: "14px" }}>
            <p
              className="game-tutorial-complete-modal-table"
              style={{
                borderTop: "1px solid #d8d9d9"
              }}
            >
              <span>{displayText.numberOfQuestions}</span>
              <span style={{ fontWeight: "bold" }}>
                {numberOfQuestions} {displayText.questions}
              </span>
            </p>
            <p className="game-tutorial-complete-modal-table">
              <span>{displayText.timePerQuestion}</span>
              <span style={{ fontWeight: "bold" }}>
                {timeAllowedPerQuestion} {displayText.seconds}
              </span>
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="custom-modal-header-footer tutorial-modal-footer">
        <Row>
          <Col
            xl={{ span: 6, offset: 3 }}
            lg={{ span: 6, offset: 3 }}
            md={{ span: 8, offset: 2 }}
            sm={12}
          >
            <NextButton
              handler={onBeginAssessment}
              buttonLabel={displayText.beginAssessment}
            />
          </Col>
        </Row>
        <Row>
          <Col
            xl={{ span: 6, offset: 3 }}
            lg={{ span: 6, offset: 3 }}
            md={{ span: 8, offset: 2 }}
            sm={12}
          >
            <button
              onClick={onRedoTutorial}
              style={{
                backgroundColor: "white",
                color: "#425cc7",
                border: "none"
              }}
            >
              {displayText.redoTutorial}
            </button>
          </Col>
        </Row>
      </Modal.Footer>
    </Col>
  );
};

export default CompleteTutorialModal;
