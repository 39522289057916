import React from "react";
import MultipleChoiceTest from "../../MultipleChoice/MultipleChoiceTest";

interface CLIKMultipleChoiceProps {
  testData: any;
  subTestId: string;
  startingQuestion: number;
  dataLoaded: boolean;
  timeRemaining: number;
  handleGoToNextSection: () => void;
  simulationTimeAllowed: number;
}

export default class CLIKMultipleChoice extends React.PureComponent<CLIKMultipleChoiceProps> {
  render() {
    const {
      testData,
      subTestId,
      startingQuestion,
      dataLoaded,
      timeRemaining,
      simulationTimeAllowed
    } = this.props;
    return (
      <div>
        <MultipleChoiceTest
          testData={testData}
          subTestId={subTestId}
          startingQuestion={startingQuestion}
          endSubTest={this.props.handleGoToNextSection}
          dataLoaded={dataLoaded}
          timeRemaining={timeRemaining}
          simulationTimeAllowed={simulationTimeAllowed}
          isCLIKv2
        />
      </div>
    );
  }
}
