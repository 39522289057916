import Language from "../../Interfaces/Language";

export const pt_pt: Language = {
  common: {
    help: "Precisa de ajuda?",
    helpCenter: "Centro de Ajuda",
    expect: "O que esperar destes testes",
    faq: "Perguntas Mais Frequentes",
    faqLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7853516-perguntas-mais-frequentes",
    copyright: "Direitos de autor",
    copyrightNotices: "Avisos de Direitos de Autor",
    copyrightNoticesLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994363-aviso-de-direitos-autorais",
    terms: "Termos de Utilização",
    termsLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994195-termos-e-condicoes-de-utilizacao",
    privacy: "Declaração de Privacidade",
    privacyLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6990050-politica-de-privacidade",
    dmca: "Política de DMCA",
    dmcaLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7853823-politica-do-digital-millenium-copyright-act",
    accommodations: "Deficiência/Alojamento",
    accommodationsLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994430-deficiencia-alojamento",
    technicalSupport: "Suporte Técnico",
    chatSupport:
      "Para obter suporte, converse com a nossa equipa através do chat no canto inferior direito desta página."
  },
  instruction: {
    eppCopyright:
      "Esta avaliação inclui material reproduzido do SalesAP ©1995 e do CSAP ©2002, Multi-Health Systems Inc. Todos os direitos reservados.",
    salesApCopyright:
      "SalesAP ©1995, Multi-Health Systems Inc. All rights reserved.",
    csapCopyright: "CSAP ©2002, Multi-Health Systems Inc. All rights reserved.",
    wtmaCopyright:
      "WTMA Copyright © 1997, 1999 Psychological Assessment Resources, Inc."
  }
};
