import React, { Component } from "react";

interface Props {
  path: string;
}

export default class ErrorPage extends Component<Props> {
  render() {
    return (
      <div>
        <h1>Error</h1>
        <p>
          The testing link submitted is suspected to be a compromised link from
          a third party selling <strong>"unauthorized"</strong> access to
          Criteria Corp testing platform. Such unauthorized use is a violation
          of Criteria’s{" "}
          <a
            href="https://www.criteriacorp.com/terms-use"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Use
          </a>{" "}
          and applicable law. Please be aware that testing links for access to
          Criteria Corp assessments such as the Criteria Cognitive Aptitude Test
          (CCAT) are only made available to paying customers of Criteria Corp
          and their job applicants. Any misuse of Criteria's testing links may
          be associated with and investigated as unauthorized activity and
          reportable by Criteria. Criteria does <strong>not</strong> offer
          practice tests or license the CCAT or its materials to third parties
          for this purpose, any such use is strictly prohibited. If you were
          charged for a prohibited use - you may wish to report such charges to
          your credit card provider, or to Stripe, PayPal, etc to get them
          reversed as fraudulent charges. The fraudulent charges may be listed
          as QQOIN Finacial Services S.R.L.
        </p>
      </div>
    );
  }
}
