import React from "react";
import Key from "../../ImageComponents/Key";
import { instructionsObjectEnglish } from "../instructionsObject";
import { instructionsObjectSpanish } from "../instructionsObject";
import InstructionsWrapper from "../InstructionsWrapper";
import NavigateButtons from "../NavigateButtons";
import FingerPositions from "../../Images/finger_positions_1289.jpeg";
import FingerPositionsMobile from "../../Images/finger_positions_1289_mobile.png";

interface Props {
  togglePractice(): void;
  language: string;
  isMobile: boolean;
  isMobileOnly?: boolean;
  isIPad13?: boolean;
}

const TaskNineInstructions = ({
  togglePractice,
  language,
  isMobile,
  isMobileOnly,
  isIPad13
}: Props) => {
  const instructionsObject =
    language === "english"
      ? instructionsObjectEnglish
      : instructionsObjectSpanish;

  const isMobileAndEnglish = (isMobile || isIPad13) && language === "english";

  return (
    <InstructionsWrapper>
      {({ instructionPage, handleContinue, handleBack }) => {
        const finalPage = 4;
        if (instructionPage > finalPage) {
          togglePractice();
        }
        return (
          <div>
            {instructionPage === 1 ? (
              <div>
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile mt-3"
                      : "instruction-text mt-3"
                  }
                >
                  {instructionsObject[9][instructionPage].text_1}
                </p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: instructionsObject[9][instructionPage].text_2
                  }}
                />
                <p
                  className={
                    isMobileOnly
                      ? "instruction-text-mobile"
                      : "instruction-text"
                  }
                >
                  {isMobileAndEnglish
                    ? instructionsObject[9][instructionPage].text_3.slice(0, -5)
                    : instructionsObject[9][instructionPage].text_3}
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="1" />,
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="2" />,
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="8" />, or
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="9" />
                  {isMobileAndEnglish
                    ? instructionsObject[9][instructionPage].text_4.slice(1)
                    : instructionsObject[9][instructionPage].text_4}
                </p>
              </div>
            ) : null}
            {instructionPage === 2 ? (
              <div
                className={
                  isMobileOnly
                    ? "instruction-text-mobile mt-3"
                    : "instruction-text mt-3"
                }
              >
                <p>{instructionsObject[9][instructionPage].text_1}</p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: instructionsObject[9][instructionPage].text_2
                  }}
                />
                <p>
                  {isMobileAndEnglish
                    ? instructionsObject[9][instructionPage].text_3.slice(0, -5)
                    : instructionsObject[9][instructionPage].text_3}
                  <Key isMobile={isMobile} isIPad13={isIPad13} text="1" />.
                </p>
              </div>
            ) : null}
            {instructionPage === 3 ? (
              <div
                className={
                  isMobileOnly
                    ? "instruction-text-mobile mt-3"
                    : "instruction-text mt-3"
                }
              >
                <p>
                  {isMobileAndEnglish
                    ? "For this task, we recommend that you place your index fingers on the 1, 2, 8, and 9 buttons as shown below."
                    : instructionsObject[9][instructionPage].text_1}
                </p>
                <div className="text-center">
                  {isMobile || isIPad13 ? (
                    <img
                      src={FingerPositionsMobile}
                      alt="numbers 1, 2, 8, and 9 as buttons"
                      width="250px"
                      height="67px"
                    />
                  ) : (
                    <img src={FingerPositions} alt="finger positions" />
                  )}
                </div>
              </div>
            ) : null}
            {instructionPage === 4 ? (
              <div
                className={
                  isMobileOnly
                    ? "instruction-text-mobile mt-3"
                    : "instruction-text mt-3"
                }
              >
                <p>{instructionsObject[9][instructionPage].text_1}</p>
                <p>
                  {isMobileAndEnglish
                    ? "If you make a mistake, your screen will display a message."
                    : instructionsObject[9][instructionPage].text_2}
                </p>
                <p>
                  {isMobileAndEnglish
                    ? "Remember, press the button that corresponds to the number that changes color."
                    : instructionsObject[9][instructionPage].text_3}
                </p>
              </div>
            ) : null}
            <NavigateButtons
              instructionPage={instructionPage}
              handleBack={handleBack}
              handleContinue={handleContinue}
              finalPage={finalPage}
              language={language}
            />
          </div>
        );
      }}
    </InstructionsWrapper>
  );
};

export default TaskNineInstructions;
