import Language from "../../Interfaces/Language";

export const ro: Language = {
  common: {
    help: "Need Help?",
    helpCenter: "Help Centre",
    helpCenterLink: "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/",
    expect: "What to expect on these tests",
    expectLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/collections/3396642-about-criteria-assessments",
    faq: "Frequently Asked Questions",
    faqLink: "/home/faq/au",
    copyright: "Copyright",
    copyrightNotices: "Copyright Notices",
    copyrightNoticesLink: "/home/copyright",
    terms: "Terms of Use",
    termsLink: "/home/terms",
    privacy: "Privacy Statement",
    privacyLink: "/home/privacy",
    dmca: "DMCA Policy",
    dmcaLink: "/home/dmca",
    accommodations: "Disability / Accommodations",
    accommodationsLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6079734-disability-accommodations",
    technicalSupport: "Technical Support",
    chatSupport:
      "For support, please chat with our team on the bottom right hand corner of this page"
  },
  instruction: {
    eppCopyright:
      "Această evaluare include material reprodus din SalesAP ©1995 și CSAP ©2002, Multi-Health Systems Inc. Toate drepturile rezervate.",
    salesApCopyright:
      "SalesAP ©1995, Multi-Health Systems Inc. All rights reserved.",
    csapCopyright: "CSAP ©2002, Multi-Health Systems Inc. All rights reserved.",
    wtmaCopyright:
      "WTMA Copyright © 1997, 1999 Psychological Assessment Resources, Inc."
  }
};
