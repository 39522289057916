import React from "react";
import { Col } from "react-bootstrap";
import { MdSignalWifiOff } from "react-icons/md";

interface Props {
  didReconnectFail: boolean;
  disconnectModalDisplayText: {
    testPaused: string;
    errorSavingQuestion: string;
    pleaseWaitReconnecting: string;
    unableToReconnectTryAgainLater: string;
  };
}

const DisconnectModal = ({
  didReconnectFail,
  disconnectModalDisplayText
}: Props) => {
  return (
    <Col className="text-center">
      <h1 style={{ fontSize: "150px", margin: "50px 0" }}>
        <MdSignalWifiOff />
      </h1>
      <h3>
        <strong>{disconnectModalDisplayText.testPaused}</strong>
      </h3>
      {!didReconnectFail ? (
        <div className="text-left">
          <p>{disconnectModalDisplayText.errorSavingQuestion}</p>
          <p>{disconnectModalDisplayText.pleaseWaitReconnecting}</p>
        </div>
      ) : (
        <div className="text-left">
          <p>{disconnectModalDisplayText.unableToReconnectTryAgainLater}</p>
        </div>
      )}
    </Col>
  );
};

export default DisconnectModal;
