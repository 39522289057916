import React, { Component } from "react";
import { Container, Row, Col, Card, Button, Image } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import { Redirect, navigate } from "@reach/router";
import insightsImage from "../Resources/images/oda-workplace-insights-image.jpg";
import { TestEventConsumer } from "../Contexts/TestEventContext";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

interface Props {
  path: string;
}

export default class InsightsReport extends Component<Props> {
  // define the context type so that we can use this.context outside of the render method
  static contextType: any = TestEventConsumer;

  handleContinue = () => {
    if (this.context.testEventData.hideEventIdScreen === 1) {
      // if the company has decided to skip showing the event ID screen, we navigate directly to overview
      navigate("/overview");
    } else {
      navigate("/event_id");
    }
  };

  render() {
    return (
      <TestEventConsumer>
        {context => {
          if (context && Object.keys(context.testEventData).length > 0) {
            const landingPageStyle = context.generateLandingPageStyle(
              context.testEventData.landingPage
            );
            return (
              <Container>
                <Row>
                  <Col
                    xl={{ span: 8, offset: 2 }}
                    lg={{ span: 8, offset: 2 }}
                    md={{ span: 10, offset: 1 }}
                    sm={12}
                  >
                    <Card>
                      <Header
                        onWelcomePage={false}
                        backgroundColor={landingPageStyle.backgroundColor}
                        customLogo={context?.testEventData.logo}
                      />
                      <Card.Body>
                        <Card.Title as="h1" className="text-center h5">
                          {context.testEventData &&
                          context.testEventData.translatedText
                            ? context.testEventData.translatedText.wpInsights
                            : "Your Workplace Insights"}
                        </Card.Title>
                        <div className="text-center mb-3">
                          <Image fluid={true} src={insightsImage} alt="" />
                        </div>
                        <p>
                          {context && context.testEventData.translatedText ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  context.testEventData.translatedText
                                    .wpInsightsText
                              }}
                            />
                          ) : null}
                        </p>
                        <Row>
                          <Col
                            xl={{ span: 6, offset: 3 }}
                            lg={{ span: 6, offset: 3 }}
                            md={{ span: 8, offset: 2 }}
                            sm={12}
                          >
                            <Button
                              block={true}
                              variant="primary"
                              id="event-id-continue-button"
                              onClick={this.handleContinue}
                            >
                              {context.testEventData &&
                              context.testEventData.translatedText
                                ? context.testEventData.translatedText
                                    .continueButton
                                : "Continue"}{" "}
                              <FaArrowRight />
                            </Button>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Footer />
              </Container>
            );
          }
          // If the user has arrived here without any way of fetching the testEventData, redirect back to the
          // eventIdForm
          return <Redirect from="verify" to="/" noThrow={true} />;
        }}
      </TestEventConsumer>
    );
  }
}
