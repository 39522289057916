// tslint:disable jsx-no-lambda
import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import {
  FaGlobe,
  FaRedo,
  FaLock,
  FaArrowLeft,
  FaArrowRight,
  FaPlus
} from "react-icons/fa";

import InternetContent from "./InternetContent";
import browser_favorites_bar from "../CLIKResources/browser_favorites_bar.png";
import WindowHeader from "./WindowHeader";

interface InternetInterface {
  currentSection: number;
  minimized: boolean;
  maximized: boolean;
  handleToggleMinimize(windowName: string): void;
  handleToggleMaximize(windowName: string): void;
  handleCloseWindow(windowName: string): void;
  submitProfileUpdate(
    answerVal: number,
    birthYear: number,
    searchText: string
  ): void;
  handleSetSim2Answer(answerNum: number, answerVal: number): void;
  handleUpdateBirthYear(year: string): void;
  handleCopyActionComplete(): void;
  handleDesktopItemClick(windowName: string): void;
  zIndexValue: number;
  disableSendButton: boolean;
}

const Internet = ({
  currentSection,
  disableSendButton,
  handleCloseWindow,
  handleCopyActionComplete,
  handleDesktopItemClick,
  handleSetSim2Answer,
  handleToggleMaximize,
  handleToggleMinimize,
  handleUpdateBirthYear,
  maximized,
  minimized,
  submitProfileUpdate,
  zIndexValue
}: InternetInterface) => {
  const initialAddressState: { [i: number]: string } = { 1: "" };
  const [addressText, setAddressText] = useState(initialAddressState);
  const [enteredAddress, setEnteredAddress] = useState(initialAddressState);
  const [tabCount, setTabCount] = useState(1);
  const [currentTab, setCurrentTab] = useState(1);

  const submitInput = (e: React.FormEvent) => {
    e.preventDefault();
    setEnteredAddress({
      ...enteredAddress,
      [currentTab]: addressText[currentTab]
    });
  };

  const handleAddressInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const addressEntered = e.target.value.toLowerCase
      ? e.target.value.toLowerCase()
      : e.target.value;
    if (
      addressEntered === "browsehop.com" ||
      addressEntered === "www.browsehop.com" ||
      addressEntered === "https://www.browsehop.com"
    ) {
      handleSetSim2Answer(7, 1);
    }
    setAddressText({ ...addressText, [currentTab]: addressEntered });
  };
  const handleProfileUpdaterButton = () => {
    handleSetSim2Answer(9, 1);
    setAddressText({
      ...addressText,
      [currentTab]: "http://www.profile-updater.com"
    });
    setEnteredAddress({
      ...enteredAddress,
      [currentTab]: "http://www.profile-updater.com"
    });
  };

  const handleSalesReportButton = () => {
    setAddressText({
      ...addressText,
      [currentTab]: "www.acme-intranet.com/sales-report"
    });
    setEnteredAddress({
      ...enteredAddress,
      [currentTab]: "www.acme-intranet.com/sales-report"
    });
  };

  const handleAddTab = () => {
    handleSetSim2Answer(8, 1);
    if (tabCount < 5) {
      setTabCount(tabCount + 1);
      setCurrentTab(tabCount + 1);
      setAddressText({ ...addressText, [tabCount + 1]: "" });
      setEnteredAddress({ ...enteredAddress, [tabCount + 1]: "" });
    }
  };

  const handleSetCurrentTab = (tabNum: number) => {
    setCurrentTab(tabNum);
  };

  const addressToTitleMap: { [k: string]: string } = {
    "http://www.profile-updater.com": "Profile Updater",
    "www.acme-intranet.com/sales-report": "Sales Report"
  };

  if (currentSection === 2) {
    addressToTitleMap["www.browsehop.com"] = "BrowseHop";
    addressToTitleMap["browsehop.com"] = "BrowseHop";
    addressToTitleMap["https://www.browsehop.com"] = "BrowseHop";
  }

  const TabHeaders = [...Array(tabCount)].map((val, i) => {
    return (
      <span
        className="clik-internet-tab"
        key={`new_tab${i + 1}`}
        onClick={() => handleSetCurrentTab(i + 1)}
        style={{
          backgroundColor: "white",
          border: "none",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          marginLeft: i === 0 ? "15px" : "0px"
        }}
      >
        <FaGlobe style={{ marginRight: "5px" }} />
        {addressToTitleMap[enteredAddress[i + 1]]
          ? addressToTitleMap[enteredAddress[i + 1]]
          : "New Tab"}
      </span>
    );
  });

  const Tabs = [...Array(tabCount)].map((val, i) => (
    <InternetContent
      key={`tab_content${i + 1}`}
      currentSection={currentSection}
      enteredAddress={enteredAddress[i + 1]}
      displayTab={i + 1 === currentTab}
      submitProfileUpdate={submitProfileUpdate}
      handleUpdateBirthYear={handleUpdateBirthYear}
      disableSendButton={disableSendButton}
      handleCopyActionComplete={handleCopyActionComplete}
    />
  ));

  return (
    <Col
      onClick={() => handleDesktopItemClick("internet")}
      xl={maximized ? 12 : 7}
      lg={maximized ? 12 : 7}
      md={maximized ? 12 : 6}
      sm={maximized ? 12 : 6}
      xs={maximized ? 12 : 6}
      className="clik-window"
      style={{
        position: "absolute",
        top: maximized ? "0" : "50px",
        left: maximized ? "0" : "150px",
        minWidth: "750px",
        width: "100%",
        minHeight: "370px",
        height: maximized ? "100%" : "",
        backgroundColor: "#FFFFFF",
        display: minimized ? "none" : "",
        zIndex: zIndexValue,
        padding: 0,
        overflow: "hidden"
      }}
    >
      <WindowHeader
        titleText="Internet"
        minimized={minimized}
        maximized={maximized}
        handleToggleMinimize={handleToggleMinimize}
        handleToggleMaximize={handleToggleMaximize}
        handleCloseWindow={handleCloseWindow}
      >
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{
            backgroundColor: "#ddddde",
            position: "relative",
            top: "-30px",
            maxHeight: "50px",
            padding: "0px"
          }}
        >
          {TabHeaders}
          {tabCount < 5 && (
            <button
              className="button-primary-text"
              onClick={handleAddTab}
              style={{
                padding: "3px",
                backgroundColor: "#ddddde",
                marginTop: "17px",
                borderRadius: 0,
                lineHeight: "1.2em",
                borderColor: "#ddddde",
                height: "28px",
                border: 0
              }}
            >
              <FaPlus color="#53565a" />
            </button>
          )}
          <Col
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ padding: "0px" }}
          >
            <Row
              noGutters={true}
              style={{
                backgroundColor: "white",
                height: "100%",
                padding: "5px",
                fontSize: "14px",
                borderBottom: "2px solid #ddddde"
              }}
            >
              <Col
                xl={3}
                lg={3}
                md={3}
                sm={3}
                xs={3}
                style={{
                  paddingLeft: "13px",
                  maxWidth: "120px",
                  paddingTop: "3px"
                }}
              >
                <FaArrowLeft style={{ marginRight: "10px" }} />
                <FaArrowRight style={{ marginRight: "20px" }} />
                <FaRedo style={{ marginRight: "10px" }} />
              </Col>
              <Col xl={9} lg={9} md={9} sm={9} xs={9}>
                <form onSubmit={submitInput} style={{ display: "flex" }}>
                  <span style={{ position: "relative", left: "30px" }}>
                    <FaLock />
                  </span>
                  <input
                    className="form-control"
                    type="text"
                    onChange={handleAddressInput}
                    value={addressText[currentTab]}
                    style={{
                      height: "28px",
                      fontSize: "1em",
                      minWidth: "453px",
                      border: "none",
                      borderRadius: "40px",
                      paddingLeft: "40px",
                      backgroundColor: "#edeeee"
                    }}
                  />
                </form>
              </Col>
            </Row>
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <img
              src={browser_favorites_bar}
              style={{
                position: "relative",
                padding: "8px 4px 6px"
              }}
              alt="browser favorites bar"
            />
            <button
              className="button-primary-text overlay-button"
              style={{ left: "0", width: "70px", height: "25px" }}
            />
            <button
              className="button-primary-text overlay-button"
              style={{ left: "70px", width: "80px", height: "25px" }}
            />
            <button
              className="button-primary-text overlay-button"
              style={{ left: "150px", width: "80px", height: "25px" }}
            />
            <button
              className="button-primary-text overlay-button"
              style={{ left: "230px", width: "70px", height: "25px" }}
            />
            <button
              onClick={handleProfileUpdaterButton}
              className="button-primary-text overlay-button"
              style={{ left: "300px", width: "115px", height: "25px" }}
            />
            <button
              className="button-primary-text overlay-button"
              style={{ left: "415px", width: "65px", height: "25px" }}
            />
            <button
              className="button-primary-text overlay-button"
              style={{ left: "480px", width: "85px", height: "25px" }}
            />
            <button
              onClick={handleSalesReportButton}
              className="button-primary-text overlay-button"
              style={{ left: "565px", width: "100px", height: "25px" }}
            />
          </Col>
          {Tabs}
        </Col>
      </WindowHeader>
    </Col>
  );
};

export default Internet;
