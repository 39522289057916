import Language from "../../Interfaces/Language";

export const fr_fr: Language = {
  common: {
    help: "Vous avez besoin d'aide ?",
    helpCenter: "Pages d'aide",
    expect: "Ce à quoi il faut s'attendre lors de ces tests",
    faq: "FAQ",
    faqLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7869759-questions-frequemment-posees",
    copyright: "Droit d'auteur",
    copyrightNotices: "Avis de droit d'auteur",
    copyrightNoticesLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994364-avis-de-droit-d-auteur",
    terms: "Conditions d'utilisation",
    termsLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994196-conditions-d-utilisation",
    privacy: "Déclaration de confidentialité",
    privacyLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6990051-politique-de-confidentialite",
    dmca: "Politique relative à la DMCA",
    dmcaLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7869758-politique-relative-a-la-dmca",
    accommodations: "Handicap et mesures d'adaptation",
    accommodationsLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994431-handicap-accessibilite",
    technicalSupport: "Assistance technique",
    chatSupport:
      "Pour obtenir une assistance, rendez-vous dans le coin inférieur droit de cette page pour dialoguer en ligne avec notre équipe."
  },
  instruction: {
    eppCopyright:
      "Cette évaluation comprend du matériel reproduit de SalesAP ©1995 et de CSAP ©2002, Multi-Health Systems Inc. Tous droits réservés.",
    salesApCopyright:
      "SalesAP ©1995, Multi-Health Systems Inc. All rights reserved.",
    csapCopyright: "CSAP ©2002, Multi-Health Systems Inc. All rights reserved.",
    wtmaCopyright:
      "WTMA Droit d'auteur © 1997, 1999 Psychological Assessment Resources, Inc."
  }
};
