// eslint-disable-entire-file
import React, { FunctionComponent } from "react";

import style from "./Loader.module.css";

const Loader: FunctionComponent = () => {
  return (
    <svg
      className={style.loader}
      id="loader"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Spinning page Loader</title>
      <path
        d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16ZM4.8 16C4.8 22.1856 9.81441 27.2 16 27.2C22.1856 27.2 27.2 22.1856 27.2 16C27.2 9.81441 22.1856 4.8 16 4.8C9.81441 4.8 4.8 9.81441 4.8 16Z"
        fill="#DDDDDE"
      />
      <path
        d="M16 2.4C16 1.07452 14.9195 -0.0184312 13.6089 0.179656C11.1206 0.555745 8.74425 1.51549 6.68036 2.99445C3.96293 4.94173 1.92372 7.69129 0.849118 10.857C-0.225485 14.0226 -0.281495 17.4454 0.688955 20.6446C1.6594 23.8437 3.60756 26.6585 6.25982 28.6937C8.91207 30.7288 12.1352 31.8821 15.4765 31.9914C18.8178 32.1008 22.1094 31.1608 24.8891 29.3035C27.6688 27.4462 29.7969 24.7648 30.9745 21.636C31.8689 19.2597 32.1809 16.7159 31.9001 14.215C31.7523 12.8978 30.4169 12.137 29.1366 12.4801V12.4801C27.8563 12.8231 27.1244 14.1441 27.1874 15.4681C27.2595 16.9846 27.0228 18.5086 26.4821 19.9452C25.6578 22.1354 24.1682 24.0123 22.2224 25.3125C20.2766 26.6126 17.9725 27.2706 15.6335 27.194C13.2946 27.1174 11.0385 26.3102 9.18187 24.8856C7.32529 23.461 5.96158 21.4906 5.28227 19.2512C4.60295 17.0118 4.64216 14.6159 5.39438 12.3999C6.1466 10.1839 7.57405 8.25921 9.47625 6.89612C10.7239 6.00204 12.1347 5.37904 13.6183 5.05616C14.9135 4.77428 16 3.72548 16 2.4V2.4Z"
        fill="url(#paint0_linear_1206_64)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1206_64"
          x1="16"
          y1="19"
          x2="29"
          y2="19"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2DCCD3" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Loader;
