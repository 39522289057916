import React, { useEffect } from "react";
import ActionButtons from "../../GameComponents/ActionButtons";
import DisconnectModal from "../../GameComponents/DisconnectModal";
import RobotImages from "../../GameComponents/RobotImages";
import TimerBar from "../../../../../Components/TimerBar";
import InterruptModal from "../../../../../Components/InterruptModal";
import controlBox from "../../../../../Resources/images/control_box.png";

interface Props {
  handleAnswerSubmit: (answer: string) => void;
  onExpiration: () => void;
  placedAtTutorial: boolean;
  showImages: boolean;
  pauseTimer: boolean;
  leftRobotImage: string;
  rightRobotImage: string;
  questionStartTime: number;
  doPauseTimer: () => void;
  showInterruptModal?: boolean;
  didReconnectFail?: boolean;
  isTutorialTestBegin?: boolean;
  showGameTutorialStartModal?: boolean;
  isAnswerCorrect?: boolean | null;
  timeFactor: number;
  answerOptions: { [propName: string]: string };
  pressKeys: { pressFKey: string; pressJKey: string };
  disconnectModalDisplayText: {
    testPaused: string;
    errorSavingQuestion: string;
    pleaseWaitReconnecting: string;
    unableToReconnectTryAgainLater: string;
  };
}

const RobotInspectorComponent = ({
  handleAnswerSubmit,
  onExpiration,
  placedAtTutorial,
  showImages,
  pauseTimer,
  leftRobotImage,
  rightRobotImage,
  questionStartTime,
  doPauseTimer,
  showInterruptModal,
  didReconnectFail,
  isTutorialTestBegin,
  showGameTutorialStartModal,
  isAnswerCorrect,
  timeFactor,
  answerOptions,
  pressKeys,
  disconnectModalDisplayText
}: Props) => {
  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    // get initial inner height of window to set they custom css --vh property to be used to size #game-test
    const vh = window.innerHeight * 0.01;
    if (document && document.documentElement) {
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
    return () => window.removeEventListener("keydown", handleKeyPress);
  });

  /* ---------- Event Handlers ---------- */

  const handleKeyPress = (event: KeyboardEvent) => {
    // if placed at the tutorial, do not respond to key press if the test hasn't begun
    if (placedAtTutorial && !isTutorialTestBegin) {
      return;
    }

    let answer = null;
    if (event.key.toUpperCase() === "F") {
      answer = "left";
    }
    if (event.key.toUpperCase() === "J") {
      answer = "right";
    }
    if (answer !== null && !pauseTimer) {
      handleAnswerSubmit(answer);
    }
  };

  const handleButtonClick = (event: any) => {
    // if placed at the tutorial, do not respond to button click if the test hasn't begun
    if (placedAtTutorial && !isTutorialTestBegin) {
      return;
    }

    const answer: string = event.currentTarget.dataset.answer;
    if (!pauseTimer) {
      handleAnswerSubmit(answer);
    }
  };

  return (
    <div id="game-test">
      <TimerBar
        questionStartTime={questionStartTime}
        onExpiration={onExpiration}
        pauseTimer={pauseTimer}
        doPauseTimer={doPauseTimer}
        showGameTutorialStartModal={showGameTutorialStartModal}
        timeFactor={timeFactor}
      />
      <div id="robot-inspector-background">
        <table id="robotInspector" style={{ width: "100%" }}>
          <tbody>
            <tr id="controlBox">
              <td colSpan={2}>
                <img src={controlBox} style={{ zIndex: 1 }} alt="control box" />
              </td>
            </tr>
            <tr>
              {showImages ? (
                <RobotImages
                  leftImage={leftRobotImage}
                  rightImage={rightRobotImage}
                  isAnswerCorrect={isAnswerCorrect}
                />
              ) : null}
            </tr>
            <tr id="conveyor">
              <td colSpan={2} />
            </tr>
          </tbody>
        </table>
        <ActionButtons
          leftLabel={answerOptions.different}
          rightLabel={answerOptions.same}
          handleAnswerSubmit={handleButtonClick}
          isWeighStation={false}
          pressKeys={pressKeys}
        />
      </div>
      {showInterruptModal ? (
        <InterruptModal>
          <DisconnectModal
            didReconnectFail={true}
            disconnectModalDisplayText={disconnectModalDisplayText}
          />
        </InterruptModal>
      ) : null}
    </div>
  );
};

export default RobotInspectorComponent;
