import React, { Component } from "react";
import Rectangle from "./Rectangle";

interface Props {
  language: string;
  isMobile: boolean;
  isIPad13: boolean;
}

export default class ColorDisplayRow extends Component<Props> {
  render() {
    const { language, isMobile, isIPad13 } = this.props;
    return (
      <div
        className="shapes-row"
        style={{ marginTop: isMobile || isIPad13 ? "0px" : "" }}
      >
        <div className="shapes">
          <Rectangle
            fill="#FFF"
            text={language === "english" ? "White" : "Blanco"}
            isMobile={isMobile}
            isIPad13={isIPad13}
          />
        </div>
        <div className="shapes">
          <Rectangle
            fill="#B7B7B7"
            text={language === "english" ? "Light Grey" : "Gris claro"}
            isMobile={isMobile}
            isIPad13={isIPad13}
          />
        </div>
        <div className="shapes">
          <Rectangle
            fill="#434343"
            text={language === "english" ? "Dark Grey" : "Gris oscuro"}
            isMobile={isMobile}
            isIPad13={isIPad13}
          />
        </div>
        <div
          className="shapes"
          style={{ marginRight: isMobile || isIPad13 ? "0px" : "" }}
        >
          <Rectangle
            fill="#000"
            text={language === "english" ? "Black" : "Negro"}
            isMobile={isMobile}
            isIPad13={isIPad13}
          />
        </div>
      </div>
    );
  }
}
