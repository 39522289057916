import React from "react";
import { Form, Dropdown, DropdownButton } from "react-bootstrap";

interface PhoneNumberFieldProps {
  id: string;
  label?: string;
  value?: any;
  maxLength?: number;
  handleInput: (
    event: React.SyntheticEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  placeholder?: string;
  fieldRequired?: boolean;
  handlePhoneCountryCodeDropdown: (value: string) => void;
  testTakerPhoneCountryCode: string;
  validated: string | null;
}

const PhoneNumberField = ({
  id,
  label,
  value,
  handleInput,
  maxLength,
  placeholder,
  fieldRequired,
  handlePhoneCountryCodeDropdown,
  testTakerPhoneCountryCode,
  validated
}: PhoneNumberFieldProps) => {
  // will need to add library to handle other countries once we add support for them
  // Only works for US phone numbers right now
  const isValidPhoneNumber = /^\+1 \(\d{3}\) \d{3}-\d{4}$/.test(value);

  return (
    <Form.Group className="mb-2">
      <Form.Label htmlFor={id} className="label">
        {fieldRequired && (
          <>
            <i aria-hidden="true">*</i>
            <span className="sr-only">Required</span>
          </>
        )}
        {label}
      </Form.Label>
      <div className="d-flex align-items-center">
        <DropdownButton
          variant="outline-secondary"
          onSelect={handlePhoneCountryCodeDropdown}
          id="phoneNumberLocaleDropdown"
          title={testTakerPhoneCountryCode}
          className="mr-2"
        >
          <Dropdown.Item
            key="US"
            eventKey="US"
            className="d-flex justify-content-between"
          >
            <div className="mr-3">United States (US)</div>
            <div>+1</div>
          </Dropdown.Item>
        </DropdownButton>
        <div className="flex-grow-1">
          <Form.Control
            id={id}
            type="text"
            value={value || ""}
            onChange={handleInput}
            maxLength={maxLength}
            placeholder={placeholder}
            required={fieldRequired}
            isInvalid={validated && value && !isValidPhoneNumber}
            aria-describedby="helper-text"
            className="phone-number-input"
          />
          {validated && value && !isValidPhoneNumber ? (
            <p className="mt-1 mb-0 error-message">
              Please enter a valid phone number
            </p>
          ) : null}
        </div>
      </div>
    </Form.Group>
  );
};

export default PhoneNumberField;
