import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Timer from "../../../../Components/Timer";
import { TestEventContext } from "../../../../Contexts/TestEventContext";
import { nodeApiURL } from "../../../../utils/constants";
import CLIKInterruptModal from "../CLIKComponents/CLIKInterruptModal";
import Desktop from "../CLIKComponents/Desktop";
import TopInstruction from "../CLIKComponents/TopInstruction";

import {
  CLIKSim2AnswerData,
  Sim2Answer
} from "../../../../Interfaces/AnswerData";

interface CLIKSimulation2Props {
  handleGoToNextSection: () => void;
  testEventId: number;
  simulationTimeAllowed: number;
  currentSection: number;
  subTestId: string;
}

// because the backend is still expecting 'birthYear' as the property name
// thus even though the browsehop search question has been updated to `the year of the first modern olympics`
// we don't want to change the state property name
interface CLIKSimulation2State {
  answer: Sim2Answer;
  timeStarted: Date | null;
  timeTaken: number | null;
  showInterruptModal: boolean;
  pauseTimer: boolean;
  failCounter: number;
  didReconnectFail: boolean;
  showSimulation: boolean;
  birthYear: string;
  answer10Val: number | null;
  disableSendButton: boolean;
  searchText: string;
}

export default class CLIKSimulation2 extends React.PureComponent<
  CLIKSimulation2Props,
  CLIKSimulation2State
> {
  state: CLIKSimulation2State = {
    answer: {
      6: {
        answer: 0
      },
      7: {
        answer: 0
      },
      8: {
        answer: 0
      },
      9: {
        answer: 0
      },
      10: {
        answer: 0
      }
    },
    timeStarted: null,
    timeTaken: null,
    showInterruptModal: false,
    pauseTimer: false,
    failCounter: 0,
    didReconnectFail: false,
    showSimulation: false,
    birthYear: "",
    answer10Val: null,
    disableSendButton: false,
    searchText: ""
  };

  // define the context type so that we can use this.context throughout the class
  static readonly contextType = TestEventContext;

  componentDidMount() {
    requestAnimationFrame(() => {
      window.scrollTo(0, 0);
    });
  }

  handleStart = () => {
    // start the timer
    if (this.state.timeStarted === null) {
      this.setState({ timeStarted: new Date(), showSimulation: true });
    }
    // show the simulation
  };

  handleSetSim2Answer = (answerNum: number, answerVal: number) => {
    this.setState({
      answer: { ...this.state.answer, [answerNum]: { answer: answerVal } }
    });
  };

  submitProfileUpdate = (
    answerVal: number,
    birthYear: number,
    searchText: string
  ) => {
    this.setState({ disableSendButton: true });
    // calculate time to complete (in seconds);
    const finishTime: number = Date.now();
    const timeTaken =
      this.state.timeTaken !== null
        ? this.state.timeTaken
        : this.state.timeStarted !== null
        ? (+finishTime - +this.state.timeStarted) / 1000
        : 0;
    this.setState({ timeTaken, answer10Val: answerVal, searchText });
    // create answerData object and send that data to redis, and then end the subTest
    const answerData: CLIKSim2AnswerData = {
      testEventId: this.props.testEventId,
      subTestId: this.props.subTestId,
      timeTaken,
      sim: { ...this.state.answer, 10: { answer: answerVal } },
      birthYear,
      searchText
    };
    this.sendCLIKSim2DataToRedis(answerData);
  };

  handleRetryButton = () => {
    this.setState({ didReconnectFail: false }, () => {
      this.handleRedisFailure();
    });
  };

  handleRedisFailure = () => {
    const { failCounter, birthYear, answer10Val, searchText } = this.state;
    this.setState(state => ({
      showInterruptModal: true,
      pauseTimer: true,
      failCounter: state.failCounter + 1
    }));
    const rootElement = document.getElementById("root");
    if (rootElement !== null) {
      rootElement.style.filter = "blur(10px)";
    }
    if (failCounter < 30) {
      setTimeout(
        () =>
          this.submitProfileUpdate(
            answer10Val || 0,
            Number(birthYear.trim()),
            searchText
          ),
        1000
      );
    } else {
      this.setState({ didReconnectFail: true, failCounter: 0 });
    }
  };

  sendCLIKSim2DataToRedis = async (
    answerData: CLIKSim2AnswerData
  ): Promise<boolean> => {
    const rootElement = document.getElementById("root");
    try {
      const response = await fetch(`${nodeApiURL}/sendCLIKSim2Answer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.context.token
        },
        body: JSON.stringify(answerData)
      });
      const responseData = await response.json();
      if (responseData.submitted !== "OK") {
        this.handleRedisFailure();
      } else {
        if (rootElement !== null) {
          rootElement.style.filter = "none";
        }
        this.props.handleGoToNextSection();
      }
    } catch (error) {
      this.handleRedisFailure();
    }
    return true;
  };

  handleUpdateBirthYear = (year: string) => {
    this.setState({ birthYear: year });
  };

  handleTimerExpire = () => {
    const answerVal = Number(this.state.birthYear.trim()) === 1896 ? 1 : 0;
    this.submitProfileUpdate(
      answerVal,
      Number(this.state.birthYear.trim()),
      this.state.searchText
    );
  };

  render() {
    const { simulationTimeAllowed } = this.props;
    return (
      <Container fluid={true}>
        <Row>
          <TopInstruction
            showSimulation={this.state.showSimulation}
            handleStart={this.handleStart}
            simulationTimeAllowed={simulationTimeAllowed}
          />
          {this.state.showSimulation && (
            <Col style={{ marginTop: "6px" }}>
              <Timer
                seconds={simulationTimeAllowed}
                onCompletion={this.handleTimerExpire}
                startTimer={true}
                color="black"
                pauseTimer={this.state.pauseTimer}
                placedAt="clikv2"
              />
            </Col>
          )}
        </Row>
        <Row>
          <ol>
            <li>Launch the Internet browser on the simulated desktop</li>
            <li>Maximize the browser window</li>
            <li>Use the address bar to go to: browsehop.com</li>
            <li>
              Perform a search to find the year of the first modern olympics
            </li>
            <li>Open a new tab in the browser window</li>
            <li>
              Go to the favorite named <b>Profile Updater</b>
            </li>
            <li>
              Fill out the form using the information learned from your search
              results
            </li>
            <li>Submit the form</li>
          </ol>
        </Row>
        {this.state.showSimulation && (
          <div style={{ marginBottom: "50px" }}>
            <Desktop
              handleSendEmail={() => {}}
              submitProfileUpdate={this.submitProfileUpdate}
              handleSetSim1Answer={() => {}}
              handleSetSim2Answer={this.handleSetSim2Answer}
              handleSetSim3Answer={() => {}}
              handleUpdateBirthYear={this.handleUpdateBirthYear}
              handleUpdateFields={() => {}}
              handleCopyActionComplete={() => {}}
              handleUpdateCurrentContact={() => {}}
              handleUpdateSalesSummaryText={() => {}}
              handleSendSalesSummary={() => {}}
              disableSendButton={this.state.disableSendButton}
              currentSection={this.props.currentSection}
            />
          </div>
        )}
        {this.state.showInterruptModal && (
          <CLIKInterruptModal
            handleRetryButton={this.handleRetryButton}
            didReconnectFail={this.state.didReconnectFail}
          />
        )}
      </Container>
    );
  }
}
